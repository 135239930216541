import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { countriesOptions } from 'src/app/data/countries';
import { statesOptions } from 'src/app/data/states';
import { SignupService } from 'src/app/services/signup/signup.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import { AuthService } from 'src/app/services/auth/auth.service';
import DDIOptions from 'src/app/data/ddi';
import Utils, { GetHelper, UtilsValidators } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-signup-unified-person-complementary-professional',
  templateUrl:
    './signup-unified-person-complementary-professional.component.html',
  styleUrls: [
    './signup-unified-person-complementary-professional.component.scss',
  ],
})
export class SignupUnifiedPersonComplementaryProfessionalComponent
  implements OnInit, OnDestroy
{
  DDIOptions = DDIOptions;

  form = this.formBuilder.group({
    cnpj: [null, [UtilsValidators.cnpj]],
    corporate_name: [null, []],
    occupation: [null, []],
    corporate_email: [null, [Validators.email]],

    phone_ddi: ['55', []],
    phone_number: [null, [UtilsValidators.telefone]],
    branch: [null, []],

    cep: [null, [UtilsValidators.cep]],
    public_place: [null, []],
    number: [null, []],
    complement: [null, []],
    district: [null, []],
    city: [null, []],
    uf: [null, []],
    country: ['1', []],
  });

  zipcodeFetched = false;

  states = statesOptions;
  countries = countriesOptions;

  isForeign = false;

  data: ProfessionalResponse;

  loading = false;

  event: any;

  foundCep = false;

  loadingContent = false;
  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private utilsService: UtilsService,
    private maskPipe: MaskPipe,
    private authService: AuthService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.getDataAndFillForm();

    this.event = this.signupService.sendComplementaryPersonData.subscribe(
      (value) => {
        if (value === 'complementaryProfessional') {
          this.sendData();
        }
      }
    );

    this.signupService.setPersonFormStatus(
      'complementaryProfessional',
      this.form.status === 'VALID'
    );

    this.setForeign();

    this.form.statusChanges.subscribe((status) => {
      this.signupService.setPersonFormStatus(
        'complementaryProfessional',
        status === 'VALID'
      );
    });

    this.form.controls.cep.valueChanges.subscribe((zipcode) => {
      if (zipcode.length === 9) {
        this.form.controls.public_place.setValidators([Validators.required]);
        this.form.controls.number.setValidators([Validators.required]);
        this.form.controls.district.setValidators([Validators.required]);
        this.form.controls.city.setValidators([Validators.required]);
        this.form.controls.uf.setValidators([Validators.required]);
        this.form.controls.country.setValidators([Validators.required]);
      } else {
        this.form.controls.public_place.setValidators([]);
        this.form.controls.number.setValidators([]);
        this.form.controls.district.setValidators([]);
        this.form.controls.city.setValidators([]);
        this.form.controls.uf.setValidators([]);
        this.form.controls.country.setValidators([]);
      }

      if (zipcode.length === 0) {
        this.form.controls.public_place.setValue(null);
        this.form.controls.number.setValue(null);
        this.form.controls.district.setValue(null);
        this.form.controls.city.setValue(null);
        this.form.controls.uf.setValue(null);
        this.form.controls.country.setValue('1');
      }

      this.form.controls.public_place.updateValueAndValidity();
      this.form.controls.number.updateValueAndValidity();
      this.form.controls.district.updateValueAndValidity();
      this.form.controls.city.updateValueAndValidity();
      this.form.controls.uf.updateValueAndValidity();
      this.form.controls.country.updateValueAndValidity();
    });

    this.signupService.fillFormEvent.subscribe(() => {
      let payload: any = {
        cnpj: this.maskPipe.transform(fakerbr.br.cnpj(), '00.000.000/0000-00'),
        corporate_name: fakerbr.company.companyName(),
        occupation: fakerbr.name.jobDescriptor(),
        corporate_email: fakerbr.internet.email(),

        phone_ddi: '55',
        phone_number: this.maskPipe.transform(
          '139' +
            String(fakerbr.random.number({ min: 11111111, max: 99999999 })),
          '(00) 00000-0000'
        ),
        branch: null,
      };

      if (!this.zipcodeFetched) {
        const zipcode = fakerbr.address.zipCodeValidByState('SP');

        const formatted = this.maskPipe.transform(zipcode, '00000-000');

        payload = {
          ...payload,
          cep: formatted,
          number: fakerbr.random.number({ min: 1, max: 1000 }),
          complement: 'Casa',
          country: '1',
        };

        this.searchZipcode({
          target: { value: formatted },
        });

        this.zipcodeFetched = true;
      }

      this.form.patchValue(payload);
    });
  }

  setForeign() {
    if (this.authService.user?.nationality?.country !== 'Brasil') {
      this.isForeign = true;
    }
  }

  async searchZipcode(event: any) {
    this.loading = true;

    const zipcode = event.target.value;

    try {
      const zipcodeRes = await this.utilsService.getZipcode(zipcode);

      if (zipcodeRes) {
        this.form.patchValue({
          public_place: zipcodeRes.logradouro,
          district: zipcodeRes.bairro,
          city: zipcodeRes.localidade,
          uf: zipcodeRes.uf,
          country: '1',
        });
        this.foundCep = true;

        this.zipcodeFetched = true;
      } else {
        this.foundCep = false;
        this.zipcodeFetched = zipcode.length === 9;
        Utils.resetZipcodeFields(this.form);
      }
    } catch (error) {
      this.zipcodeFetched = false;
      console.error(error);
      this.foundCep = false;
    }
    this.loading = false;
  }

  async sendData() {
    this.signupService.isSendindData = true;

    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        cnpj: Utils.onlyNumbers(valuesHelper.get('cnpj')),
        corporate_name: valuesHelper.get('corporate_name'),
        occupation: valuesHelper.get('occupation'),
        corporate_email: valuesHelper.get('corporate_email'),

        // ramal
      };

      if (valuesHelper.get('cep')) {
        payload['address'] = {
          cep: valuesHelper.get('cep'),
          public_place: valuesHelper.get('public_place'),
          number: Number(valuesHelper.get('number')),
          complement: valuesHelper.get('complement'),
          district: valuesHelper.get('district'),
          city: valuesHelper.get('city'),
          uf: valuesHelper.get('uf'),
          type: 1,
          address_type: 'professional',
        };
      }

      if (valuesHelper.get('cep')) {
        payload['phone'] = {
          type: 'phone',
          code: Number(valuesHelper.get('phone_ddi')),
          number: Utils.onlyNumbers(valuesHelper.get('phone_number')),
          branch: 0,
        };
      }

      console.table(valuesHelper.getRemainingObject());

      if (this.data) {
        await this.api.put<ApiResponse<ProfessionalResponse>>({
          route: 'api/registration/professional/me/',
          token: true,
          body: payload,
        });
      } else {
        await this.api.post<ApiResponse<ProfessionalResponse>>({
          route: 'api/registration/professional/',
          token: true,
          body: payload,
        });
      }

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');

      this.signupService.setComplementaryPersonStep(
        'complementaryRepresentatives'
      );
      this.signupService.changePersonStepEvent.emit('representatives');
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }

    this.signupService.isSendindData = false;
  }

  async getDataAndFillForm() {
    this.loadingContent = true;
    try {
      const { data } = await this.api.get<ApiResponse<ProfessionalResponse>>({
        route: 'api/registration/professional/me/',
        token: true,
      });

      this.data = data;

      this.signupService.setPersonFormStatus('complementaryProfessional', true);

      this.form.patchValue({
        ...data,
        ...data.address,
        cnpj: this.maskPipe.transform(data.cnpj, '00.000.000/0000-00'),
        phone_ddi: String(data.phone.code),
        phone_number: this.maskPipe.transform(
          data.phone.number,
          '(00) 00000-0000'
        ),
      });

      if (data.address) {
        this.zipcodeFetched = true;
        this.foundCep = true;
      }
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
    this.loadingContent = false;
  }
}
