<div class="dashboard">
    <div class="row w-100">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card">
                <ng-container *ngIf="!loading">
                    <app-back-button route="/app/settings"></app-back-button>
                </ng-container>

                <div
                    class=" dash-card-header d-flex align-items-start justify-content-between">
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            width: '180px',
                            height: '25px'
                          }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <h2>Fundos de zeragem</h2>
                    </ng-container>


                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                    width: '180px',
                    height: '40px'
                  }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <app-button (click)="addFundToggle(true)"
                            class="add-button">
                            <i class="fas fa-plus-circle mr-2"></i>Adicionar
                            Fundo
                            de zeragem
                        </app-button>
                    </ng-container>
                </div>

                <div class="row no-gutters">
                    <div class="col-12 padding-container dash-card-body">

                        <div clsas="styled-scroll">
                            <ng-container *ngIf="loading">
                                <table #table class="skeleton-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <ngx-skeleton-loader [theme]="{
                                                height: '15px',
                                                width: '125px',
                                                marginBottom:'0'                            
                                              }">
                                                </ngx-skeleton-loader>
                                            </th>

                                            <th>
                                                <ngx-skeleton-loader [theme]="{
                                                height: '15px',
                                                width: '125px',
                                                marginBottom:'0'                            
                                              }">
                                                </ngx-skeleton-loader>
                                            </th>

                                            <th>
                                                <ngx-skeleton-loader [theme]="{
                                                height: '15px',
                                                width: '125px',
                                                marginBottom:'0'                            
                                              }">
                                                </ngx-skeleton-loader>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let number of [0,1,2]">
                                            <td>
                                                <div class="dash-card-item">
                                                    <div
                                                        class="dash-card-item-initials">
                                                        <span>
                                                            <ngx-skeleton-loader
                                                                appearance="circle"
                                                                [theme]="{
                                                            height: '48px',
                                                            width: '48px'                            
                                                          }">
                                                            </ngx-skeleton-loader>
                                                        </span>
                                                    </div>

                                                    <app-spacer
                                                        [right]="16"></app-spacer>

                                                    <div
                                                        class="dash-card-item-content">
                                                        <div
                                                            class="dash-card-item-content-title">
                                                            <ngx-skeleton-loader
                                                                [theme]="{
                                                                height: '21px',
                                                                width: '130px',
                                                                margin:'0'                        
                                                              }">
                                                            </ngx-skeleton-loader>
                                                        </div>
                                                        <div
                                                            class="dash-card-item-content-subtitle">
                                                            <ngx-skeleton-loader
                                                                [theme]="{
                                                                height: '18px',
                                                                width: '110px'                            
                                                              }">
                                                            </ngx-skeleton-loader>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <ngx-skeleton-loader [theme]="{
                                                height: '15px',
                                                width: '30px',
                                                marginBottom:'0'
                                              }">
                                                </ngx-skeleton-loader>
                                            </td>

                                            <td>
                                                <ngx-skeleton-loader [theme]="{
                                                height: '15px',
                                                width: '150px',
                                                marginBottom:'0'
                                              }">
                                                </ngx-skeleton-loader>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container>

                            <table class="bordered" *ngIf="!loading">
                                <thead>
                                    <tr class="alternative-thead">
                                        <th>Fundos</th>
                                        <th>Vínculos</th>
                                        <th>Última atualização</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of filteredFunds">
                                        <td>
                                            <div class="dash-card-item">
                                                <div
                                                    class="dash-card-item-initials">
                                                    <span>{{getInitials(item)}}</span>
                                                </div>

                                                <app-spacer
                                                    [right]="16"></app-spacer>

                                                <div
                                                    class="dash-card-item-content">
                                                    <div
                                                        class="dash-card-item-content-title">
                                                        {{item.corporate_name}}
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-subtitle">
                                                        {{item.document}}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            {{item.funds.length}}
                                        </td>

                                        <td>
                                            <span class="status">
                                                {{item.updated | date:
                                                'dd/MM/yyyy \'às\' HH:mm'}}
                                            </span>
                                        </td>

                                        <td>
                                            <div
                                                class="d-flex justify-content-end">
                                                <button (click)="setFund(item)">
                                                    <i class="fas fa-pen"></i>
                                                </button>

                                                <app-spacer
                                                    [right]="19"></app-spacer>
                                                <button
                                                    (click)="showLinks(item)">
                                                    <svg-icon
                                                        src="assets/images/check-list.svg"></svg-icon>
                                                </button>

                                                <app-spacer
                                                    [right]="19"></app-spacer>
                                                <button
                                                    (click)="showFundDetails(item)">
                                                    <i class="fas fa-eye"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="funds.length === 0">
                                        <td colspan="4">
                                            <span
                                                class="dash-card-item-content-subtitle">Nenhum
                                                fundo de zeragem
                                                adicionado</span>
                                        </td>
                                    </tr>

                                </tbody>
                                <app-spacer [bottom]="28"></app-spacer>
                            </table>

                            <div *ngIf="funds.length === 0">
                                <app-spacer [bottom]="30"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dash-card-pagination">
                <app-pagination [data]="funds" [perPage]="50" [show]="!loading"
                    (onDataChange)="setFilteredData($event)"
                    [dashPagination]="true">
                </app-pagination>
            </div>

        </div>
    </div>
</div>

<app-side-modal [active]="addFund" (onClose)="closeModal()"
    [title]="fund?'Atualizar Fundo de zeragem':'Adicionar Fundo de zeragem'">
    <form [formGroup]="fundForm">
        <div class="row">
            <div class="col-12">
                <app-masked-input label="CNPJ" [group]="fundForm"
                    controlName="document" placeholder="12.345.678/0001-99"
                    mask="00.000.000/0000-00">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Razão social" [group]="fundForm"
                    controlName="corporate_name" [isUpperCase]="true"
                    placeholder="ACME FUNDO DE ZERAGEM LTDA">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Nome gestor" [group]="fundForm"
                    controlName="manager_name" placeholder="Digite">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <app-input label="E-mail gestor" [group]="fundForm"
                    controlName="manager_email" placeholder="email@mail.com.br">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-5">
                        <app-select [group]="fundForm" controlName="manager_ddi"
                            [items]="DDIOptions" label="DDI">
                        </app-select>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-7">
                        <app-input label="Celular" [group]="fundForm"
                            controlName="manager_phone" placeholder="Digite">
                        </app-input>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>
                </div>
            </div>
        </div>

        <app-spacer [bottom]="48"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="closeModal()" class="add-button"
                    [outline]="true" [bordless]="true">
                    Cancelar
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="addZeroingFund()" class="add-button"
                        [disabled]="getDisabled()">
                        {{fund ?'Atualizar' :'Adicionar'}} Fundo de zeragem
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>

<app-side-modal [active]="fundDetails" (onClose)="closeModal()"
    [title]="fund ? fund.corporate_name : ''">
    <div class="row" *ngIf="fund">
        <div class="col-12">
            <div class="dash-card-item-content-title">
                {{fund.document}}
            </div>
            <div class="dash-card-item-content-subtitle">
                CNPJ
            </div>
            <app-spacer [bottom]="16"></app-spacer>
        </div>

        <div class="col-12">
            <div class="dash-card-item-content-title">
                {{fund.corporate_name}}
            </div>
            <div class="dash-card-item-content-subtitle">
                RAZÃO SOCIAL
            </div>
            <app-spacer [bottom]="34"></app-spacer>
        </div>

        <div class="col-12">
            <app-label class="font-weight-bold" label="Informações do gestor">
            </app-label>
            <app-spacer [bottom]="8"></app-spacer>
        </div>

        <div class="col-12">
            <div class="dash-card-item-content-title">
                {{fund.manager_name}}
            </div>
            <div class="dash-card-item-content-subtitle">
                NOME
            </div>
            <app-spacer [bottom]="16"></app-spacer>
        </div>

        <div class="col-12 col-md-6">
            <div class="dash-card-item-content-title">
                {{fund.manager_email}}
            </div>
            <div class="dash-card-item-content-subtitle">
                E-MAIL
            </div>
        </div>

        <div class="col-12 col-md-6">
            <div class="dash-card-item-content-title">
                +55 {{fund.manager_phone}}
            </div>
            <div class="dash-card-item-content-subtitle">
                CELULAR
            </div>
        </div>
    </div>
</app-side-modal>

<app-side-modal [active]="fundLinks" (onClose)="closeModal()"
    [title]="fund ? linkLabel(fund) : ''" padding="0">
    <div class="dash-card links" *ngIf="fund">
        <div class="row no-gutters">
            <div class="col-12 padding-container dash-card-body">
                <div clsas="styled-scroll">
                    <table class="bordered"
                        *ngIf="fund.funds.length > 0; else noResult">
                        <tbody>
                            <tr *ngFor="let item of fund.funds">
                                <td>
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-initials">
                                            <span>{{getFundInitials(item.name)}}</span>
                                        </div>

                                        <app-spacer [right]="16"></app-spacer>

                                        <div class="dash-card-item-content">
                                            <div
                                                class="dash-card-item-content-title">
                                                {{item.name}}
                                            </div>
                                            <div
                                                class="dash-card-item-content-subtitle">
                                                {{item.document}}
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div class="d-flex justify-content-end">
                                        <a
                                            [routerLink]="['/app/products/fund/approval/', item.id]">
                                            <i class="fas fa-eye"></i>
                                        </a>
                                    </div>
                                </td>
                            </tr>

                            <tr *ngIf="funds.length === 0">
                                <td colspan="3">
                                    <span
                                        class="dash-card-item-content-subtitle">Nenhum
                                        cedente
                                        adicionado</span>
                                </td>
                            </tr>

                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>

                    <ng-template #noResult>
                        <span
                            class="dash-card-item-content-title text-center d-block pb-4 pt-4 mb-0">Nenhum
                            fundo
                            vinculado</span>
                    </ng-template>

                    <div *ngIf="funds.length === 0">
                        <app-spacer [bottom]="30"></app-spacer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-side-modal>