import { Component, Input, OnInit } from '@angular/core';
import { SignupService } from 'src/app/services/signup/signup.service';
import { MaskPipe } from 'ngx-mask';

@Component({
  selector: 'app-dashboard-approval-powers',
  templateUrl: './dashboard-approval-powers.component.html',
  styleUrls: ['./dashboard-approval-powers.component.scss'],
})
export class DashboardApprovalPowersComponent implements OnInit {
  @Input() approval: any;

  integrantsArr: any[] = [];

  columns: TableColumnList<Groups>[] = [
    {
      name: 'Grupos',
      cell: (row) => row.name,
    },
    {
      name: 'Pessoas',
      cell: (row) => this.formatGroupIntegrants(row.integrants),
    },
  ];

  powerGroupColumns: TableColumnList<RelevancePowerValues>[] = [
    {
      name: 'Grupo principal',
      cell: (row) => this.formatGroup([row.main_group]),
    },
    {
      name: 'Grupo secundário',
      cell: (row) => this.formatGroup(row.secondary_group),
    },
    {
      name: 'Assinaturas obrigatórias',
      cell: (row) => row.required_signs_quantity,
    },
    {
      name: 'Assinaturas necessárias',
      cell: (row) => row.necessary_signs_quantity,
    },
    {
      name: 'Poderes',
      cell: (row) => this.formatPower(row.power_type),
      size: 1.5,
    },
    {
      name: '',
      cell: (row) =>
        `<div class="icon-container">
              <div class="power-details row-${row.id}">
                  <i class="fas fa-eye ml-auto"></i>
              </div>
          </div> `,
    },
  ];

  powerGroupDetails = false;
  selectedPowerGroup: Partial<RelevancePowerValues> = null;

  data: Groups[] = [];
  powerGroupData: RelevancePowerValues[] = [];
  representatives: any[] = [];

  constructor(
    private signupService: SignupService,
    private maskPipe: MaskPipe
  ) {}

  ngOnInit(): void {
    this.data = this.approval.register.signature_group;
    this.powerGroupData = this.approval.register.signature_power;
    this.representatives = this.approval.register.representatives;
  }

  ngAfterViewInit() {
    this.setHandlers();
  }

  formatPower(power: number[]) {
    let powersArr: any[] = [];
    let authorizations = this.signupService.signatureAuthorizationChoices;

    if (power.length > 0) {
      authorizations.map((item) => {
        if (power.includes(Number(item.value))) {
          powersArr.push(item.label);
        }
      });
    }

    return powersArr ? powersArr.join(', ') : '';
  }

  formatGroup(groupIds: any[]) {
    let groupsArr: any[] = [];

    if (this.data.length > 0) {
      this.data.map((item) => {
        if (groupIds.includes(item.id)) {
          groupsArr.push(item.name);
        }
      });
    }

    return groupsArr.length > 0 ? groupsArr.join(', ') : '-';
  }

  formatPowerIntegrants(integrantsId: number[]) {
    let usersArr: any[] = [];
    if (this.representatives.length > 0) {
      this.representatives.map((item) => {
        if (integrantsId.includes(item.id)) {
          usersArr.push(this.formatCpf(item.cpf_cnpj) + ' | ' + item.full_name);
        }
      });
    }

    return usersArr ? usersArr.join(', ') : '';
  }

  formatGroupIntegrants(integrantsId: number[]) {
    let usersArr: any[] = [];

    if (this.representatives.length > 0) {
      this.representatives.map((item) => {
        if (integrantsId.includes(item.id)) {
          usersArr.push(this.formatCpf(item.cpf_cnpj) + ' | ' + item.full_name);
        }
      });
    }

    return usersArr ? usersArr.join(', ') : '';
  }

  setHandlers() {
    const power = document.querySelectorAll('.power-details');

    power.forEach((button) => {
      button.addEventListener('click', (event) => {
        const el: any = event.currentTarget;
        const idClass: any = el.classList.item(1);
        const id = idClass.split('-')[1];

        const finded = this.powerGroupData.find((item: any) => {
          if (item.id) {
            return Number(item.id) === Number(id);
          }

          return false;
        });

        if (finded) {
          this.openModal(finded);
        }
      });
    });
  }

  closeModal() {
    this.powerGroupDetails = false;
  }

  openModal(item: Partial<RelevancePowerValues>) {
    this.powerGroupDetails = true;
    this.selectedPowerGroup = item;
  }

  formatCpf(value: string) {
    const newValue = this.maskPipe.transform(value, '000.000.000-00');

    return newValue;
  }

  formatMaxValue(item) {
    if (item.max_sign_amount) {
      return item.max_sign_amount.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    } else {
      return 'Valor ilimitado';
    }
  }
}
