<div class="dashboard">
    <div class="row w-100">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card">
                <ng-container *ngIf="!loadingAudit">
                    <app-back-button route="/app/settings"></app-back-button>
                </ng-container>

                <div
                    class=" dash-card-header d-flex align-items-start justify-content-between">
                    <ng-container *ngIf="loadingAudit">
                        <ngx-skeleton-loader [theme]="{
                            width: '180px',
                            height: '25px'
                          }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loadingAudit">
                        <h2>Auditoria</h2>
                    </ng-container>

                    <ng-container *ngIf="loadingAudit">
                        <ngx-skeleton-loader [theme]="{
                width: '180px',
                height: '40px'
              }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loadingAudit">
                        <app-button (click)="addAuditToggle(true)"
                            class="add-button">
                            <i class="fas fa-plus-circle mr-2"></i>Adicionar
                            nova
                            auditoria
                        </app-button>
                    </ng-container>
                </div>

                <div class="row no-gutters">
                    <div class="col-12 padding-container dash-card-body">

                        <div clsas="styled-scroll">
                            <ng-container *ngIf="loadingAudit">
                                <table #table class="skeleton-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <ngx-skeleton-loader [theme]="{
                                                height: '15px',
                                                width: '125px',
                                                marginBottom:'0'                            
                                              }">
                                                </ngx-skeleton-loader>
                                            </th>

                                            <th>
                                                <ngx-skeleton-loader [theme]="{
                                                height: '15px',
                                                width: '125px',
                                                marginBottom:'0'                            
                                              }">
                                                </ngx-skeleton-loader>
                                            </th>

                                            <th>
                                                <ngx-skeleton-loader [theme]="{
                                                height: '15px',
                                                width: '125px',
                                                marginBottom:'0'                            
                                              }">
                                                </ngx-skeleton-loader>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let number of [0,1,2]">
                                            <td>
                                                <div class="dash-card-item">
                                                    <div
                                                        class="dash-card-item-initials">
                                                        <span>
                                                            <ngx-skeleton-loader
                                                                appearance="circle"
                                                                [theme]="{
                                                            height: '48px',
                                                            width: '48px'                            
                                                          }">
                                                            </ngx-skeleton-loader>
                                                        </span>
                                                    </div>

                                                    <app-spacer
                                                        [right]="16"></app-spacer>

                                                    <div
                                                        class="dash-card-item-content">
                                                        <div
                                                            class="dash-card-item-content-title">
                                                            <ngx-skeleton-loader
                                                                [theme]="{
                                                                height: '21px',
                                                                width: '130px',
                                                                margin:'0'                        
                                                              }">
                                                            </ngx-skeleton-loader>
                                                        </div>
                                                        <div
                                                            class="dash-card-item-content-subtitle">
                                                            <ngx-skeleton-loader
                                                                [theme]="{
                                                                height: '18px',
                                                                width: '110px'                            
                                                              }">
                                                            </ngx-skeleton-loader>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <ngx-skeleton-loader [theme]="{
                                                height: '15px',
                                                width: '30px',
                                                marginBottom:'0'
                                              }">
                                                </ngx-skeleton-loader>
                                            </td>

                                            <td>
                                                <ngx-skeleton-loader [theme]="{
                                                height: '15px',
                                                width: '150px',
                                                marginBottom:'0'
                                              }">
                                                </ngx-skeleton-loader>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container>

                            <table class="bordered" *ngIf="!loadingAudit">
                                <thead>
                                    <tr class="alternative-thead">
                                        <th>Empresas</th>
                                        <th>Vínculos</th>
                                        <th>Última atualização</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of filteredAudits">
                                        <td>
                                            <div class="dash-card-item">
                                                <div
                                                    class="dash-card-item-initials">
                                                    <span>{{getInitials(item)}}</span>
                                                </div>

                                                <app-spacer
                                                    [right]="16"></app-spacer>

                                                <div
                                                    class="dash-card-item-content">
                                                    <div
                                                        class="dash-card-item-content-title">
                                                        {{item.corporate_name}}
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-subtitle">
                                                        {{item.document}}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            {{item.funds.length}}
                                        </td>

                                        <td>
                                            <span class="status">
                                                {{item.updated | date:
                                                'dd/MM/yyyy \'às\' HH:mm'}}
                                            </span>
                                        </td>

                                        <td>
                                            <div
                                                class="d-flex justify-content-end">
                                                <button
                                                    (click)="setaudit(item)">
                                                    <i class="fas fa-pen"></i>
                                                </button>

                                                <app-spacer
                                                    [right]="19"></app-spacer>
                                                <button
                                                    (click)="showLinks(item)">
                                                    <svg-icon
                                                        src="assets/images/check-list.svg"></svg-icon>
                                                </button>

                                                <app-spacer
                                                    [right]="19"></app-spacer>
                                                <button
                                                    (click)="showauditDetails(item)">
                                                    <i class="fas fa-eye"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="audits.length === 0">
                                        <td colspan="4">
                                            <span
                                                class="dash-card-item-content-subtitle">Nenhuma
                                                auditoria
                                                adicionada</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <app-spacer [bottom]="28"></app-spacer>
                            </table>

                            <div *ngIf="audits.length === 0">
                                <app-spacer [bottom]="30"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dash-card-pagination">
                <app-pagination [data]="audits" [perPage]="50"
                    [show]="!loadingAudit"
                    (onDataChange)="setFilteredData($event)"
                    [dashPagination]="true">
                </app-pagination>
            </div>

        </div>
    </div>
</div>

<app-side-modal [active]="addAudit" (onClose)="closeModal()"
    [title]="audit?'Atualizar Auditoria':'Adicionar Auditoria'"
    paddingBottom="30">
    <form [formGroup]="auditForm">
        <div class="row">
            <div class="col-12">
                <app-masked-input label="CNPJ" [group]="auditForm"
                    controlName="document" placeholder="12.345.678/0001-99"
                    mask="00.000.000/0000-00">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Razão social" [group]="auditForm"
                    controlName="corporate_name" [isUpperCase]="true"
                    placeholder="ACME AUDITORIA DE ZERAGEM LTDA">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <app-input type="date" label="Data de constituição"
                    [group]="auditForm" controlName="constitution_date"
                    placeholder="ACME AUDITORIA DE ZERAGEM LTDA">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <app-input label="E-mail" [group]="auditForm"
                    controlName="email" placeholder="email@mail.com.br">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Ramo de atividade" [group]="auditForm"
                    controlName="branch">
                </app-input>
                <app-spacer [bottom]="56"></app-spacer>
            </div>

            <div class="col-12 col-md-6 cep-container">
                <app-masked-input [group]="auditForm" controlName="zipcode"
                    label="CEP" mask="00000-000" placeholder="99887-766"
                    (input)="searchZipcode($event)">
                </app-masked-input>
                <i *ngIf="loading"
                    class="fas fa-circle-notch fa-spin app-button-icon"></i>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="zipcodeFetched">
                <div class="col-12 col-md-9">
                    <app-input [group]="auditForm" controlName="street"
                        label="Logradouro">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-3">
                    <app-input [group]="auditForm" controlName="number"
                        type="number" label="Número">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6">
                    <app-input [group]="auditForm" controlName="complement"
                        label="Complemento">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6">
                    <app-input [group]="auditForm" controlName="district"
                        label="Bairro">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-9">
                    <app-input [group]="auditForm" controlName="city"
                        label="Cidade">
                    </app-input>
                </div>

                <div class="col-12 col-md-3">
                    <app-select [items]="signupService.ufs" [group]="auditForm"
                        controlName="state" label="UF" placeholder="SP">
                    </app-select>
                </div>
            </ng-container>
        </div>

        <app-spacer [bottom]="48"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="closeModal()" class="add-button"
                    [outline]="true" [bordless]="true">
                    Cancelar
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="addNewAudit()" class="add-button"
                        [disabled]="getDisabled()">
                        {{audit ?'Atualizar' :'Adicionar'}} Auditoria
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>

<app-side-modal [active]="auditDetails" (onClose)="closeModal()"
    [title]="audit ? audit.corporate_name : ''">
    <div class="row" *ngIf="audit">
        <div class="col-12">
            <div class="dash-card-item-content-title">
                {{audit.document}}
            </div>
            <div class="dash-card-item-content-subtitle">
                CNPJ
            </div>
            <app-spacer [bottom]="16"></app-spacer>
        </div>

        <div class="col-12">
            <div class="dash-card-item-content-title">
                {{audit.corporate_name}}
            </div>
            <div class="dash-card-item-content-subtitle">
                RAZÃO SOCIAL
            </div>
            <app-spacer [bottom]="16"></app-spacer>
        </div>

        <div class="col-12 col-md-6">
            <div class="dash-card-item-content-title">
                {{audit.constitution_date}}
            </div>
            <div class="dash-card-item-content-subtitle">
                DATA DE CONSTITUIÇÃO
            </div>
            <app-spacer [bottom]="16"></app-spacer>
        </div>

        <div class="col-12 col-md-6">
            <div class="dash-card-item-content-title">
                {{audit.email}}
            </div>
            <div class="dash-card-item-content-subtitle">
                E-MAIL
            </div>
            <app-spacer [bottom]="16"></app-spacer>
        </div>

        <div class="col-12">
            <div class="dash-card-item-content-title">
                {{audit.branch}}
            </div>
            <div class="dash-card-item-content-subtitle">
                RAMO DE ATIVIDADE
            </div>
            <app-spacer [bottom]="16"></app-spacer>
        </div>

        <div class="col-12">
            <div class="dash-card-item-content-title">
                {{audit.street}}, {{audit.number}}, {{audit.complement}} —
                {{audit.district}}, {{audit.city}} -
                {{audit.state}} Brasil
            </div>
            <div class="dash-card-item-content-subtitle">
                ENDEREÇO COMPLETO
            </div>
        </div>
    </div>
</app-side-modal>

<app-side-modal [active]="auditLinks" (onClose)="closeModal()"
    [title]="audit ? linkLabel(audit) : ''" padding="0">
    <div class="dash-card links" *ngIf="audit">
        <div class="row no-gutters">
            <div class="col-12 padding-container dash-card-body">
                <div clsas="styled-scroll">
                    <table class="bordered"
                        *ngIf="audit.funds.length > 0; else noResult">
                        <tbody>
                            <tr *ngFor="let item of audit.funds">
                                <td>
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-initials">
                                            <span>{{getFundInitials(item.name)}}</span>
                                        </div>

                                        <app-spacer [right]="16"></app-spacer>

                                        <div class="dash-card-item-content">
                                            <div
                                                class="dash-card-item-content-title">
                                                {{item.name}}
                                            </div>
                                            <div
                                                class="dash-card-item-content-subtitle">
                                                {{item.document}}
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div class="d-flex justify-content-end">
                                        <a
                                            [routerLink]="['/app/dashboard/fund/approval/', item.id]">
                                            <i class="fas fa-eye"></i>
                                        </a>
                                    </div>
                                </td>
                            </tr>

                            <tr *ngIf="audits.length === 0">
                                <td colspan="3">
                                    <span
                                        class="dash-card-item-content-subtitle">Nenhum
                                        cedente
                                        adicionado</span>
                                </td>
                            </tr>

                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>

                    <ng-template #noResult>
                        <span
                            class="dash-card-item-content-title text-center d-block pb-4 pt-4 mb-0">Nenhum
                            fundo
                            vinculado</span>
                    </ng-template>

                    <div *ngIf="audit.funds.length === 0">
                        <app-spacer [bottom]="30"></app-spacer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-side-modal>