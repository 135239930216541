import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MaskPipe } from 'ngx-mask';

import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard-approval',
  templateUrl: './dashboard-approval.component.html',
  styleUrls: ['./dashboard-approval.component.scss'],
})
export class DashboardApprovalComponent implements OnInit {
  reproveForm = this.formBuilder.group({
    description: [null, [Validators.required]],
  });

  tabs: Tab[] = [];

  status = [
    {
      department: 'Compliance',
      approved_datetime: '23/10/2021 às 16:32',
      completed: true,
    },
    {
      department: 'Cadastro',
      approved_datetime: null,
      completed: true,
    },
    {
      department: 'Diretoria',
      approved_datetime: null,
      completed: false,
    },
  ];

  tabIndex = 0;
  activedTab = 'dados-complementares';

  warningModal = false;
  approve = false;
  resetApproval = false;
  loading = true;

  approval: UserApprovalResponse = undefined;

  cards: any = undefined;

  role = '';

  isSimplified = false;

  applicableRole = 'ADM';

  departmentAttemps = {};

  isFund = false;

  self_view = false;

  validation_token = undefined;

  signing = false;

  sign_view = undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private api: ApiService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    protected sanitizer: DomSanitizer,
    private maskPipe: MaskPipe
  ) {}

  ngOnInit(): void {
    let range = this.router.url.split('/').length;
    this.role = this.router.url.split('/')[range - 2];

    /* get query params */
    this.route.queryParams.subscribe((params) => {
      if (params.sign_view) {
        this.sign_view = params.sign_view;

        Swal.fire({
          title: '<b>Valide os dados</b>',
          html: 'Verifique se os dados estão corretos e clique em <b>"Assinar como representante"</b> para prosseguir com a aprovação.',
          icon: 'info',
          confirmButtonText: 'Validar dados',
          confirmButtonColor: '#3085d6',
        });
      }
    });

    if (this.authService.token === undefined) {
      this.router.navigateByUrl(
        `/login/?role=${this.role}&id=${this.route.snapshot.params.id}`
      );
    }

    this.route.params.subscribe((params) => {
      this.self_view = this.authService.user.actived_register
        ? this.authService.user.actived_register.register.uuid === params.id
        : false;

      this.getApproval();
    });

    // this.self_view =
    //   this.authService.user.actived_register.register.department !== null;
  }

  async getApproval() {
    let role = [];

    if (this.authService.user) {
      const user_role = this.authService.user.actived_register
        ? this.authService.user.actived_register.register.role
        : this.authService.user.role;

      role = user_role.slug.split('-');

      this.applicableRole = user_role.slug.split('-')[0];
    } else {
      role.push('ASSIGNOR');
      this.applicableRole = 'ASSIGNOR';
    }

    try {
      const req_params = {
        register: this.route.snapshot.params.id,
      };

      if (this.validation_token) {
        req_params['validation_token'] = this.validation_token;
      }

      const { data } = await this.api.get<ApiResponse<UserApprovalResponse[]>>({
        route: `api/approvals/user_approval/`,
        token: true,
        params: req_params,
      });

      const approval = data[0];

      this.tabs = [];

      if (role[0] === 'ADM') {
        this.tabs.push({ label: 'Minutas', value: 'minutas' });
        this.tabs.push({ label: 'Contratos', value: 'contratos' });
      }

      this.isSimplified = approval?.register?.is_simplified;

      if (this.isSimplified) {
        this.tabs = [
          { label: 'Dados complementares', value: 'dados-complementares' },
          { label: 'Anexos', value: 'anexos' },
          ...this.tabs,
        ];

        this.loading = false;

        return;
      }

      this.approval = approval;

      this.isFund = this.approval?.register?.role?.slug.includes('PJ-FI');

      if (this.isFund) {
        this.tabs = [
          { label: 'Dados complementares', value: 'dados-complementares' },
          { label: 'Representantes', value: 'representantes' },
          { label: 'Anexos', value: 'anexos' },
          { label: 'Acompanhamento', value: 'acompanhamento' },
          { label: 'Assinaturas', value: 'assinaturas' },
          ...this.tabs,
        ];

        this.loading = false;

        return;
      }

      this.tabs = [
        { label: 'Dados complementares', value: 'dados-complementares' },
        { label: 'Representantes', value: 'representantes' },
        { label: 'Anexos', value: 'anexos' },
        { label: 'Acompanhamento', value: 'acompanhamento' },
        { label: 'Assinaturas', value: 'assinaturas' },
        { label: 'Fundos vinculados', value: 'vinculos' },
        ...this.tabs,
      ];

      if (this.approval?.register?.type === 'PJ') {
        this.tabs.splice(1, 0, {
          label: 'Composição acionária',
          value: 'composicao-acionaria',
        });

        this.tabs.splice(3, 0, {
          label: 'Grupos e poderes',
          value: 'grupos-e-poderes',
        });
      }
      this.loading = false;

      return;
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao carregar dados: ' + error);
    }
    this.loading = false;
  }

  filteredApproval(attempt, approval) {
    return approval.filter((_approval) => _approval.attempt === attempt);
  }

  closeWarningModal() {
    this.warningModal = false;
    this.reproveForm.reset();
  }

  handleApproval(approve: boolean, reset?: boolean) {
    this.approve = approve;
    this.warningModal = true;
    this.resetApproval = reset ?? false;
  }

  formatPhone(phone) {
    return this.maskPipe.transform(phone, '(00) 00000-0000');
  }

  async signApproval(approve: boolean) {
    this.signing = true;
    try {
      const payload = {
        user_approval: this.approval.id,
        department_approval: this.approval.current_department.id,
        approved: approve,
        restart: this.resetApproval,
        reason: approve ? '' : this.reproveForm.value.description,
        signed: true,
      };

      const res = await this.api.post({
        route: 'api/approvals/user_approval_signature/',
        token: true,
        body: payload,
      });

      if (approve) {
        this.toast.show(
          'info',
          'Tudo certo',
          'O cadastro foi aprovado com sucesso'
        );
      } else {
        this.toast.show(
          'info',
          `Cadastro ${this.resetApproval ? 'retornado' : 'reprovado'}`,
          'Uma notificação foi enviada ao responsável'
        );
      }

      this.warningModal = false;
      this.reproveForm.reset();

      if (this.resetApproval) {
        this.router.navigateByUrl('/app/dashboard');
      } else {
        await this.getApproval();
      }
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao aprovar inscrição');
      console.error(error);
    }
    this.signing = false;
  }

  //   async getData() {
  //     await Promise.all([this.getApproval()]);
  //   }

  onChangeTab(index: number) {
    this.activedTab = this.tabs[index].value;

    this.tabIndex = index;
  }

  getInitials(item: UserApprovalResponse) {
    const full_name =
      item.register.type.toUpperCase() === 'PF'
        ? item.register.person.full_name
        : item.register.company.corporate_name;

    return full_name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  getMapAddress(approval: any) {
    if (approval.address) {
      const fullAddress = encodeURIComponent(
        `${approval.address.public_place}, ${approval.address.number}, ${approval.address.district}, ${approval.address.city} - ${approval.address.uf}`
      );
      const mapUrl = `http://maps.google.com/maps?q=${fullAddress}&z=15&output=embed`;

      return this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl);
    }

    return '';
  }

  calculateTotal(properties: any) {
    let total = 0;

    properties.forEach((property: any) => {
      total += Number(property.current_value);
    });

    return total;
  }

  getFileStatus(status: any) {
    switch (status) {
      case 1:
        return 'Arquivo Enviado';
      case 2:
        return 'Reprovado';
      case 3:
        return 'Aprovado';
      default:
        return 'Desconhecido';
    }
  }

  changeSituation(attachment: any, situation: number) {
    Swal.fire({
      title: situation === 2 ? 'Reprovar documento' : 'Aprovar documento',
      text:
        situation === 2
          ? 'Deseja reprovar este documento?'
          : 'Deseja aprovar este documento?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      input: 'textarea',
      inputPlaceholder: 'Motivo (opcional)',
      showLoaderOnConfirm: true,
      preConfirm: (description) => {
        const payload = {
          id: attachment.id,
          situation,
          description,
        };

        return this.api
          .put({
            route: 'api/approvals/approval_file',
            token: true,
            body: payload,
          })
          .then((res) => {
            Swal.fire('Sucesso', 'Documento aprovado com sucesso', 'success');
          })
          .catch(() => {
            Swal.fire('Erro', 'Erro ao aprovar documento', 'error');
          });
      },
    });
  }

  async rediretToEdit() {
    await this.api.patch({
      route: 'api/registration/trace_step/me/',
      body: { substep: 'general', step: 'register' },
      token: true,
    });

    this.router.navigate([
      `/signup/${this.applicableRole.toLowerCase()}/${this.approval.register.type.toLowerCase()}`,
    ]);
  }

  getDisplayName(item: UserApprovalResponse) {
    if (item.register.type.toUpperCase() === 'PJ') {
      return item.register.company.corporate_name;
    }

    if (item.register.type.toUpperCase() === 'PF') {
      return item.register.person.full_name;
    }

    return '';
  }

  getDisplayProfileType(item: UserApprovalResponse) {
    if (item.register.is_simplified) {
      return 'Simplificado;';
    }

    if (item.register.role.applicable === 'provider') {
      return item.register.agent.map((agent: any) => agent.name).join(', ');
    }

    const profile_type_dict = {
      assignor: 'Cedente',
      provider: 'Prestador de serviços',
    };

    return profile_type_dict[item.register.role.applicable];
  }

  getPhone(item: UserApprovalResponse) {
    if (item.register.type.toUpperCase() === 'PF') {
      return item.register.person.cellphone.number;
    }

    if (item.register.type.toUpperCase() === 'PJ') {
      return item.register.company.cellphone.number;
    }

    return '';
  }

  getSignerName(sign: UserApprovalSignature) {
    if (sign.register) {
      if (sign.register.type.toUpperCase() === 'PF') {
        return sign.register.person.full_name;
      }

      if (sign.register.type.toUpperCase() === 'PJ') {
        return sign.register.company.corporate_name;
      }
    } else {
      if (sign.user) {
        return sign.user.name;
      }
      return 'Usuário não encontrado';
    }
  }

  async handleRepresentativeSign() {
    if (!this.sign_view) {
      Swal.fire({
        title: 'Erro',
        text: 'Não é possível assinar o cadastro, pois sua solicitação de assinatura não foi encontrada.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
      });

      return;
    }

    await Swal.fire({
      title: 'Confirmação',
      text: 'É necessário que realize a assinatura digital para avançar para a etapa de aprovação',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Assinar',
      cancelButtonText: 'Cancelar',

      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.api
          .patch({
            route: `api/registration/company_sign/${this.sign_view}/`,
            body: { signed: true },
            token: true,
          })
          .then((res) => {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Assinado com sucesso',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            });

            this.router.navigateByUrl(
              `/app/status/pj?company=${this.approval.register.company.id}`
            );
          })
          .catch((error) => {
            Swal.fire('Erro!', 'Erro ao assinar', 'error');
          });
      },
    });
  }
}
