<div class="row no-gutters">
    <div class="col-12 offset-lg-1 col-lg-11">

        <ng-container *ngIf="loading">
            <div class="row">

                <div class="col-12">
                    <ngx-skeleton-loader [theme]="{
                height: '40px',
                width: '200px',
                marginBottom:'0px'
            }">
                    </ngx-skeleton-loader>

                    <app-spacer [bottom]="32"></app-spacer>
                </div>

                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-8">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-6">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-12">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-6">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-6">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="col-2">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="col-2">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="col-6">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-6">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-12">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
            </div>
        </ng-container>


        <ng-container *ngIf="!loading">
            <div class="row">
                <div class="col-12 col-lg-7">
                    <span class="basic-content-title">
                        {{representativeAttachments.length > 0 ||
                        attachments.length
                        > 0 ? 'Agora é hora de comprovar as informações
                        inseridas' :
                        'Você não possuí documentos para comprovar'}}
                    </span>
                    <app-spacer [bottom]="54"></app-spacer>
                </div>
            </div>


            <ng-container *ngIf="attachments.length > 0">
                <app-attachment-collapse [label]="renderLabel('',false)">
                    <div *ngFor="let attachment of attachments; let i = index">
                        <div class="row justify-content-between">
                            <div class="col-12 col-lg-7">
                                <div class="d-flex flex-column">
                                    <span
                                        class="table-sum-title">{{attachment.name}}
                                        <b
                                            [class.text-danger]="attachment.required">{{attachment.required
                                            ?
                                            "(obrigatório)" :
                                            "(opcional)"}}</b></span>
                                    <app-spacer [bottom]="8"></app-spacer>
                                    <span
                                        class="table-sum-amount">{{attachment.description}}</span>
                                </div>

                                <app-spacer [bottom]="24"></app-spacer>

                                <div class="row"
                                    *ngIf="attachment.have_due_date">
                                    <div class="col-12">
                                        <h3>
                                            Validade
                                        </h3>
                                    </div>

                                    <div class="col-12 col-lg-5">
                                        <app-masked-input mask="00/00/0000"
                                            [standalone]="true" label="De"
                                            placeholder="dd/mm/aaaa"
                                            (onChange)="handleDateChange($event, 'from', i)"
                                            [hasError]="attachment.isFromInvalid">
                                        </app-masked-input>
                                    </div>
                                    <div class=" col-12 col-lg-5">
                                        <app-masked-input mask="00/00/0000"
                                            [standalone]="true" label="Até"
                                            placeholder="dd/mm/aaaa"
                                            (onChange)="handleDateChange($event, 'to', i)"
                                            [hasError]="attachment.isToInvalid">
                                        </app-masked-input>
                                    </div>
                                </div>

                                <div class="row"
                                    *ngIf="getSendedFiles(attachment.id).length > 0">
                                    <div class="col-12">
                                        <span
                                            class="table-sum-title mb-2 font-weight-bold d-flex">
                                            Arquivos enviados
                                        </span>
                                    </div>

                                    <div class="col-xs-12 col-lg-4 mb-2"
                                        *ngFor="let file of getSendedFiles(attachment.id)">
                                        <div
                                            class="d-flex flex-column justify-content-center">
                                            <img [src]="getFileImagePreview(file.file)"
                                                alt="attachment"
                                                class="img-preview">

                                            <span
                                                class="text-overflow">{{getFileName(file.file)}}</span>

                                            <ng-container
                                                *ngIf="file.type.have_due_date">
                                                <app-spacer
                                                    [bottom]="8"></app-spacer>

                                                <span>
                                                    <b>Validade:</b><br>
                                                    <b>De:</b> {{file.date_due |
                                                    date: "dd/MM/yyyy" }} <br>
                                                    <b>Até:</b>
                                                    {{file.end_date_due | date:
                                                    "dd/MM/yyyy" }}
                                                </span>
                                            </ng-container>

                                            <app-spacer
                                                [bottom]="8"></app-spacer>

                                            <div
                                                class="d-flex flex-row justify-content-between">
                                                <app-button [inverted]="true"
                                                    [center]="true"
                                                    (onClick)="openFile(file.file)">
                                                    <i class="fa fa-eye"></i>
                                                </app-button>

                                                <app-button [danger]="true"
                                                    [inverted]="true"
                                                    [center]="true"
                                                    (onClick)="removeFile(file.id)">
                                                    <i class="fa fa-trash"></i>
                                                </app-button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12 col-lg-5">
                                <app-dropzone
                                    (filesChange)="setFiles(i, $event)">
                                </app-dropzone>
                            </div>

                            <ng-container *ngIf="i + 1 !== attachments.length">
                                <div class="col-12">
                                    <app-spacer [bottom]="48"></app-spacer>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </app-attachment-collapse>
            </ng-container>

            <ng-container
                *ngFor="let representativeAttachment of representativeAttachments; let i = index">
                <app-attachment-collapse
                    [label]="renderLabel(representativeAttachment, true)">
                    <div
                        *ngFor="let attachment of representativeAttachment.attachments; let idx = index">
                        <div class="row justify-content-between">
                            <div class="col-12 col-lg-7">
                                <div class="d-flex flex-column">
                                    <span
                                        class="table-sum-title">{{attachment.name}}
                                        <b
                                            [class.text-danger]="attachment.required">{{attachment.required
                                            ?
                                            "(obrigatório)" :
                                            "(opcional)"}}</b></span>
                                    <app-spacer [bottom]="8"></app-spacer>

                                    <span
                                        class="table-sum-amount">{{attachment.description}}</span>
                                </div>

                                <app-spacer [bottom]="24"></app-spacer>

                                <div class="row"
                                    *ngIf="attachment.have_due_date">
                                    <div class="col-12">
                                        <h3>
                                            Validade
                                        </h3>
                                    </div>

                                    <div class="col-12 col-lg-5">
                                        <app-masked-input mask="00/00/0000"
                                            [standalone]="true" label="De"
                                            placeholder="dd/mm/aaaa"
                                            (onChange)="handleDateChange($event, 'from', idx, i)"
                                            [hasError]="attachment.isFromInvalid">
                                        </app-masked-input>
                                    </div>
                                    <div class=" col-12 col-lg-5">
                                        <app-masked-input mask="00/00/0000"
                                            [standalone]="true" label="Até"
                                            placeholder="dd/mm/aaaa"
                                            (onChange)="handleDateChange($event, 'to', idx, i)"
                                            [hasError]="attachment.isToInvalid">
                                        </app-masked-input>
                                    </div>
                                </div>

                                <div class="row"
                                    *ngIf="getRepresentativeSendedFiles(attachment.id, representativeAttachment.representative.id).length > 0">
                                    <div class="col-12">
                                        <span
                                            class="table-sum-title mb-2 font-weight-bold d-flex">
                                            Arquivos enviados
                                        </span>
                                    </div>

                                    <div class="col-xs-12 col-lg-4 mb-2"
                                        *ngFor="let file of getRepresentativeSendedFiles(attachment.id, representativeAttachment.representative.id)">
                                        <div
                                            class="d-flex flex-column justify-content-center">
                                            <img [src]="getFileImagePreview(file.file)"
                                                alt="attachment"
                                                class="img-preview">

                                            <span
                                                class="text-overflow">{{getFileName(file.file)}}</span>

                                            <ng-container
                                                *ngIf="file.type.have_due_date">
                                                <app-spacer
                                                    [bottom]="8"></app-spacer>

                                                <span>
                                                    <b>Validade:</b><br>
                                                    <b>De:</b> {{file.date_due |
                                                    date: "dd/MM/yyyy" }} <br>
                                                    <b>Até:</b>
                                                    {{file.end_date_due | date:
                                                    "dd/MM/yyyy" }}
                                                </span>
                                            </ng-container>


                                            <app-spacer
                                                [bottom]="8"></app-spacer>

                                            <div
                                                class="d-flex flex-row justify-content-between">
                                                <app-button [inverted]="true"
                                                    [center]="true"
                                                    (onClick)="openFile(file.file)">
                                                    <i class="fa fa-eye"></i>
                                                </app-button>

                                                <app-spacer
                                                    [right]="8"></app-spacer>

                                                <app-button [danger]="true"
                                                    [inverted]="true"
                                                    [center]="true"
                                                    (onClick)="removeFile(file.id)">
                                                    <i class="fa fa-trash"></i>
                                                </app-button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12 col-lg-5">
                                <app-dropzone
                                    (filesChange)="setRepresentativeFiles(i, idx, $event)">

                                </app-dropzone>
                            </div>

                            <ng-container
                                *ngIf="i + 1 !== representativeAttachment.attachments.length">
                                <div class="col-12">
                                    <app-spacer [bottom]="48"></app-spacer>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </app-attachment-collapse>
            </ng-container>

            <ng-container
                *ngIf="representativeAttachments.length > 0 || attachments.length > 0">
                <app-spacer [bottom]="48"></app-spacer>
                <app-button (onClick)="sendData()" [outline]="true"
                    [disabled]="sending" [loading]="sending">
                    Enviar anexos
                    <i class="fas fa-floppy-disk ml-2"></i>
                </app-button>
            </ng-container>
        </ng-container>
    </div>
</div>