<div class="dashboard h-100">
    <div class="dashboard-card no-padding d-flex flex-column justify-content-between"
        style="max-width: 800px">
        <div class="row">
            <div class="col-12">
                <div class="padding-container d-flex flex-column">
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            height: '24px',
                            width: '30px',
                            margin:'0'
                          }">
                        </ngx-skeleton-loader>

                        <ngx-skeleton-loader [theme]="{
                                height: '27px',
                                width: '100px',
                            marginBottom:'30px'
                              }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <span class="card-info-number">
                            {{funds.length > 9 ? '' :
                            0}}{{funds.length}}
                        </span>
                        <span class="card-info-title">Fundos de zeragem</span>
                    </ng-container>
                </div>
            </div>

            <div class="col-12 dashboard-card-body">

                <ng-container *ngIf="loading">
                    <table #table>
                        <thead>
                            <tr>
                                <th>
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>

                                <th class="text-right">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let number of [0,1,2,3]">
                                <td>
                                    <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '100px',
                                    marginBottom:'0'                            
                                      }">
                                    </ngx-skeleton-loader>
                                </td>
                                <td class="item-number">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '30px',
                                    marginBottom:'0'
                                  }">
                                    </ngx-skeleton-loader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <table #table *ngIf="!loading">
                    <thead>
                        <tr>
                            <th>fundo</th>
                            <th style="text-align:right;">Vínculos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let fund of visible_funds">
                            <td>
                                {{fund.corporate_name}}
                            </td>
                            <td class="item-number">
                                {{fund.funds.length}}
                                <div class="item-number-options">
                                    <ng-container
                                        *ngIf="fund.funds.length === 0">
                                        <button (click)="removeFund(fund.id)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </ng-container>
                                    <app-spacer [right]="19"></app-spacer>
                                    <button (click)="setFund(fund)">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12">
                <div
                    class="padding-container d-flex flex-row justify-content-between">

                    <ng-container *ngIf="loading">
                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                        width: '180px',
                            marginBottom:'0'                            
                              }">
                            </ngx-skeleton-loader>

                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                width: '180px',
                                marginBottom:'0'                            
                                  }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <app-button [inverted]="true"
                            (onClick)="redirect('/app/settings/funds')">
                            Ver todos
                        </app-button>

                        <app-button (click)="addFundToggle(true)"
                            class="add-button">
                            <i class="fas fa-plus-circle mr-2"></i>Adicionar
                            Fundo
                            de zeragem
                        </app-button>
                    </ng-container>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 " *ngIf="funds.length === 0 && !loading">
                <div class="empty-card">
                    <div class="img-container">
                        <img src="/assets/images/collaborator-icon.png"
                            alt="icone">
                    </div>

                    <h3>Adicione Fundos de zeragem</h3>
                    <p>
                        Adicione um novo Fundo de zeragem no botão Adicionar
                        Fundo de zeragem
                    </p>

                    <app-button (click)="addFundToggle(true)"
                        class="add-button">
                        <i class="fas fa-plus-circle mr-2"></i>Adicionar Fundo
                        de zeragem
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="addFund" (onClose)="closeModal()"
    [title]="fund?'Atualizar Fundo de zeragem':'Adicionar Fundo de zeragem'">
    <form [formGroup]="fundForm">
        <div class="row">
            <div class="col-12">
                <app-masked-input label="CNPJ" [group]="fundForm"
                    controlName="document" placeholder="12.345.678/0001-99"
                    mask="00.000.000/0000-00">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Razão social" [group]="fundForm"
                    controlName="corporate_name" [isUpperCase]="true"
                    placeholder="ACME FUNDO DE ZERAGEM LTDA">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Nome gestor" [group]="fundForm"
                    controlName="manager_name" placeholder="Digite">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <app-input label="E-mail gestor" [group]="fundForm"
                    controlName="manager_email" placeholder="email@mail.com.br">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-5">
                        <app-select [group]="fundForm" controlName="manager_ddi"
                            [items]="DDIOptions" label="DDI">
                        </app-select>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-7">
                        <app-input label="Celular" [group]="fundForm"
                            controlName="manager_phone" placeholder="Digite">
                        </app-input>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>
                </div>
            </div>
        </div>

        <app-spacer [bottom]="48"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="closeModal()" class="add-button"
                    [outline]="true" [bordless]="true">
                    Cancelar
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="addZeroingFund()" class="add-button"
                        [disabled]="getDisabled()">
                        {{fund ?'Atualizar' :'Adicionar'}} Fundo de zeragem
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>