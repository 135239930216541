import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { countriesOptions } from 'src/app/data/countries';
import { statesOptions } from 'src/app/data/states';
import { SignupService } from 'src/app/services/signup/signup.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import Utils, { GetHelper, UtilsValidators } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
@Component({
  selector: 'app-signup-unified-person-complementary-address',
  templateUrl: './signup-unified-person-complementary-address.component.html',
  styleUrls: ['./signup-unified-person-complementary-address.component.scss'],
})
export class SignupUnifiedPersonComplementaryAddressComponent
  implements OnInit, OnDestroy
{
  form = this.formBuilder.group({
    cep: [null, [Validators.required, UtilsValidators.cep]],
    public_place: [null, [Validators.required]],
    number: [null, [Validators.required]],
    complement: [null, []],
    district: [null, [Validators.required]],
    city: [null, [Validators.required]],
    uf: [null, [Validators.required]],
    country: ['1', [Validators.required]],
  });

  zipcodeFetched = false;

  countries: SelectItem[] = countriesOptions;
  states: SelectItem[] = statesOptions;

  isForeign = false;

  loading = false;

  data = null;

  event: any;

  foundCep = false;

  loadingContent = false;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private utilsService: UtilsService,
    private maskPipe: MaskPipe,
    private authService: AuthService,
    private toast: ToastService,
    private api: ApiService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.setForeign();

    this.getDataAndFillForm();

    this.event = this.signupService.sendComplementaryPersonData.subscribe(
      (value) => {
        if (value === 'complementaryAddress') {
          this.sendData();
        }
      }
    );

    this.form.valueChanges.subscribe(() => {
      this.signupService.setPersonFormStatus(
        'complementaryAddress',
        this.form.valid
      );
    });

    this.signupService.fillFormEvent.subscribe(() => {
      if (!this.zipcodeFetched) {
        const zipcode = fakerbr.address.zipCodeValidByState('SP');

        const formatted = this.maskPipe.transform(zipcode, '00000-000');

        this.form.patchValue({
          cep: formatted,
          number: fakerbr.random.number({ min: 1, max: 1000 }),
          complement: 'Casa',
          country: '1',
        });

        this.searchZipcode({
          target: { value: formatted },
        });

        this.zipcodeFetched = true;
      }
    });
  }

  setForeign() {
    if (this.authService.user?.nationality?.country !== 'Brasil') {
      this.isForeign = true;
    }
  }

  async searchZipcode(event: any) {
    this.loading = true;
    const zipcode = event.target.value;

    try {
      const zipcodeRes = await this.utilsService.getZipcode(zipcode);

      if (zipcodeRes) {
        this.form.patchValue({
          public_place: zipcodeRes.logradouro,
          district: zipcodeRes.bairro,
          city: zipcodeRes.localidade,
          uf: zipcodeRes.uf,
          country: '1',
        });

        this.foundCep = true;

        this.zipcodeFetched = true;
      } else {
        this.zipcodeFetched = zipcode.length === 9;
        this.foundCep = false;
        Utils.resetZipcodeFields(this.form);
      }
    } catch (error) {
      this.zipcodeFetched = false;
      this.foundCep = false;
      console.error(error);
    }

    this.loading = false;
  }

  async getDataAndFillForm() {
    this.loadingContent = true;

    try {
      const { data } = await this.api.get<ApiResponse<AddressResponse>>({
        route: 'api/registration/address/me/',
        token: true,
      });

      this.form.patchValue(data);
      this.zipcodeFetched = true;

      this.data = data;

      this.foundCep = true;

      this.signupService.setPersonFormStatus('complementaryAddress', true);
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
    this.loadingContent = false;
  }

  async sendData() {
    this.signupService.isSendindData = true;

    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        cep: valuesHelper.get('cep'),
        public_place: valuesHelper.get('public_place'),
        number: Number(valuesHelper.get('number')),
        complement: valuesHelper.get('complement'),
        district: valuesHelper.get('district'),
        city: valuesHelper.get('city'),
        uf: valuesHelper.get('uf'),
        country: Number(valuesHelper.get('country')),
        type: 1,
        address_type: 'user',
      };

      console.table(valuesHelper.getRemainingObject());

      if (this.data) {
        const { data } = await this.api.put({
          route: `api/registration/address/me/`,
          token: true,
          body: payload,
        });

        this.data = data;
      } else {
        const { data } = await this.api.post({
          route: 'api/registration/address/',
          token: true,
          body: payload,
        });

        this.data = data;
      }

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');

      this.signupService.setComplementaryPersonStep('complementaryBank');
      this.signupService.changePersonStepEvent.emit('bank');
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.signupService.isSendindData = false;
  }
}
