import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import DDIOptions from 'src/app/data/ddi';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';

@Component({
  selector: 'app-dashboard-zeroing-funds',
  templateUrl: './dashboard-zeroing-funds.component.html',
  styleUrls: ['./dashboard-zeroing-funds.component.scss'],
})
export class DashboardZeroingFundsComponent implements OnInit {
  fundForm = this.formBuilder.group({
    document: [null, [Validators.required]],
    corporate_name: [null, [Validators.required]],
    manager_name: [null, [Validators.required]],
    manager_email: [null, [Validators.required]],
    manager_ddi: [null, [Validators.required]],
    manager_phone: [null, [Validators.required]],
  });

  DDIOptions = DDIOptions;

  funds: any[] = [];
  fundDetails: boolean = false;
  fundLinks: boolean = false;
  filteredFunds: any[] = [];

  addFund = false;
  fund: any = undefined;

  loading = true;

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private maskPipe: MaskPipe
  ) {}

  ngOnInit(): void {
    this.getRequesteds();
    this.setData();
  }

  setFilteredData(data: any[]) {
    this.filteredFunds = data;

    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }

  addFundToggle(status: boolean) {
    this.addFund = status;
  }

  closeModal() {
    this.fundForm.reset();
    this.addFund = false;
    this.fund = undefined;
    this.fundDetails = false;
    this.fundLinks = false;
  }

  setFund(fund: any) {
    this.fundForm.patchValue({
      ...fund,
      manager_ddi: '55',
    });

    this.fund = fund;
    this.addFundToggle(true);
  }

  getFundInitials(name: any) {
    const companyNameArray = name.split(' ');
    const initials = companyNameArray
      .map((word: string) => word.charAt(0))
      .join('');
    return `${initials[0].toUpperCase()}${
      initials[1] ? initials[1].toUpperCase() : ''
    }`;
  }

  async getRequesteds() {
    try {
      const res = await this.api.get({
        route: 'api/registration/zeroing_fund/',
        token: true,
      });

      this.funds = res;
      this.filteredFunds = this.funds.slice(0, 10);
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async addZeroingFund() {
    try {
      const values = this.fundForm.value;

      const res = this.fund
        ? await this.api.patch({
            route: `api/registration/zeroing_fund/${this.fund.id}/`,
            token: true,
            body: values,
          })
        : await this.api.post({
            route: 'api/registration/zeroing_fund/',
            token: true,
            body: values,
          });

      this.getRequesteds();

      this.toast.show(
        'info',
        `Fundo ${this.fund ? 'atualizado' : 'adicionado'} com sucesso`,
        `o fundo ${values.corporate_name} foi ${
          this.fund ? 'atualizado' : 'adicionado'
        }`
      );

      this.closeModal();
      this.fund = undefined;

      this.fundForm.reset();
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao cadastrar o fundo de zeragem.'
      );
    }
  }

  setData() {
    this.signupService.fillFormEvent.subscribe(() => {
      const companyName = fakerbr.company.companyName();
      this.fundForm.patchValue({
        document: this.maskPipe.transform(
          fakerbr.br.cnpj(),
          '00.000.000/0000-00'
        ),
        corporate_name: companyName,
        manager_name: fakerbr.name.findName(),
        manager_email: fakerbr.internet.email(),
        manager_ddi: '55',
        manager_phone: this.maskPipe.transform(
          '139' +
            String(fakerbr.random.number({ min: 11111111, max: 99999999 })),
          '(00) 00000-0000'
        ),
      });
    });
  }

  getDisabled() {
    return this.fundForm.invalid;
  }

  getInitials(user: any) {
    const full_name = user.corporate_name.split(' ');
    const initials = full_name[0].charAt(0) + full_name[1].charAt(0);

    return initials;
  }

  showFundDetails(fund: any) {
    this.fund = fund;
    this.fundDetails = true;
  }

  linkLabel(fund: any) {
    if (fund) {
      return `Vínculos de ${fund.corporate_name}`;
    }
    return '';
  }

  showLinks(fund: any) {
    this.fund = fund;
    this.fundLinks = true;
  }
}
