import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  @Input() label?: string = 'Retornar ao painel';
  @Input() route: string = '/app/dashboard';
  @Input() step: number | null = null;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  redirectUser() {
    if (this.step) {
      this.router.navigateByUrl(this.route, {
        state: { step: this.step },
      });
    } else {
      this.router.navigateByUrl(this.route);
    }
  }
}
