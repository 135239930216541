import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  constructor() {}

  @Input() approval: UserApprovalResponse = undefined;

  rejected: boolean = false;
  analysis: boolean = false;
  waitingSigns: boolean = false;
  homologation: boolean = false;
  blocked: boolean = false;
  finished: boolean = false;
  label: string = '';

  ngOnInit(): void {
    if (this.approval.step <= this.approval.queue.departments.length) {
      this.analysis = true;
      this.label = 'Em análise';
    }

    if (this.approval.step == 0) {
      this.analysis = true;
      this.label = 'Aguardando representantes';
    }

    if (this.approval.editing) {
      this.analysis = true;
      this.label = 'Cadastro retornado';
    }

    if (this.approval.completed && this.approval.is_approved) {
      this.analysis = false;
      this.waitingSigns = true;
      this.label = '';

      if (this.approval.register) {
        this.label =
          this.approval.register.role.applicable == 'provider'
            ? 'Homologado'
            : 'Aguardando assinatura de contrato';
      }

      if (this.approval.fund) {
        this.label = 'Homologado';
      }
    }

    // if (this.approval.draft && this.approval.draft.status === 'completed') {
    //   this.analysis = false;
    //   this.waitingSigns = false;
    //   this.homologation = true;
    //   this.label = 'Homologado';
    // }

    if (this.approval.completed && !this.approval.is_approved) {
      this.analysis = false;
      this.waitingSigns = false;
      this.homologation = false;
      this.rejected = true;
      this.label = 'Rejeitado';
    }
  }
}
