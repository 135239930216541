import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FundService, FundValues } from 'src/app/services/fund/fund.service';
import DDIOptions from 'src/app/data/ddi';
import Utils, { UtilsValidators } from 'src/app/helpers/utils';
import UIDefaultTheme from 'src/themes';
import { SignupService } from 'src/app/services/signup/signup.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';

@Component({
  selector: 'app-dashboard-fund-approval-register-basic',
  templateUrl: './dashboard-fund-approval-register-basic.component.html',
  styleUrls: ['./dashboard-fund-approval-register-basic.component.scss'],
})
export class DashboardFundApprovalRegisterBasicComponent implements OnInit {
  @Input() loading: boolean = false;

  DDIOptions = DDIOptions;

  form = this.formBuilder.group({
    document: [null, [Validators.required, UtilsValidators.cnpj]],
    giin: [null, []],
    cbm_constitution_date: [
      null,
      [Validators.required, Utils.validateMinDate, Utils.validateMaxMinToday],
    ],
    name: [null, [Validators.required]],
    fantasy_name: [null, [Validators.required]],
    structuring_fee_date: [
      null,
      [Validators.required, Utils.validateMinDate, Utils.validateMaxDate],
    ],
    duration_period: [
      null,
      [Validators.required, Utils.validateMinDate, Utils.validateMaxDate],
    ],
    indefinite_valid_date: [null, []],
    cvm_classification: [null, [Validators.required]],
    anbima_classification: [null, [Validators.required]],
    profile: [null, [Validators.required]],
    target_audience: [null, [Validators.required]],
    fund_social_exercise: [null, [Validators.required]],
    condominium_type: [null, [Validators.required]],
    financial_assets: [null, [Validators.required]],
    transfer: [false, []],
    transfer_date: [null, []],
    assembly_date: [null, []],
    last_company_document: [null, []],
    last_company_name: [null, []],
    last_company_contact: [null, []],
    last_company_email: [null, []],
    last_company_telephone_ddi: [null, []],
    last_company_telephone_number: [null, []],
    last_company_cellphone_ddi: [null, []],
    last_company_cellphone_number: [null, []],
  });

  fund: FundValues = undefined;

  choices: { [Key in any]: any } = {};
  today = Utils.todayString();

  UIDefaultTheme = UIDefaultTheme;

  constructor(
    public fundService: FundService,
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private maskPipe: MaskPipe
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((status) => {
      Utils.getErrors(this.form);
    });

    if (this.UIDefaultTheme.brand.simplifiedFund) {
      this.form.controls.cbm_constitution_date.setValidators([
        Utils.validateWhenNotEmpty(Utils.validateMinDate),
        Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
      ]);

      this.form.controls.structuring_fee_date.setValidators([
        Utils.validateWhenNotEmpty(Utils.validateMinDate),
        Utils.validateWhenNotEmpty(Utils.validateMaxDate),
      ]);

      this.form.controls.duration_period.setValidators([
        Utils.validateWhenNotEmpty(Utils.validateMinDate),
        Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
      ]);

      this.form.controls.profile.setValidators([]);
      this.form.controls.target_audience.setValidators([]);
      this.form.controls.fund_social_exercise.setValidators([]);
      this.form.controls.condominium_type.setValidators([]);
      this.form.controls.financial_assets.setValidators([]);

      this.form.updateValueAndValidity();
    }

    this.setData(this.fundService.data);
    this.choices = this.fundService.choices;

    this.form.controls.transfer.valueChanges.subscribe((value) => {
      this.form.patchValue({
        event: value ? '2' : '1',
      });

      if (value) {
        this.form.controls.transfer_date.setValidators([
          Validators.required,
          Utils.validateMinDate,
          Utils.validateMaxDate,
        ]);
        this.form.controls.assembly_date.setValidators([
          Validators.required,
          Utils.validateMinDate,
          Utils.validateMaxDate,
        ]);
        this.form.controls.last_company_name.setValidators([
          Validators.required,
        ]);
        this.form.controls.last_company_document.setValidators([
          Validators.required,
          UtilsValidators.cnpj,
        ]);
        this.form.controls.last_company_contact.setValidators([
          Validators.required,
        ]);
        this.form.controls.last_company_email.setValidators([
          Validators.required,
          Validators.email,
        ]);
        this.form.controls.last_company_telephone_ddi.setValidators([
          Validators.required,
        ]);
        this.form.controls.last_company_telephone_number.setValidators([
          Validators.required,
          UtilsValidators.telefone,
        ]);

        this.form.controls.last_company_cellphone_ddi.setValidators([
          Validators.required,
        ]);
        this.form.controls.last_company_cellphone_number.setValidators([
          Validators.required,
          UtilsValidators.telefone,
        ]);
      } else {
        this.form.controls.transfer_date.setValidators([]);
        this.form.controls.assembly_date.setValidators([]);
        this.form.controls.last_company_name.setValidators([]);
        this.form.controls.last_company_document.setValidators([]);
        this.form.controls.last_company_contact.setValidators([]);
        this.form.controls.last_company_email.setValidators([]);
        this.form.controls.last_company_telephone_ddi.setValidators([]);
        this.form.controls.last_company_telephone_number.setValidators([]);
        this.form.controls.last_company_cellphone_ddi.setValidators([]);
        this.form.controls.last_company_cellphone_number.setValidators([]);
      }

      this.form.controls.transfer_date.updateValueAndValidity();
      this.form.controls.assembly_date.updateValueAndValidity();
      this.form.controls.last_company_name.updateValueAndValidity();
      this.form.controls.last_company_document.updateValueAndValidity();
      this.form.controls.last_company_contact.updateValueAndValidity();
      this.form.controls.last_company_email.updateValueAndValidity();
      this.form.controls.last_company_telephone_ddi.updateValueAndValidity();
      this.form.controls.last_company_telephone_number.updateValueAndValidity();
      this.form.controls.last_company_cellphone_ddi.updateValueAndValidity();
      this.form.controls.last_company_cellphone_number.updateValueAndValidity();
    });

    this.form.controls.indefinite_valid_date.valueChanges.subscribe(
      (values) => {
        if (values) {
          this.form.controls.duration_period.setValue(null);
          this.form.controls.duration_period.setValidators([]);
        } else {
          const validators = !this.UIDefaultTheme.brand.simplifiedFund
            ? [
                Validators.required,
                Utils.validateMinDate,
                Utils.validateMaxDate,
              ]
            : [
                Utils.validateWhenNotEmpty(Utils.validateMinDate),
                Utils.validateWhenNotEmpty(Utils.validateMaxDate),
              ];

          this.form.controls.duration_period.setValidators(validators);
        }

        this.form.controls.duration_period.updateValueAndValidity();
      }
    );

    this.form.valueChanges.subscribe((values) => {
      const payload: Partial<FundValues> = {
        ...values,
        cvm_classification: Number(values.cvm_classification),
        anbima_classification: Number(values.anbima_classification),
        target_audience: Number(values.target_audience),
        financial_assets: Number(values.financial_assets),
        condominium_type: Number(values.condominium_type),
        fund_social_exercise: Number(values.fund_social_exercise),
        client_service: {
          ...this.fundService?.data?.client_service,
          profile: values.profile ? Number(values.profile) : null,
        },
        transfer: values.transfer,
      };

      this.fundService.setFundFormData({
        ...this.fundService.data,
        ...payload,
      });
    });

    this.form.statusChanges.subscribe((status) =>
      this.fundService.setFundFormStatus('basic', status === 'VALID')
    );

    this.setRandomData();
  }

  setRandomData() {
    this.signupService.fillFormEvent.subscribe(() => {
      const companyName = fakerbr.company.companyName();

      const eventType = fakerbr.random.number({ min: 1, max: 2 });

      const isTransfer = eventType === 2;

      this.form.patchValue({
        transfer: isTransfer,
        profile: String(fakerbr.random.number({ min: 1, max: 3 })),
        target_audience: String(fakerbr.random.number({ min: 1, max: 5 })),
        fund_social_exercise: String(
          fakerbr.random.number({ min: 1, max: 12 })
        ),
        transfer_date: isTransfer
          ? fakerbr.date.past(20).toLocaleDateString('pt-BR', {
              timeZone: 'UTC',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : null,
        assembly_date: isTransfer
          ? fakerbr.date.past(20).toLocaleDateString('pt-BR', {
              timeZone: 'UTC',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : null,
        structuring_fee_date: fakerbr.date
          .past(20)
          .toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        duration_period: fakerbr.date.past(20).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        cbm_constitution_date: fakerbr.date
          .past(20)
          .toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        last_company_name: isTransfer ? fakerbr.company.companyName() : null,
        last_company_document: isTransfer
          ? this.maskPipe.transform(fakerbr.br.cnpj(), '00.000.000/0000-00')
          : null,
        last_company_contact: isTransfer ? fakerbr.name.findName() : null,
        last_company_email: isTransfer ? fakerbr.internet.email() : null,
        last_company_telephone_ddi: isTransfer ? '55' : null,
        last_company_telephone_number: isTransfer
          ? this.maskPipe.transform(
              '139' +
                String(fakerbr.random.number({ min: 1111111, max: 9999999 })),
              '(00) 0000-0000'
            )
          : null,
        last_company_cellphone_ddi: isTransfer ? '55' : null,
        last_company_cellphone_number: isTransfer
          ? this.maskPipe.transform(
              '139' +
                String(fakerbr.random.number({ min: 11111111, max: 99999999 })),
              '(00) 00000-0000'
            )
          : null,
        name: companyName,
        fantasy_name: companyName,
        giin: fakerbr.random.number({ min: 100000, max: 99999999 }),
        document: this.maskPipe.transform(
          fakerbr.br.cnpj(),
          '00.000.000/0000-00'
        ),
        condominium_type: String(fakerbr.random.number({ min: 1, max: 2 })),
        financial_assets: String(fakerbr.random.number({ min: 1, max: 3 })),
        anbima_classification: String(
          fakerbr.random.number({ min: 1, max: 64 })
        ),
        cvm_classification: String(fakerbr.random.number({ min: 1, max: 25 })),
        constitution_date: fakerbr.date.past(20).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
      });
    });
  }

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice: any) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  setData(values: FundValues) {
    this.fund = values;

    if (values) {
      this.form.patchValue({
        ...values,
        structuring_fee_date: values.structuring_fee_date
          ? values.structuring_fee_date.split('-').reverse().join('/')
          : null,

        cbm_constitution_date: values.cbm_constitution_date
          ? values.cbm_constitution_date.split('-').reverse().join('/')
          : null,
        duration_period: values.duration_period
          ? values.duration_period.split('-').reverse().join('/')
          : null,
        transfer_date: values.transfer_date
          ? values.transfer_date.split('-').reverse().join('/')
          : null,
        assembly_date: values.assembly_date
          ? values.assembly_date.split('-').reverse().join('/')
          : null,
        cvm_classification: String(values.cvm_classification),
        anbima_classification: String(values.anbima_classification),
        target_audience: values.target_audience
          ? String(values.target_audience)
          : null,
        financial_assets: values.financial_assets
          ? String(values.financial_assets)
          : null,
        condominium_type: values.condominium_type
          ? String(values.condominium_type)
          : null,
        fund_social_exercise: values.fund_social_exercise
          ? String(values.fund_social_exercise)
          : null,
        profile: values.client_service.profile
          ? String(values.client_service.profile)
          : null,
        transfer: values.transfer_date ? true : false,
      });

      if (values.transfer_date) {
        this.form.controls.transfer_date.setValidators([
          Validators.required,
          Utils.validateMinDate,
          Utils.validateMaxDate,
        ]);
        this.form.controls.assembly_date.setValidators([
          Validators.required,
          Utils.validateMinDate,
          Utils.validateMaxDate,
        ]);
      }

      this.form.controls.transfer_date.updateValueAndValidity();
      this.form.controls.assembly_date.updateValueAndValidity();
    }

    this.fundService.setFundFormStatus('basic', this.form.valid);
  }

  formatDate(date: string) {
    return new Date(date).toLocaleDateString();
  }
}
