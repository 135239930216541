import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-document-model',
  templateUrl: './dashboard-document-model.component.html',
  styleUrls: ['./dashboard-document-model.component.scss'],
})
export class DashboardDocumentModelComponent implements OnInit {
  form = this.formBuilder.group({
    applicable: [null, [Validators.required]],
    name: [null, [Validators.required]],
    initial_date: [
      null,
      [Validators.required, Utils.validateMaxMinToday, Utils.validateMinDate],
    ],
    final_date: [
      null,
      [
        Validators.required,
        (control: FormControl) => Utils.validateMaxMinToday(control, false),
        Utils.validateMaxDate,
      ],
    ],
    specific_profile: [null, []],
    assignor_type: [null, []],
    occupation: [null, []],
    content: [null, [Validators.required]],
  });

  applicableItems = [
    { label: 'Todos', value: 'all' },
    { label: 'Cedente', value: 'assignor' },
    { label: 'Cotista', value: 'shareholder' },
    { label: 'Prestador de serviço', value: 'provider' },
    { label: 'Administração', value: 'admin' },
    { label: 'Avalista', value: 'guarantor' },
    { label: 'Representante', value: 'representative' },
  ];

  profileTypeItems = [
    { label: 'Sem coobrigação', value: '1' },
    { label: 'Com coobrigação', value: '2' },
  ];

  occupationItems = [{ label: 'Com coobrigação', value: '1' }];

  fundId = null;

  templateId = null;

  config = {
    theme: 'silver',
    height: '500',
    width: '100%',
    plugins:
      'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools contextmenu colorpicker textpattern help',
    toolbar:
      'formatselect fontselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify indent outdent | numlist bullist outdent indent | removeformat',
    menubar: true,
    statusbar: true,
    branding: true,
  };

  shareholders: any[] = [];
  assignors: any[] = [];
  fund: any = {};

  user_choices: { [key: string]: SelectItem[] } = {};

  loading = true;

  title = '';

  documentation: any;
  baseDocumentation: any;

  categorized = {};

  constructor(
    private formBuilder: FormBuilder,
    private routeParams: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private toast: ToastService,
    public fundService: FundService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((status) => {
      Utils.getErrors(this.form);
    });

    // this.generateDocumentation();

    this.fundId = this.routeParams.snapshot.params.id;
    this.templateId = this.routeParams.snapshot.params.templateId;
    this.getDocumentVariables();

    if (this.templateId) {
      this.setData();
    } else {
      this.getUsers();
    }
    this.getFund();

    this.form.controls.specific_profile.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.assignor_type.setValue(null);
        this.form.controls.assignor_type.setValidators([]);

        this.getSpecificProfileVariables(value);
      } else {
        if (this.form.controls.applicable.value === 'assignor') {
          this.form.controls.assignor_type.setValidators([Validators.required]);
        } else {
          this.form.controls.assignor_type.setValidators([]);
        }

        this.documentation = this.baseDocumentation;
      }

      this.form.controls.assignor_type.updateValueAndValidity();
      this.form.controls.assignor_type.reset();
    });

    this.form.controls.applicable.valueChanges.subscribe((value) => {
      this.form.controls.specific_profile.setValue(null);
      this.form.controls.specific_profile.setValidators([]);

      if (value === 'assignor') {
        this.form.controls.assignor_type.setValidators([Validators.required]);
        this.form.controls.occupation.setValidators([]);

        this.form.controls.occupation.setValue(null);
      }

      if (value === 'shareholder') {
        this.form.controls.assignor_type.setValidators([]);
        this.form.controls.occupation.setValidators([]);

        this.form.controls.assignor_type.setValue(null);
        this.form.controls.occupation.setValue(null);
      }

      if (value === 'provider') {
        this.form.controls.assignor_type.setValidators([]);
        this.form.controls.occupation.setValidators([]);
        this.form.controls.specific_profile.setValidators([
          Validators.required,
        ]);

        this.form.controls.assignor_type.setValue(null);
      }

      this.form.controls.assignor_type.updateValueAndValidity();
      this.form.controls.occupation.updateValueAndValidity();
      this.form.controls.specific_profile.updateValueAndValidity();
      this.form.controls.specific_profile.setValue(null);
    });

    this.getAvaiableUsers();
  }

  async getAvaiableUser() {
    try {
      const { data } = await this.api.get<ApiResponse<Register[]>>({
        route: 'api/registration/register/',
        token: true,
        params: {
          in__approval: true,
          //   role__applicable: applicable,
        },
      });

      return data;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');

      return [];
    }
  }

  async getSpecificProfileVariables(value: string) {
    try {
      const res = await this.api.get({
        route: `api/registration/new/fund/${this.fundId}/variables/?register=${value}`,
        token: true,
      });

      this.filterDocumentation(res);
    } catch (error) {
      console.error(error);
    }
  }

  filterDocumentation(res) {
    const flatted = this.flattenObject(res);

    let newCategorized = [];

    Object.keys(flatted).forEach((key) => {
      const category = key.split('.')[0];

      const findedIndex = newCategorized.findIndex(
        (item) => item.title === category
      );

      if (findedIndex !== -1) {
        const index = newCategorized.findIndex(
          (item) => item.title === category
        );

        newCategorized[index].keys.push({
          label: key,
          value: flatted[key],
        });
      } else {
        newCategorized = [
          ...newCategorized,
          {
            title: category,
            keys: [
              {
                label: key,
                value: flatted[key],
              },
            ],
          },
        ];
      }
    });

    this.documentation = newCategorized;
  }

  async getAvaiableUsers() {
    try {
      const res = await this.getAvaiableUser();

      const new_users_choices = {};

      res.map((item: Register) => {
        const name =
          item.type === 'PF' || item.type === null
            ? item.person.full_name
            : item.company.corporate_name;

        const object = {
          value: item.uuid,
          label: name,
          extra: item,
        };

        const arr = new_users_choices[item.role.applicable]
          ? new_users_choices[item.role.applicable]
          : [];

        new_users_choices[item.role.applicable] = [...arr, object];
      });

      this.user_choices = new_users_choices;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async setData() {
    try {
      const { data } = await this.api.get({
        route: `api/dashboard/draft/${this.templateId}/`,
        token: true,
      });

      let assignorType = null;

      if (data.applicable === 'assignor') {
        if (!data.user) {
          assignorType = data.coobligation ? '2' : '1';
        }
      }

      this.form.patchValue({
        applicable: data.applicable,
        specific_profile: data.register,
        assignor_type: assignorType,
        content: data.content,
        name: data.name,
        initial_date: data.initial_date
          ? data.initial_date.split('-').reverse().join('/')
          : null,
        final_date: data.final_date
          ? data.final_date.split('-').reverse().join('/')
          : null,
      });

      if (data.applicable === 'provider') {
      }

      this.title = data.name;
    } catch (error) {
      console.error(error);
    }
  }

  async getFund() {
    try {
      const res = await this.api.get({
        route: 'api/dashboard/fund',
        token: true,
        params: {
          id: this.fundId,
        },
      });

      this.fund = res;
    } catch (error) {
      console.error(error);
    }

    this.loading = false;
  }

  getBackLink() {
    return `/app/products/fund/approval/${this.fundId}`;
  }

  redirect() {
    this.router.navigate([`/app/products/fund/approval/${this.fundId}`], {
      state: { step: 5 },
    });
  }

  getDisabled() {
    return !this.form.valid;
  }

  async getUsers() {
    try {
      const res = await this.api.get({
        route: 'api/approvals/user/pending',
        token: true,
      });

      const shareholders: any[] = [];
      const assignors: any[] = [];

      res.forEach((requested: any) => {
        const newRequested = { ...requested };
        newRequested.departments.sort((a: any, b: any) => {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });

        const applicableSlug = newRequested.queue.applicable;

        let name =
          newRequested.user.first_name + ' ' + newRequested.user.last_name;

        if (
          newRequested.user.type === 'PJ' ||
          newRequested.user.type === 'pj'
        ) {
          name = newRequested.general.corporate_name;
        }

        if (applicableSlug.includes('SHAREHOLDER')) {
          shareholders.push({
            value: newRequested.user.id,
            label: `${this.formatDocument(
              newRequested.general.document
            )} | ${name}`,
          });
        }

        if (applicableSlug.includes('ASSIGNOR')) {
          assignors.push({
            value: newRequested.user.id,
            label: `${
              newRequested.general
                ? this.formatDocument(newRequested.general.document)
                : ''
            } |  ${name}`,
          });
        }
      });

      this.shareholders = shareholders;
      this.assignors = assignors;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  formatDocument(document: any) {
    if (document) {
      if (document.type === 'CNPJ') {
        return document.number.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          '$1.$2.$3/$4-$5'
        );
      } else {
        return document.number.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
          '$1.$2.$3-$4'
        );
      }
    } else {
      return '';
    }
  }

  async createTemplate() {
    const payload = {
      name: this.form.controls.name.value,
      codiname: '',
      content: this.form.controls.content.value,
      coobligation:
        this.form.controls.applicable.value === 'assignor' &&
        this.form.controls.assignor_type.value === '2'
          ? true
          : false,
      register: this.form.controls.specific_profile.value,
      fund: this.fundId,
      applicable: this.form.controls.applicable.value,
      final_date: this.form.controls.final_date.value
        .split('/')
        .reverse()
        .join('-'),
      initial_date: this.form.controls.initial_date.value
        .split('/')
        .reverse()
        .join('-'),
    };

    try {
      if (this.templateId) {
        await this.api.patch({
          route: `api/dashboard/draft/${this.templateId}/`,
          token: true,
          body: payload,
        });
      } else {
        await this.api.post({
          route: `api/dashboard/draft/`,
          token: true,
          body: payload,
        });
      }

      this.toast.show(
        'info',
        'Sucesso',
        this.templateId
          ? 'Template atualizado, já está disponível para ser utilizado pelo sistema'
          : 'Um novo template foi criado e já está disponível para ser utilizado pelo sistema'
      );

      this.redirect();
    } catch (error) {
      console.error(error);
    }
  }

  generateEndereco(tipo) {
    return {
      logradouro: `Logradouro do ${tipo}`,
      numero: `Número do ${tipo}`,
      complemento: `Complemento do ${tipo}`,
      bairro: `Bairro do ${tipo}`,
      cidade: `Cidade do ${tipo}`,
      estado: `Estado do ${tipo}`,
      cep: `CEP do ${tipo}`,
    };
  }

  generateUsuario(tipo) {
    return {
      nome: `Nome completo do ${tipo}`,
      documento: `Número do documento do ${tipo}`,
      endereco: this.generateEndereco(tipo),
    };
  }

  generateData() {
    return {
      dia: `Dia`,
      mes: `Mês`,
      ano: `Ano`,
      hora: `Hora`,
      minutos: `Minutos`,
    };
  }

  generateFundo(tipo) {
    return {
      nome: `Nome do ${tipo}`,
      documento: `Número do documento do ${tipo}`,
    };
  }

  generateBank(tipo) {
    return {
      banco: `Nome do banco do ${tipo}`,
      agencia: `Número da agência do ${tipo}`,
      conta: `Número da conta do ${tipo}`,
    };
  }

  generateDocumentationArray(obj, prefix) {
    const result = [];
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object') {
        result.push(
          ...this.generateDocumentationArray(obj[key], `${prefix}${key}.`)
        );
      } else {
        result.push(`{{${prefix}${key}}}: ${obj[key]}`);
      }
    });
    return result;
  }

  generatePrimaryArray = (obj) => {
    const array = [];

    Object.keys(obj).forEach((key) => {
      array.push({
        name: key,
        children: this.generateDocumentationArray(obj[key], `${key}.`),
      });
    });

    return array;
  };

  generateDocumentation() {
    const documentation = {
      fundo: { ...this.generateFundo('Fundo'), ...this.generateBank('Fundo') },
      cedente: {
        ...this.generateUsuario('Cedente'),
        ...this.generateBank('Cedente'),
      },
      administrador: this.generateUsuario('Administrador'),
      gestor: this.generateUsuario('Gestor'),
      consultor: this.generateUsuario('Consultor'),
      data: this.generateData(),
    };

    this.documentation = this.generatePrimaryArray(documentation);
  }

  async getDocumentVariables() {
    try {
      const res = await this.api.get({
        route: `api/registration/new/fund/${this.fundId}/variables/`,
        token: true,
      });

      const flatted = this.flattenObject(res);

      let newCategorized = [];

      Object.keys(flatted).forEach((key) => {
        const category = key.split('.')[0];

        const findedIndex = newCategorized.findIndex(
          (item) => item.title === category
        );

        if (findedIndex !== -1) {
          const index = newCategorized.findIndex(
            (item) => item.title === category
          );

          newCategorized[index].keys.push({
            label: key,
            value: flatted[key],
          });
        } else {
          newCategorized = [
            ...newCategorized,
            {
              title: category,
              keys: [
                {
                  label: key,
                  value: flatted[key],
                },
              ],
            },
          ];
        }
      });

      this.documentation = newCategorized;
      this.baseDocumentation = newCategorized;
    } catch (error) {
      console.error(error);
    }
  }

  flattenObject(ob) {
    var toReturn = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (typeof ob[i] == 'object' && ob[i] !== null) {
        var flatObject = this.flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }

  renderValue(value) {
    return `{{${value}}}`;
  }
}
