import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { MaskPipe } from 'ngx-mask';
import { FundService } from 'src/app/services/fund/fund.service';

@Component({
  selector: 'app-dashboard-fund-approval-assignor',
  templateUrl: './dashboard-fund-approval-assignor.component.html',
  styleUrls: ['./dashboard-fund-approval-assignor.component.scss'],
})
export class DashboardFundApprovalAssignorComponent implements OnInit {
  @Input() approval: any;

  @Output() onClick = new EventEmitter<MouseEvent>();

  status_dict = {
    INITIATED: 'Iniciado',
    AVAIABLE: 'Disponível',
    REQUESTED: 'Solicitado',
    APPROVED: 'Aprovado',
    REJECTED: 'Rejeitado',
  };

  columns: TableColumnList<any>[] = [
    {
      name: 'Cedente',
      cell: (row) => `
    <div class="dash-card-item p-0">
        <div class="dash-card-item-initials mr-3">
            <span>${this.getInitials(row.register)}</span>
        </div>

        <div class="dash-card-item-content">
            <div class="dash-card-item-content-title">
                ${
                  row.register.type === 'PF'
                    ? row.register.person.full_name
                    : row.register.company.corporate_name
                } 
            </div>
            <div class="dash-card-item-content-subtitle">
                ${this.formatDocument(row.register)}
            </div>
        </div>
    </div>
        `,
      size: 2,
    },
    {
      name: 'Status',
      cell: (row) => this.status_dict[row.status],
    },
    {
      name: 'Última atualização',
      cell: (row) => this.formatDate(row.updated_at),
    },
    {
      name: '',
      cell: (row) =>
        `<div class="d-flex align-items-center icon-container">
      <div class="assignor-visualizer row-${row.id}">
          <i class="fas fa-eye"></i>
      </div>
              </div>`,
    },
  ];
  data: Partial<ShareholderListValues>[] = [];

  constructor(
    public fundService: FundService,
    private maskPipe: MaskPipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.data = this.fundService.data.links;
  }

  ngAfterViewInit() {
    this.setHandlers();
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.setHandlers();
    }, 1000);
  }

  setHandlers() {
    const visualizer = document.querySelectorAll('.assignor-visualizer');

    visualizer.forEach((button) => {
      button.addEventListener('click', (event) => {
        const el: any = event.currentTarget;
        const idClass: any = el.classList.item(1);
        const id = idClass.split('-')[1];

        const finded = this.data.find((item: any) => {
          if (item.id) {
            return Number(item.id) === Number(id);
          }

          return false;
        });

        if (finded) {
          this.handleRedirect(finded);
        }
      });
    });
  }

  handleRedirect(assignor: any) {
    this.router.navigateByUrl(
      '/app/details/assignor/' + assignor.register.uuid
    );
  }

  formatDate(date: string) {
    return date.split('T')[0].split('-').reverse().join('/');
  }

  formatDocument(user: any) {
    if (user.type === 'PF') {
      return this.maskPipe.transform(
        user.person.document.number,
        '000.000.000-00'
      );
    } else {
      return this.maskPipe.transform(
        user.company.document.number,
        '00.000.000/0000-00'
      );
    }
  }

  getInitials(user: any) {
    if (user.type === 'PF') {
      const full_name = user.person.full_name;

      return full_name
        .toUpperCase()
        .replace(/[^\w\s]/gi, '')
        .split(' ')
        .slice(0, 2)
        .map((word: string) => word.charAt(0))
        .join('');
    } else {
      const corporate_name = user.company.corporate_name;

      return corporate_name
        .toUpperCase()
        .replace(/[^\w\s]/gi, '')
        .split(' ')
        .slice(0, 2)
        .map((word: string) => word.charAt(0))
        .join('');
    }
  }
}
