<div class="row">
    <div class="col-12">
        <div class="dash-details-card">
            <div class="dash-details-card-header text-right">
                <div></div>
                <app-button [inverted]="true" (click)="addNewDocument()"
                    *ngIf="applicableRole === 'ADM'">
                    Criar template
                </app-button>
            </div>

            <app-spacer [bottom]="30"></app-spacer>
            <table class="bordered" *ngIf="data.length > 0">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Destino</th>
                        <th>Início da vigência</th>
                        <th>Fim da vigência</th>
                        <th>Última atualização</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of data">
                        <td>{{item.file}}</td>
                        <td>{{formatApplicable(item.applicable)}}</td>
                        <td>{{formatDate(item.initial_date,false)}}</td>
                        <td>{{formatDate(item.final_date,false)}}</td>
                        <td>{{formatDate(item.date_updated)}}</td>
                        <td>
                            <div class="dash-card-item-buttons">
                                <button class="dash-item-button"
                                    (click)="handleDelete(item.id)">
                                    <i class="fas fa-trash"></i>
                                </button>
                                <app-spacer [right]="16"></app-spacer>

                                <button class="dash-item-button"
                                    (click)="handleEdit(item.id)">
                                    <i class="fas fa-pen"></i>
                                </button>
                                <app-spacer [right]="16"></app-spacer>

                                <button class="dash-item-button"
                                    (click)="handleOpenVisualizer(item.id)">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <app-spacer [bottom]="28"></app-spacer>
            </table>

            <div *ngIf="data.length === 0" class="card-padding-bottom">
                <span class="dash-card-item-content-title text-center d-block">
                    Nenhum documento registrado
                </span>
            </div>
        </div>
        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>

<app-document-visualizer-modal [active]="showDocument" [file]="document"
    (onClose)="closeModal()" *ngIf="document">
</app-document-visualizer-modal>