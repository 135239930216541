<form [formGroup]="form">
    <div class="row">
        <div class="col-12 col-lg-9">
            <app-input [rows]="3" label="Taxa de administração" [group]="form"
                controlName="administration_fee">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-9">
            <app-input [rows]="3" label="Taxa de custódia" [group]="form"
                controlName="custody_fee">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-9">
            <app-input [rows]="3" label="Taxa de controladoria" [group]="form"
                controlName="controllership_fee">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-9">
            <app-input [rows]="3" label="Taxa de escrituração" [group]="form"
                controlName="bookkeeping_fee">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-9">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <app-currency-input label="Taxa geral" [group]="form"
                        controlName="general_fee" placeholder="R$ 1.000,00">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>


                <div class="col-12 col-lg-4">
                    <app-currency-input label="Taxa de Estruturação"
                        [group]="form" controlName="structuring_fee"
                        placeholder="R$ 1.000,00">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-4">
                    <app-masked-input [group]="form"
                        controlName="structuring_fee_date" mask="00/00/0000"
                        label="Primeira integralização de cotas"
                        placeholder="dd/mm/aaaa">
                    </app-masked-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-4">
                    <app-currency-input label="Taxa de Implantação sistêmica"
                        [group]="form" controlName="implantation_fee"
                        placeholder="R$ 1.000,00">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-4">
                    <app-currency-input label="Taxa de gestão" [group]="form"
                        controlName="management_fee" placeholder="R$ 1.000,00">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-4">
                    <app-currency-input label="Taxa de distribuição"
                        [group]="form" controlName="distribution_fee"
                        placeholder="R$ 1.000,00">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-4">
                    <app-currency-input label="Taxa de Verificação de Lastro"
                        [group]="form" controlName="check_fee"
                        placeholder="R$ 1.000,00">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-4">
                    <app-input label="Taxa do agente de cobrança" [group]="form"
                        controlName="agent_fee">
                    </app-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-4">
                    <app-input label="Taxa de consultoria" [group]="form"
                        controlName="consultancy_fee">
                    </app-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12">
                    <app-currency-input label="Total de taxas" [group]="form"
                        controlName="total_fee" placeholder="R$ 1.000,00">
                    </app-currency-input>
                    <app-spacer [bottom]="48"></app-spacer>
                </div>
            </div>
        </div>

        <div class="col-4">
            <app-radio [multiple]="true" formControlName="other_tax">
                Outras taxas
            </app-radio>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <ng-container *ngIf="form.controls.other_tax.value">
            <div class="col-12 col-lg-9">
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <app-masked-input label="Performance %" [group]="form"
                            controlName="performance_tax" mask="0*.00">
                        </app-masked-input>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-masked-input label="Entrada %" [group]="form"
                            controlName="entry_tax" mask="0*.00">
                        </app-masked-input>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-masked-input label="Saída %" [group]="form"
                            controlName="out_tax" mask="0*.00">
                        </app-masked-input>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-12">
                        <app-input [rows]="3" [group]="form"
                            controlName="other_tax_description">
                        </app-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-6">
                        <app-select [items]="fundService.choices.charge_on_choices"
                            label="Cobrança de outras será sobre" [group]="form"
                            controlName="charge_type">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</form>
