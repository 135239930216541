import { ApiService } from 'src/app/services/api/api.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { SignupService } from 'src/app/services/signup/signup.service';
import fakerbr from 'faker-br';
import { distinctUntilChanged } from 'rxjs/operators';
import Utils, { GetHelper } from 'src/app/helpers/utils';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-signup-unified-company-complementary-bank',
  templateUrl: './signup-unified-company-complementary-bank.component.html',
  styleUrls: ['./signup-unified-company-complementary-bank.component.scss'],
})
export class SignupUnifiedCompanyComplementaryBankComponent
  implements OnInit, OnDestroy
{
  @Input() userRole: string;

  form = this.formBuilder.group({
    bank: [null, [Validators.required]],
    agency: [null, [Validators.required]],
    checking_account: [null, [Validators.required]],
    checking_account_digit: [null, []],

    main_account: [false, []],
  });

  showForm = true;

  columns: TableColumn<BankResponse>[] = [
    {
      name: 'Banco',
      cell: (row) => `
      <span>${row.bank ? this.formatBankName(row.bank.name) : ''}</span>
        <span class="table-subtitle">${
          row.main_account ? 'Conta Principal' : ''
        }</span>
      `,
    },
    { name: 'Agência', cell: (row) => row.agency },
    {
      name: 'Conta',
      cell: (row) =>
        row.checking_account_digit
          ? `${row.checking_account}-${row.checking_account_digit}`
          : row.checking_account,
    },
  ];

  data: BankResponse[] = [];

  banks: Bank[] = [];

  addAnotherModal = false;
  confirmMainAccountModal = false;

  removeModal = false;
  selectedIndex = 0;

  loading = true;

  sending = false;

  event: any;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.signupService.choicesFetchedEvent.subscribe((choices) => {
      this.loading = false;
    });

    this.event = this.signupService.sendComplementaryCompanyData.subscribe(
      (value) => {
        if (value === 'complementaryBank') {
          this.signupService.setComplementaryCompanyStep(
            'complementaryComposition'
          );
          this.signupService.changeCompanyStepEvent.emit('composition');
        }
      }
    );

    this.getDataAndFillForm();

    this.banks = this.signupService.signupData.banks;

    this.form.controls.main_account.valueChanges.subscribe((value) => {
      if (value) {
        const hasMainAccount = this.data.some((item) => item.main_account);

        if (hasMainAccount) {
          this.confirmMainAccountModal = true;
        }
      }
    });
    this.form.controls.bank.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (value !== null) {
          this.form.controls.bank.setValidators([Validators.required]);
          this.form.controls.agency.setValidators([Validators.required]);
          this.form.controls.checking_account.setValidators([
            Validators.required,
          ]);
        } else {
          const validators =
            this.userRole !== 'provider' ? [Validators.required] : [];

          this.form.controls.bank.setValidators(validators);
          this.form.controls.agency.setValidators(validators);
          this.form.controls.checking_account.setValidators(validators);
          this.form.controls.checking_account_digit.setValidators([]);

          this.form.controls.bank.setValue(null);
          this.form.controls.agency.setValue(null);
          this.form.controls.checking_account_digit.setValue(null);
          this.form.controls.checking_account.setValue(null);
        }

        this.form.controls.bank.updateValueAndValidity();
        this.form.controls.agency.updateValueAndValidity();
        this.form.controls.checking_account_digit.updateValueAndValidity();
        this.form.controls.checking_account.updateValueAndValidity();
      });

    this.signupService.fillFormEvent.subscribe(() => {
      this.form.patchValue({
        bank: String(fakerbr.random.number({ min: 1, max: 320 })),
        agency: String(fakerbr.random.number({ min: 1000, max: 9999 })),
        checking_account_digit: String(
          fakerbr.random.number({ min: 1, max: 9 })
        ),
        checking_account: String(
          fakerbr.random.number({ min: 1000000, max: 9999999 })
        ),
      });
    });
  }

  async getDataAndFillForm() {
    this.loading = true;
    try {
      const { data } = await this.api.get<ApiResponse<BankResponse[]>>({
        route: 'api/registration/bank_information/',
        token: true,
      });

      this.showForm = !(data.length > 0);

      this.signupService.setCompanyFormStatus(
        'complementaryBank',
        data.length > 0
      );

      this.data = data;
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
    this.loading = false;
  }

  async sendData() {
    this.sending = true;
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const main_account = valuesHelper.get('main_account') ?? false;

      const filteredBanks = this.data.filter(
        (item) => item.bank.id === Number(valuesHelper.get('bank'))
      );

      const bankExists = filteredBanks.map((item) => {
        const agencyExistis =
          String(item.agency) === valuesHelper.get('agency');
        const checkingAccountExists =
          String(item.checking_account) ===
          valuesHelper.get('checking_account');
        const checkingAccountDigitExists =
          item.checking_account_digit ===
          valuesHelper.get('checking_account_digit');

        return (
          agencyExistis && checkingAccountExists && checkingAccountDigitExists
        );
      });

      if (bankExists.some((item) => item)) {
        this.toast.show('error', 'Erro', 'Banco já cadastrado');
        this.handleCloseModal();
        return;
      }

      if (main_account && this.data.length > 0) {
        const idx = this.data.findIndex((item) => item.main_account);

        await this.api.patch<ApiResponse<BankResponse>>({
          route: `api/registration/bank_information/${this.data[idx].id}/`,
          token: true,
          body: {
            main_account: false,
          },
        });
      }

      const payload = {
        bank: valuesHelper.get('bank'),
        agency: Number(Utils.onlyNumbers(valuesHelper.get('agency'))),
        checking_account: Number(
          Utils.onlyNumbers(valuesHelper.get('checking_account'))
        ),
        checking_account_digit: valuesHelper.get('checking_account_digit')
          ? Number(
              Utils.onlyNumbers(valuesHelper.get('checking_account_digit'))
            )
          : null,
        joint_account: false,
        account_owner: true,
        cpf: '',
        joint_holders_identification: '',
        main_account: this.data.length > 0 ? main_account : true,
        account_type: '1',
      };

      console.table(valuesHelper.getRemainingObject());

      const response = await this.api.post<ApiResponse<BankResponse>>({
        route: 'api/registration/bank_information/',
        token: true,
        body: payload,
      });

      this.handleCloseModal();

      this.toast.show('info', 'Sucesso', response.message);

      this.getDataAndFillForm();
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.sending = false;
  }

  handleOpenRemoveModal(index: number) {
    this.removeModal = true;
    this.selectedIndex = index;
  }

  handleRemoveCancel() {
    this.removeModal = false;
    this.confirmMainAccountModal = false;
    this.selectedIndex = 0;
  }

  handleConfirm(value: boolean) {
    this.confirmMainAccountModal = false;
    this.form.controls.main_account.setValue(value);

    this.form.controls.main_account.updateValueAndValidity();
  }

  async removeBank(index: number) {
    try {
      const findedBank = this.data[index];

      await this.api.delete({
        route: `api/registration/bank_information/${findedBank.id}/`,
        token: true,
      });

      this.removeModal = false;

      this.toast.show('info', 'Sucesso', 'Conta removida com sucesso');

      const filtered = this.data.filter((item) => item.id !== findedBank.id);
      const haveMainAccount = filtered.some((item) => item.main_account);

      if (filtered.length >= 1 && !haveMainAccount) {
        if (!filtered[0].main_account) {
          await this.api.patch<ApiResponse<BankResponse>>({
            route: `api/registration/bank_information/${filtered[0].id}/`,
            token: true,
            body: {
              main_account: true,
            },
          });
        }
      }

      this.getDataAndFillForm();
    } catch (error) {
      this.toast.show('error', 'Erro', error.error.message);
    }
  }

  getDisabled() {
    if (this.addAnotherModal) {
      return this.form.invalid || !this.form.controls.bank.value;
    }

    if (this.showForm) {
      if (this.form.controls.bank.value === null) {
        return true;
      } else {
        return this.form.invalid;
      }
    }

    return false;
  }

  triggerForm() {
    if (this.addAnotherModal) {
      this.sendData();
    } else {
      if (this.data.length === 0) {
        this.sendData();
      } else {
        this.addAnotherModal = true;
      }
    }
  }

  closeModal() {
    this.addAnotherModal = false;
  }

  handleCloseModal() {
    this.addAnotherModal = false;
    this.form.reset();
  }

  formatBankName(bankName: string) {
    if (bankName.length > 48) {
      const words = bankName.split(' ');
      const firstLine = words.slice(0, 6).join(' ');
      const secondLine = words.slice(6, words.length).join(' ');

      return secondLine.length > 0
        ? `${firstLine} <br> ${secondLine}`
        : `${firstLine}`;
    }
    return bankName;
  }
}
