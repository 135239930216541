<div class="warning-modal" [class.active]="active">
    <div class="warning-modal-background"></div>

    <div class="warning-modal-container styled-scroll"
        [class.largeSize]="size === 'lg'" [class.hasBorder]="hasBorder">

        <button class="warning-modal-close" (click)="click($event)">
            <i class="fas fa-times"></i>
        </button>

        <ng-content>

        </ng-content>
    </div>


</div>