<div class="app-dropzone-container">
    <div class="app-dropzone-entry" ngx-dropzone
        [accept]="'image/jpeg,image/jpg,image/png,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
        (change)="onSelect($event)" [maxFileSize]="maxFileSize">
        <ngx-dropzone-label [class.d-none]="files.length > 0">
            <div>
                <p class="m-0 app-dropzone-icon"><i
                        class="fas fa-file-upload"></i></p>
                <p class="m-0 app-dropzone-label">Arraste o documento até aqui
                    ou clique para procurar</p>
            </div>
        </ngx-dropzone-label>
        <div *ngFor="let file of files" class="app-dropzone-preview">
            <img title="Preview image" [src]="file.url"
                class="app-dropzone-image bg-secondary rounded" />
            <button title="Remove button" class="app-dropzone-delete"
                (click)="onRemove(file, $event)">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <p class="app-dropzone-helper-text">{{description}}</p>
</div>