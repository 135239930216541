import { SignupService } from 'src/app/services/signup/signup.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Utils from 'src/app/helpers/utils';
import { MaskPipe } from 'ngx-mask';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-approval-complementary',
  templateUrl: './dashboard-approval-complementary.component.html',
  styleUrls: ['./dashboard-approval-complementary.component.scss'],
})
export class DashboardApprovalComplementaryComponent implements OnInit {
  @Input() approval: UserApprovalResponse;
  @ViewChild('modalRoot') modalRoot;

  bankColumns: TableColumnList<Bankinformation>[] = [
    {
      name: 'Banco',
      cell: (row) => `
      <span>${row.bank.name}</span>
      <span class="app-table-details-body-row-subtitle">${row.agency} ${
        row.checking_account
      }${
        row.checking_account_digit ? '-' + row.checking_account_digit : ''
      }</span>
    `,
      size: 2,
    },
    {
      name: 'Tipo de conta',
      cell: (row) => `
      <span>${row.account_type_display}</span>
      <span class="app-table-details-body-row-subtitle">${
        row.main_account ? 'Conta principal' : ''
      }</span>`,
    },
    {
      name: 'Co-titular',
      cell: (row) => row.joint_holders_identification,
    },
    { name: 'Documento', cell: (row) => row.cpf },
  ];
  bankData: Bankinformation[] = [];

  economicGroupColumns: TableColumnList<Economicgroup>[] = [
    { name: 'Razão Social', cell: (row) => row.corporate_name.toUpperCase() },
    { name: 'CNPJ', cell: (row) => this.formatCnpj(row.cnpj) },
  ];

  economicGroupData: Economicgroup[] = [];

  pepColumns: TableColumnList<CompanyComplementaryPepListValues>[] = [
    {
      name: 'Nome',
      cell: (row) => `
    <span>${row.name}</span>
    <span class="app-table-details-body-row-subtitle">${row.document}</span>
  `,
    },
    { name: 'Vínculo', cell: (row) => row.bound },
  ];

  pepData: CompanyComplementaryPepListValues[] = [];

  thirdPartyColumns: TableColumnList<CompanyComplementaryThirdPartiesListValues>[] =
    [
      { name: 'Nome', cell: (row) => row.name },
      { name: 'Documento', cell: (row) => row.document },
    ];

  thirdPartyData: CompanyComplementaryThirdPartiesListValues[] = [
    {
      name: '0*',
      document: '0*',
    },
    {
      name: '0*',
      document: '0*',
    },
  ];

  role = '';

  isSimplified = false;

  cards: any;

  isFund = false;

  file: any = null;

  isAdmin = false;

  isPDF = false;
  url: any;
  isXLS = false;

  constructor(
    private signupService: SignupService,
    private maskPipe: MaskPipe,
    private router: Router,
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.bankData = this.approval.register.bank_information;
    this.economicGroupData = this.approval.register.economic_group;
    this.pepData =
      this.approval.register.person &&
      this.approval.register.person.pep_relationship
        ? [this.approval.register.person.pep_relationship]
        : null;
    this.cards = this.approval.cards;
    this.isSimplified = this.approval.register.is_simplified;
    let range = this.router.url.split('/').length;
    this.role = this.router.url.split('/')[range - 2];
    const user = this.authService.getUser();

    const register = user.actived_register
      ? user.actived_register.register
      : user;

    this.isAdmin = user ? register.role.slug.includes('ADM') : false;
    this.isFund = register.role.slug.includes('PJ-FI');
  }

  getPointsName(points: any) {
    if (points) {
      if (points >= 9 && points <= 18) {
        return 'Conservador';
      } else if (points >= 19 && points <= 28) {
        return 'Moderado';
      } else if (points > 28) {
        return 'Agressivo';
      } else {
        return 'Insuficiente';
      }
    }
    return 'Não possui';
  }

  getPointsColor(points: any) {
    if (points) {
      if (points >= 9 && points <= 18) {
        return '#2ecc71';
      } else if (points >= 19 && points <= 28) {
        return '#f1c40f';
      } else if (points > 28) {
        return '#e74c3c';
      } else {
        return '#c0392b';
      }
    }
    return '#f1c40f';
  }

  formatPhone(phone) {
    if (phone.length === 11) {
      return this.maskPipe.transform(phone, '(00) 00000-0000');
    } else {
      return this.maskPipe.transform(phone, '(00) 0000-0000');
    }
  }

  getChoicesName(object: string, value: any) {
    const choices = this.signupService.signupChoices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  formatBrl(amount: number | string) {
    const formatted = Utils.formatBrl(Number(amount));

    return formatted;
  }

  formatCnpj(value: string) {
    const newValue = this.maskPipe.transform(value, '00.000.000/0000-00');

    return newValue;
  }

  formatCpf(obj: any) {
    let newValue = obj.number;

    if (obj.type.name === 'CPF') {
      newValue = this.maskPipe.transform(obj.number, '000.000.000-00');
    }

    return newValue;
  }

  formatRg(item: any) {
    const newValue = this.maskPipe.transform(item.number, '00.000.000-0');
    const agency = item.dispatching_agency.split(' ')[0];

    return `${newValue} ${agency}-${item.uf}`;
  }

  formatDocument(item: any) {
    let newValue = item.number;
    let agency = item.dispatching_agency.split(' ')[0];
    let docType = this.getChoicesName('document_type', item.type)?.label;

    if (item.type === '34') {
      newValue = this.maskPipe.transform(item.number, '00.000.000-0');
      docType = 'RG';
    }

    if (item.type === '1') {
      docType = 'CNH';
    }

    return `${docType} ${newValue} ${agency}-${item.uf}`;
  }

  handleSelect(idx: any, cardSlug: string) {
    this.file = this.cards[cardSlug].files[idx];
    this.getImgUrl();
  }

  getImgUrl() {
    const regex = /\.([0-9a-z]+)(?:[\?#]|$)/i;

    const resultado = regex.exec(this.file.file);

    if (resultado && resultado[1]) {
      const extension = resultado[1].toLowerCase();

      switch (extension) {
        case 'pdf':
          this.isPDF = true;
          break;
        case 'xls':
        case 'xlsx':
          this.isXLS = true;
          break;
        default:
          this.isPDF = false;
          this.isXLS = false;
          break;
      }
    }

    let url: any = this.file.file;

    if (this.isPDF) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(this.file.file);
    }

    if (this.isXLS) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${this.file.file}`
      );
    }

    this.url = url;

    this.modalRoot.show();
  }
}
