<form [formGroup]="form">

    <div class="row no-gutters">
        <div class="offset-lg-1 col-lg-8">
            <ng-container *ngIf="loading">
                <ngx-skeleton-loader appearance="circle" [theme]="{
                    height: '150px',
                    width: '400px',
                    'border-radius': '10px'
                  }">
                </ngx-skeleton-loader>
                <app-spacer [bottom]="24"></app-spacer>
                <ngx-skeleton-loader appearance="circle" [theme]="{
                    height: '40px',
                    width: '300px',
                    'border-radius': '10px'
                }"></ngx-skeleton-loader>

                <app-spacer [bottom]="24"></app-spacer>

                <ngx-skeleton-loader></ngx-skeleton-loader>

                <app-spacer [bottom]="24"></app-spacer>

                <ngx-skeleton-loader count="7" [theme]="{
                    height: '40px',
                    'border-radius': '10px'
                }"></ngx-skeleton-loader>
            </ng-container>
        </div>
    </div>


    <ng-container *ngIf="!loading">
        <ng-container *ngIf="!haveToken">
            <div class="row no-gutters">
                <div class="offset-1 col-8">
                    <span class="basic-content-title">
                        Para começar seu cadastro, informe seus dados pessoais
                        abaixo
                    </span>
                </div>
            </div>
            <app-spacer [bottom]="24"></app-spacer>
        </ng-container>

        <div class="row no-gutters">
            <div class="offset-1 col-8">
                <h1>{{name}}</h1>
                <app-spacer [bottom]="16"></app-spacer>
                <span class="basic-content-alternative" *ngIf="haveToken">
                    Você está recebendo o convite para cadastrar
                    {{renderText()}}

                    da {{'brand.name' | theme}}.
                    Para continuar, entre com seus dados de acesso ou preencha o
                    formulário abaixo para criar sua conta
                    de
                    usuário.
                </span>
                <app-spacer [bottom]="24"></app-spacer>
                <app-button (onClick)="backToLogin()">
                    Já tem cadastro? Faça sua autenticação
                </app-button>

                <app-spacer [bottom]="24"></app-spacer>
                <div class="divider">
                    <div></div>
                    <p>ou</p>
                    <div></div>
                </div>
            </div>

        </div>

        <app-spacer [bottom]="24"></app-spacer>

        <div class="row no-gutters">
            <div class="col-12 offset-lg-1 col-lg-11">
                <div class="row">
                    <div class="col-12 col-lg-8">
                        <app-select label="Fundo de interesse" [group]="form"
                            controlName="fund" [items]="fundOptions"
                            [readOnly]="haveFund">
                        </app-select>
                        <app-spacer [bottom]="27"></app-spacer>
                    </div>
                </div>

                <div class="row" *ngIf="userRole === 'provider'">
                    <div class="col-12 col-lg-8">
                        <app-select label="Papel" [group]="form"
                            controlName="agent" [multiple]="true"
                            [items]="agentOptions" [readOnly]="haveRole">
                        </app-select>
                        <app-spacer [bottom]="27"></app-spacer>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-8">
                        <app-input label="Nome completo" [group]="form"
                            controlName="full_name"
                            helpText="Sem abreviações, como está em seus documentos"
                            placeholder="John Doe" [readOnly]="haveName"
                            [maxLength]="60">
                        </app-input>
                        <app-spacer [bottom]="27"></app-spacer>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-3">
                        <app-select [items]="signupService.nationalityChocies"
                            label="Nacionalidade" [group]="form"
                            controlName="nacionality" placeholder="Selecione">
                        </app-select>
                        <app-spacer [bottom]="27"></app-spacer>
                    </div>

                    <ng-template
                        [ngIf]="form.controls.nacionality.value === '23'"
                        [ngIfElse]="otherCountry">
                        <div class="col-12 col-lg-4">
                            <app-masked-input label="CPF" [group]="form"
                                controlName="document" mask="000.000.000-00"
                                placeholder="123.456.789-01"
                                [isDocument]="true">
                            </app-masked-input>
                            <app-spacer [bottom]="27"></app-spacer>
                        </div>
                    </ng-template>

                    <ng-template #otherCountry>
                        <div class="col-12 col-lg-4">
                            <app-input label="Número do documento"
                                [group]="form" controlName="document"
                                placeholder="Digite o número do documento"
                                [isDocument]="true">
                            </app-input>
                            <app-spacer [bottom]="27"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-5">
                            <app-select [items]="documents"
                                label="Tipo do documento" [group]="form"
                                controlName="document_type"
                                placeholder="Selecione">
                            </app-select>
                            <app-spacer [bottom]="27"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-3"
                            *ngIf="form.controls.document_type.value === 'other'">
                            <app-input label="Nome do documento" [group]="form"
                                controlName="document_name">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </ng-template>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-6">
                        <app-input label="Seu melhor e-mail" [group]="form"
                            controlName="email" placeholder="john.doe@mail.com">
                        </app-input>
                        <app-spacer [bottom]="27"></app-spacer>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4 col-lg-2">
                        <app-select label="DDI" [group]="form"
                            controlName="phone_ddi" [items]="DDIOptions"
                            placeholder="55">
                        </app-select>
                        <app-spacer [bottom]="27"></app-spacer>
                    </div>

                    <div class="col-8 col-lg-6">
                        <app-masked-input label="Número do seu celular"
                            [group]="form" controlName="phone_number"
                            mask="(00) 00000-0000||(00) 0000-0000"
                            placeholder="(00) 99887-7665">
                        </app-masked-input>
                        <app-spacer [bottom]="27"></app-spacer>
                    </div>
                </div>
            </div>

            <div class="col-12 offset-lg-1 col-lg-11">
                <app-radio [multiple]="true" formControlName="terms">
                    <div class="term-text">Declaro que estou de acordo com a
                        <a [routerLink]="['/']">Política de
                            Privacidade</a>
                    </div>
                </app-radio>
            </div>
        </div>
    </ng-container>
</form>