<div class="content">

    <div class="logo"></div>
    <div class="code-error"></div>

    <div class="text-container">
        <h2>Oops!</h2>
        <p>A página que você procura não está disponível no momento. Tente novamente mais tarde ou contate o
            administrador do sistema.</p>
    </div>
</div>