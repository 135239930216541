<div class="dashboard">
    <div class="row w-100">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card">
                <ng-container *ngIf="!loading">
                    <app-back-button></app-back-button>
                </ng-container>

                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="dash-card-header">

                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader [theme]="{
                                    width: '150px'
                                  }">
                                </ngx-skeleton-loader>
                            </ng-container>

                            <ng-container *ngIf="!loading">
                                <span>Todos os registros</span>
                            </ng-container>
                        </div>
                    </div>

                    <div class="col-12 padding-container dash-card-body">
                        <div clsas="styled-scroll">
                            <table *ngIf="loading" class="skeleton-table">
                                <thead>
                                    <tr>
                                        <th *ngFor="let number of [0,1,2]">
                                            <ngx-skeleton-loader [theme]="{
                                                width: '100px'
                                              }">
                                            </ngx-skeleton-loader>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let number of [0,1,2]">
                                        <td>
                                            <div class="dash-card-item">
                                                <div
                                                    class="dash-card-item-initials">
                                                    <span>
                                                        <ngx-skeleton-loader
                                                            appearance="circle"
                                                            [theme]="{
                                                        height: '48px',
                                                        width: '48px'
                                                      }">
                                                        </ngx-skeleton-loader>
                                                    </span>
                                                </div>

                                                <app-spacer
                                                    [right]="16"></app-spacer>

                                                <div
                                                    class="dash-card-item-content">
                                                    <div
                                                        class="dash-card-item-content-title">
                                                        <ngx-skeleton-loader
                                                            [theme]="{
                                                            height: '21px',
                                                            width: '130px',
                                                            margin:'0'
                                                          }">
                                                        </ngx-skeleton-loader>
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-subtitle">
                                                        <ngx-skeleton-loader
                                                            [theme]="{
                                                            height: '18px',
                                                            width: '110px'
                                                          }">
                                                        </ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <ngx-skeleton-loader [theme]="{
                                                height: '21px',
                                                width: '200px'
                                              }">
                                            </ngx-skeleton-loader>
                                        </td>

                                        <td>
                                            <ngx-skeleton-loader [theme]="{
                                                height: '21px',
                                                width: '100px'
                                              }">
                                            </ngx-skeleton-loader>
                                        </td>
                                    </tr>

                                </tbody>
                                <app-spacer [bottom]="28"></app-spacer>
                            </table>

                            <table *ngIf="!loading">
                                <thead>
                                    <tr>
                                        <th>Empresa</th>
                                        <th>Papel</th>
                                        <th>Estágio Atual</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of filteredUsers">
                                        <td>
                                            <div class="dash-card-item">
                                                <div
                                                    class="dash-card-item-initials">
                                                    <span>{{getInitials(item)}}</span>
                                                </div>

                                                <app-spacer
                                                    [right]="16"></app-spacer>

                                                <div
                                                    class="dash-card-item-content">
                                                    <div
                                                        class="dash-card-item-content-title">
                                                        <app-info-badge
                                                            [homologation]="item.attempt == 1"
                                                            [blocked]="item.attempt > 1"
                                                            [label]="item.attempt > 1 ? 'Atualização' : 'Novo'"></app-info-badge>
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-title">
                                                        {{getDisplayName(item)}}
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-subtitle">
                                                        Aprovação iniciada em
                                                        {{item.created_at |
                                                        date: "dd/MM/yyyy"}} |
                                                        Aprovação atualizada em
                                                        {{item.updated_at |
                                                        date: "dd/MM/yyyy"}}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td [style.max-width]="'300px'"
                                            [style.word-break]="'break-all'">
                                            <div class="dash-card-item-content">
                                                <div
                                                    class="dash-card-item-content-title">
                                                    {{formatItemName(item)}}
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <span
                                                class="status">{{getStatus(item)}}
                                            </span>
                                            <div class="status-aprovation">
                                                {{getStatusInfo(item)}}
                                            </div>
                                        </td>

                                        <td style="text-align: right">
                                            <a [routerLink]="renderLink(item)">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                        </td>
                                    </tr>

                                </tbody>
                                <app-spacer [bottom]="28"></app-spacer>
                            </table>

                            <div *ngIf="users.length === 0 && !loading">
                                <span
                                    class="dash-card-item-content-title text-center d-block">
                                    Nenhum {{roleLabel}} registrado
                                </span>

                                <app-spacer [bottom]="30"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dash-card-pagination" *ngIf="users.length > 0">
                <app-pagination [data]="users" [perPage]="50" [show]="!loading"
                    (onDataChange)="setFilteredData($event)"
                    [dashPagination]="true">
                </app-pagination>
            </div>
        </div>
    </div>
</div>
