<div class="row" *ngIf="fundService.data">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <div class="dash-details-card-body">
                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.benchmark ?? '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            BENCHMARK
                        </span>
                    </div>
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{getChoicesName('quota_type_choices',
                            fundService.data.quota_types)?.label ?? '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            TIPO DE COTA
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{getChoicesName('quota_offer_type_choices',
                            fundService.data.quota_offer_type)?.label ?? '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            TIPO DE OFERTA DE COTAS
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.initial_quota | currency: 'BRL'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            COTA INICIAL
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="26"></app-spacer>

                <ng-container *ngIf="fundService.data.quota_series">
                    <div class="row">
                        <div class="col-12">
                            <app-label class="font-weight-bold"
                                label="Série de cotas">
                            </app-label>
                        </div>
                    </div>
                    <app-spacer [bottom]="8"></app-spacer>

                    <div *ngFor="let item of fundService.data.quota_series">
                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{getChoicesName('quotas_classes_order_choices',
                                    item.quota_order)?.label}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    ORDEM
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{getChoicesName('quotas_classes_choices',
                                    item.quota_class)?.label}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CLASSE
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{item.quota_quantity ?? '0*'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    QUANTIDADE
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <ng-container *ngIf="item.quota_amount">
                                        {{item.quota_amount | currency: 'BRL'}}
                                    </ng-container>

                                    <ng-container *ngIf="!item.quota_amount">
                                        0*
                                    </ng-container>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    VALOR
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>
                    <app-spacer [bottom]="10"></app-spacer>
                </ng-container>

                <div class="row">
                    <div class="col-12">
                        <app-label class="font-weight-bold"
                            label="Limite mínimo para movimento">
                        </app-label>
                    </div>
                </div>
                <app-spacer [bottom]="8"></app-spacer>

                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.min_initial_application |
                            currency: 'BRL'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            APLICAÇÃO INICIAL
                        </span>
                    </div>
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.min_additional_application |
                            currency: 'BRL'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            APLICAÇÃO ADICIONAL
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.min_redemption | currency:
                            'BRL'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            RESGATE
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.min_permanence | currency:
                            'BRL'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            PERMANÊNCIA
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="26"></app-spacer>

                <div class="row">
                    <div class="col-12">
                        <app-label class="font-weight-bold"
                            label="Classificação Tributária">
                        </app-label>
                    </div>
                </div>
                <app-spacer [bottom]="8"></app-spacer>

                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{getChoicesName('tax_classification_deadline_choices',
                            fundService.data.tax_classification_deadline)?.label
                            ?? '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            PRAZO
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{getChoicesName('tax_classification_type_choices',
                            fundService.data.tax_classification_type)?.label ??
                            '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            TIPO
                        </span>
                    </div>

                    <div class="col-4 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{getChoicesName('tax_classification_tax_choices',
                            fundService.data.tax_classification_tax)?.label ??
                            '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            FORMA DE TRIBUTAÇÃO
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="26"></app-spacer>

                <div class="row">
                    <div class="col-12">
                        <app-label class="font-weight-bold" label="Aplicação">
                        </app-label>
                    </div>
                </div>
                <app-spacer [bottom]="8"></app-spacer>

                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.aplication_coversion}} dias
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            CONVERSÃO
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            Dias
                            {{getChoicesName('amortization_grace_period_choices',
                            fundService.data.aplication_coversion_unit)?.label}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            MEDIÇÃO DE CONVERSÃO
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.aplication_sale_off}} dias
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            LIQUIDAÇÃO
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            Dias
                            {{getChoicesName('amortization_grace_period_choices',
                            fundService.data.aplication_sale_off_unit)?.label}}

                        </span>

                        <span class="dash-details-card-item-subtitle">
                            MEDIÇÃO DE LIQUIDAÇÃO
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="26"></app-spacer>

                <div class="row">
                    <div class="col-12">
                        <app-label class="font-weight-bold" label="Resgate">
                        </app-label>
                    </div>
                </div>
                <app-spacer [bottom]="8"></app-spacer>

                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.rescue_coversion}} dias
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            CONVERSÃO
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            Dias
                            {{getChoicesName('amortization_grace_period_choices',
                            fundService.data.rescue_coversion_unit)?.label}}

                        </span>

                        <span class="dash-details-card-item-subtitle">
                            MEDIÇÃO DE CONVERSÃO
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.rescue_sale_off}} dias
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            LIQUIDAÇÃO
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            Dias
                            {{getChoicesName('amortization_grace_period_choices',
                            fundService.data.rescue_sale_off_unit)?.label}}

                        </span>

                        <span class="dash-details-card-item-subtitle">
                            MEDIÇÃO DE LIQUIDAÇÃO
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="26"></app-spacer>

                <div class="row">
                    <div class="col-12">
                        <app-label class="font-weight-bold" label="Amortização">
                        </app-label>
                    </div>
                </div>
                <app-spacer [bottom]="8"></app-spacer>

                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{getChoicesName('fund_social_exercise_choices',
                            fundService.data.fund_social_exercise)?.label ??
                            '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            EXERCÍCIO SOCIAL DO FUNDO
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{getChoicesName('amortization_frequency_choices',
                            fundService.data.amortization_frequency)?.label ??
                            '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            PERIODICIDADE
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.amortization_days }} dias
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            PERÍODO DE CARÊNCIA
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{getChoicesName('amortization_calculated_choices',
                            fundService.data.amortization_calculated)?.label}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            CALCULADA SOBRE
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer>

                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.risk_classification ?? '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            CLASSIFICAÇÃO DOS RISCOS
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.additional_information_about_amortization
                            ?? '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            INFORMAÇÕES ADICIONAIS
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="48"></app-spacer>

                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.creditory_rights ?? '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            TIPO DE DIREITOS CREDITÓRIOS
                        </span>
                    </div>

                    <div class="col-4 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.analytical_description_of_credit_rights
                            ?? '-'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            DESCRIÇÃO ANALÍTICA DOS DIREITOS CREDITÓRIOS
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer>

                <div class="row">
                    <div class="col-4 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.credit_rights_will_be_granted ?
                            'Sim' : 'Não'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            DIREITOS CREDITÓRIOS REGISTRADOS
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="48"></app-spacer>

                <div class="row">
                    <div class="col-4 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.assignment_expectation_greater_than_400
                            ? 'Sim' : 'Não'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            CESSÃO MAIOR QUE 400 RECEBÍVEIS POR TRIMESTRE
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="26"></app-spacer>

                <div class="row">
                    <div class="col-12">
                        <app-label class="font-weight-bold"
                            label="Emissão de cotas com entrega de ativos">
                        </app-label>
                    </div>
                </div>
                <app-spacer [bottom]="8"></app-spacer>

                <div class="row">
                    <div class="col-4 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{fundService.data.quota_fair_value |
                            currency:'BRL':true}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            PARÂMETRO PARA DEFINIÇÃO DO VALOR JUSTO DOS ATIVOS
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>