<div class="dashboard">
    <div class="row w-100">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card">
                <app-back-button></app-back-button>

                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="dash-card-header">
                            <span>Prestador de serviço</span>
                        </div>
                    </div>

                    <div class="col-12 padding-container dash-card-body">
                        <div clsas="styled-scroll">
                            <table class="bordered" *ngIf="providers.length > 0 && !loading">
                                <thead>
                                    <tr>
                                        <th>Prestador de serviço</th>
                                        <th>Estágio Atual</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of filteredProviders">
                                        <td>
                                            <div class="dash-card-item">
                                                <div class="dash-card-item-initials">
                                                    <span>{{getInitials(item)}}</span>
                                                </div>

                                                <app-spacer [right]="16"></app-spacer>

                                                <div class="dash-card-item-content">
                                                    <div class="dash-card-item-content-title">
                                                        {{item.user.first_name}} {{item.user.last_name}}
                                                    </div>
                                                    <div class="dash-card-item-content-subtitle">
                                                        em {{item.user.created_at | date:'dd/MM/yyyy'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <span class="status">{{getStatus(item)}}
                                            </span>
                                            <div class="status-aprovation">
                                                {{getStatusInfo(item)}}
                                            </div>
                                        </td>

                                        <!-- <td>
                                            <span class="status">{{item.step !== -1 ? item.department.department.name :
                                                'Aprovado'}}
                                            </span>
                                            <div class="status-aprovation">Aguardando aprovação
                                                ({{item.step}}/{{item.departments.length}})</div>
                                        </td> -->

                                        <td style="text-align: right;">
                                            <a [routerLink]="renderLink(item.user.role.applicable,item.id)">
                                                <button class="button">
                                                    <i class="fas fa-eye"></i>
                                                </button>
                                            </a>
                                        </td>
                                    </tr>

                                    <tr *ngIf="providers.length === 0">
                                        <td colspan="3">
                                            <span class="dash-card-item-content-subtitle">Nenhum prestador de serviço
                                                adicionado</span>
                                        </td>
                                    </tr>

                                </tbody>
                                <app-spacer [bottom]="28"></app-spacer>
                            </table>

                            <div *ngIf="providers.length === 0 && !loading">
                                <span class="dash-card-item-content-title text-center d-block">
                                    Nenhum prestador de serviço adicionado
                                </span>

                                <app-spacer [bottom]="30"></app-spacer>
                            </div>

                            <div class="pt-2 pb-2 text-center" *ngIf="loading">
                                <i class="fas fa-circle-notch fa-spin fa-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dash-card-pagination">
                <app-pagination [data]="providers" [perPage]="50" (onDataChange)="setFilteredData($event)"
                    [dashPagination]="true">
                </app-pagination>
            </div>

        </div>
    </div>
</div>
