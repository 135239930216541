import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  @Input() steps: Step[] = [];

  @Input() activeIndex = 0;
  @Input() activeSubstepSlug:
    | ComplementaryCompanySteps
    | ComplementaryPersonSteps = 'complementaryGeneral';

  constructor() {}

  ngOnInit(): void {}

  handlePassedStep(slug: string) {
    const activedIndex = this.steps[0].substeps.findIndex(
      (item) => item.slug === this.activeSubstepSlug
    );

    const slugIndex = this.steps[0].substeps.findIndex(
      (item) => item.slug === slug
    );

    if (activedIndex > slugIndex) {
      return true;
    }

    if (this.activeIndex >= 1) return true;

    return false;
  }
}
