import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-accounts',
  templateUrl: './dashboard-accounts.component.html',
  styleUrls: ['./dashboard-accounts.component.scss'],
})
export class DashboardAccountsComponent implements OnInit {
  roleForm = this.formBuilder.group({
    role: [null, [Validators.required]],
  });

  accounts: RegisterUserResponse[] = [];
  selectedAccount: RegisterUserResponse | null = null;
  accountsLoading: boolean = true;
  filteredRoleOptions: SelectItem[] = [];
  roleOptions: SelectItem[] = [];
  sendingRequest: boolean = false;

  roleModal: boolean = false;

  constructor(
    private toast: ToastService,
    private api: ApiService,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getData();
    this.setAgents();
  }

  async setAgents() {
    try {
      const res = await this.api.get({
        route: 'api/registration/agent/',
        token: true,
      });

      this.roleOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async addAgentRole() {
    try {
      const payload = [
        ...this.selectedAccount.register.agent.map((item) => item.id),
        ...this.roleForm.controls.role.value,
      ];

      await this.api.patch<ApiResponse<Register>>({
        route: `api/registration/register/${this.selectedAccount.register.uuid}/`,
        token: true,
        body: {
          agent: payload,
        },
      });

      this.toast.show('info', 'Sucesso!', 'Papel adicionado com sucesso');

      this.closeModal();
      this.getData();
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao adicionar papel');
    }
  }

  closeModal() {
    this.roleModal = false;
    this.selectedAccount = null;
    this.roleForm.reset();
  }

  async getData() {
    this.accountsLoading = true;
    try {
      const { data } = await this.api.get<ApiResponse<RegisterUserResponse[]>>({
        route: 'api/registration/register_user/',
        token: true,
        params: {
          type: 'register',
        },
      });

      const newData = data
        .sort((a, b) => {
          return (
            new Date(b.register.created_at).getTime() -
            new Date(a.register.created_at).getTime()
          );
        })
        .reverse();

      this.accounts = newData;
    } catch (error) {
      console.error(error);
    }
    this.accountsLoading = false;
  }

  renderRoles(item: RegisterUserResponse) {
    let label = '';

    if (item.register.agent && item.register.agent.length > 0) {
      label = item.register.agent.map((agent) => agent.name).join(', ');
    }

    return label;
  }

  getInitials(item: RegisterUserResponse) {
    let name = null;

    if (item.register) {
      if (item.register.type.toUpperCase() === 'PF' && item.register.person) {
        name = item.register.person.full_name;
      }

      if (item.register.type.toUpperCase() === 'PJ' && item.register.company) {
        name = item.register.company.corporate_name;
      }
    }

    return name
      ? name
          .toUpperCase()
          .replace(/[^\w\s]/gi, '')
          .split(' ')
          .slice(0, 2)
          .map((word: string) => word.charAt(0))
          .join('')
      : '-';
  }

  getName(item: RegisterUserResponse) {
    let name = null;

    if (item.register) {
      if (item.register.type.toUpperCase() === 'PF' && item.register.person) {
        name = item.register.person.full_name;
      }

      if (item.register.type.toUpperCase() === 'PJ' && item.register.company) {
        name = item.register.company.corporate_name;
      }
    }

    return name ? name : 'Cadastro em andamento';
  }

  getStatus(item: RegisterUserResponse) {
    const register = item.register;

    if (register.approval.length === 0) return 'Cadastro em andamento';

    if (register.approval[0].completed) {
      if (register.approval[0].is_approved) {
        return 'Aprovado internamente';
      } else {
        return `${register ? 'Cadastro' : 'Fundo'} rejeitado`;
      }
    } else {
      if (register.approval[0].current_department !== null) {
        return register.approval[0].current_department.type === 'department'
          ? register.approval[0].current_department.department.name
          : register.approval[0].current_department.agent.name;
      } else if (register.approval[0].step === 0) {
        return 'Edição';
      } else {
        return 'Departamento não definido';
      }
    }
  }

  handleEditRoleModal(id: number) {
    this.roleModal = true;
    this.selectedAccount = this.accounts.find((item) => item.id === id);

    const newRoleOptions = this.roleOptions.filter((item) => {
      return (
        this.selectedAccount?.register?.agent.findIndex(
          (agent) => agent.id === Number(item.value)
        ) === -1
      );
    });

    this.filteredRoleOptions = newRoleOptions;
  }

  getStatusInfo(item: RegisterUserResponse) {
    const register = item.register;

    if (register.approval.length === 0) return '';

    if (register.approval[0].completed) {
      if (register.approval[0].is_approved) {
        return 'Integração ao fundo';
      } else {
        if (register.approval[0].current_department !== null) {
          return register.approval[0].current_department.type === 'department'
            ? register.approval[0].current_department.department.name
            : register.approval[0].current_department.agent.name;
        } else if (register.approval[0].step === 0) {
          return 'Edição';
        } else {
          return 'Departamento não definido';
        }
      }
    } else if (register.approval[0].step === 0) {
      return 'Aguardando representantes';
    } else {
      return `Aguardando aprovação (${register.approval[0].step}/${register.approval[0].queue.departments.length})`;
    }
  }

  renderLink(item: RegisterUserResponse) {
    if (item.register) {
      return [
        `/app/details/${item.register.role.applicable}/`,
        item.register.uuid,
      ];
    }

    return '';
  }

  async changeActiveRegister(id: number) {
    try {
      await this.api.patch({
        route: `user/me/`,
        token: true,
        body: {
          actived_register: id,
        },
      });

      const { data } = await this.api.get<ApiResponse<NewLoginResponse>>({
        route: 'user/me/',
        token: true,
      });

      this.authService.setUser(data);
      this.handleRedirect(id);
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro!', 'Erro ao alterar conta ativa');
    }
  }

  handleRedirect(id: number) {
    const account = this.accounts.find((item) => item.id === id);

    const isApprover = account.register.is_approver;
    const applicable = account.register.role.applicable;
    const approvals = account.register.approval;
    const uuid = account.register.uuid;
    const type = account.register?.type?.toLowerCase() || '';

    if (applicable === 'representative' || applicable === 'admin') {
      this.router.navigate(['/app/dashboard']);
      return;
    }

    if (approvals.length > 0) {
      if (isApprover) {
        this.router.navigate(['/app/dashboard']);
      } else {
        this.router.navigate([`/app/details/${applicable}/`, uuid]);
      }
    } else {
      this.router.navigate([`/signup/${applicable}/${type}`]);
    }
  }
}
