<div class="dashboard">
    <div class="row w-100">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card">
                <app-back-button></app-back-button>

                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="dash-card-header">
                            <span>Cotistas</span>
                        </div>
                    </div>

                    <div class="col-12 padding-container dash-card-body">
                        <div class="dash-card-statistics">
                            <div class="dash-card-statistics-item">
                                <span class="dash-card-statistics-title">Cadastros em aberto</span>

                                <span class="dash-card-statistics-value">{{shareholders_statistics.initialized}}</span>
                            </div>


                            <div class="dash-card-statistics-item">
                                <span class="dash-card-statistics-title">Cadastros concluidos</span>
                                <app-spacer [bottom]="4"></app-spacer>

                                <span class="dash-card-statistics-value">{{shareholders_statistics.completed}}</span>
                            </div>

                            <div class="dash-card-statistics-item">
                                <span class="dash-card-statistics-title">Total de cedentes</span>
                                <app-spacer [bottom]="4"></app-spacer>

                                <span class="dash-card-statistics-value">{{shareholders_statistics.total}}</span>
                            </div>
                        </div>

                        <div clsas="styled-scroll">
                            <table class="bordered" *ngIf="shareholders.length > 0 && !loading">
                                <thead>
                                    <tr>
                                        <th>Cedente</th>
                                        <th>Estágio Atual</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of filteredShareholders">
                                        <td>
                                            <div class="dash-card-item">
                                                <div class="dash-card-item-initials">
                                                    <span>{{getInitials(item)}}</span>
                                                </div>

                                                <app-spacer [right]="16"></app-spacer>

                                                <div class="dash-card-item-content">
                                                    <div class="dash-card-item-content-title">
                                                        {{item.user.first_name}} {{item.user.last_name}}
                                                    </div>
                                                    <div class="dash-card-item-content-subtitle">
                                                        em {{item.user.created_at | date:'dd/MM/yyyy'}}
                                                    </div>
                                                </div>

                                            </div>
                                        </td>
                                        <td>
                                            <span class="status">{{item.step !== -1 ? item.department.department.name :
                                                'Aprovado'}}</span>
                                            <div class="status-aprovation">Aguardando aprovação
                                                ({{item.step}}/{{item.departments.length}})</div>
                                        </td>


                                        <td style="text-align: right;">
                                            <a [routerLink]="['/app/dashboard/approval/', item.id]">
                                                <button class="button">
                                                    <i class="fas fa-eye"></i>
                                                </button>
                                            </a>
                                        </td>

                                    </tr>

                                    <tr *ngIf="shareholders.length === 0">
                                        <td colspan="3">
                                            <span class="dash-card-item-content-subtitle">Nenhum cotista
                                                adicionado</span>
                                        </td>
                                    </tr>

                                </tbody>
                                <app-spacer [bottom]="28"></app-spacer>
                            </table>

                            <div *ngIf="shareholders.length === 0 && !loading">
                                <span class="dash-card-item-content-title text-center d-block">
                                    Nenhum cotista adicionado
                                </span>

                                <app-spacer [bottom]="30"></app-spacer>
                            </div>

                            <div class="pt-2 pb-2 text-center" *ngIf="loading">
                                <i class="fas fa-circle-notch fa-spin fa-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dash-card-pagination">
                <app-pagination [dashPagination]="true" [data]="shareholders" [perPage]="50"
                    (onDataChange)="setFilteredData($event)">
                </app-pagination>
            </div>

        </div>
    </div>
</div>