<div class="row">
  <div class="col-12">
    <div class="dash-details-card card-padding-top">
      <div class="dash-details-card-body">

        <ng-container *ngIf="attachments.length > 0">

          <div class="row justify-content-between">
            <div class="col-10 offset-1" *ngFor="let attachment of attachments; let i = index">
              <div class="row">

                <div class="col-12 col-lg-7">
                  <div class="d-flex flex-column">
                    <span class="table-sum-title">{{attachment.name}}
                      <b [class.text-danger]="attachment.required">{{attachment.required ? "(obrigatório)" :
                        "(opcional)"}}</b></span>
                    <app-spacer [bottom]="8"></app-spacer>
                    <span class="table-sum-amount">{{attachment.description}}</span>
                  </div>

                  <app-spacer [bottom]="24"></app-spacer>

                  <div class="row" *ngIf="getSendedFiles(attachment.id).length > 0">
                    <div class="col-12">
                      <span class="table-sum-title mb-2 font-weight-bold d-flex">
                        Arquivos enviados
                      </span>
                    </div>

                    <div class="col-xs-12 col-lg-4 mb-2" *ngFor="let file of getSendedFiles(attachment.id)">
                      <div class="d-flex flex-column justify-content-center">
                        <img [src]="getFileImagePreview(file.file)" alt="attachment" class="img-preview">

                        <span class="text-overflow">{{getFileName(file.file)}}</span>



                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="d-flex flex-row justify-content-between">
                          <app-button [inverted]="true" [center]="true" (onClick)="openFile(file.file)">
                            <i class="fa fa-eye"></i>
                          </app-button>

                          <app-button [danger]="true" [inverted]="true" [center]="true" (onClick)="removeFile(file.id)">
                            <i class="fa fa-trash"></i>
                          </app-button>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                <div class="col-12 col-lg-5">
                  <app-dropzone (filesChange)="setFiles(i, $event)">
                  </app-dropzone>
                </div>

                <ng-container *ngIf="i + 1 !== attachments.length">
                  <div class="col-12">
                    <app-spacer [bottom]="48"></app-spacer>
                  </div>
                </ng-container>
              </div>
            </div>


            <div class="col-10 offset-1" *ngIf="attachments.length > 0">
              <div class="d-flex justify-content-end">
                <app-spacer [bottom]="48"></app-spacer>
                <app-button (onClick)="sendData()" [outline]="true" [disabled]="getInvalid() || sending"
                  [loading]="sending">
                  Enviar anexos
                  <i class="fas fa-floppy-disk ml-2"></i>
                </app-button>
              </div>
            </div>

          </div>

        </ng-container>



      </div>
    </div>
  </div>
</div>
