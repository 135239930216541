import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-approval-documents',
  templateUrl: './dashboard-approval-documents.component.html',
  styleUrls: ['./dashboard-approval-documents.component.scss'],
})
export class DashboardApprovalDocumentsComponent implements OnInit {
  @Input() approval: any;

  form = this.formBuilder.group({
    witness: [null, [Validators.required]],
  });

  witnesses: SelectItem[] = [];
  clearAttachments: boolean = false;

  data: Contracts[] = [
    {
      name: 'Contrato de compra e venda',
      status: 'Aguardando assinatura',
      id: 1,
      witness: [
        {
          name: 'João da Silva',
          id: 1,
          document: '123.456.789-00',
          email: 'email@exemplo.com',
        },
      ],
    },
    {
      id: 2,
      name: 'Contrato de compra e venda',
      status: 'Aguardando assinatura',
      witness: [
        {
          name: 'Maria da Silva',
          id: 1,
          document: '123.456.789-00',
          email: 'email@exemplo.com',
        },
      ],
    },
  ];

  selectedData: Contracts | undefined;

  showDocumentDetailsModal: boolean = false;

  newAttachments: FilePicked[] = [];

  showDocumentModal: boolean = false;
  showAttachmentModal: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  toggleDocumentModal() {
    this.showDocumentModal = !this.showDocumentModal;
    this.form.reset();
  }

  toggleAttachmentModal() {
    this.clearAttachments = !this.showAttachmentModal;
    this.showAttachmentModal = !this.showAttachmentModal;
    this.newAttachments = [];
  }

  toggleDocumentDetailsModal() {
    this.showDocumentDetailsModal = !this.showDocumentDetailsModal;
    this.selectedData = undefined;
  }

  async generateDocument() {
    this.toggleDocumentModal();

    // this.toast.show(
    //   'info',
    //   'Sucesso!',
    //   'Documento gerado com sucesso! Aguarde a aprovação.'
    // );

    try {
      const res = await this.api.post({
        route: `api/approvals/user_approval/${this.approval.id}/generate_document/`,
        body: {
          witness: this.form.value.witness,
        },
      });

      console.log('teste 1', this.form.value.witness, res);
    } catch (error) {
      console.log(error);
    }
  }

  async getData() {
    try {
      const res = await this.api.get({
        route: 'api/registration/witnesses/',
        token: true,
      });

      this.witnesses = res.map((witness) => {
        return {
          value: witness.id,
          label: `${witness.name} | ${witness.document_number}`,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  getDisabled() {
    return this.form.invalid;
  }

  getAttachmentDisabled() {
    return this.newAttachments.length === 0;
  }

  handleOpenVisualizer(id: number) {
    this.showDocumentDetailsModal = true;

    this.selectedData = this.data.find((doc) => doc.id === id);
  }

  formatWitnesses(witnesses: Witness[]) {
    const formatNames = witnesses.map((witness) => witness.name);

    return formatNames.join(', ');
  }

  setFiles(files: FilePicked[]) {
    this.newAttachments = [...files];
  }
}
