import { Component, Input, OnInit } from '@angular/core';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-dashboard-approval-bonds',
  templateUrl: './dashboard-approval-bonds.component.html',
  styleUrls: ['./dashboard-approval-bonds.component.scss'],
})
export class DashboardApprovalBondsComponent implements OnInit {
  @Input() approval: any;

  bonds: BondValues[] = [];

  constructor() {}

  ngOnInit(): void {
    const links = this.approval.register.links
      .filter((link) => link.fund)
      .map((link: any) => ({
        short_name: link.fund.fantasy_name,
        name: link.fund.name,
        anbima_classification_label: link.fund.anbima_classification_display,
        id: link.fund.id,
        document: link.fund.document,
        status: link.status_display,
      }));

    this.bonds = [...links];

    if (this.approval.register.interest_fund) {
      const interestFund = {
        short_name: this.approval.register.interest_fund.fantasy_name,
        name: this.approval.register.interest_fund.name,
        anbima_classification_label:
          this.approval.register.interest_fund.anbima_classification_display,
        id: this.approval.register.interest_fund.id,
        document: this.approval.register.interest_fund.document,
        status: 'Fundo de interesse',
      };

      this.bonds.unshift(interestFund);
    }
  }

  getFundInitials(fund: any) {
    if (!fund) {
      return '';
    }

    return fund.name
      ? fund.name
          .toUpperCase()
          .replace(/[^\w\s]/gi, '')
          .split(' ')
          .slice(0, 2)
          .map((word: string) => word.charAt(0))
          .join('')
      : '';
  }
}
