import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import UIDefaultTheme from 'src/themes';

@Component({
  selector: 'app-signup-unified-person',
  templateUrl: './signup-unified-person.component.html',
  styleUrls: ['./signup-unified-person.component.scss'],
})
export class SignupUnifiedPersonComponent implements OnInit {
  steps: Step[] = [
    {
      icon: 'fas fa-file-alt',
      svg: 'assets/images/icon-inputdata-active.svg',
      title: 'Dados complementares',
      substeps: [
        {
          title: 'Geral',
          slug: 'complementaryGeneral',
        },
        {
          title: 'Endereço',
          slug: 'complementaryAddress',
        },
        {
          title: 'Dados bancários',
          slug: 'complementaryBank',
        },
        {
          title: 'Profissional',
          slug: 'complementaryProfessional',
        },
        {
          title: 'Representantes',
          slug: 'complementaryRepresentatives',
        },
      ],
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/icon-attachments.svg',
      title: 'Anexos & comprovantes',
    },
  ];

  _activeStep: number = 0;

  activeSubstep: ComplementaryPersonSteps = 'complementaryRepresentatives';

  redirected = false;
  hasMultipleAccounts = false;

  get activeStep() {
    return this._activeStep;
  }

  set activeStep(value) {
    this._activeStep = value;

    switch (value) {
      case 0:
        this.currentTab = 'Dados complementares';
        break;

      case 1:
        this.currentTab = 'Anexos & comprovantes';
        break;

      default:
        this.currentTab = '';
        break;
    }
  }

  currentTab: string = '';

  loading = false;

  showSupportModal = false;

  name: string | null = null;
  role: string | null = null;
  label: string | null = null;

  agentOptions: SelectItem[] = [];

  userCredentials: UserCredentials = {
    email: '',
    name: '',
  };

  accounts: RegisterUserResponse[] = [];

  public UIDefaultTheme = UIDefaultTheme;

  constructor(
    private router: Router,
    public signupService: SignupService,
    private apiService: ApiService,
    private toastService: ToastService,
    private authService: AuthService,
    private titleService: Title
  ) {
    if (this.authService.user.actived_register.register.role.slug) {
      this.titleService.setTitle(
        Utils.setTitle(
          this.authService.user.actived_register.register.role.slug
        )
      );
    }
  }

  ngOnInit(): void {
    this.getAccounts();

    this.userCredentials = {
      email: this.authService.user?.email,
      name:
        this.authService.user?.first_name +
        ' ' +
        this.authService.user?.last_name,
    };

    this.signupService.changePersonStepEvent.subscribe((step) => {
      const aux = `complementary${
        step.charAt(0).toUpperCase() + step.slice(1)
      }` as ComplementaryPersonSteps;

      this.activeSubstep = aux;
      this.loading = false;

      this.signupService.updateUserStep('register', step);
    });

    this.signupService.changeBasicInfoEvent.subscribe((value) => {
      this.label = this.getRoleLabel(this.role);

      this.name = value.toUpperCase();
    });

    this.signupService.fetchDataEvent.emit('PF');
    let urlParts = this.router.url.split('/pf')[0].split('/');
    let range = urlParts.length;
    this.role = urlParts[range - 1];

    this.getData();
  }

  async getData() {
    await this.getAgents();
    this.setInviteInfo();
    this.redirectUser();
  }

  async getAgents() {
    try {
      const res = await this.apiService.get({
        route: 'api/registration/simplified-agent/',
      });

      this.agentOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
    }
  }

  async redirectToDash() {
    await this.apiService.patch({
      route: `user/me/`,
      token: true,
      body: {
        actived_register: this.accounts[0].id,
      },
    });

    const { data } = await this.apiService.get<ApiResponse<NewLoginResponse>>({
      route: 'user/me/',
      token: true,
    });

    this.authService.setUser(data);
    this.router.navigateByUrl('/app/dashboard');
  }

  async getAccounts() {
    try {
      const { data } = await this.apiService.get<
        ApiResponse<RegisterUserResponse[]>
      >({
        route: 'api/registration/register_user/',
        token: true,
      });

      this.accounts = data;

      this.hasMultipleAccounts = data.length > 1;
    } catch (error) {
      console.error(error);
    }
  }

  getUserInitials(string) {
    return string
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  async redirectUser() {
    const user = this.authService.getUser();
    const { data } = await this.apiService.get({
      route: 'api/registration/trace_step/me/',
      token: true,
    });

    this.signupService.setComplementaryPersonStep(data.substep);

    this.setBasicInfo();

    if (data.step === 'register') {
      if (data.substep === 'attachments') {
        this.activeStep = 1;
      } else {
        this.activeSubstep = `complementary${
          data.substep.charAt(0).toUpperCase() + data.substep.slice(1)
        }` as ComplementaryPersonSteps;
        this.signupService.setComplementaryPersonStep(this.activeSubstep);
        this.activeStep = 0;
      }
    }

    if (data.step === 'review') {
      this.router.navigateByUrl('/app/status/pf');
    }

    if (data.step !== 'register' && data.step !== 'review') {
      this.router.navigateByUrl(`/app/dashboard`);
    }

    this.redirected = true;
  }

  async setBasicInfo() {
    try {
      const { data } = await this.apiService.get<ApiResponse<Company>>({
        route: 'api/registration/pj_general/me/',
        token: true,
      });

      if (data.corporate_name !== null && data.corporate_name !== '') {
        this.label = this.getRoleLabel(this.role);

        this.name = data.corporate_name.toUpperCase();
      }
    } catch (error) {
      console.log(error);
    }
  }

  getRoleLabel(role: string) {
    switch (role) {
      case 'assignor':
        return 'Cedente pessoa jurídica';

      case 'provider':
        const user = this.authService.getUser();

        let label = '';

        if (user.actived_register.register.invite) {
          label = user.actived_register.register.invite.data.agent
            .map((item) => {
              return this.agentOptions.find((agent) => agent.value === item)
                .label;
            })
            .join(', ');
        } else {
          label = user.actived_register.register.agent
            .map((item) => item.name)
            .join(', ');
        }

        return label;

      default:
        return 'Avalista pessoa jurídica';
    }
  }

  async sendApproval() {
    try {
      await this.apiService.post({
        route: 'api/approvals/user_approval/',
        token: true,
      });

      this.toastService.show(
        'info',
        'Sucesso',
        'Solicitação de aprovação enviada com sucesso'
      );

      return true;
    } catch (error) {
      this.toastService.show(
        'error',
        'Erro',
        'Falha ao enviar solicitação de aprovação ao sistema'
      );
      return false;
    }
  }

  canProceed() {
    switch (this.activeStep) {
      case 0:
        const step = this.signupService.getComplementaryPersonStep();
        return this.signupService.getPersonFormStatus(step);

      case 1:
        const attachment =
          this.signupService.getPersonFormStatus('attachments');
        return attachment;

      case 2:
        return true;
      default:
        return false;
    }
  }

  getButtonIcon(stepAction: string) {
    if (stepAction === 'next') {
      return this.canProceed()
        ? 'assets/images/icon_button_arrow.svg'
        : 'assets/images/icon_button_block.svg';
    } else {
      return this.activeStep === 0
        ? 'assets/images/icon_button_block.svg'
        : 'assets/images/icon_button_arrow.svg';
    }
  }

  runBack() {
    if (this.activeStep > 1) {
      this.activeStep--;
      return;
    }

    if (this.activeStep === 1) {
      this.activeStep--;

      this.signupService.changePersonStepEvent.emit('representatives');

      this.signupService.setComplementaryPersonStep(
        'complementaryRepresentatives'
      );

      return;
    }

    if (this.activeStep === 0) {
      switch (this.activeSubstep) {
        case 'complementaryAddress':
          this.signupService.changePersonStepEvent.emit('general');

          this.signupService.setComplementaryPersonStep('complementaryGeneral');
          break;

        case 'complementaryBank':
          this.signupService.changePersonStepEvent.emit('address');

          this.signupService.setComplementaryPersonStep('complementaryAddress');
          break;

        case 'complementaryProfessional':
          this.signupService.changePersonStepEvent.emit('bank');

          this.signupService.setComplementaryPersonStep('complementaryBank');
          break;

        case 'complementaryRepresentatives':
          this.signupService.changePersonStepEvent.emit('professional');

          this.signupService.setComplementaryPersonStep(
            'complementaryProfessional'
          );
          break;

        default:
          break;
      }
    }
  }

  async proceed() {
    this.loading = true;
    if (this.canProceed()) {
      switch (this.activeStep) {
        case 0:
          const step = this.signupService.getComplementaryPersonStep();

          if (step !== 'complementaryRepresentatives') {
            this.signupService.sendComplementaryPersonData.emit(step);
            return;
          }

          this.signupService.updateUserStep('register', 'attachments');
          this.activeStep++;

          break;

        case 1:
          this.activeStep++;

          const approvalDone = await this.sendApproval();
          if (approvalDone) {
            const { data } = await this.apiService.get<ApiResponse<Person>>({
              route: 'api/registration/pf_general/me/',
              token: true,
            });

            this.router.navigateByUrl(`/app/status/pf`);
          }

          break;

        case 2:
          this.router.navigate([`/app/dashboard`]);
          break;
      }
    }
    this.loading = false;
  }

  setInviteInfo() {
    const user = this.authService.getUser();
    const invite = user.actived_register.register.invite;

    if (!invite) return;

    this.label = this.getRoleLabel(this.role);

    this.name = this.name = invite.data.name
      ? invite.data.name.toUpperCase()
      : '';

    this.signupService.basicInfo = {
      name: invite.data.name,
      email: invite.data.email,
      document: invite.data.document,
    };
  }

  getDisabled() {
    return !this.canProceed() || this.loading;
  }

  toggleSupportModal() {
    this.showSupportModal = !this.showSupportModal;
  }
}
