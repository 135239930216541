<ng-container *ngIf="loadingContent">
    <div class="row">
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-8">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<form [formGroup]="form" *ngIf="!loadingContent">
    <div class="row">
        <div class="col-12">
            <app-radio [multiple]="true" [(ngModel)]="haveAgent"
                (onCheckChanged)="handleHaveAgent($event)"
                [ngModelOptions]="{standalone: true}">Sou
                representado por um terceiro
            </app-radio>
            <app-spacer [bottom]="50"></app-spacer>
        </div>
    </div>

    <ng-container *ngIf="haveAgent">
        <ng-container *ngIf="!showForm ">
            <div class="row">
                <div class="col-12">
                    <app-table [columns]="columns" [data]="data"
                        (onRemove)="handleRemoveData($event)"
                        (onEdit)="handleEditData($event)" [haveEdit]="true">
                    </app-table>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="showForm">
            <div class="row">
                <div class="col-12 col-lg-4 cep-container">
                    <app-masked-input [group]="form" controlName="document"
                        label="CPF" placeholder="000.000.000-00"
                        mask="000.000.000-00"
                        [readOnly]="foundedRegister !== null"
                        (input)="searchRepresentative($event)">
                    </app-masked-input>
                    <i *ngIf="loadingRepresentative"
                        class="fas fa-circle-notch fa-spin app-button-icon"></i>
                </div>
            </div>

            <app-spacer [bottom]="24"></app-spacer>


            <ng-container *ngIf="representativeFetched">

                <div class="row">
                    <div class="col-12 col-lg-8">
                        <app-input [group]="form" controlName="full_name"
                            label="Nome Completo" [maxLength]="60"
                            placeholder="João Luis Barroso"
                            [readOnly]="foundedRegister !== null">
                        </app-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <div class="row" *ngIf="foundedRegister === null">
                    <div class="col-12 col-lg-4">
                        <app-masked-input [group]="form"
                            controlName="birth_date" mask="00/00/0000"
                            label="Data de nascimento" placeholder="dd/mm/aaaa">
                        </app-masked-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-select
                            [items]="signupService.choices().marital_choices"
                            [group]="form" controlName="marital_status"
                            label="Estado civil" placeholder="Selecione">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <ng-container *ngIf="(form.controls.marital_status.value === '2' || this.form.controls['marital_status'].value === '3') &&
                foundedRegister === null   
                ">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <app-masked-input [group]="form"
                                controlName="spouse_document"
                                label="CPF do cônjuge"
                                placeholder="000.000.000-00"
                                mask="000.000.000-00">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <app-masked-input [group]="form"
                                controlName="spouse_name"
                                label="Nome do cônjuge"
                                placeholder="Kátia Inácio da Silva" mask="W*"
                                [usePatterns]="true">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <app-select
                                [items]="signupService.choices().marital_statutes"
                                [group]="form" controlName="spouse_statute"
                                label="Regime de casamento">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>
                </ng-container>

                <div class="row" *ngIf="foundedRegister === null">
                    <div class="col-12 col-lg-12">
                        <app-input [group]="form" controlName="occupation"
                            [maxLength]="30" label="Ocupação Profissional">
                        </app-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-3">
                        <app-select [items]="signupService.nationalityChocies"
                            [group]="form" controlName="nationality"
                            placeholder="Selecione" label="Nacionalidade">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-2">
                        <app-select [items]="signupService.ufs_ids"
                            [group]="form" controlName="naturalness"
                            [readOnly]="isForeign" label="Naturalidade"
                            placeholder="UF">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-6">
                        <app-select [items]="cities_options" [group]="form"
                            controlName="naturalness_city" label="Cidade"
                            [readOnly]="!form.controls.naturalness.value"
                            [placeholder]="form.controls.naturalness.value ? 'Selecione a cidade' : 'Selecione um Estado primeiro'">
                        </app-select>
                    </div>
                </div>

                <div class="row" *ngIf="foundedRegister === null">
                    <div class="col-12 col-lg-6">
                        <app-select
                            [items]="signupService.choices().document_types"
                            [group]="form" controlName="document_type"
                            label="Tipo de documento" placeholder="Selecione">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-6">
                        <app-input [group]="form" controlName="rg"
                            [maxLength]="20" label="Número do Documento"
                            placeholder="12345678">
                        </app-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-6">
                        <app-select [items]="signupService.issuingAgencies"
                            [group]="form" controlName="issuing_agency"
                            label="Órgão expedidor" placeholder="SSP">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-2">
                        <app-select [items]="signupService.ufs" [group]="form"
                            controlName="issuing_uf" label="UF"
                            placeholder="SP">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-masked-input [group]="form"
                            controlName="shipping_date" mask="00/00/0000"
                            label="Data expedição" placeholder="dd/mm/aaaa">
                        </app-masked-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <div class="row" *ngIf="foundedRegister === null">
                    <div class="col-12 col-lg-2">
                        <app-select [group]="form" controlName="celphone_ddi"
                            [items]="DDIOptions" label="DDI">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-masked-input [group]="form"
                            controlName="celphone_number" label="Celular"
                            mask="(00) 00000-0000"
                            placeholder="(00) 9 9886-6554">
                        </app-masked-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-6">
                        <app-input [group]="form" controlName="email"
                            label="E-mail" placeholder="contato@email.com">
                        </app-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-4">
                        <app-masked-input [group]="form"
                            controlName="term_validity" mask="00/00/0000"
                            label="Início do mandato" placeholder="dd/mm/aaaa">
                        </app-masked-input>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-masked-input [group]="form"
                            [readOnly]="isIndefinite"
                            controlName="max_term_validity" mask="00/00/0000"
                            label="Validade do mandato"
                            placeholder="dd/mm/aaaa">
                        </app-masked-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-spacer [bottom]="36"></app-spacer>
                        <app-radio [multiple]="true"
                            formControlName="indefinite_term">
                            Validade indeterminada
                        </app-radio>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <app-radio [multiple]="true" formControlName="pep">
                            <div class="flex-1 d-flex flex-column">
                                <span class="pep-title">Considera-se PEP</span>
                                <app-spacer [bottom]="8"></app-spacer>
                                <span class="pep-description">Considera-se
                                    Pessoa
                                    Exposta Politicamente (PEP), nos termos da
                                    legislação em
                                    vigor, (i) agente público que desempenha ou
                                    tenha desempenhado, nos últimos cinco anos,
                                    no
                                    Brasil ou em
                                    países, territórios e dependências
                                    estrangeiras,
                                    cargos, empregos ou funções públicas
                                    relevantes; ou
                                    (ii) representante, familiar (parente na
                                    linha
                                    reta até primeiro grau, cônjuge,
                                    companheiro(a) ou
                                    enteado(a)).</span>
                            </div>
                        </app-radio>
                        <app-spacer [bottom]="48"></app-spacer>
                    </div>
                </div>

                <div class="row" *ngIf="foundedRegister === null">
                    <div class="col-12 col-lg-4 cep-container">
                        <app-masked-input [group]="form" controlName="zipcode"
                            label="CEP" mask="00000-000" placeholder="99887-766"
                            (input)="searchZipcode($event)">
                        </app-masked-input>
                        <i *ngIf="searchingZipcode"
                            class="fas fa-circle-notch fa-spin app-button-icon"></i>
                    </div>
                </div>
                <app-spacer [bottom]="24"></app-spacer>

                <ng-container
                    *ngIf="zipcodeFetched  && foundedRegister === null">
                    <div class=" row">
                        <div class="col-12 col-lg-6">
                            <app-input [group]="form" controlName="public_place"
                                label="Logradouro" [readOnly]="foundCep">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-input [group]="form" controlName="number"
                                type="number" label="Número">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <app-input [group]="form" controlName="complement"
                                [maxLength]="20" label="Complemento">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <app-input [group]="form" controlName="district"
                                label="Bairro" [readOnly]="foundCep">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <app-input [group]="form" controlName="city"
                                label="Cidade" [readOnly]="foundCep">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-select [items]="signupService.ufs"
                                [group]="form" controlName="uf" label="UF"
                                placeholder="SP" [readOnly]="foundCep">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <app-side-modal [active]="addAnotherModal" [title]="renderTitle()"
            (onClose)="handleCloseModal()" paddingBottom="0">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6 cep-container">
                        <app-masked-input [group]="form" controlName="document"
                            label="CPF" placeholder="000.000.000-00"
                            mask="000.000.000-00"
                            [readOnly]="foundedRegister !== null"
                            (input)="searchRepresentative($event)">
                        </app-masked-input>
                        <i *ngIf="loadingRepresentative"
                            class="fas fa-circle-notch fa-spin app-button-icon"></i>
                    </div>
                </div>

                <app-spacer [bottom]="24"></app-spacer>

                <ng-container *ngIf="representativeFetched">
                    <div class="row">
                        <div class="col-12">
                            <app-input [group]="form" controlName="full_name"
                                label="Nome Completo" [maxLength]="60"
                                placeholder="João Luis Barroso"
                                [readOnly]="foundedRegister !== null">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row" *ngIf="foundedRegister === null">
                        <div class="col-12 col-lg-6">
                            <app-masked-input [group]="form"
                                controlName="birth_date" mask="00/00/0000"
                                label="Data de nascimento"
                                placeholder="dd/mm/aaaa">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-6">
                            <app-select
                                [items]="signupService.choices().marital_choices"
                                [group]="form" controlName="marital_status"
                                label="Estado civil" placeholder="Selecione">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <ng-container *ngIf="(form.controls.marital_status.value === '2' || this.form.controls['marital_status'].value === '3') &&
                    foundedRegister === null   
                    ">
                        <div class="row">
                            <div class="col-12 col-lg-4">
                                <app-masked-input [group]="form"
                                    controlName="spouse_document"
                                    label="CPF do cônjuge"
                                    placeholder="000.000.000-00"
                                    mask="000.000.000-00">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-8">
                                <app-masked-input [group]="form"
                                    controlName="spouse_name"
                                    label="Nome do cônjuge"
                                    placeholder="Kátia Inácio da Silva"
                                    mask="W*" [usePatterns]="true">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-12">
                                <app-select
                                    [items]="signupService.choices().marital_statutes"
                                    [group]="form" controlName="spouse_statute"
                                    label="Regime de casamento">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row" *ngIf="foundedRegister === null">
                        <div class="col-12 col-lg-6">
                            <app-input [group]="form" controlName="occupation"
                                [maxLength]="30" label="Ocupação Profissional">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-6">
                            <app-select
                                [items]="signupService.nationalityChocies"
                                [group]="form" controlName="nationality"
                                placeholder="Selecione" label="Nacionalidade">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-6">
                            <app-select [items]="signupService.ufs_ids"
                                [group]="form" controlName="naturalness"
                                [readOnly]="isForeign" label="Naturalidade"
                                placeholder="UF">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-6">
                            <app-select [items]="cities_options" [group]="form"
                                controlName="naturalness_city" label="Cidade"
                                [readOnly]="!form.controls.naturalness.value"
                                [placeholder]="form.controls.naturalness.value ? 'Selecione a cidade' : 'Selecione um Estado primeiro'">
                            </app-select>
                        </div>
                    </div>

                    <div class="row" *ngIf="foundedRegister === null">
                        <div class="col-12 col-lg-6">
                            <app-select
                                [items]="signupService.choices().document_types"
                                [group]="form" controlName="document_type"
                                label="Tipo de documento"
                                placeholder="Selecione">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-6">
                            <app-input [group]="form" controlName="rg"
                                [maxLength]="20" label="Número do Documento"
                                placeholder="12345678">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <app-select [items]="signupService.issuingAgencies"
                                [group]="form" controlName="issuing_agency"
                                label="Órgão expedidor" placeholder="SSP">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-3">
                            <app-select [items]="signupService.ufs"
                                [group]="form" controlName="issuing_uf"
                                label="UF" placeholder="SP">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-5">
                            <app-masked-input [group]="form"
                                controlName="shipping_date" mask="00/00/0000"
                                label="Data expedição" placeholder="dd/mm/aaaa">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <ng-container *ngIf="foundedRegister === null">
                        <div class="row">
                            <div class="col-12 col-lg-4">
                                <app-select [group]="form"
                                    controlName="celphone_ddi"
                                    [items]="DDIOptions" label="DDI">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-8">
                                <app-masked-input [group]="form"
                                    controlName="celphone_number"
                                    label="Celular" mask="(00) 00000-0000"
                                    placeholder="(00) 9 9886-6554">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <app-input [group]="form" controlName="email"
                                    label="E-mail"
                                    placeholder="contato@email.com">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <app-masked-input [group]="form"
                                controlName="term_validity" mask="00/00/0000"
                                label="Início do mandato"
                                placeholder="dd/mm/aaaa">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-6">
                            <app-masked-input [group]="form"
                                [readOnly]="isIndefinite"
                                controlName="max_term_validity"
                                mask="00/00/0000" label="Validade do mandato"
                                placeholder="dd/mm/aaaa">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-6">
                            <app-spacer [bottom]="36"></app-spacer>
                            <app-radio [multiple]="true"
                                formControlName="indefinite_term">
                                Validade indeterminada
                            </app-radio>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-radio [multiple]="true">
                                <div class="flex-1 d-flex flex-column">
                                    <span class="pep-title">Considera-se
                                        PEP</span>
                                    <app-spacer [bottom]="8"></app-spacer>
                                    <span class="pep-description">Considera-se
                                        Pessoa Exposta Politicamente (PEP), nos
                                        termos da
                                        legislação em
                                        vigor, (i) agente público que desempenha
                                        ou
                                        tenha desempenhado, nos últimos cinco
                                        anos,
                                        no
                                        Brasil ou em
                                        países, territórios e dependências
                                        estrangeiras, cargos, empregos ou
                                        funções
                                        públicas
                                        relevantes; ou
                                        (ii) representante, familiar (parente na
                                        linha reta até primeiro grau, cônjuge,
                                        companheiro(a) ou
                                        enteado(a)).</span>
                                </div>
                            </app-radio>
                            <app-spacer [bottom]="48"></app-spacer>
                        </div>
                    </div>

                    <div class="row" *ngIf="foundedRegister === null">
                        <div class="col-12 col-lg-4 cep-container">
                            <app-masked-input [group]="form"
                                controlName="zipcode" label="CEP"
                                mask="00000-000" placeholder="99887-766"
                                (input)="searchZipcode($event)">
                            </app-masked-input>
                            <i *ngIf="searchingZipcode" class=" fas fa-circle-notch fa-spin
                                app-button-icon"></i>
                        </div>
                    </div>
                    <app-spacer [bottom]="24"></app-spacer>

                    <ng-container
                        *ngIf="zipcodeFetched && foundedRegister === null">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <app-input [group]="form"
                                    controlName="public_place"
                                    label="Logradouro" [readOnly]="foundCep">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-2">
                                <app-input [group]="form" controlName="number"
                                    type="number" label="Número">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-input [group]="form"
                                    controlName="complement" [maxLength]="20"
                                    label="Complemento">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <app-input [group]="form" controlName="district"
                                    label="Bairro" [readOnly]="foundCep">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-input [group]="form" controlName="city"
                                    label="Cidade" [readOnly]="foundCep">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-2">
                                <app-select [items]="signupService.ufs"
                                    [group]="form" controlName="uf" label="UF"
                                    placeholder="SP" [readOnly]="foundCep">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <div class="row button-sticky">
                    <div class="col-12 col-lg-6">
                        <app-button (onClick)="handleCloseModal()"
                            class="add-button" [outline]="true">
                            Cancelar
                        </app-button>
                    </div>

                    <ng-container *ngIf="representativeFetched">
                        <div class="col-12 col-lg-6">
                            <div class="text-right">
                                <app-button (onClick)="triggerForm()"
                                    [loading]="sending"
                                    [disabled]="getDisabled()"
                                    class="add-button">
                                    Salvar representante
                                </app-button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

        </app-side-modal>

        <ng-container *ngIf="representativeFetched || !showForm">
            <div class="row">
                <div class="col-12 d-flex">
                    <app-button (onClick)="clearForm()" [disabled]="sending"
                        *ngIf="showForm" [outline]="true" class="mr-3">
                        Redefinir campos
                    </app-button>

                    <app-button (onClick)="triggerForm()" [loading]="sending"
                        [disabled]="getDisabled()" class="add-button"
                        [outline]="data.length > 0">
                        <i class="fas fa-plus-circle mr-2"></i>
                        {{data.length > 0 ? 'Adicionar outro representante'
                        :
                        'Adicionar representante'}}
                    </app-button>
                </div>
            </div>
        </ng-container>

        <app-warning-modal [active]="removeModal"
            (onClose)="handleRemoveCancel()">
            <h3>Você tem certeza?</h3>
            <app-spacer [bottom]="24"></app-spacer>
            <p>Você não poderá reverter essa ação!</p>
            <app-spacer [bottom]="56"></app-spacer>

            <app-button (onClick)="handleRemoveCancel()" [center]="true"
                class="add-button">
                Cancelar
            </app-button>

            <app-spacer [bottom]="8"></app-spacer>

            <app-button (onClick)="removeData(selectedIndex)" class="add-button"
                [center]="true" [outline]="true">
                Sim, apague!
            </app-button>
        </app-warning-modal>
    </ng-container>
</form>