import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
})
export class WarningModalComponent implements OnInit {
  @Input() active = false;
  @Input() hasBorder = false;

  @Input() size: string = 'md';

  @Output() onClose = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  click(event: MouseEvent) {
    this.onClose.emit(event);
  }
}
