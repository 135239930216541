<div class="shareholder-steps">
    <div class="shareholder-steps-item">
        <div class="shareholder-steps-item-line" [class.complete]="true"></div>
        <div class="shareholder-steps-item-line half"
            [class.complete]="approval.step > 0 || approval.completed">
        </div>

        <div class="shareholder-steps-item-indicator"
            [class.half]="approval.step == 0"
            [class.complete]="approval.step > 0 || approval.completed">
        </div>
        <app-spacer [bottom]="12"></app-spacer>

        <span class="shareholder-steps-item-department">Edição</span>
        <app-spacer [bottom]="5"></app-spacer>

    </div>

    <div class="shareholder-steps-item"
        *ngFor="let department of approval.queue.departments; let i = index">
        <div class="shareholder-steps-item-line"
            [class.complete]="isCurrentDepartment(department, i)">
        </div>
        <div class="shareholder-steps-item-line half"
            [class.complete]="getDepartmentCompleted(department)"
            *ngIf="i < approval.queue.departments.length - 1">
        </div>

        <div class="shareholder-steps-item-indicator"
            [class.complete]="getDepartmentCompleted(department)"
            [class.half]="isCurrentDepartment(department, i)"></div>
        <app-spacer [bottom]="12"></app-spacer>

        <span class="shareholder-steps-item-department">{{department.type
            === 'department' ?
            department.department.name :
            getAgentName(department.agent)}}</span>
        <app-spacer [bottom]="5"></app-spacer>

        <ng-container *ngIf="getDepartmentSigns(department).length > 0">
            <span class="shareholder-steps-item-date"
                *ngFor="let eachApproval of getDepartmentSigns(department); let i = index">
                {{eachApproval.approved ? i+1 :
                ''}}{{eachApproval.approved ? 'º aprovação' :
                eachApproval.restart ? 'Retornado' :
                'Rejeitado'}} por
                {{getRegisterName(eachApproval.register)}}<br> em
                {{eachApproval.date |
                date:'dd/MM/yyyy \'às\' HH:mm'}}
            </span>
        </ng-container>

        <span class="shareholder-steps-item-date"
            *ngIf="!getDepartmentCompleted(department)">
            Aguardando aprovação
        </span>
    </div>
</div>
