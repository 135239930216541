<ng-container *ngIf="loading">
    <div class="row">
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-8">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!loading">
    <form [formGroup]="form">
        <div class="row no-gutters w-100">
            <div class="col-12 col-lg-10">
                <div class="row">

                    <div class="col-12 text-danger">
                        * Para prosseguir, sua composição societária deve ter
                        {{isSA ? 'no mínimo 25%' : '100%'}} de
                        participação.

                        <app-spacer [bottom]="8"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-11">
                        <span class="body-text">
                            Obrigatório informar todas as pessoas jurídicas e
                            naturais da cadeia societária, até
                            alcançar a
                            pessoa
                            natural caracterizada como beneficiário final.
                        </span>
                        <app-spacer [bottom]="8"></app-spacer>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-11">
                        <span class="sub-text">
                            Considera-se beneficiário final a pessoa natural que
                            direta ou indiretamente possui mais de
                            25%
                            do capital
                            ou detém/exerce a preponderância nas deliberações
                            sociais da sociedade.
                        </span>
                        <app-spacer [bottom]="37"></app-spacer>
                    </div>
                </div>

                <ng-container *ngIf="showForm">
                    <div class="row">
                        <ng-container
                            *ngIf="authService.user.actived_register.register.role.slug !== getSlugLabel()">
                            <div class="col-12 col-lg-4">
                                <app-masked-input [group]="form"
                                    controlName="document" label="CPF / CNPJ"
                                    placeholder="000.000.000-00"
                                    mask="CPF_CNPJ">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </ng-container>

                        <div class="col-12 col-lg-8">
                            <app-input [group]="form" controlName="full_name"
                                label="Nome Completo / Razão Social"
                                placeholder="ACME LTDA">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <ng-container
                            *ngIf="authService.user.actived_register.register.role.slug !== getSlugLabel()">
                            <div class="col-12 col-lg-4">
                                <app-input [group]="form"
                                    controlName="percentage" type="number"
                                    label="% Participação" placeholder="25%"
                                    [max]="100">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </ng-container>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-radio [multiple]="true" formControlName="pep">
                                <div class="flex-1 d-flex flex-column">
                                    <span class="pep-title">PEP</span>
                                    <app-spacer [bottom]="8"></app-spacer>
                                    <span class="pep-description">Considera-se
                                        Pessoa Exposta Politicamente (PEP), nos
                                        termos da legislação
                                        em
                                        vigor, (i) agente público que desempenha
                                        ou tenha desempenhado, nos últimos
                                        cinco
                                        anos, no Brasil ou
                                        em
                                        países, territórios e dependências
                                        estrangeiras, cargos, empregos ou
                                        funções
                                        públicas relevantes; ou
                                        (ii)
                                        representante, familiar (parente na
                                        linha reta até primeiro grau, cônjuge,
                                        companheiro(a) ou
                                        enteado(a)).</span>
                                </div>
                            </app-radio>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12">
                            <app-radio [multiple]="true"
                                formControlName="pep_relationship">
                                <div class="flex-1 d-flex flex-column">
                                    <span class="option-title">
                                        Possuí relacionamento com pessoa
                                        politicamente exposta
                                    </span>
                                </div>
                            </app-radio>

                            <ng-container
                                *ngIf="form.controls.pep_relationship.value">
                                <app-spacer [bottom]="24"></app-spacer>
                                <div class="row ml-2">
                                    <div class="offset-1 col-7">
                                        <app-masked-input [group]="form"
                                            controlName="pep_relationship_document"
                                            label="CPF da pessoa"
                                            placeholder="123.456.789-01"
                                            mask="000.000.000-00">
                                        </app-masked-input>

                                        <app-spacer [bottom]="24"></app-spacer>
                                    </div>
                                </div>

                                <div class="row ml-2">
                                    <div class="offset-1 col-7">
                                        <app-input [group]="form"
                                            controlName="pep_relationship_name"
                                            label="Nome da pessoa"
                                            [maxLength]="60"
                                            placeholder="Rubens Gracindo Emiliano">
                                        </app-input>

                                        <app-spacer [bottom]="24"></app-spacer>
                                    </div>
                                </div>

                                <div class="row ml-2">
                                    <div class="offset-1 col-7">
                                        <app-input [group]="form"
                                            controlName="pep_bond"
                                            label="Vínculo" [maxLength]="20"
                                            placeholder="Esposa, marido, filho, sócio, etc">
                                        </app-input>
                                    </div>
                                </div>
                            </ng-container>
                            <app-spacer [bottom]="48"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-button (onClick)="handleTriggerForm(0)"
                                [disabled]="form.invalid" class="add-button"
                                [outline]="true" [loading]="sending">
                                <i class="fas fa-plus-circle mr-2"></i>
                                Adicionar sócio
                            </app-button>
                            <app-spacer [bottom]="72"></app-spacer>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!showForm">
                    <div class="row">
                        <div class="col-12 ">
                            <div
                                *ngFor="let item of formattedData; let i = index">
                                <div class="composition-card">
                                    <div class="composition-card-header">
                                        <h2>{{item.full_name}}
                                        </h2>
                                        <div
                                            class="composition-card-header-percent">
                                            {{getShareholdersPercentage(item.id)}}%
                                        </div>
                                    </div>

                                    <div class="composition-card-content">
                                        <div class="dash-card-body">
                                            <div class="dash-card-scroll">
                                                <table class="bordered"
                                                    *ngIf="item.shareholders.length > 0">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Participação
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr
                                                            *ngFor="let item of item.shareholders; let ci = index">
                                                            <td>
                                                                <span>{{item.full_name}}</span>
                                                                <span
                                                                    class="app-table-details-body-row-subtitle">
                                                                    {{item.document}}
                                                                </span>
                                                            </td>
                                                            <td>{{formatPercentage(item.percentage)}}%
                                                            </td>
                                                            <td>
                                                                <div
                                                                    class="dash-card-item-buttons">
                                                                    <button
                                                                        title="Editar"
                                                                        type="button"
                                                                        class="dash-item-button"
                                                                        (click)="handleEdit(item)">
                                                                        <i
                                                                            class="fas fa-pen"></i>
                                                                    </button>

                                                                    <app-spacer
                                                                        [right]="16"></app-spacer>

                                                                    <button
                                                                        title="Remover"
                                                                        type="button"
                                                                        class="dash-item-button"
                                                                        (click)="handleRemoveModal(item)">
                                                                        <i
                                                                            class="fas fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <app-spacer [bottom]="24">
                                                </app-spacer>

                                            </div>
                                        </div>

                                        <!-- <app-button
                                      *ngIf="item.shareholders.length == 0"
                                          (onClick)="sendData()"
                                          [disabled]="getDisabled() || getShareholdersPercentage(i) === 100"
                                          class="add-button"
                                          [outline]="formattedData.length > 0">
                                          <i
                                              class="fas fa-plus-circle mr-2"></i>
                                          Adicionar sócio
                                      </app-button> -->

                                        <app-button
                                            (onClick)="handleTriggerForm(item.id, true)"
                                            [loading]="sending"
                                            [disabled]="getDisabled() || getShareholdersPercentage(item.id) === 100"
                                            class="add-button"
                                            [outline]="formattedData.length > 0">
                                            <i
                                                class="fas fa-plus-circle mr-2"></i>
                                            Adicionar outro sócio
                                        </app-button>
                                    </div>
                                </div>
                                <app-spacer [bottom]="48"></app-spacer>
                            </div>
                        </div>
                    </div>
                </ng-container>



                <app-side-modal [active]="addAnotherModal"
                    title="Adicionar sócio" (onClose)="handleCloseModal()"
                    paddingBottom="0">
                    <div class="container">
                        <div class="row">
                            <ng-container
                                *ngIf="authService.user.actived_register.register.role.slug !== getSlugLabel()">
                                <div class="col-12 col-lg-4">
                                    <app-masked-input [group]="form"
                                        controlName="document"
                                        label="CPF / CNPJ"
                                        placeholder="000.000.000-00"
                                        mask="CPF_CNPJ">
                                    </app-masked-input>
                                    <app-spacer [bottom]="24"></app-spacer>
                                </div>
                            </ng-container>

                            <div class="col-12 col-lg-8">
                                <app-input [group]="form"
                                    controlName="full_name"
                                    label="Nome Completo / Razão Social"
                                    placeholder="ACME LTDA">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>

                        <div class="row">
                            <ng-container
                                *ngIf="authService.user.actived_register.register.role.slug !== getSlugLabel()">
                                <div class="col-12 col-lg-4">
                                    <app-input [group]="form"
                                        controlName="percentage" type="number"
                                        label="% Participação" placeholder="25%"
                                        [max]="100">
                                    </app-input>
                                    <app-spacer [bottom]="24"></app-spacer>
                                </div>
                            </ng-container>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <app-radio [multiple]="true"
                                    formControlName="pep"
                                    [isChecked]="form.controls.pep.value">
                                    <div class="flex-1 d-flex flex-column">
                                        <span class="pep-title">PEP</span>
                                        <app-spacer [bottom]="8"></app-spacer>
                                        <span
                                            class="pep-description">Considera-se
                                            Pessoa Exposta Politicamente (PEP),
                                            nos
                                            termos da legislação
                                            em
                                            vigor, (i) agente público que
                                            desempenha ou tenha desempenhado,
                                            nos últimos
                                            cinco
                                            anos, no Brasil ou
                                            em
                                            países, territórios e dependências
                                            estrangeiras, cargos, empregos ou
                                            funções
                                            públicas relevantes; ou
                                            (ii)
                                            representante, familiar (parente na
                                            linha reta até primeiro grau,
                                            cônjuge,
                                            companheiro(a) ou
                                            enteado(a)).</span>
                                    </div>
                                </app-radio>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12">
                                <app-radio [multiple]="true"
                                    formControlName="pep_relationship"
                                    [isChecked]="form.controls.pep_relationship.value">
                                    <div class="flex-1 d-flex flex-column">
                                        <span class="option-title">
                                            Possuí relacionamento com pessoa
                                            politicamente exposta
                                        </span>
                                    </div>
                                </app-radio>

                                <ng-container
                                    *ngIf="form.controls.pep_relationship.value">
                                    <app-spacer [bottom]="24"></app-spacer>
                                    <div class="row ml-2">
                                        <div class="offset-1 col-7">
                                            <app-masked-input [group]="form"
                                                controlName="pep_relationship_document"
                                                label="CPF da pessoa"
                                                placeholder="123.456.789-01"
                                                mask="000.000.000-00">
                                            </app-masked-input>

                                            <app-spacer [bottom]="24">
                                            </app-spacer>
                                        </div>
                                    </div>

                                    <div class="row ml-2">
                                        <div class="offset-1 col-7">
                                            <app-input [group]="form"
                                                controlName="pep_relationship_name"
                                                label="Nome da pessoa"
                                                [maxLength]="60"
                                                placeholder="Rubens Gracindo Emiliano">
                                            </app-input>

                                            <app-spacer [bottom]="24">
                                            </app-spacer>
                                        </div>
                                    </div>

                                    <div class="row ml-2">
                                        <div class="offset-1 col-7">
                                            <app-input [group]="form"
                                                controlName="pep_bond"
                                                label="Vínculo"
                                                placeholder="Esposa, marido, filho, sócio, etc">
                                            </app-input>
                                        </div>
                                    </div>
                                </ng-container>
                                <app-spacer [bottom]="48"></app-spacer>
                            </div>
                        </div>

                        <div class="row button-sticky">
                            <div class="col-12 col-lg-6">
                                <app-button (onClick)="handleCloseModal()"
                                    class="add-button" [outline]="true">
                                    Cancelar
                                </app-button>
                            </div>

                            <div class="col-12 col-lg-6">
                                <div class="text-right">
                                    <app-button
                                        (onClick)="handleTriggerForm(parentID, true)"
                                        [loading]="sending"
                                        [disabled]="getDisabled()"
                                        class="add-button">
                                        Salvar sócio
                                    </app-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </app-side-modal>

                <app-warning-modal [active]="removeModal"
                    (onClose)="handleRemoveCancel()">
                    <h3>Você tem certeza?</h3>
                    <app-spacer [bottom]="24"></app-spacer>
                    <p>Você não poderá reverter essa ação!</p>
                    <app-spacer [bottom]="56"></app-spacer>

                    <app-button (onClick)="handleRemoveCancel()" [center]="true"
                        class="add-button">
                        Cancelar
                    </app-button>

                    <app-spacer [bottom]="8"></app-spacer>

                    <app-button (onClick)="removeData()" class="add-button"
                        [center]="true" [outline]="true">
                        Sim, apague!
                    </app-button>
                </app-warning-modal>
            </div>
        </div>
    </form>
</ng-container>

<app-warning-modal [active]="showWarning">
    <h3>Atenção</h3>
    <app-spacer [bottom]="24"></app-spacer>
    <p>
        Você atualizou um dado que impacta na composição societária. Por
        isso, é necessário que você revise a composição societária.
    </p>
    <app-spacer [bottom]="56"></app-spacer>

    <app-button (onClick)="showWarning = false" [center]="true">
        ok
    </app-button>
</app-warning-modal>