<div class="row">
    <div class="col-12 col-lg-4">
        <div class="dash-details-card">
            <div class="dash-details-card-header">
                <span>Dados básicos</span>

                <ng-container
                    *ngIf="approval.cards.basic_info && approval.cards.basic_info.files.length > 0">
                    <app-attachment-button
                        [files]="approval.cards.basic_info.files"
                        (onSelect)="handleSelect($event,'basic_info')">
                    </app-attachment-button>
                </ng-container>
            </div>
            <app-spacer [bottom]="15"></app-spacer>

            <ng-container *ngIf="approval.register.type === 'PJ' && isFund">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.company.document?.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    :
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.fundation_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.fundation_date |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Data de fundação
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.branch">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">

                                    {{approval.register.company.branch_display}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Natureza jurídica
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.framework">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">

                                    {{approval.register.company.framework}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Enquadramento
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.municipal_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.municipal_registration
                                    ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Inscrição municipal
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.state_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.state_registration
                                    ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Inscrição estadual
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.nire">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.nire ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NIRE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.giin">
                            <div class="d-flex flex-column" *ngIf="!isFund">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.giin ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    GIIN
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.cellphone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.cellphone.number)
                                        ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.phone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.phone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.phone.number)
                                        ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>



                        <!-- <div class="col-12 col-md-6" *ngIf="approval.phones">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.contact_email" target="_blank">
                                        {{formatPhone(approval.phones[1].number) ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <div class="col-12"
                            *ngIf="approval.register.company.site">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.company.contact_email"
                                        target="_blank">
                                        {{approval.register.company.site ??
                                        '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.cnae">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">

                                    {{approval.register.company.cnae.name}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.net_worth">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.company.net_worth)
                                    ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Patrimônio líquido
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="divider"></div>
                <app-spacer [bottom]="30"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.company.assignor?.coobligation">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.assignor?.coobligation
                                    === "true"
                                    ? 'Sim' : 'Não'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    coobrigação
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.assignor?.relationship_with_other_institutions">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.assignor.relationship_institution}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    RELACIONAMENTO COM OUTRAS INSTITUIÇÕES
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.judicial_recovery">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.have_judicial_recovery
                                    ? 'Sim' : 'Não'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    há plano de recuperação judicial
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.judicial_recovery">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.judicial_recovery_date
                                    | date: 'dd/MM/yyyy'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Em recuperação judicial
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PJ' && isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 col-lg-6"
                            *ngIf="approval.register.company.document?.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    :
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.fundation_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.fundation_date |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Data de fundação
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <!-- <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{getChoicesName('branch_choices', approval.register.company.branch)?.label ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Natureza jurídica
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{getChoicesName('frameworks',
                                    approval.register.company.framework)?.label ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Enquadramento
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.municipal_registration ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Inscrição municipal
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.state_registration ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Inscrição estadual
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.nire ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NIRE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.contact_email" target="_blank">
                                        {{approval.phones.length > 0 ? formatPhone(approval.phones[1].number) : '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.company.contact_email" target="_blank">
                                        {{approval.register.company.site ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <div class="col-12"
                            *ngIf="approval.register.company.cnae">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.cnae.name}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.capital">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.capital ?
                                    formatBrl(approval.register.company.capital)
                                    : '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    capital social
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.anual_income">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.company.anual_income)
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    faturamento bruto anual
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.number_employees">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.number_employees
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    N.º de funcionários
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PJ' && role !== 'provider' && !isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.company.document?.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    : '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.fundation_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.fundation_date |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Data de fundação
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.branch">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.branch_display}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Natureza jurídica
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.framework">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.framework.name}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Enquadramento
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.municipal_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.municipal_registration
                                    ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Inscrição municipal
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.state_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.state_registration
                                    ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Inscrição estadual
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.free_state_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    Sim
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Isento de inscrição estadual
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.nire">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.nire ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NIRE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.giin">
                            <div class="d-flex flex-column" *ngIf="!isFund">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.giin ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    GIIN
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column" *ngIf="!isFund">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.contract_type}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Tipo de contrato
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.cellphone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.cellphone.number)
                                        ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.phone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.phone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.phone.number)
                                        ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.contact_email">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.company.contact_email"
                                        target="_blank">
                                        {{approval.register.company.contact_email}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Email
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.site">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="approval.register.company.site"
                                        target="_blank">
                                        {{approval.register.company.site}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.cnae">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.cnae.name}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.company_size">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.company_size_display}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    PORTE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.number_employees">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.number_employees
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    N.º de funcionários
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.anual_income">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.company.anual_income)
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    faturamento bruto anual
                                </span>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.capital">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.company.capital)
                                    ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    capital social
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="divider"></div>
                <app-spacer [bottom]="30"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.company.assignor?.coobligation">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.assignor.coobligation
                                    === "true"
                                    ? 'Sim' : 'Não'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    coobrigação
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.assignor?.relationship_with_other_institutions">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.assignor.relationship_institution}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    RELACIONAMENTO COM OUTRAS INSTITUIÇÕES
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12"
                            *ngIf="approval.register.company.judicial_recovery">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    Sim
                                    <ng-container
                                        *ngIf="approval.register.company.judicial_recovery_date">
                                        ,
                                        {{approval.register.company.judicial_recovery_date
                                        | date: 'dd/MM/yyyy'}}
                                    </ng-container>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Em recuperação judicial
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.billet_emitter">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    Sim
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Emite boleto
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12"
                            *ngIf="approval.register.company.nfe_emitter">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    Sim
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Emite NFe
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12"
                            *ngIf="approval.register.company.duplicate_emitter">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    Sim
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Emite duplicata
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <!-- <div class="col-12" *ngIf="approval.register.company.judicial_recovery">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.judicial_recovery_date | date: 'dd/MM/yyyy' ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Data da homologação
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.assignor.authorization_bacen_queries ? 'Sim' : 'Não'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Autoriza {{'brand.name' | theme}} efetuar consulta no BACEN
                                </span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PJ' && role === 'provider'">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 col-lg-6"
                            *ngIf="approval.register.company.document?.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    :
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.fundation_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.fundation_date |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Data de fundação
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.branch">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.branch_display}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Natureza jurídica
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.framework">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.framework.name}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Enquadramento
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.municipal_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.municipal_registration
                                    ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Inscrição municipal
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.state_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.state_registration
                                    ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Inscrição estadual
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.free_state_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    Sim
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Isento de inscrição estadual
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.nire">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.nire ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NIRE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column" *ngIf="!isFund">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.contract_type}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Tipo de contrato
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.cellphone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.cellphone.number)
                                        ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.phone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.phone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.phone.number)
                                        ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <!-- <div class="col-12 col-md-6" *ngIf="approval.phones.length > 1">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.contact_email" target="_blank">
                                        {{formatPhone(approval.phones[0].number) ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <div class="col-12"
                            *ngIf="approval.register.company.site">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.company.contact_email"
                                        target="_blank">
                                        {{approval.register.company.site ??
                                        '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.cnae">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.cnae.name}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.number_employees">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.number_employees
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    N.º de funcionários
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PF' && role !== 'provider' &&  !isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.person.document.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{formatCpf(approval.register.person.document)}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CPF
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.birth_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.birth_date |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    DATA DE NASCIMENTO
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.naturalness">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.naturalness ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NATURALIDADE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.gender">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('gender_choices',approval.register.person.gender)?.label
                                    ?? '-'
                                    }}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Gênero
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.marital_status">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('marital_choices',approval.register.person.marital_status)?.label
                                    ?? '-'
                                    }}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Estado civil
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <ng-container *ngIf="approval.register.person.spouse ">
                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.register.person.spouse.document
                                        ?? '-'}}
                                    </span>

                                    <span
                                        class="dash-details-card-item-subtitle">
                                        CPF DO CÔNJUGE
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.register.person.spouse.name
                                        ?? '-'}}
                                    </span>

                                    <span
                                        class="dash-details-card-item-subtitle">
                                        NOME DO CÔNJUGE
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-12">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{getChoicesName('marital_statute',approval.register.person.spouse.marriage_bond)?.label
                                        ?? '-'}}
                                    </span>

                                    <span
                                        class="dash-details-card-item-subtitle">
                                        REGIME DE CASAMENTO
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.mother_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.mother_name ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NOME DA MÃE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.father_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.father_name ??
                                    '-'}}

                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NOME DO PAI
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.type.name}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Tipo de documento
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.number}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Número do documento
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.uf}}
                                        /{{approval.register.person.secondary_document.dispatching_agency}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Órgão expedidor
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.expedition_date
                                        |
                                        date: 'dd/MM/yyyy' ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Data de expedição
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.person.cellphone.number)}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.phone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.phone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.person.phone.number)}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.email">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.person.email"
                                        target="_blank">
                                        {{approval.register.person.email}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Email
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.site">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="approval.register.person.site"
                                        target="_blank">
                                        {{approval.register.person.site ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.revenues">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.person.revenues)
                                    ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Rendimento dos últimos 12 meses
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container
                    *ngIf="approval.register.person.assignor?.relationship_with_other_institutions">
                    <div class="divider"></div>
                    <app-spacer [bottom]="30"></app-spacer>

                    <div class="dash-details-card-body">
                        <div class="row">
                            <div class="col-12"
                                *ngIf="approval.register.person.assignor?.relationship_with_other_institutions">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.register.person.assignor.relationship_institution}}
                                    </span>

                                    <span
                                        class="dash-details-card-item-subtitle">
                                        RELACIONAMENTO COM OUTRAS INSTITUIÇÕES
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PF' && role === 'provider' &&  !isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.person.document.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.number
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CPF
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.birth_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.birth_date |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    DATA DE NASCIMENTO
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.naturalness">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.naturalness ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NATURALIDADE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.document">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document ?
                                    formatCpf(approval.register.person.document)
                                    : '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    DOCUMENTO
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.expedition_date
                                    | date:'dd/MM/yyyy'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    DATA DE EXPEDIÇÃO
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.gender">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('gender_choices',approval.register.person.gender)?.label
                                    ?? '-'
                                    }}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Gênero
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.marital_status">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('marital_choices',approval.register.person.marital_status)?.label
                                    ?? '-'
                                    }}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Estado civil
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <ng-container *ngIf="approval.register.person.spouse">
                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.register.person.spouse.document
                                        ?? '-'}}
                                    </span>

                                    <span
                                        class="dash-details-card-item-subtitle">
                                        CPF DO CÔNJUGE
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.register.person.spouse.name
                                        ?? '-'}}
                                    </span>

                                    <span
                                        class="dash-details-card-item-subtitle">
                                        NOME DO CÔNJUGE
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-12">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{getChoicesName('marital_statute',approval.register.person.spouse.marriage_bond)?.label
                                        ?? '-'}}
                                    </span>

                                    <span
                                        class="dash-details-card-item-subtitle">
                                        REGIME DE CASAMENTO
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.mother_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.mother_name ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NOME DA MÃE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.father_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.father_name ??
                                    '-'}}

                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NOME DO PAI
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.type.name}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Tipo de documento
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.number}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Número do documento
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.uf}}
                                        /{{approval.register.person.secondary_document.dispatching_agency}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Órgão expedidor
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.expedition_date
                                        |
                                        date: 'dd/MM/yyyy' ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Data de expedição
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.site">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="approval.register.person.site"
                                        target="_blank">
                                        {{approval.register.person.site ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.revenues">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.person.revenues)
                                    ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Faturamento
                                    nos últimos 12 meses
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PF' && isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.person.document.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.number
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CPF
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.birth_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.birth_date |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    DATA DE NASCIMENTO
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.naturalness">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.naturalness ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NATURALIDADE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">

                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    DOCUMENTO
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.document">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.expedition_date
                                    | date:'dd/MM/yyyy'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    DATA DE EXPEDIÇÃO
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.gender">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('gender_choices',approval.register.person.gender)?.label
                                    ?? '-'
                                    }}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Gênero
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                        <!--
                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{getChoicesName('marital_choices',
                                    approval.general.marital_status)?.label}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Estado civil
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <ng-container
                            *ngIf="approval.general.marital_status === 2 || approval.general.marital_status === 3 ">
                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.spouse.marriage_bond !== '' || approval.spouse.marriage_bond !== null
                                        ? approval.spouse.marriage_bond : '-'}}
                                    </span>

                                    <span class="dash-details-card-item-subtitle">
                                        CPF DO CÔNJUGE
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.spouse.marriage_bond !== '' || approval.spouse.marriage_bond !== null
                                        ? approval.spouse.marriage_bond : '-'}}
                                    </span>

                                    <span class="dash-details-card-item-subtitle">
                                        NOME DO CÔNJUGE
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-12">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.spouse.marriage_bond !== '' || approval.spouse.marriage_bond !== null
                                        ? approval.spouse.marriage_bond : '-'}}
                                    </span>

                                    <span class="dash-details-card-item-subtitle">
                                        REGIME DE CASAMENTO
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.general.mother_name ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NOME DA MÃE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.general.father_name ?? '-'}}

                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NOME DO PAI
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->
                    </div>
                </div>
            </ng-container>
        </div>

        <app-spacer [bottom]="24"></app-spacer>

        <ng-container *ngIf="!isSimplified">
            <div class="dash-details-card">
                <div class="dash-details-card-header">
                    <span>Endereço</span>

                    <ng-container
                        *ngIf="approval.cards.address && approval.cards.address.files.length > 0">
                        <app-attachment-button
                            [files]="approval.cards.address.files"
                            (onSelect)="handleSelect($event,'address')">
                        </app-attachment-button>
                    </ng-container>
                </div>
                <app-spacer [bottom]="24"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{approval.register.address.cep ?? '-'}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                CEP
                            </span>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>

                        <div class="col-12 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{approval.register.address.public_place ??
                                '-'}},
                                {{approval.register.address.number?? '-'}},
                                {{approval.register.address.complement?? '-'}}
                                <br>
                                {{approval.register.address.district?? '-'}},
                                {{approval.register.address.city?? '-'}} -
                                {{approval.register.address.uf?? '-'}}<br>
                                Brasil
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                ENDEREÇO COMPLETO
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <iframe [src]="approval.map_address" width="100%" height="200" frameborder="0" style="border:0;"
                allowfullscreen></iframe> -->
            </div>
        </ng-container>
    </div>

    <div class="col-12 col-lg-8">
        <div class="row">
            <div class="col-12" *ngIf="bankData.length > 0">
                <div class="dash-details-card">
                    <div class="dash-details-card-header">
                        <span>Contas bancárias</span>

                        <ng-container
                            *ngIf="approval.cards.bank_account && approval.cards.bank_account.files.length > 0">
                            <app-attachment-button
                                [files]="approval.cards.bank_account.files"
                                (onSelect)="handleSelect($event,'bank_account')">
                            </app-attachment-button>
                        </ng-container>
                    </div>
                    <app-spacer [bottom]="25"></app-spacer>

                    <app-table-list [columns]="bankColumns" [data]="bankData">
                    </app-table-list>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12"
                *ngIf="approval.register.type !== 'PJ' && pepData">
                <div class="dash-details-card">
                    <div class="dash-details-card-header">
                        <span>Possui relacionamento com PEP</span>
                    </div>
                    <app-spacer [bottom]="25"></app-spacer>

                    <app-table-list [columns]="pepColumns" [data]="pepData">
                    </app-table-list>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12"
                *ngIf="approval.register.type === 'PJ' && economicGroupData.length > 0 && !isFund">
                <div class="dash-details-card">
                    <div class="dash-details-card-header">
                        <span>Grupo econômico</span>

                        <ng-container
                            *ngIf="approval.cards.economic_group && approval.cards.economic_group.files.length > 0">
                            <app-attachment-button
                                [files]="approval.cards.economic_group.files"
                                (onSelect)="handleSelect($event,'economic_group')">
                            </app-attachment-button>
                        </ng-container>
                    </div>
                    <app-spacer [bottom]="25"></app-spacer>

                    <app-table-list [columns]="economicGroupColumns"
                        [data]="economicGroupData" size="19px">
                    </app-table-list>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12" *ngIf="isAdmin">
                <div class="dash-details-card card-padding-top">
                    <div class="dash-details-card-body">
                        <div class="row">
                            <div class="col-6 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.created_at | date:
                                    'dd/MM/yyyy HH:mm'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CADASTRO
                                </span>
                            </div>

                            <div class="col-6 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.updated_at | date:
                                    'dd/MM/yyyy HH:mm'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    ATUALIZAÇÃO
                                </span>
                            </div>

                            <!-- <div class="col-3 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    0*
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    verificação
                                </span>
                            </div>

                            <div class="col-3 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    0*
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    encerremento
                                </span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <app-spacer [bottom]="24"></app-spacer>
</div>

<app-modal #modalRoot class="modal-demo">
    <ng-container class="app-modal-header">
        <ng-container *ngIf="file">
            <svg-icon src="assets/images/icon_drag_indicator.svg"></svg-icon>
            <div>{{file.type.name}}</div>
            <div></div>
        </ng-container>
    </ng-container>
    <ng-container class="app-modal-body">
        <ng-container *ngIf="file">
            <img [src]="url" *ngIf="!isPDF && !isXLS" style="max-width:1280px">
            <iframe [src]="url" *ngIf="isPDF || isXLS" frameborder="0"
                height="100%" width="100%">
            </iframe>
        </ng-container>
    </ng-container>
</app-modal>