<div class="row">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <div class="row">
                <div class="col-9">
                    <ng-container *ngIf="data.length > 0">
                        <div class="each-card" *ngFor="let item of data">
                            <div class="composition-card">
                                <div class="composition-card-header">
                                    <h2>{{item.parent.full_name}}
                                    </h2>
                                    <div
                                        class="composition-card-header-percent">
                                        {{getShareholdersPercentage(item)}}%
                                    </div>
                                </div>

                                <div class="composition-card-content">
                                    <div class="dash-card-body">
                                        <div class="dash-card-scroll">
                                            <table class="bordered"
                                                *ngIf="item.childrens.length > 0">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Participação
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                    <tr
                                                        *ngFor="let children of item.childrens">
                                                        <td>
                                                            <span>{{children.full_name}}</span>
                                                            <span
                                                                class="app-table-details-body-row-subtitle">
                                                                {{children.document}}
                                                            </span>
                                                        </td>
                                                        <td>{{children.percentage}}%
                                                        </td>
                                                        <td>
                                                            <div
                                                                class="dash-card-item-buttons">
                                                                <button
                                                                    title="Editar"
                                                                    type="button"
                                                                    class="dash-item-button"
                                                                    (click)="openModal(children)">
                                                                    <i
                                                                        class="fas fa-eye"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <app-spacer [bottom]="48"></app-spacer>

                        </div>

                    </ng-container>
                </div>
            </div>

            <div *ngIf="data.length === 0" class="card-padding-bottom">
                <span class="dash-card-item-content-title text-center d-block">
                    Nenhum acionista registrado
                </span>
            </div>
        </div>

        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>

<app-side-modal [active]="compositionDetails"
    [title]="compositionDetails ? selectedPartner.full_name : '-'"
    (onClose)="closeModal()" paddingBottom="30">
    <div class="container" *ngIf="selectedPartner">
        <div class="row">
            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-title">
                    {{selectedPartner ? selectedPartner.document : '-'}}
                </span>

                <span class="dash-details-card-item-subtitle">
                    {{selectedPartner.document &&
                    selectedPartner.document.length > 14 ? 'CNPJ' : 'CPF'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column"
                *ngIf="selectedPartner.pep">
                <span class="dash-details-card-item-badged-list">
                    Declaro PEP
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-title">
                    {{selectedPartner ? selectedPartner.percentage : '-'}}%
                </span>

                <span class="dash-details-card-item-subtitle">
                    participação
                </span>
                <app-spacer [bottom]="32"></app-spacer>
            </div>
        </div>

        <ng-container *ngIf="selectedPartner.pep_relationship">
            <app-label
                label="Possuí relacionamento com pessoa politicamente exposta">
            </app-label>

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-title">
                        {{selectedPartner.pep_relationship_name}}
                    </span>

                    <span class="dash-details-card-item-subtitle">
                        Nome
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-title">
                        {{selectedPartner.pep_relationship_document}}
                    </span>

                    <span class="dash-details-card-item-subtitle">
                        Documento
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-title">
                        {{selectedPartner.pep_bond}}
                    </span>

                    <span class="dash-details-card-item-subtitle">
                        Vínculo
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>
        </ng-container>

        <ng-container
            *ngIf="selectedPartner.files && selectedPartner.files.length > 0 ">
            <div class="row">
                <div class="col-12">
                    <h3>Anexos</h3>
                </div>
                <div class="col-12">
                    <app-attachment-button [files]="selectedPartner.files"
                        [full]="true"
                        (onSelect)="handleSelect($event)"></app-attachment-button>
                </div>
            </div>

            <app-spacer [bottom]="32"></app-spacer>
        </ng-container>
    </div>
</app-side-modal>

<app-modal #modalRoot class="modal-demo">
    <ng-container class="app-modal-header">
        <ng-container *ngIf="file">
            <svg-icon src="assets/images/icon_drag_indicator.svg"></svg-icon>
            <div>{{file.type.name}}</div>
            <div></div>
        </ng-container>
    </ng-container>
    <ng-container class="app-modal-body">
        <ng-container *ngIf="file">
            <img [src]="url" *ngIf="!isPDF && !isXLS" style="max-width:1280px">
            <iframe [src]="url" *ngIf="isPDF || isXLS" frameborder="0"
                height="100%" width="100%">
            </iframe>
        </ng-container>
    </ng-container>
</app-modal>