import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { SignupService } from 'src/app/services/signup/signup.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import Utils from 'src/app/helpers/utils';
import { AgentsService } from 'src/app/services/agents/agents.service';

@Component({
  selector: 'app-dashboard-settings-agents',
  templateUrl: './dashboard-settings-agents.component.html',
  styleUrls: ['./dashboard-settings-agents.component.scss'],
})
export class DashboardSettingsAgentsComponent implements OnInit {
  agentsForm = this.formBuilder.group({
    name: [null, [Validators.required]],
  });

  addAgents = false;
  agents: any[] = [];
  agent: any = undefined;
  visible_agents: any[] = [];

  agentsOptions = [];

  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private toast: ToastService,
    private signupService: SignupService,
    private maskPipe: MaskPipe,
    public agentsService: AgentsService
  ) {}

  ngOnInit(): void {
    this.getRoles();
    this.setData();
  }

  redirect(url: string) {
    this.router.navigate([url]);
  }

  async getRoles() {
    this.loading = true;

    try {
      const res = await this.api.get({
        route: 'api/registration/agent/',
        token: true,
      });

      this.agents = res.map((item: any) => {
        return {
          ...item,
          name: item.name,
          id: item.id,
        };
      });

      this.visible_agents = [...this.agents].splice(0, 10);

      this.agentsOptions = res.map((_agent: any) => {
        return {
          label: `${_agent.name}`,
          value: _agent.id,
        };
      });

      this.agentsService.data = this.agentsOptions;

      this.agentsService.onChange.emit();
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  async addAgent() {
    try {
      const values = this.agentsForm.value;

      const payload = {
        name: values.name,
      };

      const alreadyHaveAgent = this.agents.find(
        (_agent) => _agent.name === payload.name
      );

      if (alreadyHaveAgent) {
        this.toast.show('error', 'Erro', 'Já existe um agente com esse nome.');
        return;
      }

      const res = this.agent
        ? await this.api.put({
            route: `api/registration/agent/${this.agent.id}/`,
            token: true,
            body: payload,
          })
        : await this.api.post({
            route: 'api/registration/agent/',
            token: true,
            body: payload,
          });

      this.toast.show('info', 'Sucesso', 'Agente cadastrado com sucesso.');

      this.closeModal();

      this.getRoles();

      this.agent = undefined;

      this.agentsForm.reset();
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao cadastrar o agente.'
      );
    }
  }

  addAgentsToggle(status: boolean) {
    this.addAgents = status;
  }

  closeModal() {
    this.agent = undefined;
    this.addAgents = false;
  }

  async removeAgent(id: number) {
    const { isConfirmed } = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, apague!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    });

    if (isConfirmed) {
      const options: { [Key in any]: any } = {};

      this.agents
        .filter((_agent) => _agent.id !== id)
        .forEach((_agent) => {
          options[_agent.id] = _agent.name;
        });

      await Swal.fire({
        title: 'Atenção',
        text: 'Antes de continuar, selecione um papel para migrar os usuários',
        icon: 'warning',
        input: 'select',
        inputOptions: options,
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Prosseguir!',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',

        showLoaderOnConfirm: true,

        preConfirm: (role) => {
          return this.api
            .delete({
              route: 'api/dashboard/roles',
              token: true,
              params: {
                id,
                role,
              },
            })
            .then((res) => {
              this.agents = this.agents.filter((agent) => agent.id !== id);

              Swal.fire({
                title: 'Apagado!',
                text: 'Agente apagado com sucesso',
                icon: 'success',
                confirmButtonColor: '#3085d6',
              });
            })
            .catch((error) => {
              Swal.fire({
                title: 'Erro!',
                text: 'Erro ao apagar agente',
                icon: 'error',
                confirmButtonColor: '#3085d6',
              });
            });
        },
      });
    }
  }

  setAgent(agent: any) {
    this.agentsForm.patchValue({
      ...agent,
    });
    this.agent = agent;
    this.addAgentsToggle(true);
  }

  isAdminField(slug: string) {
    if (slug) {
      return slug.includes('ADM');
    }
    return false;
  }

  setData() {
    this.signupService.fillFormEvent.subscribe(() => {
      this.agentsForm.patchValue({
        name: fakerbr.name.findName(),
        slug: Utils.convertToSlug(fakerbr.name.findName()),
        description: fakerbr.random.words(3),
        applicable: 'admin',
      });
    });
  }
}
