import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-witnesses',
  templateUrl: './dashboard-witnesses.component.html',
  styleUrls: ['./dashboard-witnesses.component.scss'],
})
export class DashboardWitnessesComponent implements OnInit {
  witnessForm = this.formBuilder.group({
    name: [null, [Validators.required]],
    document: [null, [Validators.required, Utils.validateCpfCnpj]],
    email: [null, [Validators.required, Validators.email]],
  });

  loading = true;
  witness: Witness = null;
  witnesses: Witness[] = [];
  addWitnesses = false;

  filteredData = this.witnesses;

  constructor(
    private api: ApiService,
    private router: Router,
    private toast: ToastService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    try {
      const res = await this.api.get({
        route: 'api/registration/witnesses/',
        token: true,
      });

      this.witnesses = res.map((witness) => {
        return {
          id: witness.id,
          name: witness.name,
          document: witness.document_number,
          email: witness.email,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  setFilteredData(data: any[]) {
    this.filteredData = data;

    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }

  addWitnessesToggle(status: boolean) {
    this.addWitnesses = status;

    if (!status) {
      this.witnessForm.reset();
    }
  }

  async removeWitnesses(id: number) {
    try {
      await this.api.delete({
        route: `api/registration/witnesses/${id}`,
        token: true,
      });

      this.addWitnessesToggle(false);
      this.getData();
      this.toast.show('info', 'Sucesso', 'Testemunha adicionada com sucesso!');
    } catch (error) {
      console.log(error);
    }
  }

  setWitness(witness: Witness) {
    this.witnessForm.patchValue({
      ...witness,
    });
    this.witness = witness;
    this.addWitnessesToggle(true);
  }

  async addNewWitnesss() {
    try {
      const payload = {
        name: this.witnessForm.value.name,
        document_number: this.witnessForm.value.document,
        email: this.witnessForm.value.email,
      };

      if (!this.witness) {
        const emails = this.witnesses.map((witness) => witness.email);
        const documents = this.witnesses.map((witness) => witness.document);

        if (emails.includes(payload.email)) {
          this.toast.show('error', 'Erro', 'Email já cadastrado!');
          return;
        }

        if (documents.includes(payload.document_number)) {
          this.toast.show('error', 'Erro', 'CPF já cadastrado!');
          return;
        }
      }

      const res = !this.witness
        ? await this.api.post({
            route: 'api/registration/witnesses/',
            body: payload,
            token: true,
          })
        : await this.api.put({
            route: `api/registration/witnesses/${this.witness.id}/`,
            body: payload,
            token: true,
          });

      this.addWitnessesToggle(false);
      this.getData();
      this.toast.show('info', 'Sucesso', 'Testemunha adicionada com sucesso!');
    } catch (error) {
      console.log(error);
    }
  }
}
