import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard-fund-approval-files',
  templateUrl: './dashboard-fund-approval-files.component.html',
  styleUrls: ['./dashboard-fund-approval-files.component.scss'],
})
export class DashboardFundApprovalFilesComponent implements OnInit {
  attachments = [];
  files = [];

  sending = false;

  constructor(
    private api: ApiService,
    private toast: ToastService,
    public fundService: FundService
  ) {}

  ngOnInit(): void {
    this.getAttachments();
  }

  async getFiles() {
    try {
      const { data } = await this.api.get<ApiResponse<Files[]>>({
        route: 'api/registration/files/',
        token: true,
        params: {
          fund: this.fundService.data.id,
          referent: 'fund',
        },
      });

      this.files = data;
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
  }

  async getAttachments() {
    try {
      const { data } = await this.api.get<ApiResponse<Files[]>>({
        route: 'api/approvals/files_type/',
        token: true,
        params: {
          type: 'fund',
        },
      });

      this.attachments = data;

      await this.getFiles();
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
  }

  setFiles(index: number, files: FilePicked[]) {
    const newAttachments = [...this.attachments];
    newAttachments[index].files = [...files];

    this.attachments = newAttachments;

    if (newAttachments[index].files.length > 0) {
      newAttachments[index].isFromInvalid = false;
      newAttachments[index].isToInvalid = false;
    }
  }

  async removeFile(id: number) {
    try {
      await Swal.fire({
        title: 'Tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Sim, apague!',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.api
            .delete<ApiResponse<Files>>({
              route: `api/registration/files/${id}/`,
              token: true,
            })
            .finally(() => {
              this.toast.show('info', 'Sucesso', 'Arquivo removido');

              this.getFiles();
            });
        },
      });
    } catch (error) {
      this.toast.show('error', 'Erro', error.error.message);
    }
  }

  openFile(file: string) {
    window.open(file, '_blank');
  }

  getFileName(file: string) {
    let fileName = '';

    const regex = /uploads\/\d{4}\/\d{2}\/\d{2}\/(.*)\?Expires/;

    if (file.match(regex)) fileName = file.match(regex)[1];

    return fileName;
  }

  getSendedFiles(id) {
    const files = this.files.filter(
      (file) => file.referent === 'fund' && file.type.id === id
    );

    return files;
  }

  getFileImagePreview(file: string) {
    if (file.includes('.pdf')) {
      return 'assets/images/pdf.png';
    } else if (file.includes('.doc') || file.includes('.docx')) {
      return 'assets/images/word.png';
    } else if (file.includes('.xls') || file.includes('.xlsx')) {
      return 'assets/images/xls.png';
    } else if (file.includes('.ppt') || file.includes('.pptx')) {
      return 'assets/images/powerpoint.png';
    } else {
      return file;
    }
  }

  getInvalid() {
    const invalid = this.attachments.some((_attachment) => {
      if (_attachment.required) {
        if (_attachment.files.length === 0) {
          return true;
        } else {
          if (_attachment.have_due_date) {
            if (!(!!_attachment.date_due && !!_attachment.end_date_due)) {
              return true;
            }
          }
        }
      }

      return false;
    });

    const validArr = [invalid];

    return validArr.some((item) => item);
  }

  async sendAttachment(payload) {
    try {
      await this.api.post<ApiResponse<Files>>({
        route: 'api/registration/files/',
        token: true,
        body: payload,
        noDefautHeaders: true,
      });
    } catch (error) {
      this.toast.show('error', 'Erro', error.error.message);
    }
  }

  async sendAttachments(_attachment: Attachment) {
    try {
      const fund = this.fundService.data;

      const file_payload = _attachment.files.map((_file) => {
        const formData = new FormData();

        formData.append('fund', String(fund.id));
        formData.append('type', String(_attachment.id));
        formData.append('file', _file.file);
        formData.append('referent', 'fund');

        if (_attachment.end_date_due)
          formData.append('end_date_due', _attachment.end_date_due);
        if (_attachment.date_due)
          formData.append('date_due', _attachment.date_due);

        return formData;
      });

      await Promise.all(
        file_payload.map((payload) => this.sendAttachment(payload))
      );
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        `Erro ao enviar arquivo ${_attachment.name}`
      );
    }
  }

  async sendData() {
    this.sending = true;
    try {
      const registerAttachmentsRequests = this.attachments
        .filter((_attachments) => _attachments.files.length > 0)
        .map((_attachments) => this.sendAttachments(_attachments));

      await Promise.all([...registerAttachmentsRequests]);

      this.toast.show('info', 'Sucesso', 'Arquivos enviados com sucesso!');

      this.attachments = [];

      await this.getAttachments();
    } catch (error) {
      console.error(error);
    }
    this.sending = false;
  }
}
