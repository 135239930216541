<!-- mudar isso depois -->
<button class="app-button" (click)="handleShowVisualizer(0)"
    *ngIf="files.length === 1" [class.full]="full">
    Visualizar anexo
</button>

<div class="button-container" *ngIf="files.length > 1" [class.full]="full">
    <button class="button-container-content" [class.full]="full"
        [class.active]="showOptions" type="button"
        (click)="toggleShowOptions()">
        <div *ngIf="full"></div>

        Visualizar anexos
        <i class="fas fa-sort-down"></i>
    </button>
    <div class="dropdown" *ngIf="showOptions">
        <div *ngFor="let file of files; let i = index">
            <button
                (click)="handleShowVisualizer(i)">{{file.type.name}}</button>
        </div>
    </div>
</div>