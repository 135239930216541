import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-user-list',
  templateUrl: './dashboard-user-list.component.html',
  styleUrls: ['./dashboard-user-list.component.scss'],
})
export class DashboardUserListComponent implements OnInit {
  role: string;

  users: UserApprovalResponse[] = [];
  filteredUsers: UserApprovalResponse[] = [];

  loading = true;

  roleLabel = '';

  constructor(
    private routeParams: ActivatedRoute,
    private api: ApiService,
    private toast: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.role = this.routeParams.snapshot.params.role?.slice(0, -1);
    this.setRoleLabel(this.role);
    this.getData();
  }

  setRoleLabel(role: string) {
    switch (role) {
      case 'assignor':
        this.roleLabel = 'cedente';
        break;
      case 'shareholder':
        this.roleLabel = 'cotista';
        break;
      case 'provider':
        this.roleLabel = 'prestador de serviço';
        break;

      default:
        this.roleLabel = 'cedente';
        break;
    }
  }

  setFilteredData(data: any[]) {
    this.filteredUsers = data;

    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  async getData() {
    try {
      const { data } = await this.api.get<ApiResponse<UserApprovalResponse[]>>({
        route: 'api/approvals/user_approval/',
        token: true,
      });

      const sortByName = data.sort((a, b) => {
        const nameA = this.getDisplayName(a);
        const nameB = this.getDisplayName(b);

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      this.users = sortByName;

      this.loading = false;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  getDisplayName(item: UserApprovalResponse) {
    if (item.register) {
      if (item.register.type && item.register.type.toUpperCase() === 'PF') {
        return item.register.person.full_name;
      }

      if (item.register.type && item.register.type.toUpperCase() === 'PJ') {
        return item.register.company.corporate_name;
      }
    }

    if (item.fund) {
      return item.fund.fantasy_name;
    }

    return '';
  }

  formatItemName(item: UserApprovalResponse) {
    if (item.fund) {
      return 'Fundo';
    }

    if (item.register) {
      const name = item.register.role.applicable.toUpperCase();

      switch (name) {
        case 'ASSIGNOR':
          return 'Cedente';
        case 'SHAREHOLDER':
          return 'Cotista';
        case 'PROVIDER':
          let providerLabel =
            item.register.agent.length > 0
              ? item.register.agent.map((_) => _.name).join(', ')
              : 'Prestador de serviço';
          return providerLabel;

        default:
          return 'Representante';
      }
    }

    return 'Não identificado';
  }

  getStatus(item: UserApprovalResponse) {
    const register = item.register ?? item.fund;

    if (register.approval[0].completed) {
      if (register.approval[0].is_approved) {
        return 'Aprovado internamente';
      } else {
        return `${register ? 'Cadastro' : 'Fundo'} rejeitado`;
      }
    } else {
      if (register.approval[0].current_department !== null) {
        return register.approval[0].current_department.type === 'department'
          ? register.approval[0].current_department.department.name
          : register.approval[0].current_department.agent.name;
      } else if (register.approval[0].step === 0) {
        return 'Edição';
      } else {
        return 'Departamento não definido';
      }
    }
  }

  getStatusInfo(item: UserApprovalResponse) {
    const register = item.register ?? item.fund;

    if (register.approval[0].completed) {
      if (register.approval[0].is_approved) {
        return 'Integração ao fundo';
      } else {
        if (register.approval[0].current_department !== null) {
          return register.approval[0].current_department.type === 'department'
            ? register.approval[0].current_department.department.name
            : register.approval[0].current_department.agent.name;
        } else if (register.approval[0].step === 0) {
          return 'Edição';
        } else {
          return 'Departamento não definido';
        }
      }
    } else if (register.approval[0].step === 0) {
      return 'Aguardando representantes';
    } else {
      return `Aguardando aprovação (${register.approval[0].step}/${register.approval[0].queue.departments.length})`;
    }
  }

  getInitials(item: UserApprovalResponse) {
    let name = '';

    if (item.register) {
      if (item.register.type && item.register.type.toUpperCase() === 'PF') {
        name = item.register.person.full_name;
      }

      if (item.register.type && item.register.type.toUpperCase() === 'PJ') {
        name = item.register.company.corporate_name;
      }
    }

    if (item.fund) {
      name = item.fund.fantasy_name;
    }

    return name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  getName(item: any) {
    if ((item.user.type === 'PJ' || item.user.type === 'pj') && item.general) {
      return item.general.corporate_name;
    }

    return item.user.first_name + ' ' + item.user.last_name;
  }

  renderLink(item: UserApprovalResponse) {
    if (item.register) {
      return [
        `/app/details/${item.register.role.applicable}/`,
        item.register.uuid,
      ];
    }

    if (item.fund) {
      return [`/app/products/fund/approval/`, item.fund.id];
    }

    return '';
  }

  redirectTo(route: string) {
    this.router.navigate([route]);
  }
}
