import { ApiService } from 'src/app/services/api/api.service';
import { Component, OnInit } from '@angular/core';
import { SignupService } from 'src/app/services/signup/signup.service';
import Utils from 'src/app/helpers/utils';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';

type SendedValues = {
  id?: number;
  referent: string;
  slugs: string[];
};
@Component({
  selector: 'app-signup-unified-company-attachments',
  templateUrl: './signup-unified-company-attachments.component.html',
  styleUrls: ['./signup-unified-company-attachments.component.scss'],
})
export class SignupUnifiedCompanyAttachmentsComponent implements OnInit {
  _attachments: Attachment[] = [];

  _representativeAttachments: RepresentativeAttachment[] = [];

  get attachments() {
    return this._attachments;
  }

  set attachments(value) {
    this._attachments = value;
  }

  get representativeAttachments() {
    return this._representativeAttachments;
  }

  set representativeAttachments(value) {
    this._representativeAttachments = value;
  }

  today = Utils.todayString();

  selectedFramework = null;
  loading = true;

  label = 'documento da empresa';

  userType: string;

  representatives: RepresentativeResponse[] = [];
  files: Files[] = [];

  sending = false;

  constructor(
    public signupService: SignupService,
    private authService: AuthService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    const basicInfo = this.signupService.getCompanyFormData(
      'complementaryGeneral'
    );

    if (basicInfo) {
      this.selectedFramework = Number(basicInfo.framework);
    }

    this.signupService.complementaryDataFetchedEvent.subscribe((data) => {
      if (data && data.general) this.selectedFramework = data.general.framework;
    });

    if (
      this.signupService.signupChoices.frameworks &&
      this.signupService.signupChoices.frameworks.length > 0
    ) {
      this.getData();
    }

    this.signupService.choicesFetchedEvent.subscribe(() => {
      this.getData();
    });

    this.userType = this.authService.getUser().type;
  }

  async getData() {
    this.loading = true;
    await Promise.all([this.getFiles(), this.getRepresentatives()]);
    this.setAttachments();
  }

  // Remove reference from object
  removeReference(object: any) {
    const string = JSON.stringify(object);
    const newObj = JSON.parse(string);

    return newObj;
  }

  async setAttachments() {
    if (this.signupService.signupData?.requested_files) {
      let slugsToFilter: string[] = [
        'procuracao-representante',
        'comprovacao-aplicacoes-10m',
        'comprovacao-aplicacoes-1m',
        'plano-recuperacao',
      ];

      const slugsToUnfilter: string[] = [];

      if (this.signupService.complementaryData) {
        if (this.representatives.length > 0) {
          slugsToUnfilter.push('procuracao-representante');
        }

        if (
          this.signupService.complementaryData?.investor_status
            ?.has_investments_bigger_then_one_million
        ) {
          slugsToUnfilter.push('comprovacao-aplicacoes-1m');
        }

        if (
          this.signupService.complementaryData?.investor_status
            ?.has_investments_bigger_then_ten_million
        ) {
          slugsToUnfilter.push('comprovacao-aplicacoes-10m');
        }

        if (this.signupService.complementaryData?.general?.judicial_recovery) {
          slugsToUnfilter.push('plano-recuperacao');
        }
      }

      slugsToFilter = slugsToFilter.filter(
        (slug) => !slugsToUnfilter.includes(slug)
      );

      const userRole =
        this.authService.user.actived_register.register.role.slug;

      const newRequestedFiles = [
        ...this.signupService.signupData.requested_files,
      ]
        .filter((_attachment) => !slugsToFilter.includes(_attachment.slug))
        .filter((_attachment) => {
          if (
            _attachment.frameworks.length > 0 &&
            this.selectedFramework !== null
          ) {
            const framework = _attachment.frameworks.some(
              (i) => i.id === this.selectedFramework
            );

            return framework;
          }

          return true;
        })
        .filter((_attachment) => {
          const filteredRoles = _attachment.roles.map(
            (role) => role.slug === userRole
          );

          return filteredRoles.some((i) => i === true);
        });

      this.attachments = newRequestedFiles.map((file: Attachment) => ({
        id: file.id,
        name: file.name,
        description: file.description,
        slug: file.slug,
        files: [],
        required: file.required,
        date_due: '',
        end_date_due: '',
        have_due_date: file.have_due_date,
        isFromInvalid: false,
        isToInvalid: false,
        minDate: this.today,
        individual: file.individual,
      }));

      const newAttachments = this.attachments.filter((attachment) => {
        return attachment.individual;
      });

      const newRepresentativeAttachments = this.representatives.map(
        (representative) => {
          return {
            representative: {
              id: representative.representative_register.uuid,
              name: representative.full_name,
            },
            attachments: [...newAttachments],
          };
        }
      );

      this.representativeAttachments = this.removeReference(
        newRepresentativeAttachments
      );

      this.attachments = this.attachments.filter((attachment) => {
        return !attachment.individual;
      });

      await this.clearInvalidFiles();

      this.signupService.setCompanyFormStatus(
        'attachments',
        this.validateSendedFiles()
      );
    }

    this.loading = false;
  }

  async clearInvalidFiles() {
    const uuids = this.representatives.map(
      (representative) => representative.representative_register.uuid
    );

    const invalidFiles = this.files.filter(
      (file) =>
        file.referent === 'representative' && !uuids.includes(file.referent_id)
    );

    if (invalidFiles.length > 0) {
      const promises = invalidFiles.map((file) =>
        this.api.delete<ApiResponse<Files>>({
          route: `api/registration/files/${file.id}/`,
          token: true,
        })
      );

      await Promise.all(promises);
    }
  }

  setRepresentativeFiles(
    index: number,
    fileIndex: number,
    files: FilePicked[]
  ) {
    const newRepresentativeAttachments = [...this.representativeAttachments];

    newRepresentativeAttachments[index].attachments[fileIndex].files = [
      ...files,
    ];

    newRepresentativeAttachments[index].attachments[fileIndex].user =
      newRepresentativeAttachments[index].representative.id;

    this.representativeAttachments = newRepresentativeAttachments;

    if (
      newRepresentativeAttachments[index].attachments[fileIndex].files.length >
      0
    ) {
      if (
        newRepresentativeAttachments[index].attachments[fileIndex].have_due_date
      ) {
        this.dateValidator(fileIndex, index);
      }
    } else {
      if (
        !newRepresentativeAttachments[index].attachments[fileIndex]
          .have_due_date
      ) {
        newRepresentativeAttachments[index].attachments[
          fileIndex
        ].isFromInvalid = false;
        newRepresentativeAttachments[index].attachments[fileIndex].isToInvalid =
          false;
      }
    }
  }

  setFiles(index: number, files: FilePicked[]) {
    const newAttachments = [...this.attachments];
    newAttachments[index].files = [...files];

    this.attachments = newAttachments;

    if (newAttachments[index].files.length > 0) {
      if (newAttachments[index].have_due_date) {
        this.dateValidator(index);
      }
    } else {
      if (!newAttachments[index].have_due_date) {
        newAttachments[index].isFromInvalid = false;
        newAttachments[index].isToInvalid = false;
      }
    }
  }

  handleDateChange(
    value: string,
    input: string,
    index: number,
    representativeIndex?: number
  ) {
    const newAttachments =
      representativeIndex !== undefined
        ? [...this.representativeAttachments[representativeIndex].attachments]
        : [...this.attachments];

    if (this.validateDateValid(value)) {
      if (newAttachments[index]) {
        const formatedDate = value.split('/').reverse().join('-');
        if (input === 'from') {
          newAttachments[index].date_due = formatedDate;
          newAttachments[index].minDate =
            formatedDate > this.today ? formatedDate : this.today;
        } else {
          newAttachments[index].end_date_due = formatedDate;
        }
      }

      if (representativeIndex !== undefined) {
        this.representativeAttachments[representativeIndex].attachments =
          newAttachments;
        this.dateValidator(index, representativeIndex);
      } else {
        this.attachments = newAttachments;
        this.dateValidator(index);
      }
    } else {
      if (input === 'from') {
        newAttachments[index].isFromInvalid = true;
      } else {
        newAttachments[index].isToInvalid = true;
      }
    }
  }

  dateValidator(index: number, representativeIndex?: number) {
    const attachment =
      representativeIndex !== undefined
        ? this.representativeAttachments[representativeIndex].attachments[index]
        : this.attachments[index];

    if (attachment) {
      const fromDate = attachment.date_due;
      const toDate = attachment.end_date_due;

      const fromYear = fromDate.split('-')[0];
      const toYear = toDate.split('-')[0];

      const validFromYear =
        fromYear.length !== 4 ||
        new Date(fromDate) > new Date() ||
        Number(fromYear) < new Date().getFullYear() - 100;

      const validToDate =
        new Date(toDate) > new Date() &&
        Number(toYear) < new Date().getFullYear() + 100;

      if (!attachment.required && !fromDate) {
        attachment.isFromInvalid = false;
        attachment.isToInvalid = false;

        return;
      }

      if (fromDate && !toDate) {
        attachment.isFromInvalid = validFromYear;
        attachment.isToInvalid = true;

        return;
      }

      if (toDate && !fromDate) {
        attachment.isFromInvalid = true;
        attachment.isToInvalid = !validToDate;

        return;
      }

      if (fromDate && toDate) {
        const fromDateMoment = new Date(fromDate);
        const toDateMoment = new Date(toDate);
        const isInvalid = fromDateMoment > toDateMoment;

        attachment.isFromInvalid = validFromYear;
        attachment.isToInvalid = !(validToDate && !isInvalid);

        return;
      }
    }

    attachment.isFromInvalid = true;
    attachment.isToInvalid = true;
  }

  validateDateValid(value: string) {
    if (value && value.length == 10) {
      const date = value.split('/');

      if (date.length != 3) {
        return false;
      }

      const day = date[0];
      const month = date[1];
      const year = date[2];

      if (day && month && year) {
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        const _day = String(date.getDate()).padStart(2, '0');

        if (
          String(date.getFullYear()) != year ||
          date.getMonth() != Number(month) - 1 ||
          _day != day
        ) {
          return false;
        }

        if (date.toString() == 'Invalid Date') {
          return false;
        }

        return true;
      }
    }

    return false;
  }

  renderLabel(user: any, isRepresentative: boolean) {
    if (isRepresentative) {
      return `Documentos de ${user.representative.name}`;
    } else {
      return `Documentos ${
        this.userType === 'PJ' ? 'da empresa' : 'do titular'
      }`;
    }
  }

  async getRepresentatives() {
    try {
      const { data } = await this.api.get<
        ApiResponse<RepresentativeResponse[]>
      >({
        route: 'api/registration/representative/',
        token: true,
      });

      this.representatives = data;
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
  }

  async getFiles() {
    try {
      const { data } = await this.api.get<ApiResponse<Files[]>>({
        route: 'api/registration/files/',
        token: true,
      });

      this.files = data;
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
  }

  //   getInvalid() {
  //     const invalid = this.attachments.some((_attachment) => {
  //       if (_attachment.required) {
  //         if (_attachment.files.length === 0) {
  //           return true;
  //         } else {
  //           if (_attachment.have_due_date) {
  //             if (!(!!_attachment.date_due && !!_attachment.end_date_due)) {
  //               return true;
  //             }
  //           }
  //         }
  //       }

  //       return false;
  //     });

  //     const representativesInvalid = this.representativeAttachments.map(
  //       (_representative) => {
  //         const isValid = _representative.attachments.some((_attachment) => {
  //           if (_attachment.required) {
  //             if (_attachment.files.length === 0) {
  //               return true;
  //             } else {
  //               if (_attachment.have_due_date) {
  //                 if (!(!!_attachment.date_due && !!_attachment.end_date_due)) {
  //                   return true;
  //                 }
  //               }
  //             }
  //           }

  //           return false;
  //         });

  //         return isValid;
  //       }
  //     );

  //     const validArr = [...representativesInvalid, invalid];

  //     return validArr.some((item) => item);
  //   }

  getSendedFiles(id) {
    const files = this.files.filter(
      (file) => file.referent === 'register' && file.type.id === id
    );

    return files;
  }

  getRepresentativeSendedFiles(id, representativeId) {
    const files = this.files.filter(
      (file) =>
        file.referent === 'representative' &&
        file.type.id === id &&
        file.referent_id === representativeId
    );

    return files;
  }

  openFile(file: string) {
    window.open(file, '_blank');
  }

  getFileImagePreview(file: string) {
    if (file.includes('.pdf')) {
      return 'assets/images/pdf.png';
    } else if (file.includes('.doc') || file.includes('.docx')) {
      return 'assets/images/word.png';
    } else if (file.includes('.xls') || file.includes('.xlsx')) {
      return 'assets/images/xls.png';
    } else if (file.includes('.ppt') || file.includes('.pptx')) {
      return 'assets/images/powerpoint.png';
    } else {
      return file;
    }
  }

  getFileName(file: string) {
    let fileName = '';

    const regex = /uploads\/\d{4}\/\d{2}\/\d{2}\/(.*)\?Expires/;

    if (file.match(regex)) fileName = file.match(regex)[1];

    return fileName;
  }

  async removeFile(id: number) {
    try {
      await Swal.fire({
        title: 'Tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Sim, apague!',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.api
            .delete<ApiResponse<Files>>({
              route: `api/registration/files/${id}/`,
              token: true,
            })
            .finally(() => {
              this.toast.show('info', 'Sucesso', 'Arquivo removido');

              this.getFiles();
            });
        },
      });
    } catch (error) {
      this.toast.show('error', 'Erro', error.error.message);
    }
  }

  async sendAttachment(payload) {
    try {
      await this.api.post<ApiResponse<Files>>({
        route: 'api/registration/files/',
        token: true,
        body: payload,
        noDefautHeaders: true,
      });
    } catch (error) {
      this.toast.show('error', 'Erro', error.error.message);
    }
  }

  async sendAttachments(_attachment: Attachment) {
    try {
      const register = this.authService.user.actived_register.register;

      const file_payload = _attachment.files.map((_file) => {
        const formData = new FormData();

        formData.append('register', register.uuid);
        formData.append('type', String(_attachment.id));
        formData.append('file', _file.file);
        formData.append('end_date_due', _attachment.end_date_due);
        formData.append('date_due', _attachment.date_due);

        return formData;
      });

      await Promise.all(
        file_payload.map((payload) => this.sendAttachment(payload))
      );
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        `Erro ao enviar arquivo ${_attachment.name}`
      );
    }
  }

  async sendRepresentativeAttachments(_attachments: RepresentativeAttachment) {
    try {
      const register = this.authService.user.actived_register.register;

      const requests = _attachments.attachments
        .filter((_attachment) => _attachment.files.length > 0)
        .map(async (_attachment) => {
          const file_payload = _attachment.files.map((_file) => {
            const formData = new FormData();

            formData.append('register', register.uuid);
            formData.append('type', String(_attachment.id));
            formData.append('file', _file.file);
            formData.append('end_date_due', _attachment.end_date_due);
            formData.append('date_due', _attachment.date_due);

            formData.append(
              'referent_id',
              String(_attachments.representative.id)
            );
            formData.append('referent', 'representative');

            return formData;
          });

          await Promise.all(
            file_payload.map((payload) => this.sendAttachment(payload))
          );
        });

      await Promise.all(requests);
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        `Erro ao enviar arquivos de representante ${_attachments.representative.name}`
      );
    }
  }

  async sendData() {
    this.sending = true;
    try {
      const registerAttachmentsRequests = this.attachments
        .filter((_attachments) => _attachments.files.length > 0)
        .map((_attachments) => this.sendAttachments(_attachments));

      const representativesAttachmentsRequests =
        this.representativeAttachments.map((_representative) =>
          this.sendRepresentativeAttachments(_representative)
        );

      await Promise.all([
        ...registerAttachmentsRequests,
        ...representativesAttachmentsRequests,
      ]);

      this.toast.show('info', 'Sucesso', 'Arquivos enviados com sucesso!');

      this.attachments = [];
      this.representativeAttachments = [];

      this.getData();
    } catch (error) {
      console.error(error);
    }
    this.sending = false;
  }

  validateSendedFiles() {
    let filesAux: SendedValues[] = [{ referent: 'register', slugs: [] }];

    let requiredSlugs = this.attachments
      .filter((attachment) => attachment.required)
      .map((attachment) => attachment.slug);

    let representativesRequiredSlugs = [];

    if (this.representativeAttachments.length > 0) {
      representativesRequiredSlugs =
        this.representativeAttachments[0].attachments
          .filter((attachment) => attachment.required)
          .map((attachment) => attachment.slug);
    }

    if (this.representativeAttachments.length > 0) {
      this.representativeAttachments.forEach((representative) => {
        filesAux.push({
          referent: 'representative',
          slugs: [],
          id: representative.representative.id,
        });
      });
    }

    this.files.forEach((file) => {
      if (file.referent === 'representative') {
        const index = filesAux.findIndex((f) => f.id === file.referent_id);

        filesAux[index].slugs.push(file.type.slug);
      } else {
        filesAux[0].slugs.push(file.type.slug);
      }
    });

    const filesIsValid = filesAux.map((file) => {
      if (file.referent === 'register') {
        return requiredSlugs.every((slug) => file.slugs.includes(slug));
      } else {
        return representativesRequiredSlugs.every((slug) =>
          file.slugs.includes(slug)
        );
      }
    });

    return filesIsValid.every((isValid) => isValid);
  }
}
