<div class="row">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">

            <ng-container *ngIf="!representative">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-3 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="representative">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{formatCpf(representative.person.document.number)}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                cpf
                            </span>
                        </div>

                        <div class="col-4 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.full_name}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Nome Completo
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.birth_date
                                | date: 'dd/MM/yyyy'}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Data de nascimento
                            </span>
                        </div>
                    </div>

                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.marital_status_display}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Estado civil
                            </span>
                        </div>

                        <ng-container *ngIf="representative.person.spouse ">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{representative.person.spouse.document
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CPF DO CÔNJUGE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{representative.person.spouse.name
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NOME DO CÔNJUGE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{getChoicesName('marital_statutes',representative.person.spouse.marriage_bond)?.label
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    REGIME DE CASAMENTO
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </ng-container>
                    </div>

                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{
                                getNacionality(representative.person.naturalness)?.label
                                }}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Nacionalidade
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.naturalness_state.name}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Naturalidade
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.naturalness_city.name}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Cidade
                            </span>
                        </div>

                        <div class="col-4 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.occupation}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Ocupação Profissional
                            </span>
                        </div>

                    </div>

                    <app-spacer [bottom]="16"></app-spacer>


                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.secondary_document.type.name}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Tipo de documento
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.secondary_document.number}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Número do Documento
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.secondary_document.expedition_date
                                | date: 'dd/MM/yyyy'}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Data expedição
                            </span>
                        </div>


                        <div class="col-4 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.secondary_document.dispatching_agency}}/
                                {{representative.person.secondary_document.uf}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Órgão expedidor
                            </span>
                        </div>

                    </div>

                    <app-spacer [bottom]="16"></app-spacer>


                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.cellphone.number}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                Celular
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.person.email}}
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                E-mail
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dashboard-card-item-badge"
                                *ngIf="representative.pep">
                                Declarado PEP
                            </span>
                        </div>
                    </div>

                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-12 d-flex flex-column">
                            <span class="dash-details-card-item-title">
                                {{representative.address.public_place
                                ??
                                '-'}},
                                {{representative.address.number??
                                '-'}},
                                {{representative.address.complement??
                                '-'}}
                                <br>
                                {{representative.address.district??
                                '-'}},
                                {{representative.address.city??
                                '-'}} -
                                {{representative.address.uf??
                                '-'}}<br>
                                Brasil
                            </span>

                            <span class="dash-details-card-item-subtitle">
                                ENDEREÇO COMPLETO
                            </span>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </ng-container>
        </div>
    </div>
</div>