import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import UIDefaultTheme from 'src/themes';
import Utils from 'src/app/helpers/utils';
import { LoadingService } from 'src/app/services/Loading/loading.service';

@Component({
  selector: 'app-signup-unified-company',
  templateUrl: './signup-unified-company.component.html',
  styleUrls: ['./signup-unified-company.component.scss'],
})
export class SignupUnifiedCompanyComponent implements OnInit {
  steps: Step[] = [
    {
      icon: 'fas fa-file-alt',
      svg: 'assets/images/icon-inputdata-active.svg',
      title: 'Dados complementares',
      substeps: [
        {
          title: 'Geral',
          slug: 'complementaryGeneral',
        },
        {
          title: 'Endereço',
          slug: 'complementaryAddress',
        },
        {
          title: 'Dados bancários',
          slug: 'complementaryBank',
        },
        {
          title: 'Composição acionária',
          slug: 'complementaryComposition',
        },
        {
          title: 'Grupo econômico',
          slug: 'complementaryEconomic',
        },
        {
          title: 'Representantes',
          slug: 'complementaryRepresentatives',
        },
      ],
    },
    {
      icon: 'fas fa-file-alt',
      title: 'Resumo dos poderes',
      svg: 'assets/images/icon-inputdata-active.svg',
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/icon-attachments.svg',
      title: 'Anexos & comprovantes',
    },
  ];

  userCredentials: UserCredentials = {
    email: '',
    name: '',
  };

  _activeStep: number = 0;

  activeSubstep: ComplementaryCompanySteps = 'complementaryRepresentatives';

  redirected = false;
  hasMultipleAccounts = false;

  get activeStep() {
    return this._activeStep;
  }

  set activeStep(value) {
    this._activeStep = value;

    switch (value) {
      case 0:
        this.currentTab = 'Dados complementares';
        break;

      case 1:
        this.currentTab = 'Resumo dos poderes';
        break;

      case 2:
        this.currentTab = 'Anexos & comprovantes';
        break;

      default:
        this.currentTab = '';
        break;
    }
  }

  currentTab: string = '';

  loading = false;

  showSupportModal = false;

  name: string | null = null;
  role: string | null = null;
  label: string | null = null;

  agentOptions: SelectItem[] = [];

  isFund = false;

  accounts: RegisterUserResponse[] = [];

  public UIDefaultTheme = UIDefaultTheme;

  constructor(
    private router: Router,
    public signupService: SignupService,
    private apiService: ApiService,
    private toastService: ToastService,
    private authService: AuthService,
    private titleService: Title,
    public loadingService: LoadingService
  ) {
    if (this.authService.user.actived_register.register.role.slug) {
      this.titleService.setTitle(
        Utils.setTitle(
          this.authService.user.actived_register.register.role.slug
        )
      );
    }
  }

  ngOnInit(): void {
    this.getAccounts();

    this.userCredentials = {
      email: this.authService.user?.email,
      name:
        this.authService.user?.first_name +
        ' ' +
        this.authService.user?.last_name,
    };

    this.signupService.changeCompanyStepEvent.subscribe((step) => {
      const aux = `complementary${
        step.charAt(0).toUpperCase() + step.slice(1)
      }` as ComplementaryCompanySteps;

      this.activeSubstep = aux;
      this.loading = false;

      this.signupService.updateUserStep('register', step);
    });

    this.signupService.changeBasicInfoEvent.subscribe((value) => {
      this.label = this.getRoleLabel(this.role);

      this.name = value.toUpperCase();
    });

    this.signupService.fetchDataEvent.emit();

    let urlParts = this.router.url.split('/pj')[0].split('/');
    let range = urlParts.length;
    this.role = urlParts[range - 1];

    this.getData();

    this.isFund =
      this.authService.user.actived_register.register.role.slug.includes(
        'PJ-FI'
      );
  }

  async getData() {
    await this.getAgents();
    this.setInviteInfo();
    this.redirectUser();
  }

  getUserInitials(string) {
    return string
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  async getAgents() {
    try {
      const res = await this.apiService.get({
        route: 'api/registration/simplified-agent/',
      });

      this.agentOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
    }
  }

  async redirectToDash() {
    await this.apiService.patch({
      route: `user/me/`,
      token: true,
      body: {
        actived_register: this.accounts[0].id,
      },
    });

    const { data } = await this.apiService.get<ApiResponse<NewLoginResponse>>({
      route: 'user/me/',
      token: true,
    });

    this.authService.setUser(data);
    this.router.navigateByUrl('/app/dashboard');
  }

  async getAccounts() {
    try {
      const { data } = await this.apiService.get<
        ApiResponse<RegisterUserResponse[]>
      >({
        route: 'api/registration/register_user/',
        token: true,
      });

      this.accounts = data
        .sort((a, b) => {
          return (
            new Date(b.register.created_at).getTime() -
            new Date(a.register.created_at).getTime()
          );
        })
        .reverse();

      this.hasMultipleAccounts = data.length > 1;
    } catch (error) {
      console.error(error);
    }
  }

  async redirectUser() {
    const user = this.authService.getUser();
    const { data } = await this.apiService.get({
      route: 'api/registration/trace_step/me/',
      token: true,
    });

    this.signupService.setComplementaryCompanyStep(data.substep);

    this.setBasicInfo();

    if (data.step === 'register') {
      if (data.substep === 'powers') {
        this.activeStep = 1;
      } else if (data.substep === 'attachments') {
        this.activeStep = 2;
      } else {
        this.activeSubstep = `complementary${
          data.substep.charAt(0).toUpperCase() + data.substep.slice(1)
        }` as ComplementaryCompanySteps;
        this.signupService.setComplementaryCompanyStep(this.activeSubstep);
        this.activeStep = 0;
      }
    }

    if (data.step === 'review') {
      this.router.navigateByUrl('/app/status/pj?company=' + user.id);
    }

    if (data.step !== 'register' && data.step !== 'review') {
      this.router.navigateByUrl(`/app/dashboard`);
    }

    this.redirected = true;
  }

  async setBasicInfo() {
    try {
      const { data } = await this.apiService.get<ApiResponse<Company>>({
        route: 'api/registration/pj_general/me/',
        token: true,
      });

      if (data.corporate_name !== null && data.corporate_name !== '') {
        this.label = this.getRoleLabel(this.role);

        this.name = data.corporate_name.toUpperCase();
      }
    } catch (error) {
      console.log(error);
    }
  }

  getRoleLabel(role: string) {
    switch (role) {
      case 'assignor':
        return 'Cedente pessoa jurídica';

      case 'provider':
        const user = this.authService.getUser();

        let label = '';

        if (user.actived_register.register.invite) {
          label = user.actived_register.register.invite.data.agent
            .map((item) => {
              return this.agentOptions.find((agent) => agent.value === item)
                .label;
            })
            .join(', ');
        } else {
          label = user.actived_register.register.agent
            .map((item) => item.name)
            .join(', ');
        }

        return label;

      default:
        return 'Avalista pessoa jurídica';
    }
  }

  async sendApproval() {
    try {
      await this.apiService.post({
        route: 'api/approvals/user_approval/',
        token: true,
      });

      this.toastService.show(
        'info',
        'Sucesso',
        'Solicitação de aprovação enviada com sucesso'
      );

      return true;
    } catch (error) {
      this.toastService.show(
        'error',
        'Erro',
        'Falha ao enviar solicitação de aprovação ao sistema'
      );
      return false;
    }
  }

  canProceed() {
    switch (this.activeStep) {
      case 0:
        const step = this.signupService.getComplementaryCompanyStep();
        return this.signupService.getCompanyFormStatus(step);

      case 1:
        const powers = this.signupService.getCompanyFormStatus('powers');
        return powers;

      case 2:
        const attachment =
          this.signupService.getCompanyFormStatus('attachments');
        return attachment;

      case 3:
        return true;
      default:
        return false;
    }
  }

  getButtonIcon(stepAction: string) {
    if (stepAction === 'next') {
      return this.canProceed()
        ? 'assets/images/icon_button_arrow.svg'
        : 'assets/images/icon_button_block.svg';
    } else {
      return this.activeStep === 0
        ? 'assets/images/icon_button_block.svg'
        : 'assets/images/icon_button_arrow.svg';
    }
  }

  runBack() {
    switch (this.activeStep) {
      case 2:
        this.activeStep--;

        this.signupService.updateUserStep('register', 'powers');
        break;

      case 1:
        this.activeStep--;

        this.signupService.changeCompanyStepEvent.emit('representatives');

        this.signupService.setComplementaryCompanyStep(
          'complementaryRepresentatives'
        );
        break;

      case 0:
        switch (this.activeSubstep) {
          case 'complementaryAddress':
            this.signupService.changeCompanyStepEvent.emit('general');

            this.signupService.setComplementaryCompanyStep(
              'complementaryGeneral'
            );
            break;

          case 'complementaryBank':
            this.signupService.changeCompanyStepEvent.emit('address');

            this.signupService.setComplementaryCompanyStep(
              'complementaryAddress'
            );
            break;

          case 'complementaryComposition':
            this.signupService.changeCompanyStepEvent.emit('bank');

            this.signupService.setComplementaryCompanyStep('complementaryBank');
            break;

          case 'complementaryEconomic':
            this.signupService.changeCompanyStepEvent.emit('composition');

            this.signupService.setComplementaryCompanyStep(
              'complementaryComposition'
            );
            break;

          case 'complementaryRepresentatives':
            this.signupService.changeCompanyStepEvent.emit('economic');

            this.signupService.setComplementaryCompanyStep(
              'complementaryEconomic'
            );
            break;

          default:
            break;
        }

        break;
      default:
        break;
    }
  }

  async proceed() {
    this.loading = true;
    if (this.canProceed()) {
      switch (this.activeStep) {
        case 0:
          const step = this.signupService.getComplementaryCompanyStep();

          if (step !== 'complementaryRepresentatives') {
            this.signupService.sendComplementaryCompanyData.emit(step);
            return;
          }

          this.signupService.updateUserStep('register', 'powers');
          this.activeStep++;

          break;

        case 1:
          this.signupService.updateUserStep('register', 'attachments');
          this.activeStep++;
          break;

        case 2:
          this.activeStep++;

          const approvalDone = await this.sendApproval();
          if (approvalDone) {
            const { data } = await this.apiService.get<ApiResponse<Company>>({
              route: 'api/registration/pj_general/me/',
              token: true,
            });

            this.router.navigateByUrl(`/app/status/pj?company=${data.id}`);
          }

          break;

        case 3:
          this.router.navigate([`/app/dashboard`]);
          break;
      }
    }
    this.loading = false;
  }

  setInviteInfo() {
    const user = this.authService.getUser();
    const invite = user.actived_register.register.invite;

    if (!invite) return;

    this.label = this.getRoleLabel(this.role);

    this.name = invite.data.name ? invite.data.name.toUpperCase() : '';

    this.signupService.basicInfo = {
      name: invite.data.name,
      email: invite.data.email,
      document: invite.data.document,
    };
  }

  getDisabled() {
    return !this.canProceed() || this.loading;
  }

  toggleSupportModal() {
    this.showSupportModal = !this.showSupportModal;
  }
}
