<div class="row">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <app-empty-content [isVisible]="!data"
                title="Nenhum dado profissional encontrado"
                description="Não foi encontrado nenhum dado profissional nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">

            </app-empty-content>

            <ng-container *ngIf="data">
                <div class="dash-details-card-header">
                    <span>Dados da entidade</span>
                </div>

                <app-spacer [bottom]="15"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.professional.cnpj ?
                                    formatCnpj(approval.register.professional.cnpj)
                                    : '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-8">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.professional.corporate_name
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    RAZÃO SOCIAL
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.professional.occupation
                                    ?
                                    approval.register.professional.occupation :
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    OCUPAÇÃO PROFISSIONAL
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.professional.corporate_email
                                    ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    E-MAIL CORPORATIVO
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.professional.phone.number
                                    ?
                                    formatPhone(approval.register.professional.phone)
                                    :'-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.professional.branch ??
                                    '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    RAMAL
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <ng-container
                            *ngIf="approval.register.professional.address">
                            <div class="col-12 col-md-4">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.register.professional.address?.cep
                                        ?? '-'}}
                                    </span>

                                    <span
                                        class="dash-details-card-item-subtitle">
                                        CEP
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-8">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.register.professional.address?.public_place
                                        ?? '-'}},
                                        {{approval.register.professional.address?.number??
                                        '-'}},
                                        {{approval.register.professional.address?.complement??
                                        '-'}} -
                                        {{approval.register.professional.address?.district??
                                        '-'}},
                                        {{approval.register.professional.address?.city??
                                        '-'}} -
                                        {{approval.register.professional.address?.uf??
                                        '-'}}
                                        Brasil
                                    </span>

                                    <span
                                        class="dash-details-card-item-subtitle">
                                        ENDEREÇO COMPLETO
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>