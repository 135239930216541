import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import Utils from 'src/app/helpers/utils';
import Swal from 'sweetalert2';
import { MaskPipe } from 'ngx-mask';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-contributors-collaborators',
  templateUrl: './dashboard-contributors-collaborators.component.html',
  styleUrls: ['./dashboard-contributors-collaborators.component.scss'],
})
export class DashboardContributorsCollaboratorsComponent implements OnInit {
  contributorsForm = this.formBuilder.group({
    document: [null, [Validators.required]],
    name: [null, []],
    email: [null, []],
    department: [null, [Validators.required]],
    is_approver: [false, []],
  });

  contributorsLoading = false;
  addContributors = false;

  contributors: any[] = [];

  searchingRegister = false;
  contributor: any = undefined;

  departmentOptions: SelectItem[] = [];

  fetchedRegister = false;
  foundedRegister = false;
  firstOpen = true;

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private formBuilder: FormBuilder,
    private maskPipe: MaskPipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getContributors();
    this.getDepartments();

    this.contributorsForm.controls.document.valueChanges
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((values: any) => {
        if (this.contributor && this.firstOpen) {
          this.firstOpen = false;
          this.fetchedRegister = true;
          return;
        } else {
          const formatted = Utils.onlyNumbers(values);

          if (
            formatted.length === 11 &&
            this.contributor &&
            formatted === this.contributor.username
          ) {
            this.fetchedRegister = true;
            this.foundedRegister = false;
            this.setValues();
            return;
          }

          this.handleDocumentBlur();
        }
      });
  }

  async getDepartments() {
    try {
      const res = await this.api.get({
        route: 'api/dashboard/departments',
        token: true,
      });

      const options = res.map((_department) => ({
        label: _department.name,
        value: _department.id,
      }));

      this.departmentOptions = options;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao recuperar departamentos');
    }
  }

  async fetchRegister() {
    this.fetchedRegister = false;
    this.searchingRegister = true;
    try {
      const { document } = this.contributorsForm.value;

      const documentNumber = Utils.onlyNumbers(document);

      const { exists } = await this.api.post<ValidateFieldResponse>({
        route: 'validate-username-exists/',

        body: { username: documentNumber },
      });

      this.foundedRegister = exists;

      if (exists) {
        this.contributorsForm.controls.name.setValidators([]);
        this.contributorsForm.controls.email.setValidators([]);

        this.toast.show(
          'info',
          'Atenção',
          'Cadastro já existente, preencha os dados abaixo para atualizar'
        );
      } else {
        if (!this.contributor) {
          this.resetFields();
        }

        this.contributorsForm.controls.name.setValidators([
          Validators.required,
        ]);
        this.contributorsForm.controls.email.setValidators([
          Validators.required,
          Validators.email,
        ]);
      }

      this.contributorsForm.controls.name.updateValueAndValidity();
      this.contributorsForm.controls.email.updateValueAndValidity();

      this.fetchedRegister = true;
    } catch (error) {
      this.fetchedRegister = true;
    }

    this.searchingRegister = false;
  }

  async removeContributors(id: number) {
    const { isConfirmed } = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, apague!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    });

    if (isConfirmed) {
      try {
        this.api.delete({
          route: `api/dashboard/collaborator/${id}/`,
          token: true,
        });

        this.contributors = this.contributors.filter(
          (contributor) => contributor.id !== id
        );

        Swal.fire({
          title: 'Apagado!',
          text: 'Departamento apagado com sucesso',
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      } catch (error) {
        Swal.fire({
          title: 'Erro!',
          text: 'Erro ao apagar departamento',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        });
      }
    }
  }

  addContributorsToggle(status: boolean) {
    this.addContributors = status;
  }

  async addCollaborator() {
    try {
      const values = this.contributorsForm.value;

      let contribuitorId = this.contributor ? this.contributor.id : null;

      let alreadyHaveEmail = null;

      let payload: any = {
        username: Utils.onlyNumbers(values.document),
        first_name: 'Dataengine',
        last_name: 'Collaborator',
        email: 'dataengine@prosperita.io',
        department: values.department,
        is_approver: values.is_approver ?? false,
      };

      const alreadyHaveDocument = this.contributors.find(
        (item) => item.username === payload.username
      );

      if (!this.foundedRegister) {
        const [first_name, ...last_name] = values.name.split(' ');

        payload = {
          ...payload,
          first_name: first_name,
          last_name: last_name.join(' '),
          email: values.email,
        };

        alreadyHaveEmail = this.contributors.find(
          (item) => item.email === payload.email
        );

        if (alreadyHaveEmail && !this.contributor) {
          this.toast.show(
            'error',
            'Erro!',
            `Colaborador com e-mail
             já cadastrado`
          );
          return;
        }
      }

      if (this.foundedRegister) {
        if (alreadyHaveDocument) {
          if (payload.username === this.contributor?.username) {
            payload = {
              ...payload,
              first_name: values.name,
              last_name: '',
              email: values.email,
            };
          } else {
            contribuitorId = alreadyHaveDocument.id;

            const selectedCollaborator = this.contributors.find(
              (item) => item.username === payload.username
            );

            payload = {
              username: Utils.onlyNumbers(values.document),
              first_name: selectedCollaborator.first_name,
              last_name: selectedCollaborator.last_name,
              email: selectedCollaborator.email,
              department: values.department,
              is_approver: values.is_approver ?? false,
            };
          }
        }
      }

      const res =
        this.contributor || (this.foundedRegister && alreadyHaveDocument)
          ? await this.api.put({
              route: `api/dashboard/collaborator/${contribuitorId}/`,
              token: true,
              body: payload,
            })
          : await this.api.post({
              route: 'api/dashboard/collaborator/',
              token: true,
              body: payload,
            });

      if (contribuitorId) {
        this.toast.show(
          'info',
          'Sucesso!',
          'Colaborador atualizado com sucesso'
        );
      } else {
        this.toast.show('info', 'Sucesso!', 'Colaborador adicionado');
      }

      this.closeModal();
      this.getContributors();
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro!', 'Erro ao adicionar colaborador');
    }
  }

  renderTitle() {
    return this.contributor
      ? `Colaborador(a) ${this.contributor.first_name}`
      : ' Novo colaborador';
  }

  renderSubtitle() {
    return this.contributor
      ? `Atualize os dados de ${
          this.contributor.first_name + ' ' + this.contributor.last_name
        }`
      : 'Adicione um novo colaborador ao sistema';
  }

  redirect(url: string) {
    this.router.navigate([url]);
  }

  closeModal() {
    this.contributorsForm.reset();
    this.addContributors = false;
    this.contributor = undefined;
    this.firstOpen = true;
    this.fetchedRegister = false;
  }

  setContributors(contributor: any) {
    this.contributor = contributor;
    this.fetchedRegister = true;
    this.foundedRegister = false;
    this.setValues();
    this.addContributorsToggle(true);
  }

  setValues() {
    const contributor = this.contributor;
    this.contributorsForm.patchValue({
      name: `${contributor.first_name} ${contributor.last_name}`,
      email: contributor.email,
      document: this.maskPipe.transform(contributor.username, '000.000.000-00'),

      department: contributor.department.id,
      is_approver: contributor.is_approver,
    });
  }

  async handleDocumentBlur() {
    const { document } = this.contributorsForm.value;
    const formatted = Utils.onlyNumbers(document);

    if (formatted.length === 11) {
      this.fetchRegister();
    }
  }

  resetFields() {
    this.contributorsForm.controls.name.setValue(null);
    this.contributorsForm.controls.email.setValue(null);
    this.contributorsForm.controls.department.setValue(null);
    this.contributorsForm.controls.is_approver.setValue(false);
  }

  async getContributors() {
    this.contributorsLoading = true;
    try {
      const { data } = await this.api.get({
        route: 'api/dashboard/collaborator/',
        token: true,
      });

      this.contributors = data;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao recuperar colaboradores');
    }
    this.contributorsLoading = false;
  }
}
