import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-dashboard-contributors',
  templateUrl: './dashboard-contributors.component.html',
  styleUrls: ['./dashboard-contributors.component.scss'],
})
export class DashboardContributorsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
