<ng-container *ngIf="!loading">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12">
                <p>Conta Corrente</p>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="offset-1 col-11">
                <div class="row">
                    <div class="col-4">
                        <app-select [items]="signupService.choices().banks"
                            label="Banco" [group]="form"
                            controlName="checking_account_bank">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-2">
                        <app-masked-input label="Nº da agência" [group]="form"
                            controlName="checking_account_agency" mask="0*"
                            [max]="8">
                        </app-masked-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-3">
                        <app-masked-input label="Nº da conta" [group]="form"
                            controlName="checking_account_number" mask="0*">
                        </app-masked-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-2">
                        <app-masked-input label="Dígito" [group]="form"
                            controlName="checking_account_digit" mask="A">
                        </app-masked-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>
            </div>

            <!-- <div class="col-12">
                <app-button [inverted]="true"
                    (onClick)="addBankAcccount('checking')"
                    [disabled]="!getFieldsValid('checking')">
                    Adicionar outra conta
                </app-button>
                <app-spacer [bottom]="32"></app-spacer>
            </div> -->

            <!-- <ng-container *ngIf="bankAccountList.checking.length > 0">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div
                            class="col-9 d-flex flex-row justify-content-between">
                            <table>
                                <thead>
                                    <th>Banco</th>
                                    <th>Agência</th>
                                    <th>Número</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of bankAccountList.checking; let i = index">
                                        <td>{{formatBankName(item.checking_account_bank)}}
                                        </td>
                                        <td>{{item.checking_account_agency}}
                                        </td>
                                        <td>{{item.checking_account_number}}
                                        </td>
                                        <td>
                                            <div class="dash-card-item-buttons">
                                                <button class="dash-item-button"
                                                    (click)="removeBankAccount('checking',i)">
                                                    <i class="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-9">
                            <app-spacer [bottom]="48"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container> -->

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="charge_account">
                    Conta de Cobrança
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="form.controls.charge_account.value">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div class="col-4">
                            <app-select [items]="signupService.choices().banks"
                                label="Banco" [group]="form"
                                controlName="charge_account_bank">
                            </app-select>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>

                        <div class="col-2">
                            <app-masked-input label="Nº da agência"
                                [group]="form"
                                controlName="charge_account_agency" mask="0*"
                                [max]="8">
                            </app-masked-input>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>

                        <div class="col-3">
                            <app-masked-input label="Nº da conta" [group]="form"
                                controlName="charge_account_number" mask="0*">
                            </app-masked-input>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>

                        <div class="col-2">
                            <app-masked-input label="Dígito" [group]="form"
                                controlName="charge_account_digit" mask="A">
                            </app-masked-input>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>

                        <div class="col-12 col-md-3">
                            <app-input type="number"
                                label="Número contrato cobrança" [group]="form"
                                controlName="charge_account_contract_number">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- <div class="col-12" *ngIf="form.controls.charge_account.value">
                <app-button [inverted]="true"
                    (onClick)="addBankAcccount('charge')"
                    [disabled]="!getFieldsValid('charge')">
                    Adicionar outra conta
                </app-button>
                <app-spacer [bottom]="32"></app-spacer>
            </div>

            <ng-container
                *ngIf="bankAccountList.charge.length > 0 && form.controls.charge_account.value">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div
                            class="col-12 d-flex flex-row justify-content-between">
                            <table>
                                <thead>
                                    <th>Banco</th>
                                    <th>Agência</th>
                                    <th>Nº da conta</th>
                                    <th>Número contrato cobrança</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of bankAccountList.charge; let i = index">
                                        <td>{{formatBankName(item.charge_account_bank)}}
                                        </td>
                                        <td>{{item.charge_account_agency}}</td>
                                        <td>{{item.charge_account_number}}</td>
                                        <td>{{item.charge_account_contract_number}}
                                        </td>
                                        <td>
                                            <div class="dash-card-item-buttons">
                                                <button class="dash-item-button"
                                                    (click)="removeBankAccount('charge',i)">
                                                    <i class="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12">
                            <app-spacer [bottom]="48"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container> -->

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="scrow_account">
                    Conta escrow
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="offset-1 col-11"
                *ngIf="form.controls.scrow_account.value">
                <div class="row">

                    <div class="col-4">
                        <app-select [items]="signupService.choices().banks"
                            label="Conta Escrow" [group]="form"
                            controlName="scrow_account_bank">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>
            </div>

            <!-- <div class="col-12" *ngIf="form.controls.scrow_account.value">
                <app-button [inverted]="true"
                    (onClick)="addBankAcccount('scrow')"
                    [disabled]="!getFieldsValid('scrow')">
                    Adicionar outra conta
                </app-button>
                <app-spacer [bottom]="32"></app-spacer>
            </div>

            <ng-container
                *ngIf="bankAccountList.scrow.length > 0 && form.controls.scrow_account.value">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div
                            class="col-9 d-flex flex-row justify-content-between">
                            <table>
                                <thead>
                                    <th>Banco</th>
                                    <th>Agência</th>
                                    <th>Número</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of bankAccountList.scrow; let i = index">
                                        <td>{{formatBankName(item.scrow_account_bank)}}
                                        </td>
                                        <td>{{item.scrow_account_agency}}</td>
                                        <td>{{item.scrow_account_number}}</td>
                                        <td>
                                            <div class="dash-card-item-buttons">
                                                <button class="dash-item-button"
                                                    (click)="removeBankAccount('scrow',i)">
                                                    <i class="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-9">
                            <app-spacer [bottom]="48"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container> -->


            <div class="col-12">
                <app-radio [multiple]="true" formControlName="zeroing_process">
                    Zeragem automática
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="form.controls.zeroing_process.value">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div class="col-9">
                            <app-select [items]="zeroingChoices"
                                label="Fundo de zeragem" [group]="form"
                                controlName="zeroing_fund">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-4">
                            <app-select [items]="signupService.choices().banks"
                                [group]="form" controlName="zeroing_bank"
                                label="Banco">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-2">
                            <app-masked-input [group]="form"
                                controlName="zeroing_agency" type="text"
                                label="Agência" placeholder="1234" mask="0*"
                                [max]="8">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-3">
                            <app-masked-input [group]="form"
                                controlName="zeroing_account" label="Conta"
                                type="text" placeholder="12345-6" mask="0*-0">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- <div class="col-12" *ngIf="form.controls.zeroing_account.value">
                <app-button [inverted]="true"
                    (onClick)="addBankAcccount('zeroing')"
                    [disabled]="!getFieldsValid('zeroing')">
                    Adicionar outra conta
                </app-button>
                <app-spacer [bottom]="32"></app-spacer>
            </div> -->

            <!-- <ng-container
                *ngIf="bankAccountList.zeroing.length > 0 && form.controls.zeroing_process.value">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div
                            class="col-12 d-flex flex-row justify-content-between">
                            <table>
                                <thead>
                                    <th>Fundo</th>
                                    <th>Banco</th>
                                    <th>Agência</th>
                                    <th>Número</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of bankAccountList.zeroing; let i = index">
                                        <td>{{formatFundName(item.zeroing_fund)}}
                                        </td>
                                        <td>{{formatBankName(item.zeroing_bank)}}
                                        </td>
                                        <td>{{item.zeroing_agency}}</td>
                                        <td>{{item.zeroing_account}}</td>
                                        <td>
                                            <div class="dash-card-item-buttons">
                                                <button class="dash-item-button"
                                                    (click)="removeBankAccount('zeroing',i)">
                                                    <i class="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12">
                            <app-spacer [bottom]="48"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container> -->

            <div class="col-12">
                <app-radio [multiple]="true"
                    formControlName="zeroing_committed">
                    <span class="flex-1">Zeragem em compromissada</span>
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="form.controls.zeroing_committed.value">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div class="col-4">
                            <app-input label="Nome" [group]="form"
                                controlName="zeroing_committed_contact">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-4">
                            <app-masked-input label="Telefone:" [group]="form"
                                controlName="zeroing_committed_phone"
                                mask="(00) 00000-0000||(00) 0000-0000">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-4">
                            <app-input label="E-mail" [group]="form"
                                controlName="zeroing_committed_email">''
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container>

            <app-spacer [bottom]="8"></app-spacer>

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="cblc">
                    CBLC
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="form.controls.cblc.value">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div class="col-4">
                            <app-masked-input [group]="form"
                                controlName="cblc_contract_number"
                                label="Número de contrato" placeholder="123456"
                                mask="0*">
                            </app-masked-input>
                            <app-spacer [bottom]="32"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="cetip">
                    CETIP
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="form.controls.cetip.value ">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div class="col-4">
                            <app-masked-input [group]="form"
                                controlName="cetip_contract_number"
                                label="Número de contrato" placeholder="123456"
                                mask="0*">
                            </app-masked-input>
                            <app-spacer [bottom]="32"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="selic">
                    SELIC
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="form.controls.selic.value ">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div class="col-4">
                            <app-masked-input [group]="form"
                                controlName="selic_contract_number"
                                label="Número de contrato" placeholder="123456"
                                mask="0*">
                            </app-masked-input>
                            <app-spacer [bottom]="32"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="guaranteed_cblc">
                    CONTA GARANTIA - CBLC
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="form.controls.guaranteed_cblc.value ">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div class="col-4">
                            <app-masked-input [group]="form"
                                controlName="guaranteed_cblc_contract_number"
                                label="Número de contrato" placeholder="123456"
                                mask="0*">
                            </app-masked-input>
                            <app-spacer [bottom]="32"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="guaranteed_bmf">
                    CONTA GARANTIA - BM&F
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="form.controls.guaranteed_bmf.value ">
                <div class="offset-1 col-11">
                    <div class="row">
                        <div class="col-4">
                            <app-masked-input [group]="form"
                                controlName="guaranteed_bmf_contract_number"
                                label="Número de contrato" placeholder="123456"
                                mask="0*">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </form>
</ng-container>