<ng-container *ngIf="loadingContent">
    <div class="row">
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-8">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<form [formGroup]="form" *ngIf="!loadingContent">
    <div class="row">
        <div class="col-12 col-lg-4">
            <app-masked-input [group]="form" controlName="cnpj"
                label="CNPJ da entidade" mask="00.000.000/0000-00"
                placeholder="12.345.6789/0123-45">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-8">
            <app-input [group]="form" controlName="corporate_name"
                [isUpperCase]="true" label="Razão social"
                placeholder="Empresa LTDA">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-6">
            <app-input [group]="form" controlName="occupation" [maxLength]="30"
                label="Ocupação profissional" placeholder="Desenvolvedor">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-6">
            <app-input [group]="form" controlName="corporate_email"
                label="E-mail corporativo" placeholder="dev@empresaltda.com">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-2">
            <app-select label="DDI" [group]="form" controlName="phone_ddi"
                [items]="DDIOptions" placeholder="55">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-masked-input label="Número do telefone" [group]="form"
                controlName="phone_number"
                mask="(00) 00000-0000||(00) 0000-0000"
                placeholder="(00) 99887-7665"></app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-2">
            <app-masked-input [group]="form" controlName="branch" label="Ramal"
                placeholder="12" mask="099">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-4 cep-container">
            <app-masked-input [group]="form" controlName="cep" label="CEP"
                mask="00000-000" placeholder="12.345-678"
                (input)="searchZipcode($event)">
            </app-masked-input>
            <i *ngIf="loading"
                class="fas fa-circle-notch fa-spin app-button-icon"></i>
        </div>
    </div>
    <app-spacer [bottom]="24"></app-spacer>

    <ng-container *ngIf="zipcodeFetched">
        <div class="row">
            <div class="col-12 col-lg-6">
                <app-input [group]="form" controlName="public_place"
                    label="Logradouro" [readOnly]="foundCep">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-2">
                <app-input [group]="form" controlName="number" type="number"
                    label="Número" placeholder="123">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-4">
                <app-input [group]="form" controlName="complement"
                    label="Complemento" placeholder="casa">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-6">
                <app-input [group]="form" controlName="district" label="Bairro"
                    [readOnly]="foundCep">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-4">
                <app-input [group]="form" controlName="city" label="Cidade"
                    [readOnly]="foundCep">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-2">
                <app-select [items]="signupService.ufs" [group]="form"
                    controlName="uf" label="UF" placeholder="SP"
                    [readOnly]="foundCep">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

        </div>
    </ng-container>
</form>