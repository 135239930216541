import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FundService } from 'src/app/services/fund/fund.service';

@Component({
  selector: 'app-dashboard-fund-approval-providers',
  templateUrl: './dashboard-fund-approval-providers.component.html',
  styleUrls: ['./dashboard-fund-approval-providers.component.scss'],
})
export class DashboardFundApprovalProvidersComponent implements OnInit {
  @Input() approval: any;

  @Output() onClick = new EventEmitter<MouseEvent>();

  columns: TableColumnList<ServiceProvider>[] = [
    {
      name: 'Empresa',
      cell: (row) => {
        const is_person = row.register.type === 'PF';
        const name = is_person
          ? row.register.person.full_name
          : row.register.company.corporate_name;
        const person = is_person ? row.register.person : row.register.company;
        const document = person.document.number;

        return `
    <div class="dash-card-item p-0">
        <div class="dash-card-item-initials mr-3">
            <span>${row.register ? this.getInitials(name) : '-'}</span>
        </div>

        <div class="dash-card-item-content">
            <div class="dash-card-item-content-title">
                ${name}
            </div>
            <div class="dash-card-item-content-subtitle">
                ${document}
            </div>
        </div>
    </div>
        `;
      },
      size: 2,
    },
    {
      name: 'Função',
      cell: (row) => (row.agent_data ? row.agent_data.name : '-'),
    },
    {
      name: 'Data vínculo',
      cell: (row) => this.formatDate(row.created),
    },
    {
      name: '',
      cell: (row) => '',
      // `<div class="d-flex align-items-center icon-container">
      //           <a [routerLink]="['/app/dashboard/fund/approval/', ${row.id}]">
      //               <i class="fas fa-eye mr-0"></i>
      //           </a>
      //       </div>`,
    },
  ];
  data: Partial<ShareholderListValues>[] = [];

  constructor(private fundService: FundService) {}

  ngOnInit(): void {
    this.data = this.fundService.data?.service_providers ?? [];
  }

  formatDate(date: string) {
    const day = new Date(date).toLocaleDateString();
    const hours = new Date(date).toLocaleTimeString();
    return `${day} às ${hours}`;
  }

  getInitials(name: string) {
    return name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice: any) => _choice.value == value);
    }

    return { label: '', value: '' };
  }
}
