import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GuardUtils } from '../GuardUtils';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private guardUtils: GuardUtils,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.guardUtils.isAuthenticated()) {
      const user = this.authService.getUser();

      if (user) {
        const role = user.actived_register
          ? user.actived_register.register.role
          : user.role;

        const type = role.slug.split('-')[0];

        if (type === route.data.guardRole) {
          return true;
        }
      }
    }

    return this.router.parseUrl(route.data.redirectTo);
  }
}
