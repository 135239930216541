<div class="row">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <app-empty-content [isVisible]="data.length === 0"
                title="Nenhum acompanhamento encontrado"
                description="Não foi encontrado nenhum acompanhamento nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>

            <ng-container *ngIf="data.length > 0">
                <app-table-list [columns]="columns"
                    [data]="data"></app-table-list>
            </ng-container>
        </div>
        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>