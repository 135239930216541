import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { statesOptions } from 'src/app/data/states';
import {
  BasicInfo,
  SignupService,
} from 'src/app/services/signup/signup.service';

import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import { AuthService } from 'src/app/services/auth/auth.service';
import DDIOptions from 'src/app/data/ddi';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { distinctUntilChanged } from 'rxjs/operators';
import Utils, { GetHelper, UtilsValidators } from 'src/app/helpers/utils';

@Component({
  selector: 'app-signup-unified-company-complementary-general',
  templateUrl: './signup-unified-company-complementary-general.component.html',
  styleUrls: ['./signup-unified-company-complementary-general.component.scss'],
})
export class SignupUnifiedCompanyComplementaryGeneralComponent
  implements OnInit, OnDestroy
{
  DDIOptions = DDIOptions;

  @Input() userRole: string;

  form = this.formBuilder.group({
    cnpj: [null, [Validators.required, UtilsValidators.cnpj]],
    name: [null, [Validators.required]],
    have_guarantor: [false, []],
    guarantor: [null, []],

    fundation_date: [null, []],
    branch: [null, [Validators.required]],
    framework: [null, [Validators.required]],

    municipal_registration: [null, []],
    state_registration: [null, []],
    free_state_registration: [false, []],

    nire: [null, []],
    giin: [null, []],
    coobligation: [false, []],

    billet_emitter: [false, []],
    nfe_emitter: [false, []],
    duplicate_emitter: [false, []],
    have_judicial_recovery: [false, []],

    contract_type: [null, [Validators.required]],

    cellphone_ddi: ['55', []],
    cellphone_number: [
      null,
      [
        Validators.required,
        Utils.validateWhenNotEmpty(UtilsValidators.celular),
      ],
    ],
    telephone_ddi: ['55', []],
    telephone_number: [
      null,
      [Utils.validateWhenNotEmpty(UtilsValidators.telefone)],
    ],

    contact_email: [null, [Validators.required, Validators.email]],
    site: ['', []],

    send_info: [true, [Validators.requiredTrue]],

    cnae: [null, [Validators.required]],

    company_size: [null, [Validators.required]],

    net_worth: [0, [Validators.required, Validators.min(1)]],

    judicial_recovery: [false, []],
    judicial_recovery_date: [null, []],

    pep_relationship: [false, []],
    pep_relationship_document: [null, []],
    pep_relationship_name: [null, []],
    pep_bond: [null, []],

    anual_income: [0, []],
    number_employees: [null, []],
    capital: [0, []],
    authorization_bacen_queries: [false, []],
    relationship_with_other_institutions: [false, []],
    relationship_institution: [null, []],
    third_party: [false, []],
    third_party_document: [null, []],
    third_party_name: [null, []],
  });

  states = statesOptions;

  gender: SelectItem[] = [
    { label: 'Masculino', value: '1' },
    { label: 'Feminino', value: '2' },
  ];

  roles: SelectItem[] = [
    { label: 'Investidor', value: '1' },
    { label: 'Empresa', value: '2' },
  ];

  items: SelectItem[] = [
    { value: '1', label: 'Sim' },
    { value: '2', label: 'Não' },
  ];

  contractTypeItems: SelectItem[] = [
    { value: 'physical', label: 'Físico' },
    { value: 'digital', label: 'Digital' },
  ];

  guarantors: SelectItem[] = [];

  isFund = false;

  today = Utils.todayString();

  haveBasicInfo: boolean = false;

  invitedInfo: BasicInfo = {
    document: null,
    name: null,
    email: null,
  };

  data = null;

  event: any;

  loadingContent = false;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private maskPipe: MaskPipe,
    public authService: AuthService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.setFieldsByRole();
    this.getGuarantors();
    this.getDataAndFillForm();

    this.form.statusChanges.subscribe((status) => {
      this.signupService.setCompanyFormStatus(
        'complementaryGeneral',
        status === 'VALID'
      );

      if (status !== 'VALID') {
        console.error(Utils.getErrors(this.form));
      }
    });

    this.form.statusChanges.subscribe((status) => {
      this.signupService.setCompanyFormStatus(
        'complementaryGeneral',
        status === 'VALID'
      );

      if (status !== 'VALID') {
        console.error(Utils.getErrors(this.form));
      }
    });

    this.event = this.signupService.sendComplementaryCompanyData.subscribe(
      (value) => {
        if (value === 'complementaryGeneral') {
          this.sendData();
        }
      }
    );

    this.form.controls.fundation_date.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (!value) {
          this.form.controls.fundation_date.reset();
          this.form.controls.fundation_date.setValidators([]);
        } else {
          this.form.controls.fundation_date.setValidators([
            Utils.validateMaxMinToday,
            Utils.validateMinDate,
          ]);
        }

        this.form.controls.fundation_date.updateValueAndValidity();
      });

    this.form.valueChanges.subscribe((value) => {
      this.signupService.setCompanyFormStatus(
        'complementaryGeneral',
        this.form.valid
      );
    });

    if (this.userRole !== 'provider') {
      this.form.controls.anual_income.setValidators([
        Validators.required,
        Validators.min(1),
      ]);
      this.form.controls.capital.setValidators([
        Validators.required,
        Validators.min(1),
      ]);
      this.form.controls.authorization_bacen_queries.setValidators([
        Validators.requiredTrue,
      ]);

      this.form.controls.anual_income.updateValueAndValidity();
      this.form.controls.capital.updateValueAndValidity();
      this.form.controls.authorization_bacen_queries.updateValueAndValidity();
    }

    if (this.signupService.basicInfo !== null) {
      this.haveBasicInfo = true;

      this.invitedInfo = this.signupService.basicInfo;

      this.form.patchValue({
        cnpj: this.invitedInfo.document ?? null,
        name: this.invitedInfo.name ?? null,
        contact_email: this.invitedInfo.email,
      });
    }

    if (this.userRole !== 'provider') {
      this.form.controls.judicial_recovery_date.valueChanges
        .pipe(distinctUntilChanged((a: any, b: any) => a === b))
        .subscribe((value) => {
          if (!value) {
            this.form.controls.judicial_recovery_date.setValidators([]);
          } else {
            this.form.controls.judicial_recovery_date.setValidators([
              Validators.required,
              Utils.validateMaxMinToday,
              Utils.validateMinDate,
            ]);
          }
          this.form.controls.judicial_recovery_date.updateValueAndValidity();
        });
    }

    this.form.controls.judicial_recovery.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.judicial_recovery_date.setValidators([
          Validators.required,
          Utils.validateMaxMinToday,
        ]);
        this.form.controls.judicial_recovery_date.setValue(null);
        this.form.controls.coobligation.setValue(false);
      } else {
        this.form.controls.judicial_recovery_date.setValidators([]);
        this.form.controls.judicial_recovery_date.setValue(null);
      }

      this.form.controls.judicial_recovery_date.updateValueAndValidity();
      this.form.controls.coobligation.updateValueAndValidity();
    });

    this.form.controls.have_guarantor.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.guarantor.setValidators([Validators.required]);
      } else {
        this.form.controls.guarantor.setValidators([]);
        this.form.controls.guarantor.setValue(null);
        this.form.controls.guarantor.reset();
      }

      this.form.controls.guarantor.updateValueAndValidity();
    });

    if (this.userRole !== 'provider') {
      this.form.controls.relationship_with_other_institutions.valueChanges.subscribe(
        (value) => {
          if (value) {
            this.form.controls.relationship_institution.setValidators([
              Validators.required,
            ]);
          } else {
            this.form.controls.relationship_institution.setValidators([]);
          }

          this.form.controls.relationship_institution.updateValueAndValidity();
        }
      );
    }

    this.form.controls.free_state_registration.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.form.controls.state_registration.setValue(null);
        }

        this.form.controls.state_registration.updateValueAndValidity();
      }
    );

    this.signupService.fillFormEvent.subscribe(() => {
      const relationshipWithOtherInstitutions = false;
      const pepRelationship = false;
      const thirdParty = fakerbr.random.boolean();

      const fakeDomain = fakerbr.internet.domainName();

      this.form.patchValue({
        cnpj: this.invitedInfo.document
          ? this.form.controls.cnpj.value
          : this.maskPipe.transform(fakerbr.br.cnpj(), '00.000.000/0000-00'),
        name: this.invitedInfo.name
          ? this.form.controls.name.value
          : fakerbr.company.companyName(),

        contact_email: this.invitedInfo.email
          ? this.form.controls.contact_email.value
          : `contato@${fakeDomain}`,

        fundation_date: fakerbr.date.past(20).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        branch: String(fakerbr.random.number({ min: 1, max: 3 })),
        framework: String(fakerbr.random.number({ min: 1, max: 4 })),

        municipalRegistration: fakerbr.random.number({
          min: 10000000,
          max: 99999999,
        }),
        stateRegistration: fakerbr.random.number({
          min: 10000000,
          max: 99999999,
        }),

        nire: fakerbr.random.number({
          min: 10000000,
          max: 99999999,
        }),
        giin: fakerbr.random.number({
          min: 10000000,
          max: 99999999,
        }),

        cellphone_ddi: '55',
        cellphone_number: this.maskPipe.transform(
          '139' +
            String(fakerbr.random.number({ min: 11111111, max: 99999999 })),
          '(00) 00000-0000'
        ),
        telephone_ddi: '55',
        telephone_number: this.maskPipe.transform(
          '139' + String(fakerbr.random.number({ min: 1111111, max: 9999999 })),
          '(00) 0000-0000'
        ),
        coobligation: false,

        site: `https://${fakeDomain}`,

        send_info: true,

        contract_type: 'digital',

        cnae: this.signupService.signupChoices.cnaes[
          fakerbr.random.number({ min: 0, max: 672 })
        ].value,

        company_size: String(fakerbr.random.number({ min: 1, max: 3 })),
        number_employees: String(fakerbr.random.number({ min: 1, max: 100 })),

        net_worth: fakerbr.random.number({ min: 500000, max: 5000000 }),

        judicial_recovery_date: null,
      });

      if (this.userRole !== 'provider') {
        this.form.patchValue({
          thirdParty: thirdParty,
          thirdPartyDocument: thirdParty
            ? this.maskPipe.transform(fakerbr.br.cpf(), '000.000.000-00')
            : null,
          thirdPartyName: thirdParty ? fakerbr.name.findName() : null,
          pep_relationship: pepRelationship,
          pep_relationship_document: pepRelationship
            ? this.maskPipe.transform(fakerbr.br.cpf(), '000.000.000-00')
            : null,
          pep_relationship_name: pepRelationship
            ? fakerbr.name.findName()
            : null,
          pepBond: pepRelationship ? 'Sócio' : null,
          anual_income: fakerbr.random.number({ min: 500000, max: 5000000 }),
          capital: fakerbr.random.number({ min: 50000000, max: 500000000 }),
          relationship_with_other_institutions:
            relationshipWithOtherInstitutions,
          relationship_institution: relationshipWithOtherInstitutions
            ? fakerbr.company.companyName()
            : '',
          authorization_bacen_queries: true,
        });
      }
    });

    this.form.controls.pep_relationship.valueChanges.subscribe(() =>
      this.verifyPepRelationshipFields()
    );

    if (this.userRole !== 'provider') {
      this.form.controls.third_party.valueChanges.subscribe(() =>
        this.thirdPartyFields()
      );
    }
  }

  verifyPepRelationshipFields() {
    if (this.form.controls.pep_relationship.value) {
      this.form.controls.pep_relationship_document.setValidators([
        Validators.required,
        UtilsValidators.cpf,
      ]);
      this.form.controls.pep_relationship_name.setValidators([
        Validators.required,
      ]);
      this.form.controls.pep_bond.setValidators([Validators.required]);
    } else {
      this.form.controls.pep_relationship_document.setValidators([]);
      this.form.controls.pep_relationship_name.setValidators([]);
      this.form.controls.pep_bond.setValidators([]);
    }

    this.form.controls.pep_relationship_document.updateValueAndValidity();
    this.form.controls.pep_relationship_name.updateValueAndValidity();
    this.form.controls.pep_bond.updateValueAndValidity();
  }

  setFieldsByRole() {
    if (this.authService.user) {
      if (
        this.authService.user.actived_register.register.role.slug ===
        `${this.userRole.toUpperCase()}-PJ-FI`
      ) {
        this.form.controls.anual_income.setValidators([]);
        this.form.controls.company_size.setValidators([]);
        this.form.controls.capital.setValidators([]);
        this.form.controls.giin.setValidators([Validators.required]);
        this.form.controls.net_worth.setValidators([
          Validators.required,
          Validators.min(1),
        ]);

        this.isFund = true;
      } else {
        if (this.userRole !== 'provider') {
          this.form.controls.anual_income.setValidators([
            Validators.required,
            Validators.min(1),
          ]);
          this.form.controls.capital.setValidators([
            Validators.required,
            Validators.min(1),
          ]);
        }

        if (this.userRole === 'provider') {
          this.form.controls.number_employees.setValidators([
            Validators.required,
          ]);
        }
        this.form.controls.company_size.setValidators([Validators.required]);

        this.form.controls.giin.setValidators([]);
        this.form.controls.net_worth.setValidators([]);

        this.isFund = false;
      }

      if (
        this.authService.user.actived_register.register.role.slug ===
          'ASSIGNOR-PJ-IF' ||
        this.authService.user.actived_register.register.role.slug ===
          'GUARANTOR-PJ-IF'
      ) {
        this.form.controls.giin.setValidators([Validators.required]);
        this.isFund = true;
      }

      this.form.controls.number_employees.updateValueAndValidity();
      this.form.controls.anual_income.updateValueAndValidity();
      this.form.controls.company_size.updateValueAndValidity();
      this.form.controls.capital.updateValueAndValidity();
      this.form.controls.giin.updateValueAndValidity();
      this.form.controls.net_worth.updateValueAndValidity();
    }
  }

  thirdPartyFields() {
    if (this.form.controls.third_party.value) {
      this.form.controls.third_party_document.setValidators([
        Validators.required,
        Utils.validateCpfCnpj,
      ]);
      this.form.controls.third_party_name.setValidators([Validators.required]);
    } else {
      this.form.controls.third_party_document.setValidators([]);
      this.form.controls.third_party_name.setValidators([]);
    }

    this.form.controls.third_party_document.updateValueAndValidity();
    this.form.controls.third_party_name.updateValueAndValidity();
  }

  async getGuarantors() {
    const res = await this.api.get({
      route: 'api/registration/guarantor',
      token: true,
    });

    this.guarantors = res.map((item) => {
      return {
        value: item.user.id,
        label: item.user.first_name + ' ' + item.user.last_name,
      };
    });
  }

  async getDataAndFillForm() {
    this.loadingContent = true;

    try {
      const { data } = await this.api.get<ApiResponse<Company>>({
        route: 'api/registration/pj_general/me/',
        token: true,
      });

      const parsedData: CompanyComplementaryGeneralValues = {
        ...data,

        cnpj: this.maskPipe.transform(
          data.document.number,
          '00.000.000/0000-00'
        ),
        fundation_date: data.fundation_date
          ? data.fundation_date.split('-').reverse().join('/')
          : null,
        name: data.corporate_name,
        have_guarantor: !!data.guarantor,
        municipal_registration: data.municipal_registration,
        state_registration: data.state_registration,
        free_state_registration: data.free_state_registration,
        coobligation: data.assignor
          ? data.assignor.coobligation === 'true'
          : false,
        cellphone_ddi: data.cellphone ? String(data.cellphone.code) : '',
        cellphone_number: data.cellphone
          ? this.maskPipe.transform(data.cellphone.number, '(00) 00000-0000')
          : '',
        telephone_ddi: data.phone ? String(data.phone.code) : '55',
        telephone_number: data.phone
          ? this.maskPipe.transform(data.phone.number, '(00) 00000-0000')
          : '',
        send_info: true,
        net_worth: Number(data.net_worth),
        judicial_recovery: data.judicial_recovery,
        have_judicial_recovery: data.have_judicial_recovery,
        judicial_recovery_date: data.judicial_recovery_date
          ? data.judicial_recovery_date.split('-').reverse().join('/')
          : null,
        pep_relationship: false,
        pep_relationship_document: '',
        pep_relationship_name: '',
        pep_bond: '',
        anual_income: Number(data.anual_income),

        authorization_bacen_queries: data.assignor
          ? data.assignor.authorization_bacen_queries
          : false,
        relationship_with_other_institutions: false,
        relationship_institution: data.assignor
          ? data.assignor.relationship_institution
          : '',
        third_party: false,
        third_party_document: '',
        third_party_name: '',
        contract_type: data.contract_type ? String(data.contract_type) : '',

        guarantor: data.guarantor ? String(data.guarantor) : '',
        branch: data.branch ? String(data.branch) : '',
        framework: data.framework ? String(data.framework) : '',
        cnae: data.cnae ? String(data.cnae) : '',
        contact_email: data.contact_email ? String(data.contact_email) : '',
        capital: Number(data.capital),
        company_size: String(data.company_size),
        number_employees: String(data.number_employees),
      };

      this.form.patchValue(parsedData);

      if (data.assignor && data.assignor.relationship_institution) {
        this.form.controls.relationship_institution.setValidators([
          Validators.required,
        ]);

        this.form.controls.relationship_institution.updateValueAndValidity();
      }

      if (data.guarantor) {
        this.form.controls.guarantor.setValidators([Validators.required]);

        this.form.controls.relationship_institution.updateValueAndValidity();
      }

      this.signupService.setCompanyFormStatus('complementaryGeneral', true);
      this.data = parsedData;
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }

    this.loadingContent = false;
  }

  async sendData() {
    this.signupService.isSendindData = true;

    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        document: {
          number: Utils.onlyNumbers(valuesHelper.get('cnpj')),
          type: 'CNPJ',
        },
        corporate_name: valuesHelper.get('name'),
        fundation_date: valuesHelper.get('fundation_date')
          ? valuesHelper.get('fundation_date').split('/').reverse().join('-')
          : null,
        role: 1,
        giin: valuesHelper.get('giin'),
        nire: valuesHelper.get('nire'),
        contact_email: valuesHelper.get('contact_email'),
        site: valuesHelper.get('site'),
        send_info: valuesHelper.get('send_info'),
        cnae: Number(valuesHelper.get('cnae')),
        number_employees: Number(valuesHelper.get('number_employees')),
        anual_income: valuesHelper.get('anual_income')
          ? Number(valuesHelper.get('anual_income'))
          : 0,
        net_worth: Number(valuesHelper.get('net_worth')),
        judicial_recovery: valuesHelper.get('judicial_recovery'),
        judicial_recovery_date: valuesHelper.get('judicial_recovery_date')
          ? valuesHelper
              .get('judicial_recovery_date')
              .split('/')
              .reverse()
              .join('-')
          : null,
        branch: valuesHelper.get('branch'),
        capital: Number(valuesHelper.get('capital')),
        framework: valuesHelper.get('framework'),
        municipal_registration: valuesHelper.get('municipal_registration'),
        company_size: valuesHelper.get('company_size'),
        state_registration: valuesHelper.get('state_registration'),
        free_state_registration: valuesHelper.get('free_state_registration'),
        guarantor: valuesHelper.get('guarantor'),
        billet_emitter: valuesHelper.get('billet_emitter'),
        nfe_emitter: valuesHelper.get('nfe_emitter'),
        duplicate_emitter: valuesHelper.get('duplicate_emitter'),
        have_judicial_recovery: valuesHelper.get('have_judicial_recovery'),
        contract_type: valuesHelper.get('contract_type'),
      };

      if (
        valuesHelper.get('telephone_number') !== '' &&
        valuesHelper.get('telephone_number') !== null
      ) {
        payload['phone'] = {
          type: 'residential',
          code: Number(valuesHelper.get('telephone_ddi')),
          number:
            valuesHelper.get('telephone_number') !== ''
              ? Utils.onlyNumbers(valuesHelper.get('telephone_number'))
              : null,
          branch: 0,
        };
      }

      if (
        valuesHelper.get('cellphone_number') !== '' &&
        valuesHelper.get('cellphone_number') !== null
      ) {
        payload['cellphone'] = {
          type: 'phone',
          code: Number(valuesHelper.get('cellphone_ddi')),
          number: Utils.onlyNumbers(valuesHelper.get('cellphone_number')),
          branch: 0,
        };
      }

      if (
        this.authService.user.actived_register.register.role.applicable ===
        'assignor'
      ) {
        payload['assignor'] = {
          authorization_bacen_queries: valuesHelper.get(
            'authorization_bacen_queries'
          ),
          relationship_with_other_institutions: valuesHelper.get(
            'relationship_with_other_institutions'
          ),
          relationship_institution: valuesHelper.get(
            'relationship_institution'
          ),
          coobligation: String(valuesHelper.get('coobligation')),
        };
      }

      console.table(valuesHelper.getRemainingObject());

      if (this.data) {
        await this.api.put<ApiResponse<Company>>({
          route: 'api/registration/pj_general/me/',
          token: true,
          body: payload,
        });
      } else {
        await this.api.post<ApiResponse<Company>>({
          route: 'api/registration/pj_general/',
          token: true,
          body: payload,
        });
      }

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');

      this.signupService.changeBasicInfoEvent.emit(payload.corporate_name);

      this.signupService.setComplementaryCompanyStep('complementaryAddress');
      this.signupService.changeCompanyStepEvent.emit('address');
    } catch (error) {
      console.error(error);
      this.toast.show(
        'info',
        'Erro',
        `Ocorreu um erro ao salvar os dados, ${error}`
      );
    }
    this.signupService.isSendindData = false;
  }
}
