import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MaskPipe } from 'ngx-mask';
import Utils from 'src/app/helpers/utils';

@Component({
  selector: 'app-dashboard-representative-approval-bonds',
  templateUrl: './dashboard-representative-approval-bonds.component.html',
  styleUrls: ['./dashboard-representative-approval-bonds.component.scss'],
})
export class DashboardRepresentativeApprovalBondsComponent implements OnInit {
  @Input() approval: Representative = undefined;

  links: any[] = undefined;

  constructor(private maskPipe: MaskPipe) {}

  ngOnInit(): void {
    this.links = this.approval.represented;
  }

  getInitials(item: any) {
    let name = null;

    if (item.type.toUpperCase() === 'PF') {
      name = item.person.full_name;
    }

    if (item.type.toUpperCase() === 'PJ') {
      name = item.company.corporate_name;
    }

    return name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  formatDocument(item: any) {
    let document = '';

    if (item.represented_register.type === 'PJ') {
      document = this.maskPipe.transform(
        item.represented_register.company.document.number,
        '00.000.000/0000-00'
      );
    }

    if (item.represented_register.type === 'PF') {
      document = this.maskPipe.transform(
        item.represented_register.company.document.number,
        '000.000.000-00'
      );
    }

    return document;
  }

  renderLink(item: any) {
    return [
      `/app/details/${item.represented_register.role.applicable}/`,
      item.represented_register.uuid,
    ];
  }

  formatItemName(item: any) {
    const name = item.role.applicable.toUpperCase();

    switch (name) {
      case 'ASSIGNOR':
        return 'Cedente';
      case 'SHAREHOLDER':
        return 'Cotista';
      case 'PROVIDER':
        let providerLabel =
          item.represented_register.agent.length > 0
            ? item.represented_register.agent.map((_) => _.name).join(', ')
            : 'Prestador de serviço';
        return providerLabel;

      default:
        return 'Representante';
    }
  }
}
