<div class="shareholder">
  <div class="row">
    <div class="col-12 ml-auto mr-auto">
      <ng-container *ngIf="!loading">
        <app-back-button route="/app/products"></app-back-button>
      </ng-container>

      <ng-container *ngIf="isEditing">
        <div class="shareholder-card">
          <div class="row w-100 no-gutters align-items-center">
            <div class="col-12 col-lg-8 d-flex flex-row" *ngIf="loading">
              <div class="shareholder-profile-initials bg-none">
                <ngx-skeleton-loader appearance="circle" [theme]="{
                                width: '64px',
                                height: '64px',
                                margin:'0'
                              }">
                </ngx-skeleton-loader>
              </div>
              <app-spacer [right]="32"></app-spacer>
              <div class="d-flex flex-column">
                <ngx-skeleton-loader [theme]="{
                                height: '44px',
                                width: '500px',
                                margin:'0'
                              }">
                </ngx-skeleton-loader>
                <div class="d-flex flex-row align-items-center">
                  <ngx-skeleton-loader [theme]="{
                                height: '24px',
                                width:'150px'
                            }"></ngx-skeleton-loader>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-8 d-flex flex-row" *ngIf="!loading">
              <div class="shareholder-profile-initials">
                <span>
                  {{fund.name ? getInitials(fund.name) :
                  '0*'}}
                </span>
              </div>
              <app-spacer [right]="32"></app-spacer>
              <div class="d-flex flex-column">
                <span class="shareholder-profile-name">
                  {{fund.name ?? '0*'}}
                </span>
                <div class="d-flex flex-row align-items-center">
                  <span class="shareholder-profile-type">
                    {{getChoicesName('cvm_classification_choices',
                    fund.cvm_classification)?.label}}
                  </span>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-4 d-flex justify-content-end">
              <ng-container *ngIf="loading">
                <ngx-skeleton-loader [theme]="{
                            height: '44px',
                            width: '139px',
                            margin:'0'
                          }">
                </ngx-skeleton-loader>
              </ng-container>

              <ng-container *ngIf="!loading">
                <app-button [center]="true" [phantom]="true" (onClick)="redirect('/app/products')">
                  Cancelar
                </app-button>

                <app-spacer [right]="24"></app-spacer>

                <app-button [center]="true" [disabled]="canProceed()" (onClick)="proceed()">
                  Salvar alterações
                </app-button>
              </ng-container>
            </div>
          </div>
        </div>
        <app-spacer [bottom]="64"></app-spacer>
      </ng-container>

      <ng-container *ngIf="loading">
        <div class="row">
          <div class="col-2" *ngFor="let number of [0,1,2,3,4,5]">
            <ngx-skeleton-loader [theme]="{
                height: '38px',
                margin:'0'
              }">
            </ngx-skeleton-loader>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loading">
        <div class="d-flex">
          <app-spacer [left]="20"></app-spacer>
          <app-tabs [tabs]="tabs" (onChangeTab)="onChangeTab($event)" [currentTab]="tabIndex" [dashboardTab]="true">
          </app-tabs>
        </div>
      </ng-container>

      <app-spacer [bottom]="24"></app-spacer>

      <div class="row">
        <div class="col-12">
          <div class="dash-details-card">
            <div class="dash-details-card-body pt-4">
              <div class="row">
                <div class="col-12 col-md-9">
                  <ng-container *ngIf="loading">
                    <ngx-skeleton-loader [theme]="{height: '24px',width:'450px'}"></ngx-skeleton-loader>
                  </ng-container>

                  <ng-container *ngIf="fund && !loading">
                    <h3 class="fund-edit-name">
                      Editar fundo {{ fund.name }}
                    </h3>
                  </ng-container>

                  <ng-container *ngIf="!loading">
                    <div [class.d-none]="activedTab !== 'dados-basicos'">
                      <app-dashboard-fund-approval-register-basic [loading]="loading">
                      </app-dashboard-fund-approval-register-basic>
                    </div>

                    <div [class.d-none]="activedTab !== 'dados-complementares'">
                      <app-dashboard-fund-approval-register-complementary>
                      </app-dashboard-fund-approval-register-complementary>
                    </div>

                    <div [class.d-none]="activedTab !== 'prestadores-de-servico'">
                      <app-dashboard-fund-approval-register-providers>
                      </app-dashboard-fund-approval-register-providers>
                    </div>

                    <div [class.d-none]="activedTab !== 'taxas'">
                      <app-dashboard-fund-approval-register-tax>
                      </app-dashboard-fund-approval-register-tax>
                    </div>

                    <div [class.d-none]="activedTab !== 'bancario'">
                      <app-dashboard-fund-approval-register-banking>
                      </app-dashboard-fund-approval-register-banking>
                    </div>
                  </ng-container>


                </div>

                <div class="col-12 col-md-3">
                </div>
              </div>
            </div>
          </div>

          <div class="button-container">
            <ng-container *ngIf="loading">
              <ngx-skeleton-loader [theme]="{
                                height: '24px',
                                width:'150px'
                            }"></ngx-skeleton-loader>
            </ng-container>

            <ng-container *ngIf="!loading">
              <app-button [center]="true" [phantom]="true" (onClick)="redirect('/app/products')">
                Cancelar
              </app-button>

              <app-spacer [right]="24"></app-spacer>

              <app-button [center]="true" [disabled]="canProceed()" (onClick)="proceed()">
                {{fund ? 'Salvar alterações' : 'Adicionar
                fundo'}}
              </app-button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
