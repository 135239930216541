import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

type FileData = {
  label: string;
  files: Partial<AttachmentListValues>[];
};

type ModalFilesUrlsProps = {
  url: string;
  isImg: boolean;
  name: string;
};
@Component({
  selector: 'app-dashboard-approval-attachments',
  templateUrl: './dashboard-approval-attachments.component.html',
  styleUrls: ['./dashboard-approval-attachments.component.scss'],
})
export class DashboardApprovalAttachmentsComponent implements OnInit {
  @Input() approval: any;
  @ViewChild('modalRoot') modalRoot;

  @Output() onClick = new EventEmitter<MouseEvent>();

  reproveForm = this.formBuilder.group({
    description: [null, [Validators.required]],
  });

  data: FileData[] = [];

  warningModal = false;
  reproveModal = false;

  modalFiles: any = null;
  modalFilesUrls: ModalFilesUrlsProps[] | null = null;
  selectedFile: ModalFilesUrlsProps | null = null;
  selectedAttachment: Partial<AttachmentListValues> | null = null;

  representatives: any[] = [];

  file_situations = ['Arquivo Enviado', 'Reprovado', 'Aprovado'];
  fileType = [
    {
      label: '.XML',
      value: 'xml',
    },
    {
      label: '.PDF',
      value: 'pdf',
    },
    {
      label: '.PNG',
      value: 'png',
    },
    {
      label: '.JPG',
      value: 'jpg',
    },
    {
      label: '.XLS',
      value: 'xls',
    },
    {
      label: '.XLSX',
      value: 'xlsx',
    },
    {
      label: '.DOC',
      value: 'doc',
    },
    {
      label: '.DOCX',
      value: 'docx',
    },
  ];

  constructor(
    private route: Router,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private api: ApiService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.filterData();
    this.representatives = this.approval.register.representatives;
  }

  filterData() {
    const labelArr = [];
    const newData = [];

    this.approval.register.files.forEach((file: AttachmentListValues) => {
      const label = file.type.name;

      if (!labelArr.includes(label)) {
        labelArr.push(label);
      }
    });

    this.approval.register.files.forEach((file: AttachmentListValues) => {
      const label = file.type.name;

      const index = labelArr.findIndex((item) => item === label);

      if (!newData[index]) {
        newData[index] = {
          label,
          files: [],
        };
      }

      newData[index].files.push(file);
    });

    this.data = newData;
  }

  formatPartnerName(partner: any) {
    if (partner.referent === 'representative') {
      const representative = this.representatives.find(
        (rep) =>
          String(rep.representative_register.uuid) === partner.referent_id
      );

      if (!representative) {
        return '';
      }
      return `${representative.full_name}`;
    }

    return '';
  }

  formatAttachmentType(referent) {
    if (referent === 'partner') {
      return 'Acionista';
    }

    if (referent === 'representative') {
      return 'Representante';
    }

    return '';
  }

  openFile(attachments: Partial<AttachmentListValues>[]) {
    this.modalFiles = attachments;

    const newModalFilesUrls = attachments.map(
      (attachment: Partial<AttachmentListValues>) => this.getImgUrl(attachment)
    );

    this.modalFilesUrls = newModalFilesUrls;
    this.selectedFile = newModalFilesUrls[0];

    this.modalRoot.show();
  }

  setPrevAttachment() {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (index === 0) return;

    this.selectedFile = this.modalFilesUrls[index - 1];
  }

  setNextAttachment() {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (index === this.modalFilesUrls?.length - 1) return;

    this.selectedFile = this.modalFilesUrls[index + 1];

    // if (index === this.modalFilesUrls?.length - 1) {
    //   this.selectedFile = this.modalFilesUrls[0];
    //   return;
    // }

    // if (index !== undefined) {
    //   this.selectedFile = this.modalFilesUrls[index + 1];
    // }
  }

  getDisabled(direction) {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (direction === 'prev') {
      return index === 0;
    } else {
      return index === this.modalFilesUrls?.length - 1;
    }
  }

  renderIcon(file: string) {
    const fileExtension = file.split('.').pop();

    let extension = '';

    switch (fileExtension) {
      case 'pdf':
        extension = 'icon_filepdf';
        break;
      case 'jpg':
        extension = 'icon_filejpg';
        break;
      case 'png':
        extension = 'icon_filepng';
        break;
      default:
        extension = 'icon_filejpg';
        break;
    }

    return `/assets/images/${extension}.svg`;
  }

  formatFileName(file: string) {
    return file.split('/').pop()?.split('.').shift();
  }

  getAttachmentDate(term: string, file: string | number) {
    const date = new Date(file);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (term === 'start') {
      return `${day}/${month}/${year}`;
    } else {
      return `${day}/${month}/${year + 2}`;
    }
  }

  formatDate(date: string) {
    const update = new Date(date);
    const today = new Date();

    const contDays = Math.floor(
      (today.getTime() - update.getTime()) / (1000 * 3600 * 24)
    );

    if (contDays === 0) {
      return 'Hoje';
    }

    if (contDays === 1) {
      return 'Ontem';
    }

    if (contDays > 1) {
      return `há ${contDays + 1} dias atrás`;
    }

    return '';
  }

  redirectTo(path: string) {
    this.route.navigateByUrl(path);
  }

  closeModal() {
    this.warningModal = false;
    this.reproveModal = false;
    this.modalFiles = null;

    this.reproveForm.reset();
  }

  openReproveModal() {
    this.closeModal();
    this.reproveModal = true;
  }

  openWarningModal(item: Partial<AttachmentListValues>) {
    this.warningModal = true;
    this.selectedAttachment = item;
  }

  async handleReproveDocument() {
    try {
      const res = await this.api.put({
        route: 'api/approvals/approval_file',
        token: true,
        body: {
          id: this.selectedAttachment?.id,
          situation: 2,
          description: this.reproveForm.controls.description.value,
        },
      });

      this.reproveModal = false;
      this.onClick.emit();

      this.toastService.show(
        'info',
        'Documento reprovado',
        'Sua solicitação foi enviada ao responsável'
      );
    } catch (error) {
      console.error(error);
    }
  }

  getImgUrl(attachment: Partial<AttachmentListValues>) {
    let url: any = attachment.file;
    let type: string = '';

    const regex = /\.([0-9a-z]+)(?:[\?#]|$)/i;

    const resultado = regex.exec(attachment.file);

    if (resultado && resultado[1]) {
      type = resultado[1].toLowerCase();
    }

    if (type === 'pdf') {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(attachment.file);
    }

    if (type === 'xls' || type === 'xlsx') {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${attachment.file}`
      );
    }

    let auxExtensionTypes = ['pdf', 'xls', 'xlsx'];

    return {
      url,
      isImg: !auxExtensionTypes.includes(type),
      name: this.formatFileName(attachment.file),
    };
  }

  urlDecoding(label: string) {
    return decodeURIComponent(label);
  }
}
