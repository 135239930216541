<div class="pagination-container flex-column align-items-end"
    [class.d-none]="!show" [class.dash-pagination]="dashPagination">
    <div class="pagination-buttons" *ngIf="pages.length > 1">
        <button class="pagination-button prev my-1" (click)="prevPage()"
            [class.disabled]="currentPage === 1">
            Anterior
        </button>


        <button *ngFor="let page of pages; let i = index"
            class="pagination-button my-1" [class.active]="page === currentPage"
            (click)="changePage(page)">
            {{page}}
        </button>

        <button class="pagination-button next my-1" (click)="nextPage()"
            [class.disabled]="currentPage === this.pages.length">
            Próximo
        </button>
    </div>
    <div *ngIf="pages.length === 1">

    </div>

    <span class="pagination-indicator mt-2"><b>{{getPerPageNum()}}</b> de
        <b>{{data.length}}</b></span>
</div>