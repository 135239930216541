<div class="row">
    <div class="col-12">
        <div class="dash-details-card">
            <div class="dash-details-card-header text-right">
                <div></div>

                <div class="d-flex">
                    <app-button [inverted]="true"
                        (click)="toggleAttachmentModal()">
                        Anexar contrato existente
                    </app-button>

                    <app-spacer [right]="16"></app-spacer>

                    <app-button [inverted]="true"
                        (click)="toggleDocumentModal()">
                        Gerar contrato
                    </app-button>
                </div>
            </div>

            <app-spacer [bottom]="30"></app-spacer>

            <table class="bordered" *ngIf="data.length > 0">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>testemunhas</th>
                        <th>status</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of data">
                        <td>{{item.name}}</td>
                        <td>{{item.witness.length}}</td>
                        <td>{{item.status}}</td>
                        <td>
                            <div class="dash-card-item-buttons">
                                <button class="dash-item-button"
                                    (click)="handleOpenVisualizer(item.id)">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <app-spacer [bottom]="28"></app-spacer>
            </table>

            <div *ngIf="data.length === 0" class="card-padding-bottom">
                <span class="dash-card-item-content-title text-center d-block">
                    Nenhum contrato registrado
                </span>
            </div>
        </div>
        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>

<app-side-modal [active]="showDocumentModal" title="Gerar contrato"
    (onClose)="toggleDocumentModal()" paddingBottom="30">
    <form [formGroup]="form">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <app-select [items]="witnesses"
                        label="Selecione as testemunhas" controlName="witness"
                        [multiple]="true" [group]="form">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>

            <div class="row button-sticky">
                <div class="col-12 col-lg-6">
                    <app-button (onClick)="toggleDocumentModal()"
                        class="add-button" [outline]="true">
                        Cancelar
                    </app-button>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="text-right">
                        <app-button (onClick)="generateDocument()"
                            [disabled]="getDisabled()" class="add-button">
                            Gerar
                        </app-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>

<app-side-modal [active]="showAttachmentModal" title="Anexar contrato existente"
    (onClose)="toggleAttachmentModal()" paddingBottom="30">
    <form [formGroup]="form">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <app-dropzone (filesChange)="setFiles($event)"
                        [clearAttachments]="clearAttachments">
                    </app-dropzone>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>

            <div class="row button-sticky">
                <div class="col-12 col-lg-6">
                    <app-button (onClick)="toggleAttachmentModal()"
                        class="add-button" [outline]="true">
                        Cancelar
                    </app-button>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="text-right">
                        <app-button (onClick)="toggleAttachmentModal()"
                            [disabled]="getAttachmentDisabled()"
                            class="add-button">
                            Anexar
                        </app-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>

<app-side-modal [active]="showDocumentDetailsModal"
    [title]="selectedData ? selectedData.name : ''"
    (onClose)="toggleDocumentDetailsModal()" paddingBottom="30">
    <ng-container *ngIf="selectedData">
        <div class="container">
            <div class="row">
                <div class="col-12 d-flex flex-column">
                    <span class="dash-details-card-item-title">
                        {{formatWitnesses(selectedData.witness)}}
                    </span>

                    <span class="dash-details-card-item-subtitle">
                        Testemunhas
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 d-flex flex-column">
                    <span class="dash-details-card-item-title">
                        {{selectedData.status}}
                    </span>

                    <span class="dash-details-card-item-subtitle">
                        Status
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>
        </div>
    </ng-container>
</app-side-modal>