<app-container>
  <div class="row no-gutters w-100 auth-layout signup-basic-page">
    <aside class="col-12 col-lg-4 basic-sidebar">
      <div class="basic-sidebar-body">
        <img [src]="UIDefaultTheme.brand.logoHorizontal" class="sidebar-logo d-none d-lg-flex">

        <app-spacer [bottom]="56" class="d-none d-lg-block">
        </app-spacer>

        <app-stepper [steps]="steps" [activeIndex]="activeStep">
        </app-stepper>
      </div>
    </aside>

    <main class="col-12 col-lg-8 basic-content">
      <div class="row no-gutters flex-fill body-content" [class.align-items-center]="!isRepresentative">
        <div class="col-12 offset-lg-1 col-lg-11">

          <div class="row m-0">
            <div class="col-9 d-flex flex-column">
              <span class="basic-content-title">{{title}}</span>
              <app-spacer [bottom]="16"></app-spacer>
              <span class="basic-content-description" [innerHTML]="text"></span>

              <app-spacer [bottom]="48"></app-spacer>
            </div>
          </div>

          <ng-container *ngIf="company_data">
            <div class="row m-0">
              <div class="col-10">
                <div class="row">
                  <div class="col-md-4 col-sm-6 mb-3" *ngFor="let sign of company_data.signs">
                    <app-signature-card role="Representante" [name]="sign.register.person.full_name"
                      [sign]="sign.signed" [canSign]="canSign(sign)" [reSend]="canResend(sign)" [disabled]="sign.signed"
                      (onResend)="resendMail(sign.id)" (onClick)="redirectToDetails(sign.id)">
                    </app-signature-card>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>


          <app-spacer [bottom]="40"></app-spacer>

          <div class="row m-0" *ngIf="alreadySigned || !isRepresentative">
            <div class="col-12">
              <app-button label="Acessar painel inicial" (onClick)="goToPanel()">Acessar painel inicial
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</app-container>
