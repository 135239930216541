import { GuardUtils } from './../GuardUtils';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TypeGuard implements CanActivate {
  constructor(
    private guardUtils: GuardUtils,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.guardUtils.isAuthenticated()) {
      const user = this.authService.getUser();

      if (user && user.actived_register) {
        if (user.actived_register.register.type === route.data.guardType) {
          return true;
        }
      }
    }

    return this.router.parseUrl(route.data.redirectTo);
  }
}
