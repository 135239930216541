
const gitInfo = {
  branch: "master-aventis",
  commit: "5eb2385",
  origin: "https://jhonnatthansantana:ATBBYau4fyYXhCJPGvDA44j5b3LR4A640185@bitbucket.org/prosperitainova/dataengine-web-angular.git",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/5eb2385a3dece2e9fb1c0b3c87ba377965ed3774",
  date: "20/02/2024",
};

export default gitInfo;
  