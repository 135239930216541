<main class="dashboard">
  <div class="row w-100 justify-content-center">
    <ng-container *ngIf="applicable === 'representative'">


    <div class="col-11">
      <div class="dash-card">

        <div class="dash-card-header">
          <div>
            <strong>Suas assinaturas</strong>
          </div>
        </div>

        <div class="dash-card-body">
          <table>
            <thead>
              <tr>
                <th>Empresa</th>
                <th>email</th>
                <th>Assinado</th>

                <th></th>
              </tr>
            </thead>

            <tbody>
              <ng-container *ngIf="requested_signs.length > 0">
                <tr *ngFor="let sign of requested_signs">
                  <td>
                    <div class="dash-card-item-content">
                      <div class="dash-card-item-content-title">
                        {{sign.company.corporate_name}}
                      </div>
                      <div class="dash-card-item-content-subtitle">
                        {{sign.company.document}}
                      </div>
                    </div>
                  </td>

                  <td>
                    <div class="dash-card-item-content">
                      <div class="dash-card-item-content-title">
                        {{sign.company.contact_email}}
                      </div>
                    </div>
                  </td>

                  <td>
                    <!-- <i *ngIf="!sign.signed" class="fas fa-exclamation-triangle text-warning h4 m-0"></i> -->
                    <i *ngIf="sign.signed" class="fas fa-check text-success h4 m-0"></i>
                    <i *ngIf="!sign.signed" class="fas fa-times text-danger h4 m-0"></i>

                  </td>

                  <td style="text-align: right">
                    <button (click)="getSignLink(sign)">
                      <i class="fas fa-eye"></i>
                    </button>
                  </td>

                </tr>
              </ng-container>

              <ng-container *ngIf="requested_signs.length == 0">
                <tr>
                  <td colspan="4" class="text-muted">Ainda não
                    há nenhuma assinatura pendente</td>
                </tr>
              </ng-container>


            </tbody>

          </table>
        </div>
      </div>
    </div>
  </ng-container>

    <ng-container *ngIf="is_approver">
      <div class="col-12 mb-4"></div>

      <div class="col-11">
        <app-dashboard-admin-assignors></app-dashboard-admin-assignors>
        <app-spacer [bottom]="24"></app-spacer>
      </div>
    </ng-container>

  </div>
</main>
