<div class="table-details-container" [style.paddingBottom.px]="paddingBottom"
    [class.custom]="custom">
    <ul class="app-table-details" [ngStyle]="getGrid()">
        <li *ngFor="let column of columns" class="app-table-details-header"  [style.text-align]="column.align ?? 'match-parent'">
            {{column.name}}</li>
    </ul>

    <ul *ngFor="let row of data; let i = index" class="app-table-details-body"
        [ngStyle]="getGrid()">
        <li *ngFor="let column of columnsToDisplay"
            [innerHtml]="column.cell(row)" class="app-table-details-body-row"  [style.text-align]="column.align ?? 'match-parent'"
            (click)="handleClick($event)" [ngStyle]="setPadding(size)">
        </li>
    </ul>
</div>
