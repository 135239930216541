import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { countriesOptions } from 'src/app/data/countries';
import { statesOptions } from 'src/app/data/states';
import { SignupService } from 'src/app/services/signup/signup.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import Utils, { GetHelper, UtilsValidators } from 'src/app/helpers/utils';
import { ToastService } from 'src/app/services/toast/toast.service';
import { distinctUntilChanged } from 'rxjs/operators';
import DDIOptions from 'src/app/data/ddi';
import { ApiService } from 'src/app/services/api/api.service';
import faker from '@faker-js/faker';
import { AuthService } from 'src/app/services/auth/auth.service';

type FormFields = {
  [key: string]: [any, ValidatorFn[]];
};

@Component({
  selector: 'app-signup-unified-company-complementary-representative',
  templateUrl:
    './signup-unified-company-complementary-representative.component.html',
  styleUrls: [
    './signup-unified-company-complementary-representative.component.scss',
  ],
})
export class SignupUnifiedCompanyComplementaryRepresentativeComponent
  implements OnInit
{
  DDIOptions = DDIOptions;

  user_form_fields: FormFields = {
    birth_date: [
      null,
      [Validators.required, Utils.validateMinDate, Utils.validateLegalAge],
    ],
    marital_status: [null, [Validators.required]],
    document_type: [null, [Validators.required]],
    nationality: [null, [Validators.required]],
    naturalness: [null, [Validators.required]],
    naturalness_city: [null, [Validators.required]],
    rg: [null, [Validators.required, Validators.pattern('')]],
    issuing_agency: [null, [Validators.required]],
    issuing_uf: [null, [Validators.required]],
    shipping_date: [
      null,
      [Validators.required, Utils.validateMaxMinToday, Utils.validateMinDate],
    ],
    celphone_ddi: ['55', []],
    celphone_number: [null, [Validators.required, UtilsValidators.celular]],

    email: [null, [Validators.required, Validators.email]],

    occupation: [null, []],

    zipcode: [null, [Validators.required, UtilsValidators.cep]],
    public_place: [null, [Validators.required]],
    number: [null, [Validators.required]],
    complement: [null, []],
    district: [null, [Validators.required]],
    city: [null, [Validators.required]],
    uf: [null, [Validators.required]],
    country: ['1', []],

    spouse_name: [null, []],
    spouse_document: [null, []],
    spouse_statute: [null, []],
  };

  representative_form_fields: FormFields = {
    document: [null, [Validators.required, UtilsValidators.cpf]],
    full_name: [null, [Validators.required]],

    indefinite_term: [null, []],
    term_validity: [
      null,
      [Validators.required, Utils.validateMaxMinToday, Utils.validateMinDate],
    ],
    max_term_validity: [
      null,
      [
        Validators.required,
        (control: FormControl) => Utils.validateMaxMinToday(control, false),
        Utils.validateMaxDate,
      ],
    ],

    pep: [false, []],
  };

  form_fields = {
    ...this.user_form_fields,
    ...this.representative_form_fields,
  };

  form = this.formBuilder.group(this.form_fields);

  showForm = true;

  columns: TableColumn<RepresentativeResponse>[] = [
    {
      name: 'Nome completo',
      cell: (row) => row.full_name,
    },
    {
      name: 'Documento',
      cell: (row) => this.formatCpf(row.cpf_cnpj),
    },
  ];

  data: RepresentativeResponse[] = [];

  selectedRepresentative: number | null = null;

  zipcodeFetched = false;
  representativeFetched = false;

  states = statesOptions;
  countries = countriesOptions;

  today = Utils.todayString();

  tomorrow = Utils.tomorrowString();

  isIndefinite = false;

  isForeign = false;

  addAnotherModal = false;

  warningRemoveModal = false;

  loadingContent = true;

  searchingZipcode = false;

  warningModal = false;
  removeModal = false;

  selectedIndex = 0;

  cities_options: SelectItem[] = [];

  loadingRepresentative = false;

  foundedRegister: Partial<Register> | null = null;

  sending = false;

  foundCep = false;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private utilsService: UtilsService,
    private maskPipe: MaskPipe,
    private toastService: ToastService,
    private api: ApiService,
    private toast: ToastService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getDataAndFillForm();

    this.form.controls.naturalness.valueChanges.subscribe((naturalness) => {
      if (naturalness !== null) {
        this.form.controls.naturalness_city.setValidators([
          Validators.required,
        ]);
        this.getCities(naturalness);
      } else {
        this.form.controls.naturalness_city.setValidators([]);
      }

      this.form.controls.naturalness_city.reset();
      this.form.controls.naturalness_city.updateValueAndValidity();
    });

    // this.form.controls.term_validity.valueChanges
    //   .pipe(distinctUntilChanged((a: any, b: any) => a === b))
    //   .subscribe((value) => {
    //     if (!value) {
    //       this.form.controls.term_validity.setValidators([]);
    //     } else {
    //       this.form.controls.term_validity.setValidators([
    //         Validators.required,
    //         Utils.validateMaxMinToday,
    //         Utils.validateMinDate,
    //       ]);
    //     }
    //     this.form.controls.term_validity.updateValueAndValidity();
    //   });

    // this.form.controls.max_term_validity.valueChanges
    //   .pipe(distinctUntilChanged((a: any, b: any) => a === b))
    //   .subscribe((value) => {
    //     if (!value) {
    //       this.form.controls.max_term_validity.setValidators([]);
    //     } else {
    //       this.form.controls.max_term_validity.setValidators([
    //         Validators.required,
    //         (control: FormControl) => Utils.validateMaxMinToday(control, false),
    //         Utils.validateMaxDate,
    //       ]);
    //     }
    //     this.form.controls.max_term_validity.updateValueAndValidity();
    //   });

    this.form.controls.indefinite_term.valueChanges.subscribe((values) => {
      this.isIndefinite = values;

      if (values) {
        this.form.controls.max_term_validity.setValue(null);
        this.form.controls.max_term_validity.setValidators([]);
      } else {
        this.form.controls.max_term_validity.setValidators([
          Validators.required,
          (control: FormControl) => Utils.validateMaxMinToday(control, false),
          Utils.validateMaxDate,
        ]);
      }

      this.form.controls.max_term_validity.updateValueAndValidity();
    });

    this.form.controls.nationality.valueChanges.subscribe(() => {
      if (this.form.controls.nationality.value !== '23') {
        this.form.controls.naturalness.setValue(null);
        this.form.controls.naturalness_city.setValue(null);

        this.form.controls.naturalness.setValidators([]);
        this.form.controls.naturalness_city.setValidators([]);
        this.isForeign = true;
      } else {
        this.isForeign = false;

        this.form.controls.naturalness.setValidators([Validators.required]);
        this.form.controls.naturalness_city.setValidators([
          Validators.required,
        ]);
      }

      this.form.controls.naturalness.updateValueAndValidity();
      this.form.controls.naturalness_city.updateValueAndValidity();
    });

    this.form.controls.marital_status.valueChanges.subscribe(() =>
      this.setMarried()
    );

    this.form.controls.birth_date.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        this.form.controls.shipping_date.setValidators([
          Validators.required,
          Utils.validateMaxMinToday,
          Utils.validateMinDate,
          (control: FormControl) => {
            if (!control.value || !value) return null;

            const birthDateArr = value.split('/');

            const birthDateDay = birthDateArr[0];
            const birthDateMonth = birthDateArr[1];
            const birthDateYear = birthDateArr[2];

            const birthDate = new Date(
              birthDateYear,
              birthDateMonth - 1,
              birthDateDay
            );

            const shipping_dateArr = control.value.split('/');

            const shippingDateDay = shipping_dateArr[0];
            const shippingDateMonth = shipping_dateArr[1];
            const shippingDateYear = shipping_dateArr[2];

            const shippingDate = new Date(
              shippingDateYear,
              shippingDateMonth - 1,
              shippingDateDay
            );

            if (shippingDate < birthDate) {
              return { minDate: true };
            }

            return null;
          },
        ]);

        this.form.controls.shipping_date.updateValueAndValidity();
      });

    this.form.controls.shipping_date.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe(() => {
        const value = this.form.controls.birth_date.value;

        this.form.controls.shipping_date.setValidators([
          Validators.required,
          Utils.validateMaxMinToday,
          Utils.validateMinDate,
          (control: FormControl) => {
            if (!control.value || !value) return null;

            const birthDateArr = value.split('/');

            const birthDateDay = birthDateArr[0];
            const birthDateMonth = birthDateArr[1];
            const birthDateYear = birthDateArr[2];

            const birthDate = new Date(
              birthDateYear,
              birthDateMonth - 1,
              birthDateDay
            );

            const shipping_dateArr = control.value.split('/');

            const shippingDateDay = shipping_dateArr[0];
            const shippingDateMonth = shipping_dateArr[1];
            const shippingDateYear = shipping_dateArr[2];

            const shippingDate = new Date(
              shippingDateYear,
              shippingDateMonth - 1,
              shippingDateDay
            );

            if (shippingDate < birthDate) {
              return { minDate: true };
            }

            return null;
          },
        ]);

        this.form.controls.shipping_date.updateValueAndValidity();
      });

    this.signupService.choicesFetchedEvent.subscribe(() => this.setMarried());

    this.signupService.fillFormEvent.subscribe(() => {
      const zipcode = fakerbr.address.zipCodeValidByState('SP');

      const formatted = this.maskPipe.transform(zipcode, '00000-000');

      const userDocument = this.maskPipe.transform(
        fakerbr.br.cpf(),
        '000.000.000-00'
      );

      const birthdate_date = new Date();
      birthdate_date.setFullYear(birthdate_date.getFullYear() - 25);

      if (this.foundedRegister === null) {
        this.form.patchValue({
          document: userDocument,
          full_name: fakerbr.name.findName(),

          birth_date: birthdate_date.toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          marital_status: String(fakerbr.random.number({ min: 1, max: 6 })),
          document_type: String(fakerbr.random.number({ min: 1, max: 2 })),
          rg: String(fakerbr.random.number({ min: 100000000, max: 999999999 })),

          issuing_agency: 'SSP - Secretaria de Segurança Pública',
          issuing_uf: 'SP',
          nationality: '23',
          naturalness: 26,
          naturalness_city: 4707,

          shipping_date: fakerbr.date.past(10).toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          celphone_ddi: '55',
          celphone_number: this.maskPipe.transform(
            '139' +
              String(fakerbr.random.number({ min: 11111111, max: 99999999 })),
            '(00) 00000-0000'
          ),

          email: fakerbr.internet.email(),
          occupation: fakerbr.name.jobDescriptor(),
          max_term_validity: fakerbr.date
            .future(10)
            .toLocaleDateString('pt-BR', {
              timeZone: 'UTC',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
          term_validity: fakerbr.date.past(2).toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          pep: false,

          zipcode: formatted,
          number: fakerbr.random.number({ min: 1, max: 1000 }),
          complement: 'Casa',
          country: '1',

          spouse_name: fakerbr.name.findName(undefined, undefined, 'female'),
          spouse_document: this.maskPipe.transform(
            fakerbr.br.cpf(),
            '000.000.000-00'
          ),
          spouse_statute: '1',
        });

        this.searchZipcode({
          target: { value: formatted },
        });

        this.zipcodeFetched = true;

        this.searchRepresentative(userDocument, true);
      } else
        this.form.patchValue({
          indefinite_term: false,
          email: fakerbr.internet.email(),
          max_term_validity: fakerbr.date
            .future(10)
            .toLocaleDateString('pt-BR', {
              timeZone: 'UTC',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
          term_validity: fakerbr.date.past(2).toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        });
      {
      }
    });
  }

  async getCities(state: number) {
    try {
      const res = await this.api.get({
        route: `api/registration/city`,
        params: { state },
        token: true,
      });

      this.cities_options = res.map((city: { name: string; id: number }) => ({
        label: city.name,
        value: city.id,
      }));
    } catch (error) {
      console.error(error);
      this.toastService.show('error', 'Erro', 'Erro ao recuperar cidades');
    }
  }

  setMarried() {
    if (
      this.form.controls.marital_status.value === '2' ||
      this.form.controls.marital_status.value === '3'
    ) {
      this.form.controls.spouse_name.setValidators([Validators.required]);
      this.form.controls.spouse_document.setValidators([
        Validators.required,
        UtilsValidators.cpf,
        (control: FormControl) => {
          const removeSpecialCharacters = control.value
            ? control.value.replace(/[^0-9]/g, '')
            : '';
          const userDocument = this.authService.user?.documents?.number.replace(
            /[^0-9]/g,
            ''
          );
          return userDocument === removeSpecialCharacters
            ? { invalid: true }
            : null;
        },
      ]);
      this.form.controls.spouse_statute.setValidators([Validators.required]);
    } else {
      this.form.controls.spouse_name.setValidators([]);
      this.form.controls.spouse_document.setValidators([]);
      this.form.controls.spouse_statute.setValidators([]);
    }

    this.form.controls.spouse_name.updateValueAndValidity();
    this.form.controls.spouse_document.updateValueAndValidity();
    this.form.controls.spouse_statute.updateValueAndValidity();
  }

  handleEditData(index: number) {
    const data = this.data[index];
    const person = data.representative_register.person;

    this.setUserFieldsValidation(false);
    this.foundedRegister = {
      uuid: data.representative_register.uuid,
    };

    this.selectedRepresentative = index;
    this.addAnotherModal = true;
    this.zipcodeFetched = true;
    this.representativeFetched = true;

    this.form.patchValue({
      full_name: data.full_name,
      document: this.maskPipe.transform(data.cpf_cnpj, '000.000.000-00'),

      email: person.email,
      term_validity: data.min_term_validity
        ? data.min_term_validity.split('-').reverse().join('/')
        : null,
      max_term_validity: data.max_term_validity
        ? data.max_term_validity.split('-').reverse().join('/')
        : null,
      indefinite_term: data.max_term_validity ? false : true,
    });

    if (
      String(person.marital_status) === '2' ||
      String(person.marital_status) === '3'
    ) {
      this.form.patchValue({
        spouse_name: person.spouse.name,
        spouse_document: this.maskPipe.transform(
          person.spouse.document,
          '000.000.000-00'
        ),
        spouse_statute: String(person.spouse.marriage_bond),
      });
    }
  }

  async handleRemoveData(index: number) {
    const { data } = await this.api.get<ApiResponse<any[]>>({
      route: 'api/registration/signature_group/',
      token: true,
    });

    let haveRepresent = false;

    data.forEach((item) => {
      item.integrants.forEach((integrant: any) => {
        if (integrant.id === this.data[index].id) {
          this.warningRemoveModal = true;
          haveRepresent = true;
        }
      });
    });

    if (haveRepresent) return;

    this.removeModal = true;
    this.selectedIndex = index;
  }

  handleRemoveCancel() {
    this.removeModal = false;
    this.selectedIndex = 0;
  }

  async removeData(index: number) {
    try {
      const findedRepresentative = this.data[index];

      await this.api.delete<ApiResponse<null>>({
        route: `api/registration/representative/${findedRepresentative.id}/`,
        token: true,
      });

      this.handleRemoveCancel();

      this.getDataAndFillForm();
    } catch (error) {
      this.toastService.show('error', 'Erro', error.error.message);
    }
  }

  getDisabled() {
    if (this.addAnotherModal) {
      return this.form.invalid;
    }

    if (this.showForm) {
      return this.form.invalid;
    }

    return false;
  }

  async triggerForm() {
    if (this.showForm) {
      await this.sendData();
    } else {
      if (!this.addAnotherModal) {
        this.addAnotherModal = true;
      } else {
        await this.sendData();
      }
    }
  }

  setUserFieldsValidation(required: boolean) {
    Object.entries(this.user_form_fields).forEach(
      ([key, [value, validator]]) => {
        if (required) {
          this.form.get(key).setValidators(validator);
          // this.form.get(key).setValue(value);
        } else {
          this.form.get(key).setValidators([]);
          this.form.get(key).setValue(null);
        }

        // update value and validity
        this.form.get(key).updateValueAndValidity();
      }
    );
  }

  async searchRepresentative(event: any, simplified: boolean = false) {
    this.loadingRepresentative = true;

    const document = simplified
      ? Utils.onlyNumbers(event)
      : Utils.onlyNumbers(event.target.value);

    if (document.length === 11) {
      try {
        if (this.data.some((item) => item.cpf_cnpj === document)) {
          this.toast.show('error', 'Atenção', 'Representante já adicionado');
          throw new Error('Representante já adicionado');
        }

        const { data } = await this.api.get<ApiResponse<Register | null>>({
          route: `api/registration/search/representative`,
          params: { query: document },
          token: true,
        });

        if (data) {
          this.foundedRegister = data;
          this.toast.show(
            'info',
            'Atenção',
            'Representante já cadastrado, cadastre os dados abaixo para prosseguir'
          );

          this.form.patchValue({
            full_name: data.person.full_name,
          });

          this.setUserFieldsValidation(false);
        } else {
          this.foundedRegister = null;

          this.setUserFieldsValidation(true);
        }

        this.representativeFetched = true;
      } catch (error) {
        this.representativeFetched = false;
        console.error(error);
      }
    } else {
      this.form.reset();
      this.form.patchValue({
        document: simplified ? event : event.target.value,
      });
      this.representativeFetched = false;
    }
    this.loadingRepresentative = false;
  }

  async searchZipcode(event: any) {
    this.searchingZipcode = true;

    const zipcode = event.target.value;

    try {
      const zipcodeRes = await this.utilsService.getZipcode(zipcode);

      if (zipcodeRes) {
        this.form.patchValue({
          public_place: zipcodeRes.logradouro,
          district: zipcodeRes.bairro,
          city: zipcodeRes.localidade,
          uf: zipcodeRes.uf,
          country: '1',
        });
        this.foundCep = true;

        this.zipcodeFetched = true;
      } else {
        this.foundCep = false;

        this.zipcodeFetched = zipcode.length === 9;
        Utils.resetZipcodeFields(this.form);
      }
    } catch (error) {
      this.foundCep = false;

      this.zipcodeFetched = false;
      console.error(error);
    }

    this.searchingZipcode = false;
  }

  getChoices() {
    return this.signupService.choices();
  }

  handleCloseModal() {
    this.addAnotherModal = false;
    setTimeout(() => {
      this.form.reset();
      this.selectedRepresentative = null;
      this.foundedRegister = null;
      this.zipcodeFetched = false;
    }, 500);
  }

  clearForm() {
    this.form.reset();
    this.selectedRepresentative = null;
    this.foundedRegister = null;
    this.zipcodeFetched = false;
    this.representativeFetched = false;
  }

  handleWarningModal() {
    this.warningModal = false;
    this.warningRemoveModal = false;
  }

  renderTitle() {
    let title = 'Adicionar representante';

    if (this.selectedRepresentative !== null) {
      title = `Editar representante ${
        this.data[this.selectedRepresentative].full_name
      }`;
    }

    return title;
  }

  async getDataAndFillForm() {
    this.loadingContent = true;
    try {
      const { data } = await this.api.get<
        ApiResponse<RepresentativeResponse[]>
      >({
        route: 'api/registration/representative/',
        token: true,
      });

      this.data = data;

      this.showForm = !(this.data.length > 0);
      this.representativeFetched = false;

      this.signupService.setCompanyFormStatus(
        'complementaryRepresentatives',
        this.data.length > 0
      );
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
    this.loadingContent = false;
  }

  async sendData() {
    this.sending = true;
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        representative: {
          cpf_cnpj: Utils.onlyNumbers(valuesHelper.get('document')),
          full_name: valuesHelper.get('full_name'),

          can_sign: true,

          expiration_date: valuesHelper.get('max_term_validity')
            ? valuesHelper
                .get('max_term_validity')
                .split('/')
                .reverse()
                .join('-')
            : null,

          pep: valuesHelper.get('pep'),

          max_term_validity: valuesHelper.get('max_term_validity')
            ? valuesHelper
                .get('max_term_validity')
                .split('/')
                .reverse()
                .join('-')
            : null,
          min_term_validity: valuesHelper.get('term_validity')
            ? valuesHelper.get('term_validity').split('/').reverse().join('-')
            : null,
        },
      };

      if (this.foundedRegister === null) {
        const names = valuesHelper.get('full_name').split(' ');
        const [first_name, ...last_names] = names;
        const last_name = last_names.join(' ');

        const roles = this.signupService.getRoles();

        const role = roles.find((item) => item.slug === 'REPRESENTATIVE-PF-PF');

        const uuid = faker.datatype.uuid();

        payload['user'] = {
          username: Utils.onlyNumbers(valuesHelper.get('document')),
          first_name: first_name,
          last_name: last_name,
          type: 'PF',
          email: valuesHelper.get('email'),
          nationality: Number(valuesHelper.get('nationality')),
          password: uuid,
          phone: {
            type: 'phone',
            code: Number(valuesHelper.get('celphone_ddi')),
            number: Utils.onlyNumbers(valuesHelper.get('celphone_number')),
            branch: 0,
          },
          documents: {
            type: 'CPF',
            number: Utils.onlyNumbers(valuesHelper.get('document')),
          },
        };

        payload['register'] = {
          type: 'PF',
          role: role.id,
          address: {
            cep: valuesHelper.get('zipcode'),
            public_place: valuesHelper.get('public_place'),
            number: Number(valuesHelper.get('number')),
            complement: valuesHelper.get('complement'),
            district: valuesHelper.get('district'),
            city: valuesHelper.get('city'),
            uf: valuesHelper.get('uf'),
            country: 1,
            // country: Number(valuesHelper.get('country')),
            type: 1,
            address_type: 'representative',
          },
          person: {
            email: valuesHelper.get('email'),
            full_name: valuesHelper.get('full_name'),
            birth_date: valuesHelper
              .get('birth_date')
              .split('/')
              .reverse()
              .join('-'),
            marital_status: valuesHelper.get('marital_status'),
            naturalness: valuesHelper.get('nationality'),
            naturalness_state: valuesHelper.get('naturalness'),
            naturalness_city: valuesHelper.get('naturalness_city'),

            occupation: valuesHelper.get('occupation'),

            cellphone: {
              type: 'phone',
              code: Number(valuesHelper.get('celphone_ddi')),
              number: Utils.onlyNumbers(valuesHelper.get('celphone_number')),
              branch: 0,
            },

            document: {
              type: 'CPF',
              number: Utils.onlyNumbers(valuesHelper.get('document')),
            },
            secondary_document: {
              number: Utils.onlyNumbers(valuesHelper.get('rg')),
              type: valuesHelper.get('document_type'),
              dispatching_agency: valuesHelper.get('issuing_agency'),
              uf: valuesHelper.get('issuing_uf'),
              expedition_date: valuesHelper
                .get('shipping_date')
                .split('/')
                .reverse()
                .join('-'),
            },
          },
        };

        const marital_status = valuesHelper.get('marital_status');

        if (marital_status === '2' || marital_status === '3') {
          payload['register']['person']['spouse'] = {
            name: valuesHelper.get('spouse_name'),
            document: Utils.onlyNumbers(valuesHelper.get('spouse_document')),
            marriage_bond: valuesHelper.get('spouse_statute'),
          };
        }
      } else {
        payload['registered'] = this.foundedRegister.uuid;
      }

      const response =
        this.selectedRepresentative !== null
          ? await this.api.put<ApiResponse<RepresentativeResponse>>({
              route: `api/registration/representative/${
                this.data[this.selectedRepresentative].id
              }/`,
              token: true,
              body: payload,
            })
          : await this.api.post<ApiResponse<RepresentativeResponse>>({
              route: 'api/registration/representative/',
              token: true,
              body: payload,
            });

      this.toast.show('info', 'Sucesso', response.message);

      this.form.reset();
      this.addAnotherModal = false;
      this.foundedRegister = null;

      this.getDataAndFillForm();
    } catch (error) {
      console.error(error);
      if (error.error.message.includes('username')) {
        this.toast.show('info', 'Erro', 'Esse usuário já está em uso.');
      } else {
        this.toast.show('info', 'Erro', error.error.message);
      }
    }
    this.sending = false;
  }

  formatCpf(document: string) {
    return this.maskPipe.transform(document, '000.000.000-00');
  }
}
