import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';
import { FundService } from 'src/app/services/fund/fund.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-organization',
  templateUrl: './dashboard-organization.component.html',
  styleUrls: ['./dashboard-organization.component.scss'],
})
export class DashboardOrganizationComponent implements OnInit {
  @ViewChild('form') form: ElementRef<HTMLDivElement> | undefined;

  loading = true;

  today = Utils.todayString();

  funds: any[] = [];
  filteredFunds: any[] = [];

  anbimaChoices: SelectItem[] = [];

  applicableRole = 'ADM';

  filters: number = 0;
  filtered = false;

  perPageValue = 50;

  selectItems: SelectItem[] = [
    { label: '20 por página', value: '2' },
    { label: '30 por página', value: '3' },
    { label: '50 por página', value: '5' },
  ];

  perPageForm = this.formBuilder.group({
    perPage: ['20', [Validators.required]],
  });

  filterForm = this.formBuilder.group({
    search: [null],
    roles: [null],
    created_from: [null],
    created_to: [null],
    updated_from: [null],
    updated_to: [null],
  });

  showFilterModal = false;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    public signupService: SignupService,
    private authService: AuthService,
    private fundService: FundService,
    private toast: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.authService.user.actived_register) {
      const role =
        this.authService.user.actived_register.register.role.slug.split('-');
      this.applicableRole = role[0];
    } else {
      this.applicableRole = 'ADM';
    }

    this.getData();

    this.filterForm.controls.created_from.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const date = new Date(values);
        if (date.toString() === 'Invalid Date') {
          this.filterForm.controls.created_from.setValue(null);
          this.filterForm.controls.created_from.setValidators([]);
        } else {
          this.filterForm.controls.created_from.setValidators([
            Validators.required,
            (control: FormControl) => {
              const selectedDate = new Date(control.value);
              selectedDate.setHours(selectedDate.getHours() + 3);
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return selectedDate <= today ? null : { max: true };
            },
          ]);
        }

        this.filterForm.controls.created_from.updateValueAndValidity();
      });

    this.filterForm.controls.updated_from.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const date = new Date(values);
        if (date.toString() === 'Invalid Date') {
          this.filterForm.controls.updated_from.setValue(null);
          this.filterForm.controls.updated_from.setValidators([]);
        } else {
          this.filterForm.controls.updated_from.setValidators([
            Validators.required,
            (control: FormControl) => {
              const selectedDate = new Date(control.value);
              selectedDate.setHours(selectedDate.getHours() + 3);
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return selectedDate <= today ? null : { max: true };
            },
          ]);
        }

        this.filterForm.controls.updated_from.updateValueAndValidity();
      });

    this.filterForm.controls.created_to.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const date = new Date(values);
        if (date.toString() === 'Invalid Date') {
          this.filterForm.controls.created_to.setValue(null);
          this.filterForm.controls.created_to.setValidators([]);
        } else {
          this.filterForm.controls.created_to.setValidators([
            Validators.required,
            (control: FormControl) => {
              const selectedDate = new Date(control.value);
              selectedDate.setHours(selectedDate.getHours() + 3);
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return selectedDate >= today ? null : { min: true };
            },
          ]);
        }

        this.filterForm.controls.created_to.updateValueAndValidity();
      });

    this.filterForm.controls.updated_to.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const date = new Date(values);
        if (date.toString() === 'Invalid Date') {
          this.filterForm.controls.updated_to.setValue(null);
          this.filterForm.controls.updated_to.setValidators([]);
        } else {
          this.filterForm.controls.updated_to.setValidators([
            Validators.required,
            (control: FormControl) => {
              const selectedDate = new Date(control.value);
              selectedDate.setHours(selectedDate.getHours() + 3);
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return selectedDate >= today ? null : { min: true };
            },
          ]);
        }

        this.filterForm.controls.updated_from.updateValueAndValidity();
      });
  }

  setFilteredData(data: any[]) {
    this.filteredFunds = data;

    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  getStatus(item: UserApprovalResponse) {
    const register = item.fund;

    if (register.approval[0].completed) {
      if (register.approval[0].is_approved) {
        return 'Aprovado internamente';
      } else {
        return 'Fundo rejeitado';
      }
    } else {
      if (register.approval[0].current_department !== null) {
        return register.approval[0].current_department.type === 'department'
          ? register.approval[0].current_department.department.name
          : register.approval[0].current_department.agent.name;
      } else if (register.approval[0].step === 0) {
        return 'Edição';
      } else {
        return 'Departamento não definido';
      }
    }
  }

  getStatusInfo(item: UserApprovalResponse) {
    const register = item.fund;

    if (register.approval[0].completed) {
      if (register.approval[0].is_approved) {
        return 'Integração ao fundo';
      } else {
        if (register.approval[0].current_department !== null) {
          return register.approval[0].current_department.type === 'department'
            ? register.approval[0].current_department.department.name
            : register.approval[0].current_department.agent.name;
        } else if (register.approval[0].step === 0) {
          return 'Edição';
        } else {
          return 'Departamento não definido';
        }
      }
    } else if (register.approval[0].step === 0) {
      return 'Aguardando representantes';
    } else {
      return `Aguardando aprovação (${register.approval[0].step}/${register.approval[0].queue.departments.length})`;
    }
  }

  getFundInitials(approval: any) {
    return approval.fund.name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  formatDocument(document: any) {
    if (document) {
      if (document.type === 'CNPJ') {
        return document.number.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          '$1.$2.$3/$4-$5'
        );
      } else {
        return document.number.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
          '$1.$2.$3-$4'
        );
      }
    } else {
      return '';
    }
  }

  resetForm() {
    this.filterForm.reset();
    this.filterForm.controls.updated_from.setValidators([]);
    this.filterForm.controls.updated_to.setValidators([]);
    this.filterForm.controls.created_from.setValidators([]);
    this.filterForm.controls.created_to.setValidators([]);

    this.filters = 0;
  }

  async filterUsers() {
    this.filters = 0;

    try {
      const values = this.filterForm.value;

      this.filtered = Object.entries(values).some(([key, value]: any) => {
        return value !== null && value !== '';
      });

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          filters[key] = value;
        }
      });

      const { data } = await this.api.get<ApiResponse<UserApprovalResponse[]>>({
        route: 'api/approvals/user_approval/',
        token: true,
        params: filters,
      });

      this.filteredFunds = data;

      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de cedente.'
      );
    }
  }

  async getData() {
    this.loading = true;
    await Promise.all([this.getFunds(), this.getChoices()]);
  }

  async getChoices() {
    try {
      const res = await this.api.get({
        route: 'api/registration/new/fund-choices',
        token: true,
      });

      this.anbimaChoices = res.ANBIMA_CLASSIFICATION_CHOICES;
    } catch (error) {
      console.error(error);
    }
  }

  async getFunds() {
    try {
      const res = await this.api.get({
        route: 'api/approvals/user_approval/',
        params: {
          type: 'fund',
        },
        token: true,
      });

      this.funds = res.data.sort((a: any, b: any) => {
        const aDate = new Date(a.updated_at);
        const bDate = new Date(b.updated_at);

        return bDate.getTime() - aDate.getTime();
      });

      const funds = res.data.map((approval: any) => {
        return {
          label: approval.fund.name,
          value: approval.fund.id,
        };
      });

      this.fundService.fundList = funds;
      this.fundService.onChange.emit();
    } catch (error) {
      console.error(error);
    }
  }

  async removeFund(id: number) {
    await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, apague!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.api
          .delete({
            route: 'api/dashboard/fund',
            token: true,
            params: {
              id,
            },
          })
          .then((res) => {
            this.funds = this.funds.filter(
              (approval) => approval.fund.id !== id
            );

            Swal.fire({
              title: 'Apagado!',
              text: 'Fundo apagado com sucesso',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            });
          })
          .catch((error) => {
            Swal.fire({
              title: 'Erro!',
              text: 'Erro ao apagar fundo',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            });
          });
      },
    });
  }

  redirectToNewFund() {
    this.router.navigate(['/app/products/fund/new']);
  }
}
