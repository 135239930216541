import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class DropzoneComponent implements OnInit {
  @Output() filesChange = new EventEmitter<FilePicked[]>();

  @Input() clearAttachments?: boolean = false;

  @Input() description: string =
    'O arquivo deve estar no formato PDF, JPG, JPEG, PNG, XLS ou XLSX. (1MB)';

  _files: FilePicked[] = [];

  set files(files: FilePicked[]) {
    this._files = files;
    this.filesChange.emit(this._files);
  }

  get files() {
    return this._files;
  }

  maxFileSize = 1024 * 1024 * 5;

  constructor(private toast: ToastService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.clearAttachments) {
      this.onRemove(this.files[0], new MouseEvent('click'));
    }
  }

  getFileUrl(file: File, callback: (url: string) => void) {
    const fileReader = new FileReader();

    fileReader.onload = (e: any) => {
      callback(e.target.result);
    };

    fileReader.readAsDataURL(file);
  }

  onSelect(event: any) {
    if (this.files.length === 0) {
      event.addedFiles.forEach((file: File) => {
        if (file.name.includes('.pdf')) {
          const newFiles = [
            ...this.files,
            { file, url: 'assets/images/pdf.png' },
          ];
          this.files = newFiles;
        } else if (file.name.includes('.xls') || file.name.includes('.xlsx')) {
          const newFiles = [
            ...this.files,
            { file, url: 'assets/images/xls.png' },
          ];
          this.files = newFiles;
        } else {
          this.getFileUrl(file, (url) => {
            const newFiles = [...this.files, { file, url }];

            this.files = newFiles;
          });
        }
      });
    } else {
      this.toast.show(
        'error',
        'Erro',
        'Só é possível anexar um arquivo por vez'
      );
    }

    event.rejectedFiles.forEach((file: File) => {
      if (file.size > this.maxFileSize) {
        this.toast.show('error', 'Erro', 'O arquivo deve ter no máximo 5MB');
      }
    });
  }

  onRemove(file: FilePicked, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (file) {
      const newFiles = [...this.files];

      newFiles.splice(this.files.indexOf(file), 1);

      this.files = newFiles;
    }
  }
}
