<form [formGroup]="form">
    <div class="row">
        <div class="col-12 col-lg-9">
            <app-select [items]="options.admin" label="Administrador"
                [group]="form" controlName="admin">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-9">
            <app-select [items]="options.custodian" label="Custodiante"
                [group]="form" controlName="custodian">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-9">
            <app-select [items]="options.manager" label="Gestor" [group]="form"
                controlName="manager">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-9">
            <app-select [items]="options.controller" label="Controlador"
                [group]="form" controlName="controller">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-9">
            <app-select [items]="options.distribuition"
                label="Distribuidor / Coordenador Líder" [group]="form"
                controlName="coordinator">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-9">
            <app-select [items]="options.agent" label="Agente de cobrança"
                [group]="form" controlName="agent">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <!-- <div class="col-12 col-lg-3">
            <app-spacer [top]="40"></app-spacer>
            <app-switch [group]="form" controlName="agent"
                label="Destituído"></app-switch>
        </div> -->

        <div class="col-12 col-lg-9">
            <app-select [items]="options.consultant" label="Consultor legal"
                [group]="form" controlName="consultant">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
        <!-- 
        <div class="col-12 col-lg-3">
            <app-spacer [top]="40"></app-spacer>
            <app-switch [group]="form" controlName="coordinator"
                label="Destituído">
            </app-switch>
        </div> -->
    </div>

    <app-spacer [top]="24"></app-spacer>

    <ng-container *ngIf="showForm">
        <app-label label="Adicionar outro agente">
        </app-label>

        <div class="row">
            <div class="col-12 col-lg-3">
                <app-select [items]="availableAgents" label="Agente"
                    [group]="newAgentForm" controlName="function">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
            <div class="col-12 col-lg-6">
                <app-select [items]="selectedOptions"
                    label="Prestador homologado" [group]="newAgentForm"
                    controlName="function_user">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>
    </ng-container>

    <div class="row">
        <div class="col-12">
            <app-button [inverted]="true" (onClick)="cancelAddAgent()"
                class="mr-3" *ngIf="showForm">
                Cancelar
            </app-button>

            <app-button [inverted]="true" (onClick)="handleAddAgent()"
                [disabled]="!newAgentForm.valid && showForm">
                Adicionar agente
            </app-button>

            <app-spacer [bottom]="48"></app-spacer>
        </div>
    </div>

    <div class="row">
        <ng-container *ngIf="additionalProvidersArr.length > 0">
            <div class="col-9 d-flex flex-row justify-content-between">
                <table>
                    <thead>
                        <th>Agente</th>
                        <th>Prestador</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of additionalProvidersArr; let i = index">
                            <td>{{formatAgentRole(item.service)}}
                            </td>
                            <td>{{formatAgentName(item)}}
                            </td>
                            <td>
                                <div class="dash-card-item-buttons">
                                    <button class="dash-item-button"
                                        (click)="removeAgent(i)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</form>