import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService, FundValues } from 'src/app/services/fund/fund.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-fund-approval',
  templateUrl: './dashboard-fund-approval-register.component.html',
  styleUrls: ['./dashboard-fund-approval-register.component.scss'],
})
export class DashboardFundApprovalRegisterComponent implements OnInit {
  tabs: Tab[] = [
    { label: 'Dados básicos', value: 'dados-basicos' },
    { label: 'Dados complementares', value: 'dados-complementares' },
    { label: 'Prestadores de serviço', value: 'prestadores-de-servico' },
    { label: 'Taxas', value: 'taxas' },
    { label: 'Bancário', value: 'bancario' },
  ];

  tabIndex = 0;
  activedTab = 'dados-basicos';

  fund: FundValues = undefined;
  loading = false;
  isEditing = false;

  constructor(
    private route: ActivatedRoute,
    private toast: ToastService,
    private api: ApiService,
    protected sanitizer: DomSanitizer,
    public fundService: FundService,
    public router: Router
  ) {}

  ngOnDestroy(): void {
    this.fundService.reset();
  }

  ngOnInit(): void {
    this.isEditing = this.route.snapshot.params.id;
    this.getData();
    this.tabIndex = history.state.step ?? 0;

    this.activedTab = this.tabs[this.tabIndex].value;
  }

  async getFund() {
    try {
      const { data } = await this.api.get({
        route: `api/registration/new/fund/${this.route.snapshot.params.id}/`,
        token: true,
      });

      const fund = { ...data };

      this.fund = fund;
      this.fundService.data = fund;
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao carregar dados: ' + error);
    }
  }

  async getChoices() {
    try {
      const res = await this.api.get({
        route: 'api/registration/fund',
        token: true,
      });

      const choices: { [Key in any]: any } = {};

      Object.entries(res.choices_data).forEach(([key, value]: any) => {
        choices[key] = value.map((item: any) => {
          return {
            label: String(item[1]),
            value: String(item[0]),
          };
        });
      });

      const filterFunctions = [4, 6, 7, 8, 9, 10];

      choices.service_provider_choices =
        choices.service_provider_choices.filter((service_provider: any) =>
          filterFunctions.includes(Number(service_provider.value))
        );

      this.fundService.choices = choices;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao carregar dados');
    }
  }

  async getData() {
    this.loading = true;
    if (this.route.snapshot.params.id) {
      await this.getFund();
    }
    await this.getChoices();

    this.loading = false;
  }

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice: any) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  onChangeTab(index: number) {
    this.activedTab = this.tabs[index].value;
    this.tabIndex = index;
  }

  getInitials(name: any) {
    const arr = name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ');

    const firstNameChar = arr[0][0];
    const lastNameChar = arr[arr.length - 1][0];

    return firstNameChar + lastNameChar;
  }

  redirect(url: string) {
    this.router.navigate([url]);
  }

  canProceed() {
    const basicStatus = this.fundService.getFundFormStatus('basic');
    const complementaryStatus =
      this.fundService.getFundFormStatus('complementary');
    const taxStatus = this.fundService.getFundFormStatus('tax');
    const bankingStatus = this.fundService.getFundFormStatus('banking');
    const providersStatus = this.fundService.getFundFormStatus('providers');

    const status =
      basicStatus &&
      complementaryStatus &&
      taxStatus &&
      bankingStatus &&
      providersStatus;

    return !status;
  }

  async proceed() {
    if (this.canProceed()) {
      this.toast.show(
        'error',
        'Erro!',
        'Preencha todos os campos obrigatórios'
      );
      return;
    }

    let data = this.fundService.getFundFormData();

    const payload = {
      ...data,
      zeroing_process: data.client_service.zeroing_process,
      transfer_date: data.transfer ? data.transfer_date : null,
      assembly_date: data.transfer ? data.assembly_date : null,
      last_company_document: data.transfer ? data.last_company_document : null,
      last_company_name: data.transfer ? data.last_company_name : null,
      last_company_contact: data.transfer ? data.last_company_contact : null,
      last_company_email: data.transfer ? data.last_company_email : null,
      last_company_telephone_ddi: data.transfer
        ? data.last_company_telephone_ddi
        : null,
      last_company_telephone_number: data.transfer
        ? data.last_company_telephone_number
        : null,
      last_company_cellphone_ddi: data.transfer
        ? data.last_company_cellphone_ddi
        : null,
      last_company_cellphone_number: data.transfer
        ? data.last_company_cellphone_number
        : null,
    };

    try {
      const res = this.fund
        ? await this.api.patch({
            route: `api/registration/new/fund/` + this.fund.id + `/`,
            token: true,
            body: {
              ...payload,
              transfer_date: payload.transfer_date
                ? Utils.convertBRdateToUSdate(payload.transfer_date)
                : null,
              assembly_date: payload.assembly_date
                ? Utils.convertBRdateToUSdate(payload.assembly_date)
                : null,
              cbm_constitution_date: payload.cbm_constitution_date
                ? Utils.convertBRdateToUSdate(payload.cbm_constitution_date)
                : null,
              duration_period: payload.duration_period
                ? Utils.convertBRdateToUSdate(payload.duration_period)
                : null,
              structuring_fee_date: payload.structuring_fee_date
                ? Utils.convertBRdateToUSdate(payload.structuring_fee_date)
                : null,
            },
          })
        : await this.api.post({
            route: 'api/registration/new/fund/',
            token: true,
            body: {
              ...payload,
              transfer_date: payload.transfer_date
                ? Utils.convertBRdateToUSdate(payload.transfer_date)
                : null,
              assembly_date: payload.assembly_date
                ? Utils.convertBRdateToUSdate(payload.assembly_date)
                : null,
              cbm_constitution_date: payload.cbm_constitution_date
                ? Utils.convertBRdateToUSdate(payload.cbm_constitution_date)
                : null,
              duration_period: payload.duration_period
                ? Utils.convertBRdateToUSdate(payload.duration_period)
                : null,
              structuring_fee_date: payload.structuring_fee_date
                ? Utils.convertBRdateToUSdate(payload.structuring_fee_date)
                : null,
            },
          });

      this.toast.show(
        'info',
        'Sucesso!',
        `Fundo ${this.fund ? 'atualizado' : 'adicionado'} com sucesso`
      );
      this.router.navigate(['/app/products']);
    } catch (error) {
      this.toast.show(
        'error',
        'Erro!',
        `Erro ao ${this.fund ? 'atualizar' : 'adicionar'} fundo`
      );
    }
  }
}
