<ng-container *ngIf="type == 'admin'">
    <app-dashboard-admin></app-dashboard-admin>
</ng-container>

<ng-container *ngIf="type != 'admin'">
    <app-dashboard-provider></app-dashboard-provider>
</ng-container>

<ng-container *ngIf="type == ''">
  <ngx-skeleton-loader [count]="5" appearance="circle">
  </ngx-skeleton-loader>
</ng-container>
