import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() data: any[] = [];
  filteredData = this.data;
  @Input() show: boolean = true;

  @Input() dashPagination: boolean = false;

  @Input() perPage = 5;
  currentPage = 1;
  pages = [1];

  @Output() onPageChange = new EventEmitter<number>();
  @Output() onDataChange = new EventEmitter<any[]>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      if (changes.data.currentValue !== changes.data.previousValue) {
        setTimeout(() => {
          this.paginateData();
        }, 1000);
      }
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.paginateData();
    }, 1000);
  }

  paginateData() {
    const filtered = this.data.filter(
      (_, i) =>
        i >= this.perPage * (this.currentPage - 1) &&
        i < this.perPage * this.currentPage
    );

    const pageNumber = Math.ceil(this.data.length / this.perPage);

    const pages = [];

    for (let i = 1; i <= pageNumber; i++) {
      pages.push(i);
    }

    this.pages = pages;
    this.filteredData = filtered;

    this.onDataChange.emit(filtered);
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;

      this.paginateData();
    }
  }

  nextPage() {
    if (this.currentPage < this.pages.length) {
      this.currentPage++;

      this.paginateData();
    }
  }

  changePage(page: number) {
    this.currentPage = page;

    this.paginateData();

    this.onPageChange.emit(page);
  }

  getPerPageNum() {
    if (this.data.length < this.perPage) {
      return this.data.length;
    } else {
      const num = this.perPage * this.currentPage;

      if (num > this.data.length) {
        return this.data.length;
      }

      return num;
    }
  }
}
