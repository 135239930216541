import { ToastService } from './../../../../services/toast/toast.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { SignupService } from 'src/app/services/signup/signup.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import { distinctUntilChanged } from 'rxjs/operators';
import Utils, { GetHelper, UtilsValidators } from 'src/app/helpers/utils';

@Component({
  selector: 'app-signup-unified-company-complementary-economic',
  templateUrl: './signup-unified-company-complementary-economic.component.html',
  styleUrls: ['./signup-unified-company-complementary-economic.component.scss'],
})
export class SignupUnifiedCompanyComplementaryEconomicComponent
  implements OnInit, OnDestroy
{
  form = this.formBuilder.group({
    cnpj: [null, [UtilsValidators.cnpj]],
    corporate_name: [null, []],
  });

  showForm = true;

  columns: TableColumn<EconomicGroupResponse>[] = [
    { name: 'CNPJ', cell: (row) => this.formatCnpj(row.cnpj) },
    { name: 'Razão Social', cell: (row) => row.corporate_name.toUpperCase() },
  ];

  data: EconomicGroupResponse[] = [];

  addAnotherModal = false;
  removeModal = false;

  selectedIndex = null;

  isDataValid: boolean = false;

  loading = false;

  sending = false;

  event: any;

  errorText;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private maskPipe: MaskPipe,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.getDataAndFillForm();

    this.event = this.signupService.sendComplementaryCompanyData.subscribe(
      (value) => {
        if (value === 'complementaryEconomic') {
          this.signupService.setComplementaryCompanyStep(
            'complementaryRepresentatives'
          );
          this.signupService.changeCompanyStepEvent.emit('representatives');
        }
      }
    );

    this.signupService.fillFormEvent.subscribe(() => {
      this.form.patchValue({
        cnpj: this.maskPipe.transform(fakerbr.br.cnpj(), '00.000.000/0000-00'),
        corporate_name: fakerbr.company.companyName(),
      });
    });

    this.form.controls.cnpj.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        this.errorText = 'Documento inválido';

        if (!value) {
          this.form.controls.cnpj.setValue(null);
        } else {
          if (value.length === 18) {
            const alreadyExists = this.data.find(
              (item) => item.cnpj === Utils.onlyNumbers(value)
            );

            const sameDocument =
              this.data[this.selectedIndex]?.cnpj === Utils.onlyNumbers(value);

            if (sameDocument) return;

            if (alreadyExists) {
              this.form.controls.cnpj.setErrors({ alreadyExists: true });
              this.errorText = 'Documento já cadastrado';
            }
          }
        }
      });

    this.form.controls.corporate_name.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (!value) {
          this.form.controls.corporate_name.setValue(null);
        }
      });

    this.form.valueChanges.subscribe((values) => {
      this.signupService.setCompanyFormData(
        'complementaryEconomicForm',
        values
      );
    });
  }

  getDisabled() {
    if (!this.showForm && !this.addAnotherModal) {
      return false;
    }

    const { cnpj, corporate_name } = this.form.controls;

    if (cnpj.value !== null && corporate_name.value !== null) {
      return this.form.invalid;
    } else {
      return true;
    }
  }

  async triggerForm() {
    if (this.showForm) {
      await this.sendData();
      this.showForm = false;
      this.addAnotherModal = false;
    } else {
      if (!this.addAnotherModal) {
        this.addAnotherModal = true;
      } else {
        await this.sendData();
        this.addAnotherModal = false;
      }
    }
  }

  renderTitle() {
    let title = 'Adicionar grupo econômico';

    if (this.selectedIndex !== null) {
      title = `Editar grupo econômico - ${
        this.data[this.selectedIndex].corporate_name
      }`;
    }

    return title;
  }

  handleCloseAddAnotherModal() {
    this.addAnotherModal = false;
    this.selectedIndex = null;
    this.form.reset();
  }

  handleOpenRemoveModal(index: number) {
    this.removeModal = true;
    this.selectedIndex = index;
  }

  handleEditItem(index: number) {
    this.triggerForm();
    this.selectedIndex = index;
    this.form.patchValue({
      corporate_name: this.data[index].corporate_name.toUpperCase(),
      cnpj: this.maskPipe.transform(
        this.data[index].cnpj,
        '00.000.000/0000-00'
      ),
    });
  }

  handleRemoveCancel() {
    this.removeModal = false;
    this.selectedIndex = null;
  }

  async removeEconomic(index: number) {
    try {
      const findedEconomic = this.data[index];

      const { message } = await this.api.delete({
        route: `api/registration/economic_group/${findedEconomic.id}/`,
        token: true,
      });

      this.removeModal = false;
      this.selectedIndex = null;

      this.toast.show('info', 'Sucesso', message);

      this.getDataAndFillForm();
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', error.error.message);
    }

    this.isDataValid = this.data.length > 0;
  }

  async getDataAndFillForm() {
    this.loading = true;
    try {
      const { data } = await this.api.get<ApiResponse<EconomicGroupResponse[]>>(
        {
          route: 'api/registration/economic_group/',
          token: true,
        }
      );

      this.data = data;

      this.showForm = this.data.length === 0;

      this.signupService.setCompanyFormStatus('complementaryEconomic', true);
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
    this.loading = false;

    this.isDataValid = this.data.length > 0;
  }

  async sendData() {
    this.sending = true;
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        cnpj: Utils.onlyNumbers(valuesHelper.get('cnpj')),
        corporate_name: valuesHelper.get('corporate_name'),
      };

      const findedEconomic = this.data.find(
        (item) => item.cnpj === payload.cnpj
      );

      if (findedEconomic && !this.selectedIndex === null) {
        this.toast.show('error', 'Erro', 'Grupo econômico já cadastrado');
        this.form.reset();
        return;
      }

      console.table(valuesHelper.getRemainingObject());

      const response =
        this.selectedIndex !== null
          ? await this.api.patch<ApiResponse<EconomicGroupResponse>>({
              route: `api/registration/economic_group/${
                this.data[this.selectedIndex].id
              }/`,
              token: true,
              body: payload,
            })
          : await this.api.post<ApiResponse<EconomicGroupResponse>>({
              route: 'api/registration/economic_group/',
              token: true,
              body: payload,
            });

      this.toast.show('info', 'Sucesso', response.message);

      this.form.reset();

      this.getDataAndFillForm();
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.sending = false;
  }

  formatCnpj(value: string) {
    const newValue = this.maskPipe.transform(value, '00.000.000/0000-00');

    return newValue;
  }
}
