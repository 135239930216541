<form [formGroup]="form">
    <div class="row">
        <div class="col-12 col-lg-3">
            <app-input label="Benchmark" [group]="form" controlName="benchmark"
                [maxLength]="30">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-select [items]="fundService.choices.quota_type_choices"
                label="Tipo de Cota" [group]="form" controlName="quota_types">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-select [items]="fundService.choices.quota_offer_type_choices"
                label="Tipo de Oferta de Cotas" [group]="form"
                controlName="quota_offer_type">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-6 col-lg-3">
            <app-currency-input [group]="form" controlName="initial_quota"
                label="Valor da cota inicial" placeholder="R$ 1.000,00">
            </app-currency-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <div class="d-flex align-items-center">
                <app-label label="Série de Cotas">
                </app-label>
            </div>
            <app-spacer [bottom]="8"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-select label="Ordem"
                [items]="fundService.choices.quotas_classes_order_choices"
                [group]="serieForm" controlName="serie_order"
                placeholder="Selecione">
            </app-select>
            <app-spacer [bottom]="8"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-select label="Classe"
                [items]="fundService.choices.quotas_classes_choices"
                [group]="serieForm" controlName="serie_class"
                placeholder="Selecione">
            </app-select>
            <app-spacer [bottom]="8"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-masked-input label="Quantidade" [group]="serieForm"
                controlName="serie_quantity" placeholder="Digite" mask="0*"
                [max]="5">
            </app-masked-input>
            <app-spacer [bottom]="8"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-currency-input label="Valor" [group]="serieForm"
                controlName="serie_amount" placeholder="R$ 1.000,00">
            </app-currency-input>
            <app-spacer [bottom]="8"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
          <app-masked-input [group]="serieForm" [readOnly]="isIndefinite"
              controlName="quota_initial_date" mask="00/00/0000"
              label="Data inicial" placeholder="dd/mm/aaaa">
          </app-masked-input>
          <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-masked-input [group]="serieForm" [readOnly]="isIndefinite"
                controlName="quota_final_date" mask="00/00/0000"
                label="Data final" placeholder="dd/mm/aaaa">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-5">
            <app-spacer [bottom]="36"></app-spacer>
            <app-radio [multiple]="true"
                (onCheckChanged)="setUndeterminatedDate($event)">
                Data indeterminada
            </app-radio>
        </div>


        <div class="col-12">
            <app-button [inverted]="true" (onClick)="addSerie()"
                [disabled]="getSeriesFormValid()">
                Adicionar Cota
            </app-button>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <ng-container *ngIf="series.length > 0">
            <div class="col-12 d-flex flex-row justify-content-between">
                <table>
                    <thead>
                        <th>Ordem</th>
                        <th>Classe</th>
                        <th>Quantidade</th>
                        <th>Valor</th>
                        <th>Data inicial</th>
                        <th>Data final</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let serie of series; let i = index">
                            <td>{{serie.serie_order.label}}</td>
                            <td>{{serie.serie_class.label}}</td>
                            <td>{{serie.serie_quantity}}</td>
                            <td>{{serie.serie_amount | currency:'BRL'}}</td>
                            <td>{{serie.quota_initial_date ?? 'Indeterminada'}}
                            <td>{{serie.quota_final_date ?? 'Indeterminada'}}
                            </td>
                            <td>
                                <div class="dash-card-item-buttons">
                                    <button class="dash-item-button"
                                        (click)="removeSerie(i)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </ng-container>

        <div class="col-12">
            <div class="d-flex align-items-center">
                <app-label label="Limite mínimo para movimentação">
                </app-label>
            </div>
            <app-spacer [bottom]="8"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-currency-input [group]="form"
                controlName="min_initial_application" label="Aplicação inicial"
                placeholder="R$ 1.000,00">
            </app-currency-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-currency-input [group]="form"
                controlName="min_additional_application"
                label="Aplicação adicional" placeholder="R$ 1.000,00">
            </app-currency-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-currency-input [group]="form" controlName="min_redemption"
                label="Resgate" placeholder="R$ 1.000,00">
            </app-currency-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-currency-input [group]="form" controlName="min_permanence"
                label="Permanência" placeholder="R$ 1.000,00">
            </app-currency-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <div class="d-flex align-items-center">
                <app-label label="Classificação tributária">
                </app-label>
            </div>
            <app-spacer [bottom]="8"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-select label="Prazo"
                [items]="fundService.choices.tax_classification_deadline_choices"
                [group]="form" placeholder="selecione"
                controlName="tax_classification_deadline">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-select label="Tipo"
                [items]="fundService.choices.tax_classification_type_choices"
                [group]="form" placeholder="selecione"
                controlName="tax_classification_type">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-select label="Forma de tributação"
                [items]="fundService.choices.tax_classification_tax_choices"
                [group]="form" placeholder="selecione"
                controlName="tax_classification_tax">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <div class="d-flex align-items-center">
                <app-label label="Aplicação">
                </app-label>
            </div>
            <app-spacer [bottom]="8"></app-spacer>
        </div>

        <div class="col-12  col-lg-3">
            <app-masked-input controlName="aplication_coversion"
                label="Conversão em dias" [group]="form" mask="0*" [max]="3">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-2">
            <app-select
                [items]="fundService.choices.amortization_grace_period_choices"
                label="Medição" placeholder="Selecione" [group]="form"
                controlName="aplication_coversion_unit">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>

        </div>

        <div class="col-12  col-lg-3">
            <app-masked-input controlName="aplication_sale_off"
                label="Liquidação em dias" [group]="form" mask="0*" [max]="3">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-2">
            <app-select
                [items]="fundService.choices.amortization_grace_period_choices"
                label="Medição" placeholder="Selecione" [group]="form"
                controlName="aplication_sale_off_unit">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <div class="d-flex align-items-center">
                <app-label label="Resgate">
                </app-label>
            </div>
            <app-spacer [bottom]="8"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-masked-input controlName="rescue_coversion"
                label="Conversão em dias" [group]="form" mask="0*" [max]="3">
            </app-masked-input>
            <app-spacer [bottom]="48"></app-spacer>
        </div>

        <div class="col-12 col-lg-2">
            <app-select
                [items]="fundService.choices.amortization_grace_period_choices"
                label="Medição" placeholder="Selecione" [group]="form"
                controlName="rescue_coversion_unit">
            </app-select>
            <app-spacer [bottom]="48"></app-spacer>
        </div>


        <div class="col-12 col-lg-3">
            <app-masked-input controlName="rescue_sale_off"
                label="Liquidação em dias" [group]="form" mask="0*" [max]="3">
            </app-masked-input>

            <app-spacer [bottom]="48"></app-spacer>
        </div>

        <div class="col-12 col-lg-2">
            <app-select
                [items]="fundService.choices.amortization_grace_period_choices"
                label="Medição" placeholder="Selecione" [group]="form"
                controlName="rescue_sale_off_unit"></app-select>
            <app-spacer [bottom]="48"></app-spacer>
        </div>

        <div class="col-12  col-lg-3">
            <app-select
                [items]="fundService.choices.amortization_frequency_choices"
                label="Periodicidade da Amortização" [group]="form"
                controlName="amortization_frequency">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12  col-lg-3">

            <app-masked-input [group]="form" label="Carência para Amortização"
                controlName="amortization_days" placeholder="Digite" mask="0*"
                [max]="3">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-2">
            <app-select
                [items]="fundService.choices.amortization_grace_period_choices"
                label="Medição" placeholder="Selecione" [group]="form"
                controlName="amortization_grace_period"></app-select>
            <app-spacer [bottom]="48"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-select
                [items]="fundService.choices.amortization_calculated_choices"
                label="Amortização calculada sobre" [group]="form"
                controlName="amortization_calculated">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-input label="Classificação dos riscos" [group]="form"
                controlName="risk_classification">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-5">
            <app-input label="Informações adicionais sobre a amortização"
                [group]="form"
                controlName="additional_information_about_amortization">
            </app-input>
            <app-spacer [bottom]="48"></app-spacer>
        </div>

        <div class="col-12 col-lg-7">
            <app-input label="Descrição analítica dos direitos creditórios"
                [group]="form"
                controlName="analytical_description_of_credit_rights">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-5">
            <app-select label="Tipo de direito creditórios"
                [items]="creditory_rights_items" [group]="form"
                controlName="creditory_rights" placeholder="Selecione">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <app-radio [multiple]="true"
                formControlName="credit_rights_will_be_granted">
                <span class="flex-1">Direitos creditórios
                    registrados</span>
            </app-radio>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <app-radio [multiple]="true"
                formControlName="assignment_expectation_greater_than_400">
                <span class="flex-1">Cessão maior que
                    400 recebíveis por trimestre
                </span>
            </app-radio>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <app-radio [multiple]="true"
                formControlName="delivery_asset_quotas_issuance">
                Emissão de cotas com entrega de ativos
            </app-radio>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="offset-1 col-3"
            *ngIf="form.controls.delivery_asset_quotas_issuance.value">
            <app-masked-input mask="0*"
                label="Parâmetro utilizado para definição do valor justo dos ativos entregues na emissão de cotas"
                [group]="form" controlName="quota_fair_value">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>
</form>
