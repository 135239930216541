<ng-container *ngIf="loading">
    <div class="row">
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-8">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!loading">
    <form [formGroup]="form">

        <ng-container *ngIf="!showForm">
            <div class="row">
                <div class="col-12">
                    <app-table [columns]="columns" [data]="data"
                        (onRemove)="handleOpenRemoveModal($event)"></app-table>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="showForm">
            <div class="row">
                <div class="col-12 col-lg-8">
                    <app-select [items]="signupService.choices().banks"
                        [group]="form" controlName="bank" label="Banco">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>

            <div class="ng-container" *ngIf="form.controls.bank.value !== null">
                <div class="row">
                    <div class="col-12 col-lg-2">
                        <app-masked-input [group]="form" controlName="agency"
                            type="text" label="Agência" placeholder="1234"
                            mask="0*">
                        </app-masked-input>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-masked-input [group]="form"
                            controlName="checking_account" label="Conta"
                            type="text" placeholder="12345-6" mask="0*">
                        </app-masked-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-2">
                        <app-masked-input [group]="form"
                            controlName="checking_account_digit" label="Dígito"
                            type="text" placeholder="1" mask="999">
                        </app-masked-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-6">
                        <app-select [items]="accountTypes" [group]="form"
                            controlName="account_type" label="Tipo de conta"
                            placeholder="Selecione">
                        </app-select>
                        <app-spacer [bottom]="54"></app-spacer>
                    </div>
                </div>

                <div class="row">
                    <ng-container *ngIf="this.data.length > 0">
                        <div class="col-12">
                            <app-radio [multiple]="true"
                                formControlName="main_account">Conta principal
                            </app-radio>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </ng-container>

                    <div class="col-12" *ngIf="userRole === 'provider'">
                        <app-radio [multiple]="true"
                            formControlName="joint_account">
                            Esta conta é conjunta
                        </app-radio>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12">
                        <app-radio [multiple]="true"
                            formControlName="not_owner">
                            Não sou o titular desta conta
                        </app-radio>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <ng-container *ngIf="form.controls.not_owner.value">
                    <div class="row offset-1">
                        <div class="col-12 col-lg-4">
                            <app-masked-input [group]="form" controlName="cpf"
                                label="CPF do co-titular" mask="000.000.000-00"
                                placeholder="123.456.789-01"
                                [errorText]="cpfErrorMessage"></app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-6">
                            <app-masked-input [group]="form"
                                controlName="joint_holders_identification"
                                label="Identificação do co-titular"
                                placeholder="Bruna Mazzeo" mask="W*"
                                [usePatterns]="true">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>
                </ng-container>

                <app-spacer [bottom]="24"></app-spacer>

                <div class="row">
                    <div class="col-12">
                        <app-spacer [bottom]="8"></app-spacer>
                    </div>
                </div>
            </div>

        </ng-container>

        <app-side-modal [active]="addAnotherModal"
            title="Adicionar conta bancária" (onClose)="handleCloseModal()"
            paddingBottom="0">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <app-select [items]="signupService.choices().banks"
                            [group]="form" controlName="bank" label="Banco">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <div class="ng-container"
                    *ngIf="form.controls.bank.value !== null">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <app-masked-input [group]="form"
                                controlName="agency" type="text" label="Agência"
                                placeholder="1234" mask="0*">
                            </app-masked-input>


                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-6">
                            <app-masked-input [group]="form"
                                controlName="checking_account" label="Conta"
                                type="text" placeholder="12345" mask="0*">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-masked-input [group]="form"
                                controlName="checking_account_digit"
                                label="Dígito" type="text" placeholder="1"
                                mask="999">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <app-select [items]="accountTypes" [group]="form"
                                controlName="account_type" label="Tipo de conta"
                                placeholder="Selecione">
                            </app-select>
                            <app-spacer [bottom]="54"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <ng-container *ngIf="this.data.length > 0">
                            <div class="col-12">
                                <app-radio [multiple]="true"
                                    formControlName="main_account">Conta
                                    principal</app-radio>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </ng-container>

                        <div class="col-12">
                            <app-radio [multiple]="true"
                                formControlName="not_owner">Não sou o titular
                                desta conta
                            </app-radio>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <ng-container *ngIf="form.controls.not_owner.value">
                        <div class="row offset-1">
                            <div class="col-12 col-lg-6">
                                <app-masked-input [group]="form"
                                    controlName="cpf" label="CPF do co-titular"
                                    mask="000.000.000-00"
                                    placeholder="123.456.789-01">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-6">
                                <app-masked-input [group]="form"
                                    controlName="joint_holders_identification"
                                    label="Identificação do co-titular"
                                    placeholder="Bruna Mazzeo" mask="W*"
                                    [usePatterns]="true">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>
                    </ng-container>

                    <app-spacer [bottom]="24"></app-spacer>

                    <div class="row">
                        <div class="col-12">
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>
                    </div>
                </div>

                <div class="row button-sticky">
                    <div class="col-12 col-lg-6">
                        <app-button (onClick)="handleCloseModal()"
                            class="add-button" [outline]="true">
                            Cancelar
                        </app-button>
                    </div>

                    <div class="col-12 col-lg-6">
                        <div class="text-right">
                            <app-button (onClick)="triggerForm()"
                                [disabled]="getDisabled()" class="add-button">
                                Salvar conta bancária
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
        </app-side-modal>

        <app-warning-modal [active]="confirmMainAccountModal"
            (click)="handleRemoveCancel()">
            <h3>Esta é sua conta principal?
            </h3>

            <app-spacer [bottom]="24"></app-spacer>
            <p>Você ja possuí uma conta principal,
                deseja alterar mesmo assim?
            </p>
            <app-spacer [bottom]="56"></app-spacer>

            <app-button (onClick)="handleConfirm(false)" [center]="true"
                class="add-button">
                Cancelar
            </app-button>

            <app-spacer [bottom]="8"></app-spacer>

            <app-button (onClick)="handleConfirm(true)" class="add-button"
                [center]="true" [outline]="true">
                Sim, alterar!
            </app-button>
        </app-warning-modal>

        <app-warning-modal [active]="removeModal"
            (onClose)="handleRemoveCancel()">
            <h3>Você tem certeza?</h3>
            <app-spacer [bottom]="24"></app-spacer>
            <p>Você não poderá reverter essa ação!</p>
            <app-spacer [bottom]="56"></app-spacer>

            <app-button (onClick)="handleRemoveCancel()" [center]="true"
                class="add-button">
                Cancelar
            </app-button>

            <app-spacer [bottom]="8"></app-spacer>

            <app-button (onClick)="removeBank(selectedIndex)" class="add-button"
                [center]="true" [outline]="true">
                Sim, apague!
            </app-button>
        </app-warning-modal>


        <div class="row" *ngIf="form.controls.bank.value !== null || !showForm">
            <div class="col-12 col-lg-6">
                <ng-container *ngIf="data.length === 0">
                    <app-button (onClick)="triggerForm()" [outline]="true"
                        [disabled]="getDisabled()">
                        Salvar dados
                        <i class="fas fa-floppy-disk ml-2"></i>
                    </app-button>
                </ng-container>

                <ng-container *ngIf="data.length > 0">
                    <app-button (onClick)="triggerForm()"
                        [disabled]="getDisabled()" *ngIf="!showForm"
                        class="add-button" [outline]="true">
                        <i class="fas fa-plus-circle mr-2"></i>
                        Adicionar outra conta
                    </app-button>
                </ng-container>
            </div>
        </div>
    </form>
</ng-container>