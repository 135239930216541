import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import DDIOptions from 'src/app/data/ddi';
import Utils from 'src/app/helpers/utils';
import { FundService, FundValues } from 'src/app/services/fund/fund.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import UIDefaultTheme from 'src/themes';
import { distinctUntilChanged } from 'rxjs/operators';
import fakerbr from 'faker-br';

@Component({
  selector: 'app-dashboard-fund-approval-register-complementary',
  templateUrl:
    './dashboard-fund-approval-register-complementary.component.html',
  styleUrls: [
    './dashboard-fund-approval-register-complementary.component.scss',
  ],
})
export class DashboardFundApprovalRegisterComplementaryComponent
  implements OnInit
{
  DDIOptions = DDIOptions;

  form = this.formBuilder.group({
    benchmark: [null, [Validators.required]],
    quota_types: [null, [Validators.required]],
    quota_offer_type: [null, [Validators.required]],
    initial_quota: [null, [Validators.required, Utils.maxDigits(12)]],
    min_initial_application: [null, [Validators.required, Utils.maxDigits(12)]],
    min_additional_application: [
      null,
      [Validators.required, Utils.maxDigits(12)],
    ],
    min_redemption: [null, [Validators.required, Utils.maxDigits(12)]],
    min_permanence: [null, [Validators.required, Utils.maxDigits(12)]],
    tax_classification_deadline: [null, [Validators.required]],
    tax_classification_type: [null, [Validators.required]],
    tax_classification_tax: [null, [Validators.required]],
    aplication_coversion: [null, [Validators.required]],
    aplication_coversion_unit: [null, [Validators.required]],
    aplication_sale_off: [null, [Validators.required]],
    aplication_sale_off_unit: [null, [Validators.required]],
    rescue_coversion: [null, [Validators.required]],
    rescue_coversion_unit: [null, [Validators.required]],
    rescue_sale_off: [null, [Validators.required]],
    rescue_sale_off_unit: [null, [Validators.required]],
    amortization_frequency: [null, [Validators.required]],
    amortization_days: [null, [Validators.required]],
    amortization_calculated: [null, [Validators.required]],
    amortization_grace_period: ['1', [Validators.required]],
    risk_classification: [null, []],
    additional_information_about_amortization: [null, []],
    analytical_description_of_credit_rights: [null, []],
    creditory_rights: [null, []],
    credit_rights_will_be_granted: [null, []],
    assignment_expectation_greater_than_400: [null, []],
    delivery_asset_quotas_issuance: [null, []],
    quota_fair_value: [null, []],
  });

  serieForm = this.formBuilder.group({
    serie_order: [null, []],
    serie_class: [null, []],
    serie_quantity: [null, [Utils.maxDigits(12)]],
    serie_amount: [null, [Utils.maxDigits(12)]],
    quota_undetermined: [null, []],
    quota_final_date: [null, []],
  });

  creditory_rights_items = [
    { value: '0', label: 'Escriturais' },
    { value: '1', label: 'Físicos' },
    { value: '2', label: 'N/A' },
  ];

  fund: FundValues = undefined;

  choices: { [Key in any]: any } = {};

  series: any[] = [];

  showForm = true;

  UIDefaultTheme = UIDefaultTheme;
  isIndefinite = false;

  constructor(
    private formBuilder: FormBuilder,
    public fundService: FundService,
    public signupService: SignupService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((status) => {
      Utils.getErrors(this.form);
    });

    if (this.UIDefaultTheme.brand.simplifiedFund) {
      this.form.controls.benchmark.setValidators([]);
      this.form.controls.quota_types.setValidators([]);
      this.form.controls.quota_offer_type.setValidators([]);
      this.form.controls.tax_classification_deadline.setValidators([]);
      this.form.controls.tax_classification_type.setValidators([]);
      this.form.controls.tax_classification_tax.setValidators([]);
      this.form.controls.aplication_coversion.setValidators([]);
      this.form.controls.aplication_coversion_unit.setValidators([]);
      this.form.controls.aplication_sale_off.setValidators([]);
      this.form.controls.aplication_sale_off_unit.setValidators([]);
      this.form.controls.rescue_coversion.setValidators([]);
      this.form.controls.rescue_coversion_unit.setValidators([]);
      this.form.controls.rescue_sale_off.setValidators([]);
      this.form.controls.rescue_sale_off_unit.setValidators([]);
      this.form.controls.amortization_frequency.setValidators([]);
      this.form.controls.amortization_days.setValidators([]);
      this.form.controls.amortization_grace_period.setValidators([]);
      this.form.controls.amortization_calculated.setValidators([]);

      this.serieForm.controls.serie_order.setValidators([]);
      this.serieForm.controls.serie_class.setValidators([]);
      this.serieForm.controls.serie_quantity.setValidators([]);
      this.serieForm.controls.serie_amount.setValidators([]);

      this.form.updateValueAndValidity();
      this.serieForm.updateValueAndValidity();
    }

    this.choices = this.fundService.choices;

    this.setData(this.fundService.data);
    this.checkSeriesArr();

    this.form.controls.delivery_asset_quotas_issuance.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.form.controls.quota_fair_value.setValidators([
            Validators.required,
            Utils.maxDigits(10),
          ]);
        } else {
          this.form.controls.quota_fair_value.setValidators([]);
        }

        this.form.controls.quota_fair_value.updateValueAndValidity;
      }
    );

    this.form.valueChanges.subscribe((values: Partial<FundValues>) => {
      this.fundService.setFundFormData({
        ...this.fundService.data,
        ...values,
      });
    });

    this.form.statusChanges.subscribe((status) => {
      const isSeriesNecessary = this.UIDefaultTheme.brand.simplifiedFund
        ? true
        : this.series.length > 0;

      this.fundService.setFundFormStatus(
        'complementary',
        status === 'VALID' && isSeriesNecessary
      );
    });

    this.serieForm.controls.quota_final_date.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (!value) {
          this.serieForm.controls.quota_final_date.setValidators([]);
        } else {
          this.serieForm.controls.quota_final_date.setValidators([
            Validators.required,
            (control: FormControl) => Utils.validateMaxMinToday(control, false),
            Utils.validateMaxDate,
          ]);
        }
        this.serieForm.controls.quota_final_date.updateValueAndValidity();
      });

    this.setRandomData();
  }

  setRandomData() {
    this.signupService.fillFormEvent.subscribe(() => {
      this.form.patchValue({
        benchmark: fakerbr.random.words(1),
        quota_offer_type: String(fakerbr.random.number({ min: 1, max: 2 })),
        initial_quota: fakerbr.random.number({ min: 500000, max: 2000000 }),
        quota_types: String(fakerbr.random.number({ min: 1, max: 2 })),
        min_initial_application: fakerbr.random.number({
          min: 500000,
          max: 2000000,
        }),
        min_additional_application: fakerbr.random.number({
          min: 500000,
          max: 2000000,
        }),
        min_redemption: fakerbr.random.number({ min: 500000, max: 2000000 }),
        min_permanence: fakerbr.random.number({ min: 500000, max: 2000000 }),
        tax_classification_deadline: String(
          fakerbr.random.number({ min: 1, max: 2 })
        ),
        tax_classification_type: String(
          fakerbr.random.number({ min: 1, max: 5 })
        ),
        tax_classification_tax: String(
          fakerbr.random.number({ min: 1, max: 5 })
        ),
        aplication_coversion: fakerbr.random.number({ min: 1, max: 10 }),
        aplication_coversion_unit: String(
          fakerbr.random.number({ min: 1, max: 2 })
        ),
        aplication_sale_off: fakerbr.random.number({ min: 1, max: 10 }),
        aplication_sale_off_unit: String(
          fakerbr.random.number({ min: 1, max: 2 })
        ),
        rescue_coversion: fakerbr.random.number({ min: 1, max: 10 }),
        rescue_coversion_unit: String(
          fakerbr.random.number({ min: 1, max: 2 })
        ),
        rescue_sale_off: fakerbr.random.number({ min: 1, max: 10 }),
        rescue_sale_off_unit: String(fakerbr.random.number({ min: 1, max: 2 })),

        amortization_frequency: String(
          fakerbr.random.number({ min: 1, max: 5 })
        ),
        amortization_days: String(fakerbr.random.number({ min: 1, max: 30 })),
        amortization_grace_period: String(
          fakerbr.random.number({ min: 1, max: 2 })
        ),
        amortization_calculated: String(
          fakerbr.random.number({ min: 1, max: 3 })
        ),
        risk_classification: fakerbr.random.words(3),
        additional_information_about_amortization: fakerbr.random.words(3),
        analytical_description_of_credit_rights: fakerbr.random.words(3),
        creditory_rights: 1,
        credit_rights_will_be_granted: true,
        assignment_expectation_greater_than_400: true,
        delivery_asset_quotas_issuance: true,
        quota_fair_value: fakerbr.random.number({
          min: 500000,
          max: 2000000,
        }),
      });

      for (let i = 0; i < fakerbr.random.number({ min: 1, max: 5 }); i++) {
        const serieObject = {
          serie_order: fakerbr.random.number({ min: 1, max: 11 }),
          serie_class: fakerbr.random.number({ min: 1, max: 10 }),
          serie_quantity: fakerbr.random.number({ min: 1, max: 10 }) * 1000,
          serie_amount: fakerbr.random.number({ min: 1, max: 10 }) * 1000000,
        };

        this.serieForm.patchValue(serieObject);
        this.addSerie();
      }
    });
  }

  checkSeriesArr() {
    if (this.series.length === 0 && !this.UIDefaultTheme.brand.simplifiedFund) {
      this.serieForm.controls.serie_order.setValidators([Validators.required]);
      this.serieForm.controls.serie_class.setValidators([Validators.required]);
      this.serieForm.controls.serie_quantity.setValidators([
        Validators.required,
        Utils.maxDigits(12),
      ]);
      this.serieForm.controls.serie_amount.setValidators([
        Validators.required,
        Utils.maxDigits(12),
      ]);
    } else {
      this.serieForm.controls.serie_order.setValidators([]);
      this.serieForm.controls.serie_class.setValidators([]);
      this.serieForm.controls.serie_quantity.setValidators([
        Utils.maxDigits(12),
      ]);
      this.serieForm.controls.serie_amount.setValidators([Utils.maxDigits(12)]);
    }

    this.serieForm.controls.serie_order.updateValueAndValidity();
    this.serieForm.controls.serie_class.updateValueAndValidity();
    this.serieForm.controls.serie_quantity.updateValueAndValidity();
    this.serieForm.controls.serie_amount.updateValueAndValidity();
  }

  setUndeterminatedDate(value: boolean) {
    if (value) {
      this.serieForm.controls.quota_final_date.setValue(null);
      this.serieForm.controls.quota_final_date.setValidators([]);
    }

    this.isIndefinite = value;

    this.serieForm.controls.quota_final_date.updateValueAndValidity();
  }

  addSerie() {
    const formValues = this.serieForm.value;

    if (!this.getSeriesFormValid() && this.serieForm.status === 'VALID') {
      this.series.push({
        ...formValues,
        serie_class: {
          value: formValues.serie_class,
          label: this.choices.quotas_classes_choices.find(
            (item) => item.value === String(formValues.serie_class)
          ).label,
        },
        serie_order: {
          value: formValues.serie_order,
          label: this.choices.quotas_classes_order_choices.find(
            (item) => item.value === String(formValues.serie_order)
          ).label,
        },
      });

      this.serieForm.reset();
      this.checkSeriesArr();

      const obj = [
        {
          fund: this.fundService.data.id,
          quota_amount: formValues.serie_amount,
          quota_class: formValues.serie_class,
          quota_order: formValues.serie_order,
          quota_quantity: formValues.serie_quantity,
          quota_undetermined: formValues.quota_undetermined,
          quota_final_date: formValues.quota_final_date
            ? Utils.convertBRdateToUSdate(formValues.quota_final_date)
            : null,
        },
      ];

      const quota_series_arr =
        this.fundService.data && this.fundService.data.quota_series
          ? this.fundService.data.quota_series
          : [];

      this.fundService.setFundFormData({
        ...this.fundService?.data,
        quota_series: [...quota_series_arr, ...obj],
      });

      const isSeriesNecessary = this.UIDefaultTheme.brand.simplifiedFund
        ? true
        : this.series.length > 0;

      this.fundService.setFundFormStatus(
        'complementary',

        this.form.status === 'VALID' && isSeriesNecessary
      );
    }
  }

  removeSerie(index: number) {
    this.series.splice(index, 1);

    const arr = this.fundService.data.quota_series;

    const newArr = arr.filter((item, i) => i !== index);

    this.fundService.setFundFormData({
      ...this.fundService.data,
      quota_series: newArr,
    });

    const isSeriesNecessary = this.UIDefaultTheme.brand.simplifiedFund
      ? true
      : this.series.length > 0;

    this.fundService.setFundFormStatus(
      'complementary',

      this.form.status === 'VALID' && isSeriesNecessary
    );

    this.checkSeriesArr();
  }

  getSeriesFormValid() {
    const formValues = this.serieForm.value;

    const required_fields = [
      'serie_order',
      'serie_class',
      'serie_quantity',
      'serie_amount',
    ];

    if (!this.isIndefinite) {
      required_fields.push('quota_final_date');
    }

    const veryfiedValues = required_fields.some((key) => {
      return formValues[key] === null || formValues[key] === '';
    });

    /* const veryfiedValues = Object.keys(formValues).some((key) => {
      return formValues[key] === null || formValues[key] === '';
    }); */

    return veryfiedValues || this.serieForm.status !== 'VALID';
  }

  setData(values: FundValues) {
    this.fund = values;

    if (values) {
      this.form.patchValue({
        ...values,
        quota_types: values.quota_types ? String(values.quota_types) : null,
        quota_offer_type: values.quota_offer_type
          ? String(values.quota_offer_type)
          : null,
        tax_classification_deadline: values.tax_classification_deadline
          ? String(values.tax_classification_deadline)
          : null,
        tax_classification_type: values.tax_classification_type
          ? String(values.tax_classification_type)
          : null,
        tax_classification_tax: values.tax_classification_tax
          ? String(values.tax_classification_tax)
          : null,
        aplication_coversion_unit: values.aplication_coversion_unit
          ? String(values.aplication_coversion_unit)
          : null,
        aplication_sale_off_unit: values.aplication_sale_off_unit
          ? String(values.aplication_sale_off_unit)
          : null,
        rescue_coversion_unit: values.rescue_coversion_unit
          ? String(values.rescue_coversion_unit)
          : null,
        rescue_sale_off_unit: values.rescue_sale_off_unit
          ? String(values.rescue_sale_off_unit)
          : null,
        amortization_frequency: values.amortization_frequency
          ? String(values.amortization_frequency)
          : null,
        amortization_grace_period: String(values.amortization_grace_period),
        amortization_calculated: values.amortization_calculated
          ? String(values.amortization_calculated)
          : null,
      });

      this.series = values.quota_series.map((serie: any) => {
        const obj = {
          serie_order: this.choices.quotas_classes_order_choices.find(
            ({ value }: any) => serie.quota_order == value
          ),
          serie_class: this.choices.quotas_classes_choices.find(
            ({ value }: any) => serie.quota_class == value
          ),
          serie_quantity: serie.quota_quantity,
          serie_amount: serie.quota_amount,
          quota_undetermined: serie.quota_undetermined,
          quota_final_date: serie.quota_final_date,
        };

        return obj;
      });

      this.showForm = this.series.length === 0;
    }

    const isSeriesNecessary = this.UIDefaultTheme.brand.simplifiedFund
      ? true
      : this.series.length > 0;

    this.fundService.setFundFormStatus(
      'complementary',
      this.form.status === 'VALID' && isSeriesNecessary
    );
  }
}
