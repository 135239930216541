import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-button-with-dropdown',
  templateUrl: './button-with-dropdown.component.html',
  styleUrls: ['./button-with-dropdown.component.scss'],
})
export class ButtonWithDropdownComponent implements OnInit {
  @ViewChild('content') content: any;

  @Input() label: string = 'click';
  @Input() showOptions: boolean = false;
  @Input() outline: boolean = false;
  @Input() haveIcon: boolean = false;

  @Output() onClick = new EventEmitter<MouseEvent>();
  @Output() toggleShowOptions = new EventEmitter<MouseEvent>();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  click(event: MouseEvent) {
    this.onClick.emit(event);
  }

  handleShowOptions(event) {
    this.toggleShowOptions.emit(event);
  }
}
