import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-shareholders',
  templateUrl: './dashboard-shareholders.component.html',
  styleUrls: ['./dashboard-shareholders.component.scss'],
})
export class DashboardShareholdersComponent implements OnInit {
  shareholders: any[] = [];
  filteredShareholders: any[] = [];
  shareholders_statistics = {
    initialized: 0,
    completed: 0,
    total: 0,
  };

  loading = false;

  constructor(private api: ApiService, private toast: ToastService) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.loading = true;
    await Promise.all([this.getUsers(), this.getStatistics()]);
    this.loading = false;
  }

  setFilteredData(data: any[]) {
    this.filteredShareholders = data;
  }

  async getStatistics() {
    try {
      const res = await this.api.get({
        route: 'api/approvals/user/pending?role=shareholder&all=true',
        token: true,
      });

      const shareholders: any[] = [];

      res.forEach((requested: any) => {
        const newRequested = { ...requested };

        const roleSlug = newRequested.user.role.slug;

        if (roleSlug.includes('SHAREHOLDER')) {
          shareholders.push(newRequested);
        }
      });

      this.setShareholders(shareholders);
    } catch (error) {
      console.error(error);
    }
  }

  async getUsers() {
    try {
      const res = await this.api.get({
        route: 'api/approvals/user/pending?role=shareholder',
        token: true,
      });

      const shareholders: any[] = [];

      res.forEach((requested: any) => {
        const newRequested = { ...requested };
        newRequested.departments.sort((a: any, b: any) => {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });

        newRequested['department'] = newRequested.departments.find(
          (department: any) => department.order === newRequested.step
        );

        const roleSlug = newRequested.user.role.slug;

        if (roleSlug.includes('SHAREHOLDER')) {
          shareholders.push(newRequested);
        }
      });

      const filteredAssignors = shareholders.filter(
        (assignor: any) => !(assignor.completed && !assignor.is_approved)
      );

      this.shareholders = filteredAssignors;
      this.filteredShareholders = filteredAssignors.slice(0, 10);
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  setShareholders(data: any[]) {
    const newStatistics = {
      initialized: 0,
      completed: 0,
      total: 0,
    };

    data.forEach((assignor: any) => {
      newStatistics['total'] += 1;
      if (assignor.completed) {
        newStatistics['completed'] += 1;
      } else {
        newStatistics['initialized'] += 1;
      }
    });

    this.shareholders_statistics = newStatistics;

    this.shareholders = data;
  }

  getInitials(user: any) {
    const full_name = user.first_name + ' ' + user.last_name;

    return full_name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }
}
