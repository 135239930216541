import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

type PartnerCards = {
  parent: Partner;
  childrens: Partner[];
};
@Component({
  selector: 'app-dashboard-approval-composition',
  templateUrl: './dashboard-approval-composition.component.html',
  styleUrls: ['./dashboard-approval-composition.component.scss'],
})
export class DashboardApprovalCompositionComponent implements OnInit {
  @Input() approval: UserApprovalResponse;
  @ViewChild('modalRoot') modalRoot;

  columns: TableColumnList<Partner>[] = [
    {
      name: 'Nome',
      cell: (row) => `
      ${
        row.pep
          ? `<span class="dash-details-card-item-badged-list">Declaro PEP</span>`
          : ''
      }
      <span>${row.full_name} </span>
      <span class="app-table-details-body-row-subtitle">
      ${row.document ?? ''}
      </span>
    `,
      size: 2,
    },
    {
      name: 'Relacionamento',
      cell: (row) => `
        <span>${row.pep_relationship_name ?? ''}</span>
        <span class="app-table-details-body-row-subtitle">
        ${row.pep_relationship_document ?? ''}
        </span>
      `,
      size: 2,
    },
    {
      name: '% Participação',
      cell: (row) => `${row.percentage}%`,
    },
    {
      name: '',
      cell: (row) =>
        `<div class="icon-container">
              <div class="representative-details row-${row.id}">
                  <i class="fas fa-eye ml-auto"></i>
              </div>
          </div> `,
    },
  ];
  data: PartnerCards[] = [];

  compositionDetails = false;
  selectedPartner: Partner = null;

  showModal = false;
  file: Files = null;

  isPDF = false;
  url: any;
  isXLS = false;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.setData();
  }

  setData() {
    const partnersList = this.filterPartners(this.approval.register.partners);

    const firstCard = partnersList.filter(
      (partner: Partner) => partner.parent === null
    );

    const parents = partnersList.filter(
      (partner: Partner) => partner.document.length === 18
    );

    const childrens = partnersList.filter(
      (partner: Partner) => partner.parent !== null
    );

    const parentCards = parents.map((parent: Partner) => {
      const childrensList = childrens.filter(
        (children: Partner) => children.parent.id === parent.id
      );

      return {
        parent,
        childrens: [...childrensList],
      };
    });

    const data = [
      {
        parent: {
          full_name: this.approval.register.company.corporate_name,
        },
        childrens: [...firstCard],
      },
      ...parentCards,
    ];

    this.data = data;
  }

  getShareholdersPercentage(item: PartnerCards) {
    const percentage = item.childrens.reduce(
      (acc, curr) => acc + curr.percentage,
      0
    );

    return percentage;
  }

  filterPartners(partners) {
    return partners.filter((partner: Partner) => partner.document);
  }

  closeModal() {
    this.compositionDetails = false;
  }

  openModal(item: Partner) {
    this.compositionDetails = true;
    this.selectedPartner = item;
  }

  handleSelect(idx: any) {
    this.file = this.selectedPartner.files[idx];
    this.getImgUrl();
  }

  getImgUrl() {
    const regex = /\.([0-9a-z]+)(?:[\?#]|$)/i;

    const resultado = regex.exec(this.file.file);

    if (resultado && resultado[1]) {
      const extension = resultado[1].toLowerCase();

      switch (extension) {
        case 'pdf':
          this.isPDF = true;
          break;
        case 'xls':
        case 'xlsx':
          this.isXLS = true;
          break;
        default:
          this.isPDF = false;
          this.isXLS = false;
          break;
      }
    }

    let url: any = this.file.file;

    if (this.isPDF) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(this.file.file);
    }

    if (this.isXLS) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${this.file.file}`
      );
    }

    this.url = url;

    this.modalRoot.show();
  }
}
