import { Injectable, EventEmitter } from '@angular/core';

export type SerieValues = {
  fund: number;
  id?: number;
  quota_amount: string;
  quota_class: number;
  quota_order: number;
  quota_quantity: number;
  quota_undetermined: boolean;
  quota_final_date: string;
};

export type FundValues = {
  additional_information_about_amortization: string;
  administration_fee: string;
  agent_fee: string;
  amortization_calculated: number;
  amortization_frequency: number;
  amortization_days: number;
  amortization_grace_period: number;
  analytical_description_of_credit_rights: string;
  anbima_classification: number;
  anbima_classification_label: string;
  aplication_coversion: number;
  aplication_coversion_unit: number;
  aplication_sale_off: number;
  aplication_sale_off_unit: number;
  assembly_date: string;
  assignment_expectation_greater_than_400: boolean;
  benchmark: string;
  bookkeeping_fee: string;
  cbm_constitution_date: string;
  certifier: string;
  change_date: string;
  charge_type: number;
  check_fee: string;
  client_service: {
    profile: number;
    internal_account: boolean;
    internal_account_number: string;
    checking_account: boolean;
    checking_account_bank: string;
    checking_account_number: string;
    checking_account_agency: string;
    charge_account: boolean;
    charge_account_bank: string;
    charge_account_number: string;
    charge_account_contract_number: string;
    charge_account_agency: string;
    audit: string;
    custody: boolean;
    scrow_account: boolean;
    scrow_account_bank: string;
    cblc: boolean;
    cblc_contract_number: string;
    cetip: boolean;
    cetip_contract_number: string;
    selic: boolean;
    selic_contract_number: string;
    guaranteed_cblc: boolean;
    guaranteed_cblc_contract_number: string;
    guaranteed_bmf: boolean;
    guaranteed_bmf_contract_number: string;
    outsourced_company: boolean;
    outsourced_company_name: string;
    zeroing_process: string;
    zeroing_bank: string;
    zeroing_agency: string;
    zeroing_account: string;
  };
  committed_zeroing: boolean;
  company: null;
  condominium_type: number;
  consultancy_fee: string;
  contact_name: string;
  controllership_fee: string;
  created: string;
  credit_rights_will_be_granted: boolean;
  creditory_rights: string;
  custody_fee: string;
  cvm_classification: number;
  delivery_asset_quotas_issuance: boolean;
  distribution_fee: null;
  document: string;
  giin: string;
  duration_period: string;
  email: string;
  entry_tax: number;
  event: number;
  external_id: number;
  fantasy_name: string;
  financial_assets: number;
  first_passport_date: string;
  form_filled_by: string;
  fund_builder: string;
  fund_social_exercise: number;
  general_fee: null;
  hiring_certifier: boolean;
  id: number;
  implantation_fee: string;
  initial_quota: string;
  is_simplified: boolean;
  last_company_cellphone_ddi: string;
  last_company_cellphone_number: string;
  last_company_contact: string;
  last_company_document: string;
  last_company_email: string;
  last_company_name: string;
  last_company_telephone_ddi: string;
  last_company_telephone_number: string;
  management_fee: string;
  manager_email: null;
  manager_name: null;
  manager_phone: null;
  min_additional_application: string;
  min_initial_application: string;
  min_permanence: string;
  min_redemption: string;
  name: string;
  other_tax: boolean;
  other_tax_description: string;
  out_tax: number;
  performance_tax: number;
  quota_fair_value: string;
  quota_offer_type: number;
  quota_series: SerieValues[];
  quota_types: number;
  rescue_coversion: number;
  rescue_coversion_unit: number;
  rescue_sale_off: number;
  rescue_sale_off_unit: number;
  risk_classification: string;
  service_providers: any[];
  short_name: null;
  slug: string;
  structuring_fee: string;
  structuring_fee_date: string;
  target_audience: number;
  tax_classification_deadline: number;
  tax_classification_tax: number;
  tax_classification_type: number;
  telephone_ddi: string;
  telephone_number: string;
  total_fee: string;
  transfer: boolean;
  transfer_date: string;
  type: number;
  updated: string;
  zeroing_automatic: {
    account: number;
    agency: number;
    bank: number;
    id?: number;
    zeroing_fund: number;
  };
  zeroing_account: {
    agency: number;
    bank: number;
    cetip: boolean;
    checking_account: number;
    email: string;
    favored: string;
    favored_checking_account: number;
    favored_cnpj: string;
    fund_bank: number;
    fund_cnpj: string;
    fund_contact_name: string;
    ted: boolean;
  };
  zeroing_committed: boolean;
  zeroing_manager: {
    email: string;
    name: string;
    phone: string;
  };
  links: any[];
  zeroing_phone: string;
  zeroing_process: boolean;
  files: any[];
};

type FundForm = {
  basic: boolean;
  complementary: boolean;
  tax: boolean;
  banking: boolean;
  providers: boolean;
};

type FundFormKeys = keyof FundForm;

@Injectable({
  providedIn: 'root',
})
export class FundService {
  data: FundValues | null = null;
  fundList: SelectItem[] = [];
  signatures: UserApprovalSignature[] = [];

  onChange = new EventEmitter();

  fetchData = new EventEmitter();

  choices: { [Key in any]: any } = {};

  fundFormValid: FundForm = {
    basic: false,
    complementary: false,
    tax: false,
    banking: false,
    providers: false,
  };

  formStatusChangeEvent = new EventEmitter();

  constructor() {}

  setFundFormData(data: Partial<FundValues>): void {
    this.data = {
      ...this.data,
      ...data,
    };

    this.onChange.emit();
  }

  getFundFormData() {
    return this.data;
  }

  setFundFormStatus(key: FundFormKeys, status: boolean): void {
    if (this.fundFormValid[key] !== status) {
      this.fundFormValid[key] = status;
      this.formStatusChangeEvent.emit();
    }
  }

  getFundFormStatus(key: FundFormKeys): any {
    return this.fundFormValid[key];
  }

  reset() {
    this.data = null;
    this.signatures = [];
    this.fundList = [];
    this.choices = {};
    this.fundFormValid = {
      basic: false,
      complementary: false,
      tax: false,
      banking: false,
      providers: false,
    };
  }
}
